import { Button } from "@shopify/polaris";
import React, { useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import { ColumnHeaderWrapper, ColumnHeader, FortnoxRowsWrapper } from "../styles";
import FortnoxOfferRow from "./FortnoxOfferRow";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import getTotalStats from "../Utilities/getTotalStats";
import { numberFormatter } from "src/js/Utilities";
import Colors from "src/js/Colors";
import InfoView from "src/js/components/InfoView";

const FortnoxOfferRows = ({ t, offer, onChange, isSaving }) => {
	const handleChangeForm = (index: number, row: FortnoxOfferRowType | null) => {
		const rows = [...(offer.OfferRows || [])];
		if (!row) {
			rows.splice(index, 1);
		} else {
			rows[index] = row;
		}

		onChange("OfferRows")(rows);
	};

	const totalStats = useMemo(() => {
		const stats = getTotalStats(offer);

		const valueStyle = {
			textAlign: "end",
		};

		const formatter = (value) => {
			return numberFormatter({
				value: value || 0,
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) as unknown as number;
		};

		return [
			{
				label: t("fortnox.offer.fields.total.label", "Totalt att betala"),
				value: formatter(stats.totalToPay),
				valueStyle,
				style: { fontWeight: "bold", color: Colors.green },
			},
			{
				label: t("fortnox.offer.fields.total_exl_vat.label", "Totalt exkl. moms"),
				value: formatter(stats.totalExclVat),
				valueStyle,
			},
			{
				label: t("fortnox.offer.fields.vat.label", "moms"),
				value: formatter(stats.vat),
				valueStyle,
			},
			{
				label: t("fortnox.offer.fields.roundoff.label", "Öresavrundning"),
				value: formatter(stats.roundoff),
				valueStyle,
			},
		];
	}, [offer, t]);

	const disabled = !!offer?.OrderReference || isSaving || offer?.Cancelled;

	const onDragEnd = useCallback(
		(result) => {
			const { destination, source, draggableId } = result;
			if (!destination || !draggableId) return;

			const reorderedRows = Array.from(offer?.OfferRows);
			const [removed] = reorderedRows.splice(source.index, 1);
			reorderedRows.splice(destination.index, 0, removed);
			onChange("OfferRows")(reorderedRows);
		},
		[onChange, offer?.OfferRows]
	);
	const rows = offer?.OfferRows || [];

	const columns = [
		{
			header: t("fortnox.row.fields.ArticleNumber.label", "Artikelnr"),
			handle: "ArticleNumber",
		},
		{
			header: t("fortnox.row.fields.Description.label", "Benämning"),
			handle: "Description",
		},
		{
			header: t("fortnox.row.fields.Quantity.label", "Antal"),
			handle: "Quantity",
		},
		{
			header: t("fortnox.row.fields.Unit.label", "Enhet"),
			handle: "Unit",
		},
		{
			header: t("fortnox.row.fields.Price.label", "À-Pris"),
			handle: "Price",
		},
		{
			header: t("fortnox.row.fields.Discount.label", "Rabatt"),
			handle: "Discount",
		},
		{
			header: t("fortnox.row.fields.VAT.label", "Moms"),
			handle: "VAT",
		},
		{
			header: t("fortnox.row.fields.sum.label", "Summa"),
			handle: "sum",
		},
	] as { header: string; handle: string }[];

	return (
		<FortnoxRowsWrapper>
			<ColumnHeaderWrapper>
				{columns.map((column) => (
					<ColumnHeader key={column.handle}>{column.header}</ColumnHeader>
				))}
			</ColumnHeaderWrapper>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={`Offer_${offer.DocumentNumber}_rows`}>
					{(droppableProvided, snapshot) => {
						return (
							<div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
								{rows?.map((row: FortnoxOfferRowType, index: number) => {
									// const key = JSON.stringify({ ...row, index });
									const key = index;
									return (
										<Draggable draggableId={String(index)} index={index} key={key}>
											{(provided, snapshot) => {
												return (
													<div key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="row">
														<FortnoxOfferRow
															columns={columns}
															disabled={disabled}
															offer={offer}
															key={key}
															row={row}
															index={index}
															onChange={handleChangeForm}
														/>
													</div>
												);
											}}
										</Draggable>
									);
								})}
								{droppableProvided.placeholder}
							</div>
						);
					}}
				</Droppable>
			</DragDropContext>

			{!disabled && (
				<FortnoxOfferRow
					columns={columns}
					className="row"
					key={rows?.length}
					offer={offer}
					row={null}
					index={rows?.length}
					onChange={handleChangeForm}
				/>
			)}
			<br />
			<Button
				onClick={() => {
					handleChangeForm(rows.length, {
						ArticleNumber: "",
						Description: "",
						Quantity: 0,
						Unit: "",
						Price: 0,
						Discount: 0,
						sum: 0,
					} as FortnoxOfferRowType);
				}}
				primary
				icon={CirclePlusMajor}
			/>

			<InfoView style={{ display: "flex", justifyContent: "end" }} details={totalStats} />
		</FortnoxRowsWrapper>
	);
};
export default withTranslation(["fortnox", "common"])(FortnoxOfferRows);
