import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";
import BoardRowSelectModal from "src/js/components/BoardRowSelectModal";
import moment from "moment";
import SendAsEmailModal from "../SendAsEmailModal";
import useQuery from "src/js/hooks/useQuery";
import getFilledCustomerFields from "../Utilities/getFilledCustomerFields";
import getFilledDeliveryFields from "../Utilities/getFilledDeliveryFields";
import { formatRowToRead, formatRowToWrite } from "../Utilities/formatRows";

type SetFormFunction = (form: Partial<FortnoxInvoiceType> | null | ((prevForm: Partial<FortnoxInvoiceType>) => Partial<FortnoxInvoiceType>)) => void;

type FortnoxInvoiceContextValue = {
	invoice: FortnoxInvoiceType | null;
	form: Partial<FortnoxInvoiceType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	pdf: any;
	isFetchingPDF: boolean;
	handleSave: (invoice: FortnoxInvoiceType, row?: BoardRowType) => any;
	contact: ContactType | null;
	customer: FortnoxCustomerType | null;
	row: BoardRowType | null;
	handleOpenRowSelectModal: () => void;
	handleSendToScrive: () => void;
	upload?: any;
	boardRowValue?: any;
	handleCancelInvoice: () => void;
	isSaving: boolean;
	handleCreditInvoice: () => void;
	handleSetAsSentInvoice: () => void;
	handleBookkeepInvoice: () => void;
	handleSendInvoiceAsEmailPre: (opt?: any) => void;
	handleSendInvoiceAsEInvoice: () => void;
	handleCreatePdfUpload: (order: FortnoxInvoiceType, pdfData: any) => any;
	pdfUploads: any;
	isFetchingPDFUploads: boolean;
} & WithTranslation;

const FortnoxInvoiceContext = React.createContext({} as FortnoxInvoiceContextValue);

export const FortnoxInvoiceContextProvider = withTranslation(["fortnox", "common"])(
	({ id: propsId, match, history, children, t, defaultData = null }) => {
		const [form, setForm] = useState<Partial<FortnoxInvoiceType> | null>(defaultData);
		const id = propsId || match.params.id;
		const contactId = match.params.contactId;
		const [row, setRow] = useState<BoardRowType | null>(null);
		const [upload, setUpload] = useState<any | null>(null);
		const [boardRowValue, setBoardRowValue] = useState<any | null>(null);
		const [rowModalIsOpen, setRowModalIsOpen] = useState<boolean | "submit">(false);
		const [isSaving, setIsSaving] = useState(false);
		const [emailModalOptions, setEmailModalOptions] = useState<any>(null);
		const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);

		const queryClient = useQueryClient();

		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/invoices/${id}.json`);
				return res.data;
			} catch (error) {
				toastr.error(error);
				return null;
			}
		}, [id]);
		const queryKey = [id && `fortnox_invoice_${id}`].filter(Boolean);
		const enabled = !!(id && queryKey?.length);

		const { data: invoice = null, isFetching }: UseQueryResult<FortnoxInvoiceType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: (enabled && history.location.state?.data) || null,
			enabled,
		});

		// PDF
		const fetchPDF = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/invoices/${id}/download.pdf`);

				return res.data;
			} catch (error) {
				toastr.error(error);
			}
		}, [id]);
		const {
			data: pdf = null,
			isFetching: isFetchingPDF,
			refetch: refreshPDF,
		}: UseQueryResult<any> = useQuery({
			queryKey: [id && `fortnox_invoice-${id}_pdf`].filter(Boolean),
			queryFn: fetchPDF,
			refetchOnWindowFocus: false,
			enabled: !!(invoice?.DocumentNumber && id),
		});

		//SELLFINITY PDF
		const fetchSellfinityPDFs = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/invoices/${id}/uploads.json`);

				return res.data;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [id]);

		const pdfUploadsQueryKey = [id && `fortnox_invoice-${id}_pdf_uploads`].filter(Boolean);

		const {
			data: pdfUploads = null,
			isFetching: isFetchingPDFUploads,
			refetch: refreshPDFUploads,
		}: UseQueryResult<any> = useQuery({
			queryKey: pdfUploadsQueryKey,
			queryFn: fetchSellfinityPDFs,
			refetchOnWindowFocus: false,
			enabled: !!(invoice?.DocumentNumber && id),
		});

		const createCRMPdfUpload = useCallback(
			async (invoice, pdfData = {}) => {
				const invoiceId = invoice.DocumentNumber || id;
				if (invoiceId) invoice.DocumentNumber = invoiceId;

				const { pages, template, variables, name = `Invoice ${invoice.DocumentNumber}` } = pdfData;

				const res = await API.post(
					`/api/fortnox/invoices/${invoiceId}/pdf_template/${template.id}.json`,
					{
						pages: pages.map((page) => page.data),
						name,
					},
					{
						params: variables,
					}
				);

				toastr.success(t("fortnox.responses.pdf_created", "PDF skapad"));

				return res;
			},
			[id, t]
		);

		const createPDF = async ({ invoice, pdfData = {} }) => {
			const response = await createCRMPdfUpload(invoice, pdfData);
			return response?.data.upload;
		};

		const mutationPdfUploads = useMutation(createPDF, {
			onSuccess: (data) => {
				refreshPDFUploads();
			},
		});

		const handleCreatePdfUpload = useCallback(
			async (invoice: FortnoxInvoiceType, pdfData = {}) => {
				try {
					if (!invoice) {
						throw new Error("no invoice");
					}

					return await mutationPdfUploads.mutateAsync({ invoice, pdfData });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				}
			},
			[mutationPdfUploads]
		);

		//----------

		// Contact
		const fetchContact = useCallback(async () => {
			try {
				if (contactId) {
					const res = await API.get(`/api/contacts/${contactId}.json`);
					if (res.data.contact) queryClient.setQueryData([`contact_${res.data.contact.id}`], res.data.contact);
					return res.data.contact;
				} else if (invoice?.CustomerNumber) {
					const res = await API.get(`/api/fortnox/customer/${invoice?.CustomerNumber}/contact.json`);
					if (res.data.contact) queryClient.setQueryData([`contact_${res.data.contact.id}`], res.data.contact);
					return res.data.contact;
				}

				return null;
			} catch (error) {
				toastr.error(error);
			}
		}, [contactId, invoice?.CustomerNumber, queryClient]);
		const { data: contact = null }: UseQueryResult<ContactType | null> = useQuery({
			queryKey: [contactId ? `contact_${contactId}` : invoice?.CustomerNumber && `fortnox_customer_${invoice?.CustomerNumber}_contact`].filter(
				Boolean
			),
			queryFn: fetchContact,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.contact || null,
			enabled: !!(contactId || invoice?.CustomerNumber),
		});

		// custoemr
		const customerID = invoice?.CustomerNumber || contact?.fortnox_customer_id;
		const fetchCustomer = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/customer/${customerID}.json`);

				return res.data?.customer || null;
			} catch (error) {
				toastr.error(error);
				return null;
			}
		}, [customerID]);
		const { data: customer = null }: UseQueryResult<FortnoxCustomerType | null> = useQuery({
			queryKey: [customerID && `fortnox_customer_${customerID}`].filter(Boolean),
			queryFn: fetchCustomer,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.customer || null,
			enabled: !!customerID,
		});

		const handleFormatRowsToRead = useCallback((invoice: FortnoxInvoiceType) => {
			const rows = invoice.InvoiceRows?.map((row) => formatRowToRead(row));

			return {
				...invoice,
				InvoiceRows: rows,
			};
		}, []);

		const handleFormatRowsToWrite = useCallback((invoice: FortnoxInvoiceType) => {
			const rows = invoice.InvoiceRows?.map((row) => formatRowToWrite(row));

			return {
				...invoice,
				InvoiceRows: rows,
			};
		}, []);

		useEffect(() => {
			if (invoice)
				setForm(handleFormatRowsToRead({ ...invoice, ...(customer && !invoice?.DocumentNumber ? getFilledCustomerFields(customer, invoice) : {}) }));
		}, [invoice, customer, handleFormatRowsToRead]);

		useEffect(() => {
			if (!customer) return;
			setForm((c) => {
				if (c?.DocumentNumber) return c;
				return {
					...c,
					OurReference: customer?.OurReference,
					YourReference: customer?.YourReference,
					DueDate: moment(c?.InvoiceDate || null)
						.add(customer?.TermsOfPayment || 30, "days")
						.format("YYYY-MM-DD"),

					...getFilledCustomerFields(customer, c),
					...getFilledDeliveryFields(customer, c),
				};
			});
		}, [customer]);

		const saveFunction = useCallback(
			async (invoice, boardRow = row) => {
				const invoiceId = invoice.DocumentNumber || id;
				if (invoiceId) {
					invoice.DocumentNumber = invoiceId;
				}

				const endpoint = invoiceId ? `/api/fortnox/invoices/${invoiceId}.json` : `/api/fortnox/contacts/${contactId}/invoices.json`;

				const res = await (invoiceId
					? API.put(endpoint, { ...invoice, board_row_id: boardRow?.id })
					: API.post(endpoint, { ...invoice, board_row_id: boardRow?.id }));

				const successMessage = invoiceId
					? t("fortnox.responses.invoice_saved", "Fakturan uppdaterad")
					: t("fortnox.responses.invoice_created", "Faktura skapad");

				toastr.success(successMessage);

				if (!invoiceId) {
					history.replace(`/admin/fortnox/invoices/${res.data.DocumentNumber}`, {
						data: res.data,
					});
				}

				return res;
			},
			[id, t, history, contactId, row]
		);

		const update = async ({ invoice, boardRow = row }) => {
			const response = await saveFunction(invoice, boardRow);
			return response?.data;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});

		const handleSave = useCallback(
			async (invoice: FortnoxInvoiceType, boardRow = row) => {
				try {
					if (!invoice) {
						throw new Error("no invoice");
					}

					if (!boardRow) {
						setRowModalIsOpen("submit");
						throw new Error("");
					}
					setIsSaving(true);
					return await mutation.mutateAsync({ invoice: handleFormatRowsToWrite(invoice), boardRow });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				} finally {
					setIsSaving(false);
				}
			},
			[mutation, row, handleFormatRowsToWrite]
		);

		const cancel = async ({ invoice }) => {
			try {
				const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/cancel.json`);
				toastr.success(t("fortnox.responses.invoice_cancelled", "Fakturan makulerad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationCancelInvoice = useMutation(cancel, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleCancelInvoice = useCallback(async () => {
			return await mutationCancelInvoice.mutateAsync({ invoice });
		}, [invoice, mutationCancelInvoice]);

		const credit = async ({ invoice }) => {
			try {
				const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/credit.json`);
				toastr.success(t("fortnox.responses.invoice_credited", "Fakturan krediterad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationCreditInvoice = useMutation(credit, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleCreditInvoice = useCallback(async () => {
			return await mutationCreditInvoice.mutateAsync({ invoice });
		}, [invoice, mutationCreditInvoice]);

		const setAsSent = async ({ invoice }) => {
			try {
				const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/externalprint.json`);
				toastr.success(t("fortnox.responses.invoice_setAsSented", "Fakturan markerad som skickad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationSetAsSentInvoice = useMutation(setAsSent, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleSetAsSentInvoice = useCallback(async () => {
			return await mutationSetAsSentInvoice.mutateAsync({ invoice });
		}, [invoice, mutationSetAsSentInvoice]);

		const sendInvoiceAsEInvoice = async ({ invoice }) => {
			try {
				const res = await API.get(`/api/fortnox/invoices/${invoice.DocumentNumber}/einvoice.json`);
				toastr.success(t("fortnox.responses.invoice_sendInvoiceAsEInvoiceed", "Fakturan markerad som skickad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationSendInvoiceAsEInvoice = useMutation(sendInvoiceAsEInvoice, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleSendInvoiceAsEInvoice = useCallback(async () => {
			return await mutationSendInvoiceAsEInvoice.mutateAsync({ invoice });
		}, [invoice, mutationSendInvoiceAsEInvoice]);

		const bookKeep = async ({ invoice }) => {
			try {
				const res = await API.put(`/api/fortnox/invoices/${invoice.DocumentNumber}/bookkeep.json`);
				toastr.success(t("fortnox.responses.bookeeped", "Fakturan bokförd"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationBookKeepInvoice = useMutation(bookKeep, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleBookkeepInvoice = useCallback(async () => {
			return await mutationBookKeepInvoice.mutateAsync({ invoice });
		}, [invoice, mutationBookKeepInvoice]);

		const handleSendInvoiceAsEmailPre = useCallback(async (opt = null) => {
			setEmailModalIsOpen(true);
			setEmailModalOptions(opt);
		}, []);

		useEffect(() => {
			if (!emailModalIsOpen) setEmailModalOptions(null);
		}, [emailModalIsOpen]);

		const sendAsEmail = async ({ invoice }) => {
			try {
				const res = await API.get(`/api/fortnox/invoices/${invoice.DocumentNumber}/email.json`);
				toastr.success(t("fortnox.responses.invoice_setAsSented", "Fakturan skickad via email"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return invoice;
			}
		};
		const mutationSendInvoiceAsEnail = useMutation(sendAsEmail, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleSendInvoiceAsEmail = useCallback(async () => {
			return await mutationSendInvoiceAsEnail.mutateAsync({ invoice });
		}, [invoice, mutationSendInvoiceAsEnail]);

		const getBoardRow = useCallback(async () => {
			if (!id) return;
			try {
				const result = await API.get(`/api/fortnox/invoices/${id}/board_row.json`);
				if (result?.data) {
					const { board_row: boardRow, upload, board_row_value } = result.data;

					store.dispatch({
						type: "SET_BOARD_ROWS",
						rows: [boardRow],
					});

					setRow(boardRow);
					setUpload(upload);
					setBoardRowValue(board_row_value);
				}
			} catch (e) {
				console.error("Error", e);
			}
		}, [id]);

		useEffect(() => {
			getBoardRow();
		}, [getBoardRow]);

		const handleOpenRowSelectModal = useCallback(() => {
			setRowModalIsOpen(true);
		}, []);

		const dealBoard = store.getState().boards[store.getState().account.sales_board_id];
		const boardRow = row?.id ? store.getState().board_rows[row?.id] : row;
		const column = dealBoard?.columns?.find((c) => c.title === "Fakturor");

		const handleSendToScrive = useCallback(async () => {
			try {
				if (!row || !column || !upload) return;
				const result = await API.post("/api/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/scrive_upload.json", {
					id: upload.id,
					// scrive_data: {
					// 	days_to_sign: 14, // here we can change the default variables for scrive based on the fortnox data
					// },
				});

				if (result.data.error) throw result.data.error;

				if (result.data.file.id) {
					toastr.success(t("scrive.actions.prepare.success", "Dokument förberett för signering"));
					const scriveId = result.data.file.scrive_id;
					const url = `/admin/scrive/documents/${scriveId}`;

					history.push(url, { document: result.data.file.scrive_data, edit: true, tabId: "parties" });
				}
			} catch (e) {
				toastr.error(e);
			}
		}, [column, history, row, t, upload]);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				isFetchingPDF,
				t,
				pdf,
				id,
				handleSave,
				contact,
				customer,
				invoice,
				row: boardRow,
				handleOpenRowSelectModal,
				handleSendToScrive,
				upload,
				boardRowValue,
				handleCancelInvoice,
				handleCreditInvoice,
				handleSetAsSentInvoice,
				handleBookkeepInvoice,
				handleSendInvoiceAsEmailPre,
				handleSendInvoiceAsEInvoice,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			}),
			[
				isFetching,
				t,
				id,
				form,
				isFetchingPDF,
				pdf,
				handleSave,
				setForm,
				contact,
				customer,
				invoice,
				boardRow,
				handleOpenRowSelectModal,
				handleSendToScrive,
				upload,
				boardRowValue,
				handleCancelInvoice,
				handleCreditInvoice,
				handleSetAsSentInvoice,
				handleBookkeepInvoice,
				handleSendInvoiceAsEmailPre,
				handleSendInvoiceAsEInvoice,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			]
		);

		return useMemo(
			() => (
				<FortnoxInvoiceContext.Provider value={value}>
					<BoardRowSelectModal
						open={!!rowModalIsOpen}
						onClose={() => {
							setRowModalIsOpen(false);
						}}
						column={column}
						board={dealBoard}
						contact={contact}
						onSelect={(row) => {
							setRow(row);
							if (rowModalIsOpen === "submit") {
								handleSave(form as FortnoxInvoiceType, row);
							}
							setRowModalIsOpen(false);
						}}
					/>
					<SendAsEmailModal
						invoice={invoice}
						loading={isSaving}
						type="invoice"
						options={emailModalOptions}
						open={!!emailModalIsOpen}
						onClose={() => setEmailModalIsOpen(false)}
						EmailInformation={form?.EmailInformation}
						onSend={async (EmailInformation: EmailInformation, disabled) => {
							const newForm = { EmailInformation };

							try {
								const res = !disabled ? await handleSave(newForm as FortnoxInvoiceType) : true;
								if (res) {
									const response = await handleSendInvoiceAsEmail();

									if (emailModalOptions?.bookkeep) {
										await handleBookkeepInvoice();
									}

									setEmailModalIsOpen(false);
									return response;
								}
							} catch (error) {
								console.error("error:", error);
							}
						}}
					/>
					{children}
				</FortnoxInvoiceContext.Provider>
			),
			[
				value,
				children,
				rowModalIsOpen,
				column,
				dealBoard,
				contact,
				form,
				handleSave,
				handleSendInvoiceAsEmail,
				isSaving,
				emailModalIsOpen,
				invoice,
				emailModalOptions,
				handleBookkeepInvoice,
			]
		);
	}
);
export default FortnoxInvoiceContext;
