import React, { Component } from "react";
import { TextField, Avatar, FormLayout, LegacyStack, Text, Button, Icon, Popover, ActionList } from "@shopify/polaris";
import { MobileAcceptMajor, MobileCancelMajor, SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import BoardHelper from "./BoardHelper.js";
import MemberAvatar from "./components/MemberAvatar";
import MyPopover from "../../components/Popover.js";
import API from "../../API";
import { toastr } from "../../components/toastr.js";

class SimpleCellPerson extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			value: props.value,
			allUsers: props.board.members,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ value: props.value });
	}

	addMember(member) {
		if (this.state.value.indexOf(member.user_id) < 0) {
			if (this.props.column.options.single) {
				this.state.value = [member.user_id];
			} else {
				this.state.value.push(member.user_id);
			}
			this.setState({ value: this.state.value });
			this.props.onChange(this.state.value);
		} else {
			this.removeMember(member);
		}
		// this.setState({ active: false });
	}

	removeMember(member) {
		const index = this.state.value.indexOf(member.user_id);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			this.setState({ value: this.state.value });
			this.props.onChange(this.state.value);
		}
	}

	inviteMember() {
		this.setState({ saving: true });
		API.post("/api/boards/" + this.props.column.board_id + "/members.json", { title: this.state.title, email: this.state.email }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });
				this.addMember(result.data.member);
				this.props.onUpdateBoard(result.data.board);
				toastr.success(this.props.t("cell.person.responses.invited", "Bjöd in ny medlem"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		const users =
			this.state.allUsers && this.state.allUsers.length ? this.state.allUsers.filter((user) => user.enabled || (user.user && user.user.enabled)) : [];
		const usersAdded = users.filter((c) => this.state.value.indexOf(c.user_id) >= 0) || [];
		const usersNotAdded = users.filter((c) => this.state.value.indexOf(c.user_id) < 0) || [];
		const added = usersAdded && usersAdded.length ? usersAdded.sort((a = {}, b = {}) => a.title && a.title.localeCompare(b.title)) : [];
		const notAdded = usersNotAdded && usersNotAdded.length ? usersNotAdded.sort((a = {}, b = {}) => a.title && a.title.localeCompare(b.title)) : [];

		const options = []
			.concat(added, notAdded)
			.filter(
				({ name = "", email = "" }) =>
					!this.state.query || name.toLowerCase().includes(this.state.query) || email.toLowerCase().includes(this.state.query)
			)
			.map((member) => ({
				onAction: this.addMember.bind(this, member),
				content: (
					<div className="personItem">
						<LegacyStack>
							<MemberAvatar member={member} />
							<LegacyStack vertical spacing="none">
								<div>{member.title}</div>
								<Text variation="subdued">{member.email}</Text>
							</LegacyStack>
							{this.state.value && this.state.value.indexOf(member.user_id) >= 0 ? (
								<div className="removePersonIndicator">
									<div className="remove">
										<Icon source={MobileCancelMajor} color="red" />
									</div>
									<div className="added">
										<Icon source={MobileAcceptMajor} color="green" />
									</div>
								</div>
							) : null}
						</LegacyStack>
					</div>
				),
			}));

		options.push({
			content: (
				<LegacyStack>
					<Avatar customer size="small" />
					<LegacyStack vertical spacing="none">
						<div>{this.props.t("cell.person.actions.invite", "Bjud in medlem")}</div>
						<Text variation="subdued">{this.props.t("cell.person.actions.add_new", "Lägg till ny medlem")}</Text>
					</LegacyStack>
				</LegacyStack>
			),
			onAction: () => {
				this.setState({ invite: true });
			},
		});

		const members = [];
		for (let i = 0; i < this.state.value.length; i++) {
			const user = BoardHelper.getUser(this.state.value[i]);
			if (user) {
				members.push({ user_id: user.id, user });
			}
		}

		return (
			<span
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				<MyPopover
					active={this.state.open}
					activator={
						<span
							onClick={(e) => {
								e.stopPropagation();
								this.setState({ open: !this.state.open });
							}}
							style={{ display: "flex" }}
						>
							{this.state.value.length > 0 ? (
								this.state.value.map((userId) => {
									const user = BoardHelper.getUser(userId);
									const member = { user_id: userId, user };
									return <MemberAvatar key={member.user_id} member={member} />;
								})
							) : (
								<Avatar size="small" customer />
							)}
						</span>
					}
					onClose={() => {
						this.setState({ open: false });
					}}
				>
					{this.state.invite ? (
						<Popover.Section title={this.props.t("cell.person.actions.invite_short", "Bjud in")}>
							<FormLayout>
								<TextField
									label={this.props.t("cell.person.fields.title.label", "För- och efternamn")}
									value={this.state.title}
									onChange={(val) => {
										this.setState({ title: val });
									}}
								/>
								<TextField
									label={this.props.t("cell.person.fields.email.label", "E-post")}
									type="email"
									value={this.state.email}
									onChange={(val) => {
										this.setState({ email: val });
									}}
								/>
								<span
									onClick={(event) => {
										event.stopPropagation();
									}}
								>
									<Button
										disabled={!this.state.title || !this.state.email}
										loading={this.state.saving}
										fullWidth
										primary
										onClick={this.inviteMember.bind(this)}
									>
										{this.props.t("cell.person.actions.invite_short", "Bjud in")}
									</Button>
								</span>
							</FormLayout>
						</Popover.Section>
					) : null}
					<span style={{ position: "absolute", left: 7, top: 10 }}>
						<Icon source={SearchMinor} color="inkLighter" />
					</span>
					<input
						onKeyDown={(event) => {
							if (event.key === "Escape") {
								this.setState({ query: "" });
							}
						}}
						className="bgInput"
						style={{ paddingLeft: 30 }}
						placeholder={this.props.t("cell.person.placeholder", "Sök person...")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value.toLowerCase() });
						}}
					/>
					{!this.state.invite ? (
						<div
							className="asdasdasdasd"
							onClick={(event) => {
								event.stopPropagation();
							}}
						>
							<ActionList items={options} />
						</div>
					) : null}
				</MyPopover>
			</span>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(SimpleCellPerson);
