import React, { Component } from "react";
import { Icon, LegacyStack, Button } from "@shopify/polaris";
import { DragHandleMinor, CircleCancelMajor, SettingsMajor } from "@shopify/polaris-icons";
import API from "../../API";
import { toastr } from "../../components/toastr.js";

import WidgetIframe from "./WidgetIframe.js";
import WidgetHtml from "./WidgetHtml.js";
import WidgetNumber from "./WidgetNumber.js";
import WidgetChart from "./WidgetChart.js";
import WidgetList from "./WidgetList.js";
import WidgetSummaryList from "./WidgetSummaryList.js";
import BoardFilters from "./components/BoardFilters";
import IconSelector, { getIconByString } from "./components/IconSelector";
import BoardHelper from "./BoardHelper";

class DashboardWidget extends Component {
	constructor(props) {
		super(props);

		this.state = {
			editingTitle: false,
			title: props.widget.title,
			description: props.widget.description ? props.widget.description : null,
			filters: this.props.widget.options.filters || [],
		};
	}

	updateOptions() {
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getBoards() {
		if (this.props.dashboard) {
			return this.props.dashboard.boards;
		} else {
			return [this.props.board];
		}
	}

	changeWidgetTitle() {
		this.setState({ editingTitle: false });
		if (this.state.title != this.props.widget.title || this.state.description != this.props.widget.description) {
			this.props.widget.title = this.state.title;
			this.props.widget.description = this.state.description;

			API.put(
				"/" +
					"api" +
					(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
					"/widgets/" +
					this.props.widget.id +
					".json",
				{ title: this.state.title, description: this.state.description },
				{ params: {} }
			)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					this.props.onUpdate(result.data.widget);
				})
				.catch((error) => {
					toastr.error(error);
				});
		}
	}

	changeFilters(filters) {
		this.props.widget.options.filters = filters;
		this.updateOptions();
		this.setState({ filters });
	}

	changeIcon(icon) {
		this.props.widget.icon = icon;
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ icon: this.props.widget.icon },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	changeColor(color) {
		this.props.widget.color = color;
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ color: this.props.widget.color },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	addDateToFilters() {
		const filters = [];
		for (let i = 0; i < this.state.filters.length; i++) {
			filters.push(Object.assign({}, this.state.filters[i]));
			if (filters[i].advanced && filters[i].operator == "quickdateselector") {
				filters[i].operator = "between";
				filters[i].value = this.getYmd(this.props.from);
				filters[i].value2 = this.getYmd(this.props.to);
			}
		}
		return filters;
	}

	getFilteredRows() {
		return BoardHelper.filterRows(this.props.rows, this.addDateToFilters());
	}

	getYmd(date) {
		let month = date.getMonth() + 1; // months from 1-12
		let day = date.getDate();
		const year = date.getFullYear();

		if (month < 10) {
			month = "0" + month;
		}

		if (day < 10) {
			day = "0" + day;
		}

		return year + "-" + month + "-" + day;
	}

	render() {
		let filteredRows = null;
		let haveSettings = true;

		let content = null;
		if (this.props.widget.type == "iframe") {
			content = (
				<WidgetIframe
					showSettings={this.state.settingsOpen}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					loading={this.props.loading}
					widget={this.props.widget}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		} else if (this.props.widget.type == "html") {
			haveSettings = false;
			content = (
				<WidgetHtml
					showSettings={this.state.settingsOpen}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					loading={this.props.loading}
					widget={this.props.widget}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		} else if (this.props.widget.type == "number") {
			filteredRows = this.getFilteredRows();
			content = (
				<WidgetNumber
					showSettings={this.state.settingsOpen}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					loading={this.props.loading}
					widget={this.props.widget}
					rows={filteredRows}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		} else if (this.props.widget.type == "chart") {
			filteredRows = this.getFilteredRows();
			content = (
				<WidgetChart
					showSettings={this.state.settingsOpen}
					showColorSetting={this.state.showColorSetting}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					color={this.props.color}
					loading={this.props.loading}
					widget={this.props.widget}
					rows={filteredRows}
					groups={this.props.groups}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		} else if (this.props.widget.type == "list") {
			filteredRows = this.getFilteredRows();
			content = (
				<WidgetList
					showSettings={this.state.settingsOpen}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					loading={this.props.loading}
					widget={this.props.widget}
					rows={filteredRows}
					filters={this.state.filters}
					groups={this.props.groups}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		} else if (this.props.widget.type == "summary") {
			filteredRows = this.getFilteredRows();
			content = (
				<WidgetSummaryList
					showSettings={this.state.settingsOpen}
					dashboard={this.props.dashboard}
					board={this.props.board}
					compact={!!(this.props.widget.width <= 2)}
					loading={this.props.loading}
					widget={this.props.widget}
					rows={filteredRows}
					filters={this.state.filters}
					groups={this.props.groups}
					editing={this.props.editing}
					onUpdate={this.props.onUpdate}
					onCloseSettings={() => {
						this.setState({ settingsOpen: false });
					}}
					ref={(widget) => {
						// eslint-disable-next-line react/no-unused-class-component-methods
						this.widget = widget;
					}}
				/>
			);
		}
		return (
			<div
				style={{ height: "100%", position: "relative" }}
				className={
					"dashboard_widget " +
					(!this.props.widget.options.full_width ? "Polaris-Stack Polaris-Stack--vertical Polaris-Stack--noWrap Polaris-Stack--spacingNone" : "") +
					(this.props.editing ? " editing" : "") +
					" widget_" +
					this.props.widget.type +
					" color_" +
					(this.props.widget.color || "blue") +
					" " +
					(this.props.widget.options.highlighted_background ? "highlighted_background" : "")
				}
			>
				<div
					style={{
						position: "absolute",
						width: "100%",
						borderRadius: "5px 0px 5px 0px",
						zIndex: 100,
						paddingRight: 10,
						paddingLeft: this.props.widget.type != "number" ? 30 : 0,
						top: -30,
					}}
					className="title-row"
				>
					<LegacyStack>
						{this.props.widget.type != "number" ? (
							<LegacyStack.Item fill>
								{this.state.editingTitle ? (
									<div
										onClick={(event) => {
											event.stopPropagation();
										}}
									>
										<div
											style={{
												border: "1px dashed #777",
											}}
										>
											<input
												ref={(input) => {
													if (input) {
														input.focus();
													}
												}}
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														this.changeWidgetTitle();
													} else if (event.key === "Escape") {
														this.setState({
															title: this.props.widget.title,
															editingTitle: false,
														});
													}
												}}
												onChange={(event) => {
													this.setState({ title: event.target.value });
												}}
												onBlur={this.changeWidgetTitle.bind(this)}
												type="text"
												value={this.state.title}
												style={{
													fontSize: "16px",
													fontWeight: 300,
													display: "block",
													width: "100%",
													border: 0,
												}}
											/>
										</div>
									</div>
								) : (
									<div
										onClick={() => {
											if (this.props.editing) {
												this.setState({ editingTitle: true });
											}
										}}
									>
										<span className="overflow-ellipsis widget-title">{this.state.title}</span>
									</div>
								)}
							</LegacyStack.Item>
						) : null}
						<div className="dashboard-widget-hover">
							<LegacyStack spacing="tight" distribution="trailing" wrap={false}>
								{this.props.editing && !this.state.editingTitle && !this.props.widget.options.full_width ? (
									<div style={{ cursor: "move" }} className=" dashboard-drag-handle">
										<Icon source={DragHandleMinor} color="inkLighter" />
									</div>
								) : null}

								{this.props.editing && !this.state.editingTitle && filteredRows !== null ? (
									<BoardFilters
										icon
										onChangeFilters={this.changeFilters.bind(this)}
										filters={this.state.filters}
										boards={this.getBoards()}
										dashboard={this.props.dashboard}
										board={this.props.board}
										groups={this.props.groups}
										filteredRows={filteredRows}
										rows={this.props.rows}
									/>
								) : null}

								{this.props.editing && !this.state.editingTitle && haveSettings ? (
									<div className="">
										<Button
											plain
											icon={SettingsMajor}
											onClick={() => {
												this.setState({
													settingsOpen: !this.state.settingsOpen,
												});
											}}
										/>
									</div>
								) : null}
								{this.props.editing && !this.state.editingTitle ? (
									<div className="">
										<Button plain icon={CircleCancelMajor} onClick={this.props.onRemove} />
									</div>
								) : null}
							</LegacyStack>
						</div>
					</LegacyStack>
				</div>

				<div className={(!this.props.widget.options.full_width ? "Polaris-Stack__Item Polaris-Stack__Item--fill " : "") + "widget_content"}>
					<div style={{ height: "100%", position: "relative" }}>{content}</div>
				</div>
				<div
					onClick={() => {
						if (this.getBoards().length == 1 && !this.props.board) {
							// Gå direkt till boarden, merga filter
							const filters = [];
							for (let i = 0; i < this.props.filters.length; i++) {
								filters.push(this.props.filters[i]);
							}
							for (let i = 0; i < this.state.filters.length; i++) {
								filters.push(this.state.filters[i]);
							}

							if (!this.props.editing) {
								window.open("/admin/workspaces/none/boards/" + this.getBoards()[0].id + "#" + JSON.stringify(filters));
							}
						}
					}}
					className={
						"widget_icon_container " +
						(this.props.widget.color ? " color-" + this.props.widget.color : "") +
						(this.props.widget.width <= 2 ? " compact" : " ") +
						(this.props.widget.type != "number" ? " plain" : " ") +
						(this.props.editing ? " editing" : " ")
					}
				>
					<div style={{ textAlign: "center" }}>
						{this.props.editing ? (
							<IconSelector
								color={this.props.widget.color}
								icon={this.props.widget.icon}
								onChange={this.changeIcon.bind(this)}
								onChangeColor={this.changeColor.bind(this)}
							/>
						) : (
							<Icon source={getIconByString(this.props.widget.icon)} />
						)}
					</div>
				</div>
			</div>
		);
	}
}
export default DashboardWidget;
