import React, { useState, useRef } from "react";
import { Icon } from "@shopify/polaris";
import { Link } from "react-router-dom";
import HomeIcon from "../icons/Home";
import MainMenuSectionSearch from "./MainMenuSectionSearch";
import IntersectionObserverElement from "./IntersectionObserverElement";
import Spinner from "./Spinner";

const MainMenuSection = (props) => {
	const { items, title, actions, children, id, secondaryItemsStyle, loading, className, url, icon, onClick, toggleMenu, history, location } = props;

	const [search, setSearch] = useState("");
	const ref = useRef<any>(null);
	const secondaryRef = useRef(null);

	const getTriggerIcon = () => {
		if (!icon) return <HomeIcon />;
		if (React.isValidElement(icon)) return icon;
		return <Icon source={icon} />;
	};

	const getIcon = (iconProp) => {
		if (!iconProp) return null;
		if (React.isValidElement(iconProp)) return iconProp;
		return <Icon source={iconProp} />;
	};

	const handleClick = (itemUrl, itemOnClick, closeOnClick) => {
		if (itemOnClick) itemOnClick();
		if (closeOnClick && toggleMenu) {
			toggleMenu();
		}
		if (itemUrl) history.push(itemUrl);
	};

	const onSearchChange = (value) => {
		setSearch(value);
	};

	return (
		<div ref={ref} id={id} className={`mainmenu-item ${className || ""}`}>
			{url ? (
				<Link to={url} className="mainmenu-item-trigger" data-content={title} onClick={onClick} style={{}}>
					{getTriggerIcon()}
					<span className="mainmenu-item-trigger_title">{title}</span>
				</Link>
			) : (
				<span className="mainmenu-item-trigger" data-content={title} onClick={onClick} style={{}}>
					{getTriggerIcon()}
					<span className="mainmenu-item-trigger_title">{title}</span>
				</span>
			)}
			<div ref={secondaryRef} className="mainmenu-item__secondary noscroll">
				<div className="mainmenu-item__secondary__header">
					<span className="mainmenu-item__secondary__header__title">
						{title} {loading && <Spinner size="small" />}
					</span>
					<div className="mainmenu-item__secondary__actions" />
				</div>
				{(items || (actions && actions.length > 0)) && <MainMenuSectionSearch onChange={onSearchChange} value={search} actions={actions} />}
				<div className="mainmenu-item__secondary__items" style={secondaryItemsStyle}>
					{children}
					{items &&
						items
							.filter((item) => item.label?.toLowerCase()?.includes((search || "").toLowerCase()))
							.map((item, index) => {
								const { label, icon: itemIcon, url: itemUrl, activeUrl, onClick: itemOnClick, closeOnClick } = item;
								const isActive = (activeUrl || itemUrl) && location && location.pathname.includes(activeUrl || itemUrl) ? "active" : "";

								return (
									<div
										className={`mainmenu-item__secondary__item ${isActive}`}
										onClick={() => handleClick(itemUrl, itemOnClick, closeOnClick)}
										key={index}
									>
										<div>
											{getIcon(itemIcon)}
											{label}
										</div>
									</div>
								);
							})}
					<IntersectionObserverElement
						parent={secondaryRef.current}
						hideShadow
						onChange={(intersecting) => {
							const shadow = document.querySelector(`#${id}-shadow`);
							if (shadow) {
								if (intersecting) {
									shadow.classList.remove("visible");
									shadow.classList.add("invisible");
								} else if (ref.current!.classList.contains("active")) {
									shadow.classList.remove("invisible");
									shadow.classList.add("visible");
								}
							}
						}}
						shadowStyle={{ width: ref.current?.clientWidth }}
					/>
				</div>
			</div>
			<div id={`${id}-shadow`} className="bottom-scroll-shadow" />
		</div>
	);
};

export default MainMenuSection;
