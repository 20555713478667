import React, { Component } from "react";
import { FormLayout, Banner, Text, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import Modal from "src/js/components/modal";

class UsageOrderModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, quantity: 100 });
		}
	}

	getPrice() {
		let price = 10000;
		if (this.props.type == "scrive") {
			price = this.props.limits ? this.props.limits.scrives_extra_price : 10000;
		}
		if (this.props.type == "companies") {
			price = this.props.limits ? this.props.limits.company_import_extra_price : 10000;
		}
		if (this.props.type == "emails") {
			price = this.props.limits ? this.props.limits.emails_extra_price : 10000;
		}

		return price;
	}

	placeOrder() {
		this.setState({ saving: true });
		API.post("/api/usage_orders.json", { quantity: this.state.quantity, type: this.props.type }, {})
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("account.usage.responses.order_placed", "Din beställning är lagd"));
				if (this.props.onComplete) {
					this.props.onComplete();
				} else {
					this.props.onClose();
				}
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		let label = this.props.t("account.usage.labels.usage", "förbrukning");
		const price = this.getPrice();
		if (this.props.type == "scrive") {
			label = this.props.t("account.usage.labels.scrive", "Scrive signeringar");
		}
		if (this.props.type == "companies") {
			label = this.props.t("account.usage.labels.companies", "Bolagsimporter");
		}
		if (this.props.type == "emails") {
			label = this.props.t("account.usage.labels.emails", "E-postutskick");
		}
		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={this.props.t("account.usage.titles.order_more", "Beställ extra") + " " + label}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: this.props.onClose,
						},
					]}
					primaryAction={{
						content: this.props.t("account.actions.order", "Beställ"),
						onAction: this.placeOrder.bind(this),
						loading: this.state.saving,
					}}
				>
					<Modal.Section>
						<FormLayout>
							<Select
								label={this.props.t("account.usage.fields.quantity.label", "Antal att beställa")}
								value={this.state.quantity + ""}
								onChange={(v) => {
									this.setState({ quantity: v });
								}}
								options={[
									{ value: "100", label: this.props.t("account.usage.fields.quantity.options.100", "100") },
									{ value: "1000", label: this.props.t("account.usage.fields.quantity.options.1000", "1000") },
									{ value: "2500", label: this.props.t("account.usage.fields.quantity.options.2500", "2500") },
									{ value: "5000", label: this.props.t("account.usage.fields.quantity.options.5000", "5000") },
									{ value: "10000", label: this.props.t("account.usage.fields.quantity.options.10000", "10000") },
								]}
							/>
							<Text>{this.props.t("account.usage.text.price_st", "Pris per st: {{price}} kr", { price })}</Text>
							<Text variation="strong">
								{this.props.t("account.usage.text.sum_price", "Summa: {{sum}} kr", {
									sum: this.state.quantity * price,
								})}
							</Text>
							<Banner status="info">
								{this.props.t(
									"account.usage.text.ordered",
									"Efter beställning kommer en faktura att skickas till er via mail, först när den är betald tillfaller krediterna ert konto"
								)}
							</Banner>
						</FormLayout>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}

export default withTranslation(["account", "common"], { withRef: true })(UsageOrderModal);
