import React, { useContext } from "react";
import ProgressJobContext from "./ProgressJobContext";
import { Icon, ProgressBar, Popover } from "@shopify/polaris";
import ProgressJob from "./ProgressJob";
import { ToolsMajor } from "@shopify/polaris-icons";
import styled, { css } from "styled-components";
import MyPopover from "../Popover";
import Colors from "src/js/Colors";

type ProgressJobsAccumulator = {
	inProgressJobs: ProgressJobType[];
	jobs: ProgressJobType[];
};

const ProgressJobTopBarButton = () => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { progresJobs, refetch, history, open, handleToggle, handleClose } = useContext(ProgressJobContext);

	const { inProgressJobs, jobs } = progresJobs
		.sort((a: ProgressJobType, b: ProgressJobType) => b.id - a.id)
		.reduce<ProgressJobsAccumulator>(
			(acc, job: ProgressJobType) => {
				// if (job.status === "in_progress") {
				// 	acc.inProgressJobs.push(job);
				// } else {
				acc.jobs.push(job);
				// }
				return acc;
			},
			{ inProgressJobs: [], jobs: [] }
		);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { progress, inProgressCount } = progresJobs.reduce<{
		progress: number;
		inProgressCount: number;
	}>(
		(acc, job: ProgressJobType) => {
			if (job.status === "in_progress") {
				return {
					progress: acc.progress + job.progress,
					inProgressCount: acc.inProgressCount + 1,
				};
			}

			return acc;
		},
		{
			progress: 0,
			inProgressCount: 0,
		}
	);

	const totalProgress = inProgressCount ? progress / inProgressCount : 0;

	if (progresJobs.length === 0) return null;
	return (
		<MyPopover
			open={open}
			onClose={handleClose}
			activator={
				<Button onClick={handleToggle} inProgress={!!totalProgress}>
					{(!!totalProgress && <ProgressBar progress={totalProgress} size="small" />) as unknown as string}
					<Icon source={ToolsMajor} />
				</Button>
			}
		>
			<Popover.Section>
				{!!inProgressJobs?.length && inProgressJobs.map((job: ProgressJobType) => <ProgressJob history={history} key={job.id} progresJob={job} />)}

				{jobs.map((job: ProgressJobType) => (
					<ProgressJob history={history} key={job.id} progresJob={job} />
				))}
			</Popover.Section>
		</MyPopover>
	);
};

export default ProgressJobTopBarButton;

const Button = styled.button<{ inProgress: boolean }>`
	display: flex;
	gap: 0.6250rem;

	cursor: pointer;

	border: none;
	border-radius: 25px;
	padding: 0.4em 0.6em;
	align-items: center;
	box-shadow: 1px 4px 12px 0px rgba(0, 0, 0, 0.1882352941);
	background: var(--main2);
	min-width: 45px;
	justify-content: center;
	transition: width 500ms;

	width: 45px;

	.Polaris-ProgressBar {
		width: 100px;
	}

	&&&& {
		svg {
			height: 20px;
			width: 20px;
			transition: fill 0.2s;
		}

		${({ inProgress }) => {
			if (inProgress)
				return css`
					width: 145px;

					svg {
						fill: ${Colors.accent} !important;
					}
				`;
		}}
	}

	.Polaris-ProgressBar {
		.Polaris-ProgressBar__Indicator {
			background: ${Colors.accent};
		}
	}
`;
