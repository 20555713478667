import React, { useCallback } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import ItemCard from "src/js/components/ItemCard";
import { format } from "../Utilities/formatRow";
import DocumentStatusBar from "src/js/views/Scrive/Document/DocumentStatusBar";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import { FormLayout, OptionList, Tooltip } from "@shopify/polaris";
import { toastr } from "src/js/components/toastr";
import { ExportMinor } from "@shopify/polaris-icons";
import API from "src/js/API";
import IndexTable from "src/js/components/IndexTable";
import type { IndexFiltersProps as PolarisIndexFiltersProps } from "@shopify/polaris";
import { useSelector } from "react-redux";

type OrdersListProps = {
	history: any;
	additionalAction?: any;
	inlinePrimaryAction?: any;
	params?: any;
	primaryAction?: any;
	titleHidden?: boolean;
	filterPrimaryAction?: PolarisIndexFiltersProps["primaryAction"];
} & WithTranslation;

const OrdersList = ({
	history,
	additionalAction,
	inlinePrimaryAction,
	primaryAction,
	titleHidden,
	t,
	filterPrimaryAction,
	params,
}: OrdersListProps) => {
	const ref = React.useRef<any>();
	const [exportIOptions, setExportOptions] = React.useState<any>({ include: ["order"] });
	const orderBoard = useSelector((state: any) => state.account.internal_order_board);

	const handleChangeExportOptions = useCallback(
		(key) => (value) => {
			setExportOptions((prev) => ({
				...prev,
				[key]: value,
			}));
		},
		[]
	);

	const handleExportAction = useCallback(async () => {
		const params = ref.current.getParams();
		delete params.limit;
		delete params.offset;

		try {
			const response = await API.post("/api/orders/export.json", { options: exportIOptions }, { params });

			toastr.success(t("orders.responses.export.success", "Export tillagd"));

			return response;
		} catch (e) {
			toastr.error(e);
		}
	}, [t, exportIOptions]);

	const renderItem = useCallback(
		(item: OrderType) => {
			const scriveData = item?.upload?.board_row_value?.value.find((v) => v.id === item?.upload?.id)?.scrive_data;

			const isDraft = !item.signed_at && !item.marked_as_signed_at && !item.cancelled_at && !item.prepared_for_signing_at;

			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/orders/${item.id}`, {
							data: item,
						});
					}}
					title={
						<div>
							<p>
								Order #{item.order_number}
								{isDraft && <Badge color={Colors.grey}>{t("orders.fields.isDraft.label", "Utkast")}</Badge>}
								{item.signed_at && (
									<Tooltip content={t("orders.fields.signed_at.label", "Signerad") + " " + item.signed_at}>
										<Badge color={Colors.green}>{t("orders.fields.signed_at.label", "Signerad")}</Badge>
									</Tooltip>
								)}
								{item.marked_as_signed_at && (
									<Tooltip content={t("orders.fields.marked_as_signed_at.label", "Markerad som godkänd") + " " + item.marked_as_signed_at}>
										<Badge color={Colors.green}>{t("orders.fields.marked_as_signed_at.label", "Markerad som godkänd")}</Badge>
									</Tooltip>
								)}
								{item.cancelled_at && (
									<Tooltip content={t("orders.fields.cancelled_at.label", "Makulerad") + " " + item.cancelled_at}>
										<Badge color={Colors.red}>{t("orders.fields.cancelled_at.label", "Makulerad")}</Badge>
									</Tooltip>
								)}
								{item.prepared_for_signing_at && !item.signed_at && (
									<Tooltip content={t("orders.fields.prepared_for_signing_at.label", "Förberedd för signering") + " " + item.prepared_for_signing_at}>
										<Badge color={Colors.blue}>{t("orders.fields.prepared_for_signing_at.label", "Förberedd för signering")}</Badge>
									</Tooltip>
								)}
							</p>
							{scriveData?.id && (
								<DocumentStatusBar
									style={{ width: 400 }}
									title=""
									onClick={(e) => {
										e.stopPropagation();

										history.push(`/admin/scrive/documents/${scriveData?.id}`, { document: scriveData, edit: true, tabId: "parties" });
									}}
									document={scriveData}
								/>
							)}
						</div>
					}
					leftColumn={[
						{
							label: t("orders.fields.order_date.label", "Orderdatum"),
							value: item.order_date,
						},
						{
							label: t("orders.fields.customer_fields.customer.name.label", "Kund"),
							value: item.customer_fields?.customer?.name,
						},
						{
							label: t("orders.fields.customer_fields.name.label1", "Kundnamn"),
							value: item.customer_fields?.name,
						},
						{
							label: t("orders.fields.our_reference.label", "Vår referens"),
							value: item.our_reference,
						},
						{
							label: t("orders.fields.your_reference.label", "Er referens"),
							value: item.your_reference,
						},
						{
							label: t("orders.fields.total_price_excluding_vat	614.label", "Totalt exkl. moms"),
							value: format(item.total_price_excluding_vat as number, false),
						},
						{
							label: t("orders.fields.total_price_including_vat.label", "Totalt"),
							value: format(item.total_price_including_vat as number, false),
						},
						{
							label: t("orders.fields.total.label", "Moms"),
							value: format(item.total_vat as number, false),
						},
					]}
					rightColumn={[
						{
							label: t("orders.fields.your_order_number.label1", "Ert ordernummer"),
							value: item.your_order_number,
						},
						{
							label: t("orders.fields.created_at.label", "Skapad"),
							value: item.created_at,
						},
						{
							label: t("orders.fields.created_by_user.label", "Skapad av"),
							value: item.created_by_user?.name,
						},
					]}
				/>
			);
		},
		[history, t]
	);

	const statusColumns = orderBoard?.columns?.filter((c) => c.type === "status") || [];
	const filters = statusColumns.map((c) => {
		return {
			type: "options",
			label: `Board status: ${c.title}`,
			allowMultiple: true,
			key: `column-${c.id}`,
			options:
				c.options?.statuses?.map((status) => ({
					label: status.label,
					value: status.id,
					media: <div style={{ height: 10, width: 10, borderRadius: "50%", background: status.color, marginRight: "0.25rem" }} />,
				})) || [],
		};
	});

	return (
		<>
			<IndexTable
				titleHidden={titleHidden}
				title={t("orders.title", "Orders") as string}
				history={history}
				primaryAction={
					primaryAction !== undefined
						? primaryAction
						: {
								content: t("orders.actions.new.label", "Ny order") as string,
								url: "/admin/orders/create",
						  }
				}
				inlinePrimaryAction={inlinePrimaryAction}
				additionalAction={additionalAction}
				filterPrimaryAction={filterPrimaryAction}
				secondaryActions={[
					{
						content: t("orders.actions.export.label", "Exportera") as string,
						onAction: handleExportAction,
						icon: ExportMinor,
						confirmation: {
							title: t("orders.actions.export.confirmation.title", "Exportera order") as string,
							content: (
								<FormLayout>
									<p>
										{
											t("orders.actions.export.confirmation.content", "Vill du exportera {{count}}st ordrar?", {
												count: ref.current?.getCount() || "-",
											}) as string
										}
									</p>

									<OptionList
										title={t("orders.actions.export.options.title", "Inkludera i export:") as string}
										selected={["order", ...(exportIOptions?.include || [])]}
										onChange={handleChangeExportOptions("include")}
										allowMultiple
										options={[
											{
												label: t("orders.actions.export.options.incluce_rows", "Order") as string,
												value: "order",
												disabled: true,
											},
											{
												label: t("orders.actions.export.options.incluce_rows2", "Orderrader") as string,
												value: "order_rows",
											},
											{
												label: t("orders.actions.export.options.incluce_customer_fields", "Kundfält") as string,
												value: "customer_fields",
											},
											{
												label: t("orders.actions.export.options.incluce_delivery_fields", "Leveransfält") as string,
												value: "delivery_fields",
											},
											{
												label: t("orders.actions.export.options.include_board_statuses", "Statusar från workspaces") as string,
												value: "board_statuses",
											},
										]}
									/>
								</FormLayout>
							),
						},
						// enabled: store.getState().user?.roles?.includes("ROLE_ADMIN"),
					},
				].filter((a) => !("enabled" in a) || a.enabled)}
				ref={ref}
				limit={10}
				selectable={false}
				resourceUrl="/api/orders.json"
				resourceName={{
					singular: t("orders.singular", "Order"),
					plural: t("orders.plural", "Ordrar"),
				}}
				params={params}
				resourceHandle="orders"
				columns={[
					{
						handle: "",
						label: "",
						render: renderItem,
					},
				]}
				filters={filters}
			/>
		</>
	);
};
export default withTranslation(["orders", "common"])(OrdersList);
