import React, { Component } from "react";
import { LegacyStack, Icon, ActionList } from "@shopify/polaris";
import { MobileCancelMajor, DeleteMajor, CircleRightMajor, ArchiveMajor, DuplicateMinor } from "@shopify/polaris-icons";
import { toastr as t } from "react-redux-toastr";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import MissingColumnConfirmationModal from "./MissingColumnConfirmationModal.js";
import BoardSelector from "./BoardSelector.js";
import BoardHelper from "../BoardHelper.js";
import Popover from "../../../components/Popover.js";

class BulkActionMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	deleteRows(undo, rows) {
		const params = Object.assign({}, {});
		if (undo) params.undo = true;

		API.delete("/api/boards/" + this.props.board.id + "/rows.json", { params, data: { ids: rows && rows.length ? rows : this.props.selection } })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (!result.data.rows && result.data.message) {
					toastr.success(result.data.message);
					return;
				}

				if (result.data.rows && result.data.rows.every((r) => r.removed)) {
					t.warning(
						this.props.t("board.bulk_menu.responses.removed", "Tog bort {{counts}}st rader", {
							counts: result.data.rows.length,
						}),
						this.props.t("board.bulk_menu.actions.revert", "Klicka här för att ångra"),
						{
							timeOut: 12000,
							onToastrClick: () =>
								this.deleteRows(
									true,
									result.data.rows.map((r) => r.id)
								),
						}
					);
				} else {
					toastr.success(
						this.props.t("board.bulk_menu.responses.reverted_removed", "Ångrade ta bort av {{counts}}st rader", {
							counts: result.data.rows.length,
						})
					);
				}
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
			});

		for (let i = 0; i < this.props.selection.length; i++) {
			BoardHelper.removeRow(this.props.selection[i]);
		}

		this.deselectAll();
	}

	duplicateRows() {
		this.setState({ saving: true, showMoveOption: false });

		for (let i = 0; i < this.props.rows.length; i++) {
			if (this.props.selection.indexOf(this.props.rows[i].id) >= 0) {
				this.props.onDuplicateRow(this.props.rows[i], this.props.rows[i].position + 1);
			}
		}

		this.deselectAll();
	}

	shouldShowArchives() {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("archived") == 1;
	}

	archiveRows(undoArchive, rows) {
		this.setState({ saving: true, showMoveOption: false });

		API.put("/api/boards/" + this.props.board.id + "/rows.json", { archived: !undoArchive, ids: rows && rows.length ? rows : this.props.selection })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (!result.data.rows && result.data.message) {
					toastr.success(result.data.message);
					return;
				}

				if (result.data.rows && result.data.rows.every((r) => r.archived)) {
					t.warning(
						this.props.t("board.bulk_menu.actions.archived", "Arkivarde {{counts}}st rader", { counts: result.data.rows.length }),
						this.props.t("board.bulk_menu.actions.revert", "Klicka här för att ångra"),
						{
							timeOut: 12000,
							onToastrClick: () =>
								this.archiveRows(
									true,
									result.data.rows.map((r) => r.id)
								),
						}
					);
				} else {
					toastr.success(
						this.props.t("board.bulk_menu.actions.reverted_removed", "Ångrade arkivering av {{counts}}st rader", {
							counts: result.data.rows.length,
						})
					);
				}
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});

		if (!undoArchive) {
			for (let i = 0; i < this.props.selection.length; i++) {
				BoardHelper.removeRow(this.props.selection[i]);
			}
		}

		this.deselectAll();
	}

	moveRowToGroup(group, force = false) {
		this.setState({ saving: true, showMoveOption: false });

		const params = Object.assign({}, {});
		params.ids = this.props.selection;

		API.post("/api/boards/" + this.props.board.id + "/rows/move.json", { group, force }, { params })
			.then((result) => {
				if (result.data.unmapped) {
					this.setState({
						saving: false,
						failed_move_group: { id: result.data.group_id },
						missing_columns: result.data.unmapped,
						failed_move_board: result.data.board,
					});
					return;
				}
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ saving: false, failed_move_group: false });
				for (let i = 0; i < result.data.rows.length; i++) {
					BoardHelper.removeRow(result.data.rows[i].id);
				}

				this.deselectAll();
			})
			.catch((error) => {
				console.error("error: ", error);
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	deselectAll() {
		this.props.deselectAll();
	}

	changeGroup(group) {
		this.setState({ saving: true, showMoveOption: false });

		const params = Object.assign({}, {});
		params.ids = this.props.selection;

		API.put("/api/boards/" + this.props.board.id + "/rows.json", { group_id: group.id }, { params })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				for (let i = 0; i < result.data.rows.length; i++) {
					this.props.onUpdateRow(result.data.rows[i]);
				}
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});

		this.deselectAll();
	}

	render() {
		const showingArchives = this.shouldShowArchives();

		const dots = [];
		for (let i = 0; i < this.props.rows.length; i++) {
			if (this.props.selection.indexOf(this.props.rows[i].id) >= 0) {
				let color = "#eee";
				let position = 0;
				for (let s = 0; s < this.props.groups.length; s++) {
					if (this.props.groups[s].id == this.props.rows[i].group_id) {
						color = this.props.groups[s].color;
						position = this.props.groups[s].position;
						break;
					}
				}
				dots.push({ color, position });
			}
		}
		return (
			<div className={"bulkToolbar" + (dots.length ? " open" : "")}>
				<div className="bulkbar">
					<LegacyStack spacing="none">
						<div className="bulkCount">{this.props.selection.length}</div>
						<div style={{ width: "215px", paddingBottom: "5px" }}>
							<LegacyStack.Item fill>
								<LegacyStack vertical spacing="tight">
									<div style={{ paddingTop: 15, paddingLeft: 20, fontSize: 18 }}>
										{this.props.selection.length == 1
											? this.props.t("board.bulk_menu.row_marked", "Rad markerad")
											: this.props.t("board.bulk_menu.rows_marked", "Rader markerade")}
									</div>
									<div style={{ paddingLeft: 20 }}>
										<LegacyStack spacing="extraTight">
											{dots
												.sort((a, b) => {
													if (a.position < b.position) return -1;
													if (a.position > b.position) return 1;
													if (a.position == b.position) return 0;
													return 0;
												})
												.slice(0, 100)
												.map((dot, index) => (
													<div
														key={index + ""}
														style={{
															width: 10,
															height: 10,
															borderRadius: 5,
															backgroundColor: dot.color,
														}}
													/>
												))}
											<span>{dots?.length > 100 && `+ ${dots.length - 100}`}</span>
										</LegacyStack>
									</div>
								</LegacyStack>
							</LegacyStack.Item>
						</div>
						<div>
							<LegacyStack>
								<a onClick={this.duplicateRows.bind(this)} className="bulkActionButton">
									<Icon source={DuplicateMinor} />
									<span>{this.props.t("board.bulk_menu.actions.duplicate", "Duplicera")}</span>
								</a>
								<a onClick={this.archiveRows.bind(this, showingArchives, null)} className="bulkActionButton">
									<Icon source={ArchiveMajor} />
									<span>
										{showingArchives
											? this.props.t("board.bulk_menu.actions.unarchive", "Avarkivera")
											: this.props.t("board.bulk_menu.actions.unarchive", "Arkivera")}
									</span>
								</a>
								<a onClick={this.deleteRows.bind(this, false, null)} className="bulkActionButton">
									<Icon source={DeleteMajor} />
									<span>{this.props.t("board.bulk_menu.actions.remove", "Ta bort")}</span>
								</a>
								<Popover
									active={this.state.showMoveOption}
									activator={
										<a
											onClick={() => {
												this.setState({
													showMoveOption: !this.state.showMoveOption,
													showMove: !this.state.showMoveOption ? false : this.state.showMove,
													showMoveGroup: !this.state.showMoveOption ? false : this.state.showMoveGroup,
												});
											}}
											className="bulkActionButton"
										>
											<Icon source={CircleRightMajor} />
											<span>{this.props.t("board.bulk_menu.actions.move", "Flytta")}</span>
										</a>
									}
									onClose={() => {
										this.setState({ showMoveOption: false });
									}}
								>
									{this.state.showMove ? (
										<BoardSelector onSelect={this.moveRowToGroup.bind(this)} board={this.props.board} requireGroup />
									) : this.state.showMoveGroup ? (
										<BoardSelector onSelect={this.changeGroup.bind(this)} board={this.props.board} sameBoard />
									) : (
										<span
											onClick={(event) => {
												event.stopPropagation();
											}}
										>
											<ActionList
												items={[
													{
														content: this.props.t("board.bulk_menu.actions.move_to_group", "Flytta till grupp"),
														icon: CircleRightMajor,
														onAction: () => {
															this.setState({ showMoveGroup: true });
														},
													},
													{
														content: this.props.t("board.bulk_menu.actions.move_to_board", "Flytta till board"),
														icon: CircleRightMajor,
														onAction: () => {
															this.setState({ showMove: true });
														},
													},
												]}
											/>
										</span>
									)}
								</Popover>
							</LegacyStack>
						</div>
						<a onClick={this.deselectAll.bind(this)} className="bulkCancel">
							<Icon source={MobileCancelMajor} />
						</a>
					</LegacyStack>
				</div>
				{this.state.failed_move_group ? (
					<MissingColumnConfirmationModal
						open
						loading={this.state.saving}
						board={this.state.failed_move_board}
						columns={this.state.missing_columns}
						onConfirm={() => {
							this.moveRowToGroup(this.state.failed_move_group, true);
						}}
						onClose={() => {
							this.setState({ failed_move_group: null });
						}}
					/>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BulkActionMenu);
