import React, { Component } from "react";
import { LegacyStack, Scrollable, Checkbox, FormLayout, Select } from "@shopify/polaris";
import { DragDropContext } from "react-beautiful-dnd";
import { withTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import $ from "jquery";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import KanbanGroup from "./components/KanbanGroup";
import BoardHelper from "./BoardHelper";
import Sheet from "../../components/sheet";

class BoardViewKanban extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		setTimeout(() => {
			// eslint-disable-next-line react/no-unused-state
			this.setState({}, () => this.setState({}));
		}, 0);
	}

	onDragEnd(info) {
		const row = Object.assign({}, BoardHelper.getRow(info.draggableId));

		const pcss = info.destination.droppableId.split("-");
		const newGroup = pcss[0] * 1;
		const newStatus = pcss[1] * 1;
		const newIndex = info.destination.index;

		const pcs2 = info.source.droppableId.split("-");
		const oldGroup = pcs2[0] * 1;
		const oldStatus = pcs2[1] * 1;

		const statusColumn = this.props.board.columns.find((col) => col.id == this.props.view.group_by);

		if (oldStatus != newStatus || (newGroup && newGroup != oldGroup)) {
			const data = {};

			if (oldStatus != newStatus) {
				row.column_values[statusColumn.id].value = newStatus;
				for (let i = 0; i < row.values.length; i++) {
					if (row.values[i].column_id == statusColumn.id) {
						row.values[i].value = newStatus;
					}
				}
				data.values = [{ column_id: statusColumn.id, value: newStatus }];
			}
			if (newGroup && newGroup != oldGroup) {
				data.group_id = newGroup;
				row.group_id = newGroup;
			}
			store.dispatch({ type: "UPDATE_BOARD_ROW", row });

			API.put("/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json", data, { params: {} })
				.then((result) => {})
				.catch((error) => {
					toastr.error(error);
				});
		}
		let index = -1;
		let lasti = null;
		let found = false;
		if (this.props.view.divide_by_group) {
			const kanbanOrderingByGroup = [];

			for (let i = 0; i < this.props.view.options.kanbanOrderingByGroup.length; i++) {
				if (this.props.view.options.kanbanOrderingByGroup[i] != row.id) {
					const oldRow = BoardHelper.getRow(this.props.view.options.kanbanOrderingByGroup[i]);
					if (oldRow) {
						let status = null;
						if (statusColumn.id in oldRow.column_values) {
							status = oldRow.column_values[statusColumn.id].value;
						}
						if (
							oldRow.group_id == newGroup &&
							status == newStatus &&
							this.props.rows.find((r) => r.id == this.props.view.options.kanbanOrdering[i])
						) {
							index++;
							if (index == newIndex) {
								kanbanOrderingByGroup.push(row.id);
								found = true;
							}
							lasti = i;
						}
						kanbanOrderingByGroup.push(this.props.view.options.kanbanOrderingByGroup[i]);
					}
				}
			}
			if (!found) {
				if (lasti !== null) {
					kanbanOrderingByGroup.splice(lasti, 0, row.id);
				} else {
					kanbanOrderingByGroup.unshift(row.id);
				}
			}

			for (let i = 0; i < this.props.rows.length; i++) {
				if (kanbanOrderingByGroup.indexOf(this.props.rows[i].id) < 0) {
					kanbanOrderingByGroup.push(this.props.rows[i].id);
				}
			}

			this.props.view.options.kanbanOrderingByGroup = kanbanOrderingByGroup;
			this.props.onUpdateView(this.props.view);
		} else {
			const kanbanOrdering = [];

			for (let i = 0; i < this.props.view.options.kanbanOrdering.length; i++) {
				if (this.props.view.options.kanbanOrdering[i] != row.id) {
					const oldRow = BoardHelper.getRow(this.props.view.options.kanbanOrdering[i]);
					if (oldRow) {
						let status = null;
						if (statusColumn.id in oldRow.column_values) {
							status = oldRow.column_values[statusColumn.id].value;
						}
						if (status == newStatus && this.props.rows.find((r) => r.id == this.props.view.options.kanbanOrdering[i])) {
							index++;
							if (index == newIndex) {
								kanbanOrdering.push(row.id);
								found = true;
							}
							lasti = i;
						}
						kanbanOrdering.push(this.props.view.options.kanbanOrdering[i]);
					}
				}
			}
			if (!found) {
				if (lasti !== null) {
					kanbanOrdering.splice(lasti, 0, row.id);
				} else {
					kanbanOrdering.unshift(row.id);
				}
			}

			for (let i = 0; i < this.props.rows.length; i++) {
				if (kanbanOrdering.indexOf(this.props.rows[i].id) < 0) {
					kanbanOrdering.push(this.props.rows[i].id);
				}
			}

			this.props.view.options.kanbanOrdering = kanbanOrdering;
			this.props.onUpdateView(this.props.view);
		}
	}

	sortRows(a, b) {
		let posa = null;
		let posb = null;

		if (this.props.view.divide_by_group) {
			posa = this.props.view.options.kanbanOrderingByGroup.indexOf(a.id);
			posb = this.props.view.options.kanbanOrderingByGroup.indexOf(b.id);
		} else {
			posa = this.props.view.options.kanbanOrdering.indexOf(a.id);
			posb = this.props.view.options.kanbanOrdering.indexOf(b.id);
		}

		if (posa < 0) {
			posa = 100000;
		}
		if (posb < 0) {
			posb = 100000;
		}

		return posa - posb;
	}

	getColumnOptions(types) {
		const arr = [];
		if (types.indexOf("group") >= 0) {
			arr.push({
				value: "group",
				label: this.props.t("board.kanban.column_options.groups.label", "Grupper"),
			});
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push({
					value: this.props.board.columns[i].id + "",
					label: this.props.board.columns[i].title,
				});
			}
		}
		return arr;
	}

	getOption(option) {
		return this.props.view.options && typeof this.props.view.options === "object" && this.props.view.options[option];
	}

	render() {
		// const groupByColumn =  this.props.board.columns.find((column) => column.id == this.props.view.group_by);

		let groupByColumn = null;
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].id == this.props.view.group_by) {
				groupByColumn = this.props.board.columns[i];
				break;
			}
		}

		const groupByOptions = this.getColumnOptions(["status"]);

		const personOptions = this.getColumnOptions(["person"]);

		const titleRowOptions = this.getColumnOptions(["contact", "checkbox", "board_number", "tags"]);
		const lastRowOptions = this.getColumnOptions([
			"contact",
			"datetime",
			"status",
			"checkbox",
			"number",
			"formula",
			"timetrack",
			"text",
			"timeline",
			"created_at",
			"link",
			"company_number",
			"phone",
		]);
		const valueOptions = this.getColumnOptions(["number", "timetrack", "formula"]);

		const infoOptions = this.getColumnOptions(["rowlinkfrom"]);

		const height = window.innerHeight - ((this.headerRef?.clientHeight || 0) + 15 + 56);

		return (
			<div style={{ height: "100%" }}>
				<div
					ref={(ref) => {
						this.headerRef = ref;
					}}
					className="fixed-column"
					style={{ marginRight: -10000, width: $(".board-container").width() }}
				>
					{this.props.header}
				</div>
				<div
					style={{
						height,
					}}
					className="Polaris-Page Polaris-Page--fullWidth kanban"
				>
					<DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
						{groupByColumn &&
							(this.props.view.divide_by_group ? (
								<Virtuoso
									data={this.props.groups.filter((group) => {
										const rows = this.props.rows.filter((row) => row.group_id == group.id);
										if (!this.props.haveSearch || rows.length > 0) {
											return true;
										}
										return false;
									})}
									computeItemKey={(index, group) => {
										return group?.id || index;
									}}
									style={{ flex: 1 }}
									itemContent={(key, group) => {
										return (
											<KanbanGroup
												key={key}
												onUpdateGroup={this.props.onUpdateGroup}
												rows={this.props.rows.filter((row) => row.group_id == group.id).sort(this.sortRows.bind(this))}
												group={group}
												board={this.props.board}
												column={groupByColumn}
												editRow={this.props.editRow}
												onCreateRow={this.props.onCreateRow}
												view={this.props.view}
											/>
										);
									}}
								/>
							) : (
								// this.props.groups
								// 	.filter((group) => {
								// 		let rows = this.props.rows.filter((row) => row.group_id == group.id);
								// 		if (!this.props.haveSearch || rows.length > 0) {
								// 			return true;
								// 		}
								// 		return false;
								// 	})
								// 	.map((group, key) => (
								// 		<KanbanGroup
								// 			key={key}
								// 			onUpdateGroup={this.props.onUpdateGroup}
								// 			rows={this.props.rows.filter((row) => row.group_id == group.id).sort(this.sortRows.bind(this))}
								// 			group={group}
								// 			board={this.props.board}
								// 			column={groupByColumn}
								// 			editRow={this.props.editRow}
								// 			onCreateRow={this.props.onCreateRow}
								// 			view={this.props.view}
								// 		/>
								// 	))
								<KanbanGroup
									onUpdateGroup={this.props.onUpdateGroup}
									rows={this.props.rows.sort(this.sortRows.bind(this))}
									group={{ id: 0 }}
									board={this.props.board}
									column={groupByColumn}
									editRow={this.props.editRow}
									onCreateRow={this.props.onCreateRow}
									view={this.props.view}
								/>
							))}
					</DragDropContext>
				</div>

				<Sheet
					title={this.props.t("board.kanban.terms.settings", "Inställningar")}
					open={this.props.showSettings}
					onClose={this.props.onCloseSettings}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ padding: "1.0000rem", height: "100%" }}>
							<FormLayout>
								<Select
									label={this.props.t("board.kanban.fields.column.label", "Kanban kolumn")}
									options={groupByOptions}
									value={this.props.view.group_by}
									onChange={(v) => {
										this.props.view.group_by = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
								{titleRowOptions && titleRowOptions.length && (
									<LegacyStack distribution="fillEvenly">
										<Select
											label={this.props.t("board.kanban.fields.title.label", "Titel")}
											options={[{ value: "", label: "-" }].concat(titleRowOptions)}
											value={this.getOption("titleRow_columnId")}
											onChange={(v) => {
												this.props.view.options.titleRow_columnId = v;
												this.props.onUpdateView(this.props.view);
											}}
											helpText={this.props.t("board.kanban.fields.title.helpText", "kontakt, taggar, eller checkbox")}
										/>

										<Select
											label={this.props.t("board.kanban.fields.title2.label", "Titel 2")}
											options={[{ value: "", label: "-" }].concat(titleRowOptions)}
											value={this.getOption("titleRow_columnId2")}
											onChange={(v) => {
												this.props.view.options.titleRow_columnId2 = v;
												this.props.onUpdateView(this.props.view);
											}}
											helpText={this.props.t("board.kanban.fields.title2.helpText", "kontakt eller checkbox")}
										/>
									</LegacyStack>
								)}

								<Select
									label={this.props.t("board.kanban.fields.sumRow_columnId.label", "Värde")}
									options={[{ value: "", label: "-" }].concat(valueOptions)}
									value={this.getOption("sumRow_columnId")}
									onChange={(v) => {
										this.props.view.options.sumRow_columnId = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>

								<Select
									label={this.props.t("board.kanban.fields.info_columnId.label", "Information")}
									options={[{ value: "", label: "-" }].concat(infoOptions)}
									value={this.getOption("info_columnId")}
									onChange={(v) => {
										this.props.view.options.info_columnId = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>

								<LegacyStack distribution="fillEvenly">
									{lastRowOptions && lastRowOptions.length && (
										<Select
											label={this.props.t("board.kanban.fields.lastRow_column_id.label", "Övrig rad")}
											options={[{ value: "", label: "-" }].concat(lastRowOptions)}
											value={this.getOption("lastRow_columnId")}
											onChange={(v) => {
												this.props.view.options.lastRow_columnId = v;
												this.props.onUpdateView(this.props.view);
											}}
											helpText="kontakt eller checkbox"
										/>
									)}
									<Select
										label={this.props.t("board.kanban.fields.color_by.label", "Ansvarig person")}
										options={personOptions}
										value={this.props.view.color_by}
										onChange={(v) => {
											this.props.view.color_by = v;
											this.props.onUpdateView(this.props.view);
										}}
									/>
								</LegacyStack>
								<Checkbox
									label={this.props.t("board.kanban.fields.divide_by_group.label", "Dela upp i grupper")}
									checked={this.props.view.divide_by_group}
									onChange={(v) => {
										this.props.view.divide_by_group = v;
										this.props.onUpdateView(this.props.view);
									}}
								/>
							</FormLayout>
						</Scrollable>
					</div>
				</Sheet>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardViewKanban);
