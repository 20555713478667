import React, { Component } from "react";
import { EmptyState, Badge, ExceptionList, Spinner } from "@shopify/polaris";
import { ImportMinor, NoteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import ImportModal from "../Workspaces/components/ImportModal.js";
import NumberBadge from "../../components/NumberBadge.js";
import StatsBoxWrapper from "../../components/statsBoxes";
import IconWithBackround from "../../components/IconWithBackround";
import Colors, { colorToRgba } from "../../Colors";
import { InfoIcon, ListIcon, SadFaceIcon, CheckmarkWithBottomIcon } from "../../icons";
import IndexTable from "src/js/components/IndexTable";

class EmailContacts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			empty: false,
			firstFetch: true,
			visibleItems: [],
			stats: {},
		};
	}

	componentDidMount() {
		this.fetchStats();
	}

	importExcel() {
		// this.setState({openUpload:true});
		this.setState({ showImportModal: true });
	}

	gotoCampaign(item) {
		item.path = "/admin/contacts/" + item.id;
		this.props.history.push("/admin/contacts/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("email_contact.plural", "E-postkontakter"),
					item: { id: item.id, item, path: "/admin/contacts/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	getValidationBadge(status) {
		// bounced, processing, not_approved, unsubscribed, approved, verified
		if (status == "verified") {
			return <NumberBadge color={Colors.green}>{this.props.t("email_contact.status.verified", "verifierad")}</NumberBadge>;
		} else if (status == "bounced") {
			return <NumberBadge color={Colors.red}>{this.props.t("email_contact.status.bounced", "studsad")} </NumberBadge>;
		} else if (status == "not_approved") {
			return <NumberBadge color={Colors.red}>{this.props.t("email_contact.status.not_approved", " ej godkänd<")} </NumberBadge>;
		} else if (status == "unsubscribed") {
			return <NumberBadge color={Colors.orange}>{this.props.t("email_contact.status.unsubscribed", "avregistrerad")} </NumberBadge>;
		} else if (status == "approved") {
			return <NumberBadge color={Colors.green}>{this.props.t("email_contact.status.approved", "godkänd")} </NumberBadge>;
		}
		return <NumberBadge color={Colors.lightblue}>{this.props.t(`email_contact.status.${status}`, { status })} </NumberBadge>;
	}

	onItemsFetched(items) {
		if (items && items.length < 1 && this.state.firstFetch) {
			this.setState({ empty: true });
		}
		for (let i = 0; i < items.length; i++) {
			items[i].path = "/admin/email_campaigns/" + items[i].id;
		}
		this.setState({ visibleItems: items, firstFetch: false });
	}

	fetchStats() {
		this.setState({ loadingStats: true });
		API.get("/api/email_contacts_stats.json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ loadingStats: false });
					return;
				}
				this.setState({ stats: result.data, loadingStats: false });
			})
			.catch((error) => {
				this.setState({ loadingStats: false });
				toastr.error(error);
			});
	}

	render() {
		if (this.state.empty) {
			return (
				<div>
					<EmptyState
						heading={this.props.t("email_contact.empty.heading", "Inga e-postkontakter")}
						action={{
							content: this.props.t("emaiol_contact.actions.import", "Importera kontakter"),
							onAction: () => {
								this.setState({ showImportModal: true });
							},
						}}
						image="/assets/images/empty_state/NoResults.png"
					>
						<p>{this.props.t("email_contact.empty.text", "Skicka ut en e-postkampanj eller importera kontakter här")}</p>
					</EmptyState>
					<ImportModal
						open={this.state.showImportModal}
						asVisitor
						type="people"
						onComplete={() => {
							this.setState({ empty: false, firstFetch: true });
						}}
						onClose={() => {
							this.setState({ showImportModal: false });
						}}
					/>
				</div>
			);
		}

		const filters = [
			{
				key: "email_opted",
				label: this.props.t("email_contact.filters.email_opted.label", "Är e-postkontakt"),
				type: "select",
				options: [
					{ label: this.props.t("common.terms.yes", "Ja"), value: 1 },
					{ label: this.props.t("common.terms.no", "Nej"), value: 0 },
				],
				shortcut: true,
			},
			{
				key: "status",
				label: this.props.t("email_contact.filters.status.label", "Status"),
				operatorText: this.props.t("common.terms.is", "är"),
				type: "select",
				options: [
					{ value: "bounced", label: this.props.t("email_contact.filters.status.options.bounced", "studsad") },
					{ value: "not_approved", label: this.props.t("email_contact.filters.status.options.not_approved", "ej godkänd") },
					{ value: "unsubscribed", label: this.props.t("email_contact.filters.status.options.unsubscribed", "avregistrerad") },
					{ value: "approved", label: this.props.t("email_contact.filters.status.options.approved", "godkänd") },
					{ value: "verified", label: this.props.t("email_contact.filters.status.options.verified", "verifierad") },
				],
				shortcut: true,
			},
		];

		const columns = [
			{
				handle: "NAME",
				label: this.props.t("email_contact.columns.name.label", "Namn"),
				sortable: false,
				render: (item) => {
					const exceptions = [];

					if (item.note) {
						exceptions.push({
							icon: NoteMajor,
							description: item.note,
						});
					}

					const exceptionList = exceptions.length ? (
						<div className="CustomerListItem__Exceptions">
							<ExceptionList items={exceptions} />
						</div>
					) : null;

					return (
						<div>
							<div className="CustomerListItem__Profile">
								<h3 className="CustomerListItem__Title">{item.name}</h3>
								<span className="CustomerListItem__Location">{item.parent ? item.parent.name : null}</span>
							</div>
							<div>
								{item.tags.map((tag, key) => (
									<Badge key={key}>{tag}</Badge>
								))}
							</div>
							{exceptionList}
						</div>
					);
				},
			},
			{
				handle: "EMAIL",
				label: this.props.t("email_contact.columns.email.label", "E-post"),
				sortable: false,
				render: (item) => item.email,
			},
			{
				handle: "VALIDATION_STATUS",
				label: this.props.t("email_contact.columns.validation_status.label", "Status"),
				sortable: false,
				render: (item) => this.getValidationBadge(item.validation_status),
			},
		];

		const getBoxTitle = (key) => {
			if (this.state.loadingStats) return <Spinner size="small" />;
			if (this.state.stats && key in this.state.stats) {
				return `${this.state.stats[key]} st`;
			}
			return "-";
		};

		return (
			<div>
				<IndexTable
					history={this.props.history}
					extraHeader={
						<StatsBoxWrapper
							perRow={4}
							items={[
								{
									title: getBoxTitle("total"),
									text: this.props.t("email_contact.stats.total.label", "Totalt"),
									icon: <IconWithBackround icon={<ListIcon color={Colors.blue} />} backgroundColor={colorToRgba(Colors.blue, 0.2)} />,
								},
								{
									title: getBoxTitle("verified"),
									text: this.props.t("email_contact.stats.verified.label", "Verifierade"),
									icon: (
										<IconWithBackround icon={<CheckmarkWithBottomIcon color={Colors.green} />} backgroundColor={colorToRgba(Colors.green, 0.2)} />
									),
								},
								{
									title: getBoxTitle("non_verified"),
									text: this.props.t("email_contact.stats.non_verified.label", "Ej verifierade"),
									icon: <IconWithBackround icon={<InfoIcon color={Colors.orange} />} backgroundColor={colorToRgba(Colors.orange, 0.2)} />,
								},
								{
									title: getBoxTitle("bounced"),
									text: this.props.t("email_contact.stats.bounced.label", "Studsad"),
									icon: <IconWithBackround icon={<SadFaceIcon color={Colors.red} />} backgroundColor={colorToRgba(Colors.red, 0.2)} />,
								},
							]}
						/>
					}
					setRefreshHandler={(refreshHandler) => {
						this.refresh = refreshHandler;
					}}
					title={this.props.t("email_contact.plural", "E-postkontakter")}
					secondaryActions={[
						{
							icon: ImportMinor,
							content: this.state.uploading ? (
								<Spinner size="small" color="teal" />
							) : (
								this.props.t("email_contact.actions.import", "Importera kontakter")
							),
							onAction: this.importExcel.bind(this),
						},
					]}
					savedSearchHandle="email_campaigns"
					resourceUrl="/api/email_contacts.json?email_opted=1&have_email=1"
					resourceHandle="contacts"
					onItemsFetched={this.onItemsFetched.bind(this)}
					resourceName={{
						singular: this.props.t("email_contact.contact.singular", "kontakt"),
						plural: this.props.t("email_contact.contact.plural", "kontakter"),
					}}
					defaultSort="SELLER_DESC"
					onClickRow={(item) => {
						this.gotoCampaign(item);
					}}
					columns={columns}
					filters={filters}
				/>
				<ImportModal
					open={this.state.showImportModal}
					asVisitor
					onComplete={() => {
						this.refresh();
					}}
					type="people"
					onClose={() => {
						this.setState({ showImportModal: false });
					}}
				/>
			</div>
		);
	}
}

export default withTranslation(["email_contact", "common"], { withRef: true })(EmailContacts);
