import React, { Component } from "react";
import { LegacyStack, FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import Modal from "src/js/components/modal";

import StripeForm from "./stripe/stripe_form.js";

class BillingServiceModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, saving: false, form: { first_name: "", last_name: "", street: "", city: "", zip: "", country: "SE" } };
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.setState({ open: props.open });
	}

	setTokenizerCallback(callback) {
		this.callback = callback;
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	addCard() {
		this.setState({ saving: true });
		this.callback();
	}

	stripeResponseHandler(response) {
		if (response.error) {
			toastr.error(response.error.message);
			this.setState({ saving: false });
		} else {
			// Post to server
			const form = this.state.form;
			form.token = response.source.id;
			API.post(store.getState().authentication.endpointPrefix + "/billing_services.json", form, {
				params: store.getState().authentication.defaultParams,
			})
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					this.props.onComplete(result.data.billing_service);
					toastr.success("Card added");
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	getName() {
		const name = this.state.form.first_name + " " + this.state.form.last_name;
		return name.trim();
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("billing.modals.add_card.title", "Add credit card")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Close"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.add", "Add"),
					loading: this.state.saving,
					onAction: this.addCard.bind(this),
				}}
			>
				<Modal.Section>
					<StripeForm
						name={this.getName()}
						onResponse={this.stripeResponseHandler.bind(this)}
						setTokenizerCallback={this.setTokenizerCallback.bind(this)}
					/>
				</Modal.Section>
				<Modal.Section>
					<FormLayout>
						<LegacyStack distribution="fill">
							<TextField
								label={this.props.t("billing.modals.add_card.fields.first_name.label", "First name")}
								value={this.state.form.first_name}
								onChange={this.updateForm.bind(this, "first_name")}
							/>
							<TextField
								label={this.props.t("billing.modals.add_card.fields.last_name.label", "Last name")}
								value={this.state.form.last_name}
								onChange={this.updateForm.bind(this, "last_name")}
							/>
						</LegacyStack>
						<TextField
							label={this.props.t("billing.modals.add_card.fields.street.label", "Street address")}
							value={this.state.form.street}
							onChange={this.updateForm.bind(this, "street")}
						/>
						<LegacyStack distribution="fill">
							<TextField
								label={this.props.t("billing.modals.add_card.fields.zip.label", "Zip")}
								value={this.state.form.zip}
								onChange={this.updateForm.bind(this, "zip")}
							/>
							<TextField
								label={this.props.t("billing.modals.add_card.fields.city.label", "City")}
								value={this.state.form.city}
								onChange={this.updateForm.bind(this, "city")}
							/>
						</LegacyStack>
						<LegacyStack distribution="fill">
							<Select
								label={this.props.t("billing.modals.add_card.fields.country.label", "Country")}
								options={store.getState().countries}
								onChange={this.updateForm.bind(this, "country")}
								value={this.state.form.country}
							/>
						</LegacyStack>
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["billing", "common"], { withRef: true })(BillingServiceModal);
