import HighchartsReact from "highcharts-react-official";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import moment from "moment";
import { LegacyCard, InlineError, Spinner } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import Colors, { colorToRgba } from "../../../Colors";
import IconWithBackround from "../../../components/IconWithBackround";
import { StatsBox } from "src/js/components/statsBoxes";
import { DollarIcon, GraphIcon, HeartIcon } from "../../../icons";
import { store } from "../../../store";
import TimeLine from "../../../components/TimeLine";
import Note from "../Note";
import LatestContactDeals from "../LatestContactDeals";
import GoalPercentage from "../../Goals/GoalPercentage";
import UpcomingActivity from "../UpcomingActivity";
import { toastr } from "../../../components/toastr.js";
import LateActivity from "../LateActivity";
import useQuery from "src/js/hooks/useQuery";
import { UseQueryResult } from "@tanstack/react-query";
import Select from "src/js/components/select/Select";
import fetchRows from "../../Workspaces/boardutils/fetchRows";
import { useEventListener } from "src/js/hooks/UseEventListener";
import { currencyFormatter } from "src/js/Utilities";

const ContactDashboard = ({ t, setCounts, contact, updateForm, setTab, history }) => {
	const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));
	const [data, setData] = useState(null);
	const [latestDeal, setLatestDeal] = useState<any>(null);
	const boardId = store.getState().account.sales_board_id;

	const fetchBoard = useCallback(async () => {
		try {
			const res = await API.get(`/api/boards/${boardId}.json`);
			return res.data.board;
		} catch (error) {
			toastr.error(error);
			return null;
		}
	}, [boardId]);

	const queryKey = [boardId && `board_${boardId}`].filter(Boolean);
	const { data: board = null, isFetching: IsLoadingBoard }: UseQueryResult<BoardType | null> = useQuery({
		queryKey,
		queryFn: fetchBoard,
		refetchOnWindowFocus: false,
		initialData: store.getState().boards[boardId] || null,
		enabled: !!boardId,
	});

	const params: any = useMemo(() => {
		const p: any = {};

		if (contact?.id) {
			p.contact_id = contact.id;
			p.also_archived = 1;
		}

		return p;
	}, [contact?.id]);

	const handleFetchRows = useCallback(async () => {
		const rows: any = await fetchRows({ boardId, params });
		setCounts?.(boardId, rows?.filter((r) => !r.archived).length);
		return rows;
	}, [boardId, params, setCounts]);

	const rowsQueryKey = [boardId && `board_${boardId}_rows`, Object.keys(params).length && `board_${boardId}_rows_${JSON.stringify(params)}`].filter(
		Boolean
	);

	const {
		data: rows = [],
		isFetching: IsLoadingrows,
		refetch: refreshRows,
	}: UseQueryResult<any | null> = useQuery({
		queryKey: rowsQueryKey,
		queryFn: handleFetchRows,
		refetchOnWindowFocus: false,
		initialData: [],
		enabled: !!boardId,
	});

	useEventListener("refreshDeals" as any, refreshRows);

	const getMissingColumns = useCallback(() => {
		if (!board || !board.columns) return [];
		return ["affärsstatus", "omsättning", "vunnen/förlorad"].filter((title) => !board.columns.find((col) => col.title.toLowerCase() === title));
	}, [board]);

	const getData = () => {
		// loading / placeholder data
		const zeroAr = Array.from(Array(12)).map(() => 0);
		const indexAr = Array.from(Array(12)).map((v, index) => index);
		if (!data) return { currentYear: indexAr, prevYear: indexAr };

		const currentYear = data[moment(selectedYear).format("YYYY")];
		const prevYear = data[moment(selectedYear).subtract(1, "year").format("YYYY")];

		return {
			currentYear: (currentYear && Object.values(currentYear)) || zeroAr,
			prevYear: (prevYear && Object.values(prevYear)) || zeroAr,
		};
	};
	const getValue = (row, column) => {
		return (row && column && column.id && row.column_values && row.column_values[column.id] && row.column_values[column.id].value) || 0;
	};

	const transaformRowsToData = useCallback(
		(rows): any => {
			if (!board || getMissingColumns().length) return [];

			const column = board.columns.find((col) => col.title.toLowerCase() === "affärsstatus");
			const omsattningColumn = board.columns.find((col) => col.title.toLowerCase() === "omsättning");
			const wonDateColumn = board.columns.find((col) => col.title.toLowerCase() === "vunnen/förlorad");
			const wonStatusIds = board?.options?.stats?.won.status_values || [];

			const wonRows = rows
				.sort((a, b) =>
					wonDateColumn && moment(getValue(a, wonDateColumn)?.datetime).isAfter(moment(getValue(b, wonDateColumn)?.datetime)) ? -1 : 1
				)
				.filter((row) => {
					return row?.column_values?.[wonDateColumn?.id]?.value?.datetime && wonStatusIds.includes(row?.column_values?.[column?.id]?.value);
				});

			const data = wonRows.reduce((acc, row) => {
				const d = wonDateColumn && getValue(row, wonDateColumn)?.datetime;
				const omsattning = getValue(row, omsattningColumn);
				if (!d) return acc;
				const year = moment(d).format("YYYY");
				const month = moment(d).format("M");

				const currentMonthValue = (acc[year] && acc[year][month]) || 0;
				return Object.assign(acc, {
					[year]: Object.assign(acc[year] || {}, {
						[month]: currentMonthValue + (omsattning || 0),
					}),
				});
			}, {});

			// Adding empty values for the missing months do the grapth doesnt crash
			const dataWithAllMonths = Object.keys(data).reduce((acc, year) => {
				for (let i = 1; i <= 12; i++) {
					if (!acc[year][i]) acc[year][i] = 0;
				}

				return acc;
			}, data);

			return {
				data: dataWithAllMonths,
				latestDeal: {
					value: omsattningColumn && wonRows[0]?.column_values?.[omsattningColumn.id]?.value,
					date: wonDateColumn && wonRows[0] && getValue(wonRows[0], wonDateColumn)?.datetime,
					created_at: wonRows[0]?.created_at,
				},
			};
		},
		[board, getMissingColumns]
	);

	useEffect(() => {
		const { data, latestDeal } = transaformRowsToData(rows);

		setData(data);
		setLatestDeal(latestDeal);
	}, [boardId, rows, transaformRowsToData, setCounts]);

	useEffect(() => {
		Highcharts.setOptions({
			lang: {
				numericSymbols: [null, t("common.terms.currency.million", "miljoner"), t("common.terms.currency.billion", "miljarder")],
			},
		});
	}, [fetchBoard, t, refreshRows]);

	const labels = moment.monthsShort();
	const { currentYear, prevYear } = getData();
	const empty = (!currentYear || currentYear.every((i) => !i)) && (!prevYear || prevYear.every((i) => !i));
	const loading = IsLoadingBoard || IsLoadingrows;

	const graphData = {
		title: null,
		credits: {
			enabled: false,
		},
		tooltip: {
			borderRadius: 5,
			padding: 15,
			shadow: true,
		},
		chart: {
			type: "column",
			height: 333 - (60 + 40),
		},

		yAxis: {
			title: {
				text: null,
			},
			labels: {
				// style: {
				// 	color: "rgba(242, 242, 242, 0.1)",
				// },
			},

			// gridLineColor: "#F2F2F2;",
			gridLineDashStyle: "longdash",
		},
		xAxis: {
			// crosshair: {
			// 	width: 1,
			// 	dashStyle: "dotted",
			// 	className: "highcharts-crosshair",
			// },
			categories: labels,
		},
		series: [
			{
				name: selectedYear,
				data: currentYear,
				color: {
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, loading ? "rgba(100,100,100, 0.5)" : colorToRgba(Colors.green, 0.75)],
						[1, loading ? "rgba(150,150,150, 0.5)" : Colors.green],
					],
				},
				showInLegend: false,
			},
			{
				name: moment(selectedYear).subtract(1, "year").format("YYYY"),
				data: prevYear,
				color: {
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, loading ? "rgba(100,100,100, 0.5)" : colorToRgba(Colors.blue, 0.75)],
						[1, loading ? "rgba(150,150,150, 0.5)" : Colors.blue],
					],
				},
				showInLegend: false,
			},
		],
		plotOptions: {
			series: { borderRadius: 4, borderColor: "rgba(0,0,0,0)" },
			column: { pointWidth: 8 },
			bar: {},
		},
	};

	const yearOptions = (() => {
		const years =
			Object.keys(data || {}).map((year) => ({
				label: year,
				value: year,
			})) || [];

		if (!years.find((y) => y.value === moment().format("YYYY"))) {
			years.push({
				label: moment().format("YYYY"),
				value: moment().format("YYYY"),
			});
		}

		return years.sort((a, b) => (moment(a.value).isBefore(b.value) ? 1 : -1));
	})();

	const missingColumns = getMissingColumns();

	return (
		<div className="contact_dashboard">
			<div className="contact-statsbox-wrapper">
				<StatsBox
					item={{
						title: t("contact.dashboard.deals.latest_won", "Senaste vunna affär"),
						text: loading ? (
							<Spinner size="small" />
						) : latestDeal ? (
							currencyFormatter({ value: latestDeal.value || 0, currency: "SEK" })
						) : (
							t("contact.dashboard.deals.empty", "Ingen affär")
						),
						icon: <IconWithBackround icon={<HeartIcon color={Colors.blue} />} color={Colors.blue} />,
					}}
				>
					<p
						style={{
							color: "var(--textColor2)",
							position: "absolute",
							top: 10,
							right: 10,
						}}
					>
						{latestDeal?.date && moment(latestDeal.date).format("YYYY-MM-DD")}
					</p>
				</StatsBox>

				<StatsBox
					item={{
						title: t("contact.dashboard.deals.total_text", "Vunna affärer {{year}}", { year: selectedYear }),
						text: (() => {
							if (loading) return <Spinner size="small" />;
							if (!data || !data[selectedYear]) return 0;
							const value: any = Object.values(data[selectedYear]).reduce((acc: any, v: any) => acc + parseFloat(v || 0), 0);
							const prevData: any = data[moment(selectedYear).subtract(1, "year").format("YYYY")] || {};
							const prevValue: any = Object.values(prevData).reduce((acc: any, v: any) => acc + parseFloat(v || 0), 0);

							const percentageDiff = ((value - prevValue) / prevValue) * 100;

							return (
								<div
									style={{
										display: "flex",
										gap: "0.6250rem",
										alignItems: "center",
									}}
								>
									{currencyFormatter({ value, currency: "SEK" })}

									{Number.isFinite(percentageDiff) && <GoalPercentage tooltip={false} value={percentageDiff.toFixed()} />}
								</div>
							);
						})(),
						icon: <IconWithBackround icon={<DollarIcon color={Colors.purple} />} color={Colors.purple} />,
					}}
				/>
				<StatsBox
					item={{
						title: t("contact.dashboard.activity.latest", "Senaste aktivitet"),
						text: loading ? (
							<Spinner size="small" />
						) : contact.last_activity_at ? (
							moment(contact.last_activity_at).format("YYYY-MM-DD")
						) : (
							t("contact.dashboard.activity.empty", "Ingen aktivitet")
						),
						icon: <IconWithBackround icon={<DollarIcon color={Colors.green} />} color={Colors.green} />,
					}}
				/>
				<StatsBox
					item={{
						title: t("contact.dashboard.deals.total_text", "Vunna affärer {{year}}", {
							year: moment(selectedYear).subtract(1, "year").format("YYYY"),
						}),
						text: (() => {
							if (loading) return <Spinner size="small" />;
							if (!data || !data[moment(selectedYear).subtract(1, "year").format("YYYY")]) return 0;
							const value = Object.values(data[moment(selectedYear).subtract(1, "year").format("YYYY")]).reduce(
								(acc: any, v: any) => acc + parseFloat(v || 0),
								0
							) as number;

							return currencyFormatter({ value, currency: "SEK" });
						})(),
						icon: <IconWithBackround icon={<GraphIcon color={Colors.purple} />} color={Colors.purple} />,
					}}
				/>
			</div>

			<UpcomingActivity
				contact={contact}
				preview
				onShowMore={() => {
					setTab("upcoming_tasks");
				}}
				onFetch={(data) => {
					setCounts("upcoming_tasks", data?.count);
				}}
			/>

			<LateActivity
				contact={contact}
				preview
				onShowMore={() => {
					setTab("late_tasks");
				}}
				onFetch={(data) => {
					setCounts("late_tasks", data?.count);
				}}
			/>

			<Note
				contact={contact}
				// className={`note_${contact?.is_company ? "company" : "person"}`}
				note={contact?.note}
				onUpdate={(note) => updateForm("note", note)}
				disabled={contact?.removed}
			/>

			<div className="timeline">
				<TimeLine
					title={t("contact.timeline.title", "Senaste aktivitet")}
					onShowMore={() => {
						setTab("info");
					}}
					preview
					disabled={contact?.removed}
					handle={"contacts/" + contact.id}
					tasks={[]}
					deals={[]}
					tickets={[]}
					projects={[]}
					call_lists={[]}
					history={history}
					onComment={() => {}}
					onNewContact={() => {}}
					contact={contact}
					isCompany={contact.is_company}
				/>
			</div>

			<LatestContactDeals contact={contact} rows={rows} board={board} loading={loading} setTab={setTab} />

			<div className="contact_dashboard__graph">
				{empty && (
					<div
						style={{
							position: "absolute",
							inset: 0,
							backgroundColor: "var(--main2-50)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							borderRadius: "0.4375rem",
							zIndex: 1,
						}}
					>
						<span>{t("contact.dashboard.graph.empty.period", "Det finns inget statistik för denna period")}</span>
					</div>
				)}

				<LegacyCard sectioned>
					<h2 className="card_title">{t("contact.dashboard.graph.title", "Försäljning")}</h2>

					<div className="contact_dashboard__graph_header">
						{missingColumns.length ? (
							<InlineError
								fieldID="missing_columns"
								message={t("contact.dashboard.errors.missing_columns", "Saknar kolumner {{missingColumns}} i board {{boardTitleId}}", {
									boardTitleId: store.getState().account.sales_board_id,
									missingColumns: missingColumns.join(", "),
								})}
							/>
						) : (
							<React.Fragment>
								<div>
									<span className="color-year">
										<div className="circle" style={{ backgroundColor: Colors.green }} />
										{selectedYear}
									</span>
									<span className="color-year">
										<div className="circle" style={{ backgroundColor: Colors.blue }} />

										{moment(selectedYear).subtract(1, "year").format("YYYY")}
									</span>
								</div>

								<Select
									options={yearOptions}
									disabled={!data}
									value={selectedYear}
									onChange={(selectedYear) => {
										setSelectedYear(selectedYear);
									}}
								/>
							</React.Fragment>
						)}
					</div>
					<HighchartsReact highcharts={Highcharts} options={graphData} />
				</LegacyCard>
			</div>
		</div>
	);
};
export default withTranslation(["contact", "common"], { withRef: true })(ContactDashboard);
