import { UseQueryResult, useQuery as useReactQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { useDeepCompareMemo } from "use-deep-compare";

type useQueryProps = {
	queryKey: any;
	queryFn: any;
	refetchOnWindowFocus?: boolean;
	initialData?: any;
	enabled?: boolean;

	fetchOnce?: boolean;

	[key: string]: any;
};

const useQuery = ({
	queryKey,
	queryFn,
	refetchOnWindowFocus = false,
	initialData,
	enabled: propsEnabled = true,
	fetchOnce,
	...rest
}: useQueryProps) => {
	const queryClient = useQueryClient();
	const filteredQueryKey = useDeepCompareMemo(() => {
		return queryKey?.filter(Boolean);
	}, [queryKey]);
	const enabled = propsEnabled && filteredQueryKey?.length > 0;

	const queryFnWrapper = async (i) => {
		if (!enabled) return initialData || null;
		if (fetchOnce && queryClient.getQueryData(filteredQueryKey)) return queryClient.getQueryData(filteredQueryKey);

		const res = await queryFn(i);
		if (res === undefined) return initialData || null;
		return res;
	};

	const queryResult = useReactQuery({
		queryKey: filteredQueryKey,
		queryFn: queryFnWrapper,
		refetchOnWindowFocus,
		initialData,
		enabled,
		...rest,
	}) as UseQueryResult<any, any>;

	const data = useMemo(() => {
		return {
			...queryResult,
			error: queryResult.error?.response?.data?.message || queryResult.error?.message || queryResult.error,
		};
	}, [queryResult]);

	useEffect(() => {
		if (!enabled) {
			//remove query from cache when the key is an empty array or not enabled
			queryClient.removeQueries(filteredQueryKey, { exact: true });
		}

		return () => {
			if (!enabled) {
				//remove query from cache when the key is an empty array or not enabled
				queryClient.removeQueries(filteredQueryKey, { exact: true });
			}
		};
	}, [enabled, filteredQueryKey, queryClient]);

	return data;
};

export default useQuery;
