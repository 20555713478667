import React, { Component } from "react";
import { Layout, LegacyCard, FormLayout, TextField, Button } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { store } from "../../store";

import { toastr } from "../../components/toastr.js";

class Tracking extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saving: false,
			ignored_paths_orig: store.getState().account.ignored_paths,
			ignored_paths: store.getState().account.ignored_paths,
		};
	}

	saveIgnoredPaths() {
		this.setState({ saving: true });
		API.put("/api/account.json", { ignored_paths: this.state.ignored_paths })
			.then((result) => {
				this.setState({ saving: false, ignored_paths: result.data.account.ignored_paths, ignored_paths_orig: result.data.account.ignored_paths });
				store.dispatch({ type: "SET_IGNORED_PATHS", ignored_paths: result.data.account.ignored_paths });
				toastr.success(this.props.t("tracking.responses.paths.updated", "Ignorerade sökvägar uppdaterades"));
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateIgnoredPaths(value) {
		const rows = value.split("\n");
		const newRows = [];
		for (let i = 0; i < rows.length; i++) {
			let row = rows[i];
			if (row.length > 0 && row.substr(0, 1) != "/") {
				row = "/" + row;
			}
			newRows.push(row.trim());
		}
		this.setState({ ignored_paths: newRows.join("\n") });
	}

	render() {
		return (
			<div>
				<Page
					title={this.props.t("tracking.title", "Spårning")}
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				>
					<Layout>
						<Layout.AnnotatedSection
							title={this.props.t("tracking.title", "Spårning")}
							description={this.props.t("tracking.description", "Spåra dina besökare på din hemsida")}
						>
							<LegacyCard sectioned title={this.props.t("tracking.terms.card.title", "Spårningskod")}>
								<FormLayout>
									<TextField
										label={this.props.t("tracking.fields.script.label", "Kopiera och klistra in följande kod på din hemsida")}
										multiline
										value={
											// eslint-disable-next-line quotes
											'<script>\n\twindow.__st=window.__st || {"offset":3600};\n\t//User id: window.__st.uid=4;\n\t//User e-mail: window.__st.umail=\'user@example.com\';\n</script>\n<script src="https://' +
											store.getState().account.handle +
											".sellfinity.com/tracking/" +
											store.getState().account.id +
											// eslint-disable-next-line quotes
											'.js"></script>'
										}
									/>
									<TextField
										label={this.props.t("tracking.fields.conversions_script.label", "Kod för att spåra konverteringar på din hemsida")}
										multiline
										value={
											"<script>\n\twindow.__ct = window.__ct||[];\n\twindow.__ct.push({\n\t\tid: '55',\t\t//Unikt id för konverteringen\n\t\ttype: 'lead',\t\t//Ett av event, lead eller sale\n\t\ttitle: 'Lead från hemsida',\t\t//Namn för din konvertering\n\t\tvalue: 500,\t\t//Värdet (är värdet över 0 skapas en affär)\n\t\tcurrency: 'SEK',\t\t//Valutakod\n\t\tdeal_id: ''\t\t//Affärs-id (om någon)\n\t});\n</script>"
										}
									/>
									<TextField
										label={this.props.t("tracking.fields.paths.label", "Ignorerade sökvägar")}
										multiline
										value={this.state.ignored_paths}
										onChange={this.updateIgnoredPaths.bind(this)}
										helpText={this.props.t(
											"tracking.fields.paths.helptext",
											"En sökväg per rad, t.ex /admin ignorerar alla besök från adresser som börjar på /admin"
										)}
									/>
									{this.state.ignored_paths != this.state.ignored_paths_orig ? (
										<Button onClick={this.saveIgnoredPaths.bind(this)} loading={this.state.saving} primary>
											{this.props.t("common.actions.save", "Spara")}
										</Button>
									) : null}
								</FormLayout>
							</LegacyCard>
						</Layout.AnnotatedSection>
					</Layout>
				</Page>
			</div>
		);
	}
}

export default withTranslation(["tracking", "common"], { withRef: true })(Tracking);
