import React, { Component } from "react";
import { CalendarMajor, MobileVerticalDotsMajor, CircleAlertMajor, CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import $ from "jquery";
import { Icon, Spinner, Scrollable, Truncate, Button, Tooltip } from "@shopify/polaris";
import axios from "axios";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import sv from "date-fns/locale/sv";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { store } from "../../../store";
import SyncCalendarModal from "../../Workspaces/components/SyncCalendarModal.js";
import { toastr } from "../../../components/toastr";
import BoardHelper from "../../Workspaces/BoardHelper";
import "react-datepicker/dist/react-datepicker.css";

import Popover from "../../../components/Popover";
import Colors, { colorToRgba } from "../../../Colors";
import CalendarModalContent from "../../Workspaces/components/CalendarModal/CalendarModalContent";
import CalendarPopupPreview from "../../Workspaces/components/CalendarModal/CalendarPopupPreview";
import CalendarHelper from "../../Workspaces/components/CalendarModal/CalendarHelper";
import Sheet from "../../../components/sheet";
import { addRowForEachDateInDates, formatDeadlineRows } from "../../Workspaces/boardutils/BoardUtils";
import CalendarRow from "./CalendarRow";

registerLocale("sv", sv);
const Dot = ({ color }) => (
	<div
		style={{
			display: "inline-block",
			marginRight: "3px",
			height: "10px",
			width: "10px",
			borderRadius: "10px",
			backgroundColor: color,
		}}
	/>
);

class Calendar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedDate: moment(props.selectedDate).isValid() ? moment(props.selectedDate) : moment(),
			// calendarEvents: [],
			rows: [],
			boards: [],
			loading: true,
		};
		this.refresMonthTimer = null;
		// this.datePickerRef = React.createRef();
		this.openModal = this.handleOpenModal.bind(this);
		this.refresh = this.handleRefresh.bind(this);
	}

	componentDidMount() {
		this.fetchMonthsCalendarEvents();
		clearInterval(this.calendarSyncInterval);
		this.getSyncData();
		this.calendarSyncInterval = setInterval(this.getSyncData.bind(this), 10 * 60 * 1000);

		window.addEventListener("open_calendar", this.openModal, false);
		window.addEventListener("refreshCalendar", this.refresh, false);
	}

	getSyncData() {
		const calenderBoard = store.getState().user.calendar_board;
		const columnTimeline = calenderBoard && calenderBoard.columns.find((c) => c.type === "timeline");
		if (!calenderBoard || !columnTimeline) return;

		API.get(`/api/boards/${calenderBoard.id}/columns/${columnTimeline.id}.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const board = Object.assign({}, calenderBoard);
				for (let i = 0; i < board.columns.length; i++) {
					if (board.columns[i].id == result.data.column.id) {
						board.columns[i] = result.data.column;
						store.dispatch({ type: "SET_USER", user: Object.assign({}, store.getState().user, { calendar_board: board }) });
						break;
					}
				}

				this.setState({});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	componentWillUnmount() {
		this.cancelRequest();
		clearInterval(this.calendarSyncInterval);

		window.removeEventListener("open_calendar", this.openModal, false);
		window.removeEventListener("refreshCalendar", this.refresh, false);
	}

	UNSAFE_componentWillReceiveProps(props) {
		// this.handleData({ rows: Object.values(props.rows) });

		if (props.selectedDate && moment(props.selectedDate).isValid()) {
			this.handleDateChange(moment(props.selectedDate));
		}
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	fetchMonthsCalendarEvents({ silent, date = this.state.selectedDate } = {}) {
		const CancelToken = axios.CancelToken;
		if (!silent) this.setState({ rows: [], loading: true });

		const calenderBoardID = store.getState().user.calendar_board && store.getState().user.calendar_board.id;
		const params = Object.assign(
			{
				assigned_user_id: store.getState().user.id,
				dates_from: moment(date).startOf("month").format("YYYY-MM-DD"),
				dates_to: moment(date).endOf("month").format("YYYY-MM-DD"),
				// limit: 20,
				board_id: calenderBoardID,
			},
			{}
		);

		API.get("/api/board_rows/search.json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
			params,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				store.dispatch({ type: "SET_BOARD_ROWS", rows: result.data.rows.concat(Object.values(store.getState().board_rows)) });

				this.handleData(result.data);

				setTimeout(() => this.setState({ loading: false }), 0);
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	// getTimelineColumnId(board) {
	// 	const col = board.columns.find((c) => c.type === "timeline" && c.connected_column_id);
	// 	return col && col.id;
	// }

	handleData(result = {}) {
		const boards = result.boards || this.state.boards || [];
		const rows = result.rows || this.state.rows || [];
		const groups = result.groups || this.state.groups || [];

		BoardHelper.fetchResources("linked_rows", result.linked_row_ids || []);
		BoardHelper.fetchResources("contacts", result.contact_ids || []);
		BoardHelper.fetchResources("tickets", result.ticket_ids || []);

		// const { earlierThisWeek, today, upcoming, done } = BoardHelper.getEventsFromRows(rows, boards, groups, true);
		// const board = store.getState().user.calendar_board;
		// const timeLineColumnId = this.getTimelineColumnId(board);

		this.setState((c) => ({
			rows: formatDeadlineRows({ rows, boards })?.map((row) => {
				return {
					...row,
					statusColor: CalendarHelper.getInlineStatusLabelColor(CalendarHelper.getMyResponse(row?.deadline.dateValue)),
				};
			}),
			boards: boards || c.boards,
			groups: groups || c.groups,
		}));
	}

	handleRefresh(refreshOther = true) {
		this.fetchMonthsCalendarEvents({ silent: true });
		if (refreshOther) {
			window.dispatchEvent(new CustomEvent("refreshGoals"));
		}
	}

	handleDateChange(e) {
		if (!moment(moment(this.state.selectedDate).format("YYYY-MM-DD")).isSame(moment(moment(e).format("YYYY-MM-DD")))) {
			if (!moment(moment(this.state.selectedDate).format("YYYY-MM-DD")).isSame(moment(moment(e).format("YYYY-MM-DD")), "month")) {
				clearTimeout(this.refresMonthTimer);
				this.refresMonthTimer = setTimeout(() => this.fetchMonthsCalendarEvents({ date: e }), 250);
			}
			this.setState({
				selectedDate: e,
			});
			if (this.props.onChange) this.props.onChange(moment(e).format("YYYY-MM-DD HH:mm"));
		}
	}

	// onUpdateValue(row, column = {}, data) {
	// 	// Update single row
	// 	let found = false;
	// 	for (let i = 0; i < row.values.length; i++) {
	// 		if (row.values[i].column_id == column.id) {
	// 			found = true;
	// 			row.values[i] = Object.assign(row.values[i], data);
	// 			break;
	// 		}
	// 	}

	// 	if (!found) {
	// 		row.values.push(Object.assign({ column_id: column.id }, data));
	// 	}

	// 	API.post(
	// 		"/" + this.props.external ? "api" : "api" + "/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/values.json",
	// 		data,
	// 		{
	// 			params: {},
	// 		}
	// 	)
	// 		.then((result) => {
	// 			if (result.data.error) {
	// 				toastr.error(result.data.error);
	// 				return;
	// 			}
	// 			for (let i = 0; i < this.state.rows.length; i++) {
	// 				if (this.state.rows[i].id == result.data.row.id) {
	// 					this.state.rows[i] = result.data.row;
	// 					break;
	// 				}
	// 			}
	// 			this.setState({ rows: this.state.rows });
	// 		})
	// 		.catch((error) => {
	// 			toastr.error(error);
	// 		});
	// }

	updateCell(row, column, value) {
		BoardHelper.onUpdateValue(row, column, value);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	openRow(board, row) {
		this.setState({
			modalIsOpen: true,
			row,
			board,
		});
	}

	disconnectSyncedCalendar() {
		this.setState({ loadingDisconnectCalendar: true });
		const calenderBoard = store.getState().user.calendar_board;

		const column = store.getState().user.calendar_board.columns.find((c) => c.type == "timeline");
		const data = {
			options: {},
		};

		API.put("/api/boards/" + store.getState().user.calendar_board.id + "/columns/" + column.id + ".json", data, {
			params: {},
		})
			.then((result) => {
				toastr.success("Synkad Kalender bortagen");
				const board = Object.assign({}, calenderBoard);
				for (let i = 0; i < board.columns.length; i++) {
					if (board.columns[i].id == result.data.column.id) {
						board.columns[i] = result.data.column;
						store.dispatch({ type: "SET_USER", user: Object.assign({}, store.getState().user, { calendar_board: board }) });
						break;
					}
				}

				this.setState({
					loadingDisconnectCalendar: false,
					dropdownActive: false,
				});
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
				this.setState({ loadingDisconnectCalendar: false });
			});
	}

	handleOpenModal(e) {
		if (e?.type === "open_calendar") {
			this.setState({
				modalIsOpen: true,
				date: (moment(e.detail.date).isValid && moment(e.detail.date).format("YYYY-MM-DD HH:mm")) || null,
				row: e.detail.row,
				board: e.detail.board || store.getState().user.calendar_board,
			});
		} else {
			this.setState({
				modalIsOpen: true,
				date: moment(this.state.selectedDate).isValid && moment(this.state.selectedDate).format("YYYY-MM-DD HH:mm"),
				row: null,
			});
		}
	}

	closeModal() {
		this.setState({ modalIsOpen: false, row: null, date: null });
	}

	render() {
		const baseRows = this.state.rows;
		const rows = addRowForEachDateInDates(baseRows);

		const todayRows = rows
			.filter((c) => moment(moment(c.deadline.date).format("YYYY-MM-DD")).isSame(moment(moment(this.state.selectedDate).format("YYYY-MM-DD")), "day"))
			.sort(
				(a, b) => new Date(moment(a.deadline.date).format("YYYY-MM-DD HH:MM:SS")) - new Date(moment(b.deadline.date).format("YYYY-MM-DD HH:MM:SS"))
			);

		const highlightedEventsObj = rows.reduce(
			(obj, item) =>
				Object.assign(obj, {
					[item.statusColor]: [].concat(obj[item.statusColor] || [], [new Date(item.date)]),
				}),
			{}
		);
		const highlightedEvents =
			Object.keys(highlightedEventsObj).length &&
			Object.keys(highlightedEventsObj).map((key) => ({
				[key]: highlightedEventsObj[key],
			}));

		const renderDayContents = (day, date) => {
			const thisDay = rows.filter((r) =>
				moment(moment(r.deadline.date).format("YYYY-MM-DD")).isSame(moment(moment(date).format("YYYY-MM-DD")), "day")
			);

			// if (!moment(moment(date).format("YYYY-MM-DD")).isSame(moment(moment(this.state.selectedDate).format("YYYY-MM-DD")), "month")) return null;

			const dotColors = thisDay.map((i) => i.statusColor);

			return (
				<div className="day-inner" style={moment(date).isSame(moment(this.state.selectedDate), "day") ? { borderColor: dotColors?.[0] } : {}}>
					<span style={moment(date).isSame(moment(this.state.selectedDate), "day") ? { color: `${dotColors?.[0]}` } : {}}>{day}</span>
					{!moment(date).isSame(moment(this.state.selectedDate), "day") && (
						<div className="dot-container">
							{dotColors?.map((color, index) => (
								<svg key={index} style={{ margin: "1px" }} fill={color} viewBox="0 0 1 1" height="5px" xmlns="http://www.w3.org/2000/svg">
									<circle cx="0.5" cy="0.5" r="0.5" />
								</svg>
							))}
						</div>
					)}
				</div>
			);
		};

		const syncExists = BoardHelper.getColumnIntegrationActive(store.getState().user.calendar_board);
		const syncExpired = BoardHelper.getColumnIntegrationIsExpired(store.getState().user.calendar_board);

		const dropdownItems = [
			syncExists && (
				<Button key="remove sync" destructive onClick={this.disconnectSyncedCalendar.bind(this)} loading={this.state.loadingDisconnectCalendar}>
					{this.props.t("calendar.actions.disconnect", "Koppla bort synkad kalender")}
				</Button>
			),
		].filter((i) => i);
		const column = store.getState().user.calendar_board && store.getState().user.calendar_board.columns.find((c) => c.type == "timeline");

		const row = this.state.row || {
			title: "",
			column_values: {},
		};

		return (
			<div className="calendarContainer">
				<Sheet open={this.state.modalIsOpen} onClose={this.closeModal.bind(this)} title={this.props.t("calendar.actions.new", "Nytt kalenderinlägg")}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ flex: 1 }}>
							<CalendarModalContent
								row={row}
								date={this.state.date}
								board={this.state.board || store.getState().user.calendar_board}
								updateCell={this.updateCell.bind(this, row)}
								onClose={this.closeModal.bind(this)}
								onComplete={this.refresh}
							/>
						</Scrollable>
					</div>
				</Sheet>

				{!this.props.hideHeader && (
					<div className="to_do_header">
						<div style={{ display: "flex", alignItems: "center", gap: "1.2500rem" }}>
							<h2>{this.props.t("calendar.title", "Min kalender")}</h2>
							{dropdownItems && !!dropdownItems.length && (
								<Popover
									active={this.state.dropdownActive}
									fullHeight
									onClose={() => this.setState({ dropdownActive: false })}
									activator={
										<button
											className={`disconnect_calendar_button ${this.state.dropdownActive ? "open" : "closed"}`}
											onClick={() => this.setState((c) => ({ dropdownActive: !c.dropdownActive }))}
										>
											<Icon source={MobileVerticalDotsMajor} color="rgb(150,150,150)" size={16} />
										</button>
									}
								>
									<div className="calendar_dropdown">{dropdownItems.map((i) => i)}</div>
								</Popover>
							)}

							{(!syncExists || syncExpired) && (
								<Tooltip
									content={
										syncExists
											? this.props.t("calendar.actions.reconnect", "Återkoppla kalender")
											: this.props.t("calendar.actions.connect", "Koppla kalender")
									}
								>
									<Button
										onClick={() => {
											this.setState({ showSyncCalendarModal: true });
										}}
										plain={syncExists}
										destructive={syncExists}
									>
										<span className={`${syncExists ? "reconnect" : "connect"}`}>
											{syncExists ? (
												<Icon source={CircleAlertMajor} />
											) : (
												<React.Fragment>
													<Icon source={CalendarMajor} />
													{this.props.t("calendar.actions.connect_short", "Koppla")}
												</React.Fragment>
											)}
										</span>
									</Button>
								</Tooltip>
							)}
						</div>
						<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
							{store.getState().user.calendar_board && (
								<Button onClick={this.openModal}>
									<div className="button_inner">
										<span>{this.props.t("calendar.actions.new", "Nytt kalenderinlägg")}</span>
										<Icon source={CirclePlusOutlineMinor} />
									</div>
								</Button>
							)}
						</div>
					</div>
				)}
				<div className="upcomingEvents">
					<div className="calendar">
						<DatePicker
							inline
							selected={new Date(this.state.selectedDate)}
							onSelect={this.handleDateChange.bind(this)}
							onChange={this.handleDateChange.bind(this)}
							highlightDates={highlightedEvents}
							calendarStartDay={1}
							onMonthChange={this.handleDateChange.bind(this)}
							renderDayContents={renderDayContents}
							locale="sv"
							// filterDate={(date) =>
							// 	moment(moment(date).format("YYYY-MM-DD")).isSame(moment(moment(this.state.selectedDate).format("YYYY-MM-DD")), "month")
							// }
							dayClassName={() => "calendar-day"}
							showWeekNumbers={false}
						/>
					</div>

					<div style={{ display: "flex", gap: "0.6250rem", padding: "0.3125rem" }}>
						<span>
							<Dot color={Colors.green} />
							{this.props.t("calendar.status.accepted.text", "Accepterad")}
						</span>
						<span>
							<Dot color={Colors.red} />
							{this.props.t("calendar.status.declined.text", "Nekad")}
						</span>
						<span>
							<Dot color={Colors.blue} />
							{this.props.t("calendar.status.tentative.text", "Preliminärt")}
						</span>
						<span>
							<Dot color="#ffffff" />
							{this.props.t("calendar.status.no_answer.text", "Inget svar")}
						</span>
					</div>

					<div className="calendarList">
						{(!todayRows || !todayRows.length) && (
							<div
								className={`todoItem todoItem-calendar${this.state.loading ? " loading" : ""}`}
								style={{
									// backgroundColor: "#fff",
									borderLeft: `7px solid ${colorToRgba(Colors.grey, 0.5)}`,
									background: "var(--cardbg)",
									// height: 150,
									minHeight: 50,
									borderTopRightRadius: 10,
									borderBottomRightRadius: 10,
									borderTopLeftRadius: 2,
									borderBottomLeftRadius: 2,
									alignItems: "center",
									display: "flex",
									padding: "0.6250rem",
									gap: "0.6250rem",
								}}
							>
								{this.state.loading && <Spinner size="small" />}
								<p className="todoItem_title">
									<Truncate>
										{this.state.loading
											? this.props.t("calendar.loadingData", "Hämtar data...")
											: this.props.t("calendar.empty.day", "Inga kalenderinlägg denna dag")}
									</Truncate>
								</p>
							</div>
						)}

						{todayRows.map((row, index) => (
							<CalendarRow
								key={row.key}
								row={row}
								index={index}
								loading={this.state.loading}
								onClick={(e) => {
									this.calendarPopup.open(row, row.deadline.dateColumn, row.board, "green", $(e.nativeEvent.target).closest(".todoItem")[0]);
								}}
								refresh={this.refresh}
							/>
						))}
					</div>
					<CalendarPopupPreview
						onComplete={this.refresh}
						ref={(r) => {
							this.calendarPopup = r;
						}}
					/>
				</div>
				{store.getState().user.calendar_board && (
					<SyncCalendarModal
						key={column.id}
						open={this.state.showSyncCalendarModal}
						onClose={() => {
							this.setState({ showSyncCalendarModal: false });
						}}
						onOpen={() => {
							this.setState({ showSyncCalendarModal: true });
						}}
						onUpdateColumn={(column) => {}}
						column={column}
						board={store.getState().user.calendar_board}
						title={this.props.t("calendar.syncModal.title", "Koppla en kalender")}
					/>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	board_contacts: state.board_contacts,
	user: state.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(withTranslation(["calendar", "common"], { withRef: true })(Calendar));
