import React, { Component } from "react";

import { Checkbox, Button, Icon, Collapsible, LegacyCard, OptionList } from "@shopify/polaris";
import moment from "moment";
import { CirclePlusMajor, FilterMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import TimeLineRow from "./TimeLineRow.js";
import IntersectionObserverElement from "./IntersectionObserverElement";
import RowModal from "../views/Workspaces/components/RowModal";
import SkeletonSimpleCard from "./SkeletonSimpleCard";
import Popover from "./Popover";
import { formatDeadlineRows } from "../views/Workspaces/boardutils/BoardUtils";
import TaskCompleteModal from "../views/Agenda/TaskCompleteModal";

class TimeLine extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			showAll: store.getState().contact_activity_selection.show_all,
			comments: props.comments || [],
			expanded: props.expanded,
			types: [],
		};
		this.ref = React.createRef();
		this.refresh = this.refresh.bind(this);
	}

	componentDidMount() {
		this.fetchComments();
		window.addEventListener("refreshActivities", this.refresh, false);
	}

	componentWillUnmount() {
		window.removeEventListener("refreshActivities", this.refresh, false);
	}

	refresh() {
		this.fetchComments();
	}

	fetchComments() {
		this.setState({ loading: true });
		API.get("/api/contacts/" + this.props.contact.id + "/comments.json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const rows = result.data.comments?.map((c) => c.row).filter((r) => r);
				const boards = rows.map((r) => store.getState().boards[r.board_id]).filter((r) => r);
				const deadlineRows = formatDeadlineRows({ rows, boards });

				const contacts = result.data.comments?.flatMap((c) => c.contacts || [c.contact]).filter((r) => r);
				store.dispatch({ type: "SET_BOARD_CONTACTS", contacts });

				const comments = result.data.comments.map((comment) => {
					if (!comment?.row) return comment;
					comment.row = deadlineRows.find((r) => r.id == comment.row.id) || comment.row;

					return comment;
				});
				this.setState({
					comments,
				});
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	onRemove(comment) {
		this.setState({
			comments: this.state.comments.filter((com) => com.id !== comment.id),
		});
		this.fetchComments();
	}

	onOpenNew() {
		this.setState({ newModalIsOpen: true });
	}

	handleReplaceComment(comment) {
		if (comment?.id) {
			const index = this.state.comments?.findIndex((c) => c.id === comment.id);
			if (index > -1) {
				this.state.comments[index] = comment;
			}
			this.setState({ comments: this.state.comments });
		}

		this.fetchComments();
	}

	render() {
		const events = this.state.comments || [];

		const sortedEvents = events.sort((a, b) => {
			if ((a.date || a.created_at) == (b.date || b.created_at)) {
				if (a.id > b.id) {
					return -1;
				} else {
					return 1;
				}
			}
			return moment(a.date || a.created_at).isBefore(moment(b.date || b.created_at)) ? 1 : -1;
		});

		const activityOptions = store.getState().contact_activities.map((activity) => ({
			value: activity.id + "",
			label: activity.title,
		}));

		activityOptions.unshift({
			value: "",
			label: this.props.t("timeline.actions.create_activity", "Skapa ny aktivitet"),
		});

		const filterOptions = [
			...store.getState().contact_activities.map((activity) => ({
				value: activity.id + "",
				label: activity.title,
			})),
		];

		const sortedFilteredEvents = this.state.types?.length
			? sortedEvents.filter((evt) => {
					if (this.state.types.includes(String(evt.activity?.id))) return true;
					return false;
			  })
			: sortedEvents;

		return (
			<LegacyCard sectioned>
				<div className="timeline-wrapper noscroll" style={{ ...(this.props.style || {}) }} ref={this.ref}>
					<div className="timeline__header">
						<h2 className="small_card_title">
							{this.props.title || this.props.t("timeline.title", "Aktivitetslogg")}
							<Popover
								activator={<Button icon={FilterMajor} plain onClick={() => this.setState((c) => ({ filterIsOpen: !c.filterIsOpen }))} />}
								active={this.state.filterIsOpen}
								onClose={() => {
									this.setState({ filterIsOpen: false });
								}}
							>
								<OptionList
									title="Typ av aktivitet"
									allowMultiple
									options={filterOptions}
									selected={this.state.types}
									onChange={(types) => {
										this.setState({ types });
									}}
								/>
							</Popover>
						</h2>
						{!this.props.disabled && (
							<Button plain onClick={this.onOpenNew.bind(this)}>
								<div className="inner_button">
									Skapa ny <Icon source={CirclePlusMajor} />
								</div>
							</Button>
						)}
					</div>
					<div className={`timeline__rows${this.props.preview ? " preview" : ""}`}>
						{!this.props.preview && (
							<Checkbox
								label="Visa all aktivitet"
								checked={this.state.showAll}
								onChange={() => {
									this.setState({ showAll: !this.state.showAll }, () => {
										store.dispatch({
											type: "SET_CONTACT_ACTIVITY_SELECTION",
											selection: { show_all: this.state.showAll },
										});
									});
								}}
							/>
						)}

						{sortedFilteredEvents?.slice(0, this.props.initialLimit || 1)?.map((event, key) => (
							<TimeLineRow
								contact={this.props.contact}
								preview={this.props.preview}
								key={event.id}
								event={event}
								onRemove={this.onRemove.bind(this)}
								onChange={this.handleReplaceComment.bind(this)}
								history={this.props.history}
								onSuccess={this.refresh}
								loading={this.state.loading}
							/>
						))}

						<Collapsible open={this.state.expanded}>
							{sortedFilteredEvents?.slice(this.props.initialLimit || 1)?.map((event, key) => (
								<TimeLineRow
									contact={this.props.contact}
									key={event.id}
									event={event}
									onRemove={this.onRemove.bind(this)}
									onChange={this.handleReplaceComment.bind(this)}
									history={this.props.history}
									onSuccess={this.refresh}
									loading={this.state.loading}
								/>
							))}
						</Collapsible>

						{events && events.length > (this.props.initialLimit || 1) && (
							<div className="showmore">
								<button
									style={{
										border: "none",
										background: "transparent",
										outline: "none",
									}}
									onClick={() => (this.props.onShowMore ? this.props.onShowMore() : this.setState((c) => ({ expanded: !c.expanded })))}
								>
									{this.state.expanded
										? this.props.t("timeline.actions.show_less", "Visa mindre")
										: this.props.t("timeline.actions.show_more", "Visa mer")}
								</button>
							</div>
						)}
						<IntersectionObserverElement
							hideShadow
							onChange={(v) => this.setState({ intersecting: v })}
							parent={this.ref.current}
							// shadowStyle={{ width: this.ref.current && this.ref.current.clientWidth }}
						/>
						{this.state.loading && !events?.length && (
							<div className="loading-skeleton">
								<SkeletonSimpleCard lines={2} secondaryActions={false} primaryAction={false} />
							</div>
						)}
					</div>
				</div>
				<div className={`bottom-scroll-shadow${!this.state.intersecting ? " visible" : " invisible"}`} />

				{store.getState().account.sales_board_id in store.getState().boards ? (
					<RowModal
						open={this.state.showLeadModal}
						board={store.getState().boards[store.getState().account.sales_board_id]}
						contact={this.props.contact}
						onUpdateRow={() => {}}
						onUpdateValue={() => {}}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ showLeadModal: false });
						}}
						onCreate={() => {}}
					/>
				) : null}

				<TaskCompleteModal
					type="comment"
					contacts={this.props.contact ? [this.props.contact] : []}
					activator={null}
					open={this.state.newModalIsOpen}
					onClose={(haveSubmit) => {
						if (haveSubmit) this.fetchComments();
						this.setState({ newModalIsOpen: false });
					}}
					onSuccess={(comment) => {
						this.state.comments.push(comment);
						this.setState({ comments: this.state.comments });

						this.fetchComments();
					}}
				/>

				{/* <ActivityModal
					history={this.props.history}
					contact={this.props.contact}
					open={this.state.newModalIsOpen}
					onClose={() => this.setState({ newModalIsOpen: false })}
					onSuccess={(comment) => {
						this.state.comments.push(comment);
						this.setState({ comments: this.state.comments });

						this.fetchComments();
					}}
					onNewContact={this.props.onNewContact}
				/> */}
			</LegacyCard>
		);
	}
}
export default withTranslation(["timeline", "common", "upload"], { withRef: true })(TimeLine);
