import React, { Component } from "react";
import { Icon, Collapsible, Select } from "@shopify/polaris";
import axios from "axios";
import { ChevronDownMinor, ChevronRightMinor } from "@shopify/polaris-icons";
import moment from "moment";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import CircleDiagramBox from "./CircleDiagramBox";
import UserGoalModal from "../../components/UserGoalModal";

import GoalDateSelector from "./GoalDateSelector";
import GoalUserListItem from "./GoalUserListItem";

moment.locale("sv");

class GoalViewSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stats: [],
			// loading: true,
		};
	}

	componentDidMount() {
		if (!this.props.loading && this.props.goal && this.props.goal.id) this.fetchStats(this.props.goal.id, this.props.from, this.props.to);
	}

	UNSAFE_componentWillReceiveProps(props) {
		const id = props.goal && props.goal.id;
		const prevId = this.props.goal && this.props.goal.id;
		if (!props.loading && (id != prevId || props.from !== this.props.from || props.to !== this.props.to)) {
			this.fetchStats(id, props.from, props.to);
		} else if (!id) {
			this.setState({ sum: 0, stats: [], days: null });
		}
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	fetchStats(id = this.props.goal && this.props.goal.id, from, to) {
		this.setState({ sum: 0, stats: [], days: null });

		if (!id || !from || !to) return null;
		this.cancelRequest();
		const CancelToken = axios.CancelToken;
		this.setState({ loadingStats: true });

		// console.log(`Hämtar stats för ${this.props.goal.title} (${this.props.goal.id}) mellan ${from} -> ${to}`);

		API.get("/api/goals/" + id + "/stats.json?total=1&from=" + from + "&to=" + to, {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
		})
			.then((result) => {
				this.setState((c) => ({
					loadingStats: false,
					stats: result.data.stats,
					sum: result.data.sum,
					// count: result.data.count,
					days: result.data.days,
					showGroups: !result.data.stats || !result.data.stats.length ? false : c.showGroups,
				}));
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					this.setState({ loadingStats: false });
					return;
				}
				console.error("error:", error);
				toastr.error(error);
				this.setState({ loadingStats: false });
			});
	}

	getUserStats(user) {
		return this.state.stats.filter((s) => s.user_id == user.id);
	}

	getUserSumPercentage(user) {
		const stats = this.getUserStats(user);
		const sum = stats.reduce((acc, stats) => acc + parseFloat(stats.sum), 0);
		return (sum / this.props.goal.users.filter((s) => s.user_id == user.id)[0].forecasted_value) * 100;
		// return (sum / this.props.goal.users.filter((s) => s.user_id == user.id)[0].forecasted_value) * 100;
	}

	getUserSum(user) {
		const stats = this.getUserStats(user);

		let sum = 0;
		for (let i = 0; i < stats.length; i++) {
			sum += stats[i].sum;
		}

		return sum;
	}

	editUserGoal(user) {
		if (store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0) {
			this.setState({ editingUser: user, editingUserModal: true });
		}
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	updateUser(field, value) {
		this.props.goal.users.find((user) => user.id == this.state.editingUser.id)[field] = value;
		this.props.updateGoal(this.props.goal);
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	saveForm() {
		if (!this.props.goal || !this.props.goal.id) {
			toastr.error(this.props.t("goals.error.no_id", "Inget målsättnings id"));
			return;
		}

		API.put("/api/goals/" + this.props.goal.id + ".json", this.props.goal)
			.then((result) => {
				this.setState({ editingUserModal: false });
				this.props.updateGoal(result.data.goal);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	money(pAmount, moneyFormat) {
		const amount = pAmount;
		let result;
		const pattern = /\{\{\s*(\w+)\s*\}\}/;

		function addSpaces(money) {
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			money = money.replace(/(\d+)(\d{3}[. ]?)/g, "$1 $2");
			return money;
		}

		switch (moneyFormat.match(pattern)[1]) {
			case "detailed": {
				result = addSpaces(parseInt(amount).toString());
				const dec = parseFloat(amount).toString();
				if (dec.indexOf(".") >= 0) {
					const pcs = dec.split(".", 2);
					result = result + "." + pcs[1];
				}
				break;
			}
			case "amount":
				result = addSpaces(parseFloat(amount).toFixed(2).toString());
				break;
			case "fixed_amount":
				result = addSpaces(parseFloat(amount).toFixed(0).toString());
				break;
			default:
				return amount;
		}

		const formated = moneyFormat.replace(pattern, result);
		if ((!formated && formated != 0) || ["NaN", "undefined", "null"].some((i) => i === formated)) return "...";
		return formated;
	}

	getGroups() {
		const groups = {};
		if (this.props.goal && this.props.goal.users) {
			for (let i = 0; i < this.props.goal.users.length; i++) {
				let groupId = 0;
				if (this.props.goal.users[i].user.group_id) {
					groupId = this.props.goal.users[i].user.group_id;
				}

				let group = null;
				if (groupId) {
					group = store.getState().groups.find((group) => group.id + "" == groupId + "");
					if (!group) {
						groupId = 0;
					}
				} else {
					group = null;
				}

				if (!(groupId in groups)) {
					groups[groupId] = {
						id: groupId,
						title: group ? group.name : this.props.t("goals.terms.no_group", "Ingen grupp"),
						value: 0,
						sum: 0,
						forecasted_value: 0,
					};
				}
				groups[groupId].value += (this.props.goal.users[i].value / this.props.goal.days) * this.state.days;
				groups[groupId].forecasted_value += parseFloat(this.props.goal.users[i].forecasted_value) || 0;
				groups[groupId].sum += this.getUserSum(this.props.goal.users[i].user);
			}
		}

		const groupsArray = Object.values(groups);
		if (groupsArray.length > 1) {
			return groupsArray;
		}
		return [];
	}

	render() {
		// if (this.props.loading) {
		// 	return <Skeleton2Col />;
		// }

		const loading = this.props.loading || this.state.loadingStats;
		const groups = this.getGroups();

		const value =
			this.props.goal &&
			this.props.goal.users &&
			this.props.goal.users.reduce((acc, user) => acc + (user.value / this.props.goal.days) * this.state.days, 0);
		// const value =  this.props.goal && this.props.goal.value;
		const empty = !loading && (!this.state.stats || !this.state.stats.length);
		return (
			<React.Fragment>
				{/* <div className="goal-view-section" style={empty && { backgroundColor: "rgba(0, 0, 15, 0.5)" }}> */}
				<div className="goal-view-section">
					<div className="goal-view-section-header" style={{ display: "flex", justifyContent: "space-between" }}>
						<div style={{ display: "flex", gap: "0.6250rem" }}>
							{this.props.title && <h2>{this.props.title}</h2>}

							{this.props.goal && (
								<GoalDateSelector
									goal={this.props.goal}
									from={this.props.from}
									to={this.props.to}
									plain={!this.props.setDates}
									firstDate={this.props.firstDate}
									previous_goals={this.props.previous_goals}
									onChange={(from, to, interval) => this.props.setDates(from, to, interval)}
								/>
							)}

							{/* <DateSelect
								from={this.state.from}
								to={this.state.to}
								goal={this.props.goal}
								onChange={({ from, to }) => {
									this.props.updateDate({ from, to });
								}}
								disabled={this.props.section !== 1}
							/> */}
						</div>
						{this.props.onChange && (
							<div className="plain-select" style={{ display: "flex", alignItems: "flex-start", gap: "0.6250rem", justifyContent: "end", height: "30px" }}>
								<span style={{ color: "var(--textColor2)" }}>{this.props.t("goals.terms.sort", "Sortering")}:</span>
								<Select
									options={[
										{ label: this.props.t("goals.terms.percetage", "Procent"), value: "percentage" },
										{ label: this.props.t("goals.terms.value", "Värde"), value: "value" },
									]}
									disabled={!this.props.goal}
									value={this.props.sortByPercentage ? "percentage" : "value"}
									onChange={(v) => {
										this.props.onChange({ sortByPercentage: v === "percentage" });
									}}
								/>
							</div>
						)}
					</div>
					<div style={{ position: "relative" }}>
						<div style={{ position: "relative" }}>
							<CircleDiagramBox
								// title={this.props.goal.title
								value={value}
								sortByPercentage={this.props.sortByPercentage}
								goal={this.props.goal}
								loading={loading}
								stats={this.state.stats}
								sum={this.state.sum}
								color={this.props.color}
								empty={!loading && (!this.state.stats || !this.state.stats.length)}
								days={moment(this.props.to).diff(moment(this.props.from), "days")}
							/>
						</div>
						<div style={empty && !loading ? { opacity: 0.25 } : {}}>
							{groups && !!groups.length && (
								<div className="goal-view-expand_groups" onClick={() => this.setState((c) => ({ showGroups: !c.showGroups }))}>
									<b>
										{this.state.showGroups ? this.props.t("common.terms.hide", "Dölj") : this.props.t("common.terms.show", "Visa")}{" "}
										{this.props.t("common.terms.groups", "grupper")}
									</b>{" "}
									<Icon source={this.state.showGroups ? ChevronDownMinor : ChevronRightMinor} />
								</div>
							)}

							<Collapsible open={this.state.showGroups}>
								<div className="goal-user-list" style={{ marginBottom: this.state.showGroups ? "1.2500rem" : 0 }}>
									{groups.map((group) => (
										<CircleDiagramBox
											group={group}
											key={group.id}
											title={group.title}
											goal={this.props.goal}
											loading={loading}
											stats={this.state.stats}
											sum={group.sum}
											value={group.value}
											forecasted_value={group.forecasted_value}
											color={this.props.color}
											empty={!loading && (!this.state.stats || !this.state.stats.length)}
											days={moment(this.props.to).diff(moment(this.props.from), "days")}
										/>
									))}
								</div>
							</Collapsible>
							<div className="goal-user-list" style={{ marginTop: groups && groups.length ? 0 : "0.6250rem" }}>
								<div style={{ display: "flex", justifyContent: "space-between" }}>
									<h2>
										<b>{this.props.t("goals.terms.leaderboard", "Leaderboard")}</b>
									</h2>
								</div>
								{loading && !this.state.stats && Array.from(Array(3)).map((v, index) => <GoalUserListItem loading index={index} key={index} />)}
								{this.props.goal &&
									this.props.goal.users &&
									this.state.stats &&
									this.props.goal.users
										.sort((user1, user2) => {
											const sum1 = this.props.sortByPercentage ? this.getUserSumPercentage(user1.user) : this.getUserSum(user1.user);
											const sum2 = this.props.sortByPercentage ? this.getUserSumPercentage(user2.user) : this.getUserSum(user2.user);
											return sum2 - sum1;
										})
										.map((user = {}, index) => {
											const value = (user.value / this.props.goal.days) * this.state.days;

											return (
												<GoalUserListItem
													loading={loading}
													index={index}
													money={this.money.bind(this)}
													suffix={this.props.goal.suffix}
													goal={this.props.goal}
													key={user.user_id || user?.user?.id || index}
													onClick={this.editUserGoal.bind(this, user)}
													user={user}
													value={value}
													getUserSum={this.getUserSum.bind(this)}
													sortByPercentage={this.props.sortByPercentage}
													days={moment(this.props.to).diff(moment(this.props.from), "days")}
												/>
											);
										})}
							</div>
						</div>
						{/* {empty && (
							<div
								style={{ position: "absolute", inset: 0, backgroundColor: "var(--main2-50)", zIndex: 2, pointerEvents: "none", borderRadius: "6px" }}
							></div>
						)} */}
					</div>
				</div>

				<UserGoalModal
					open={this.state.editingUserModal}
					user={this.state.editingUser && this.state.editingUser.user}
					goal={this.props.goal}
					onClose={() => {
						this.setState({ editingUserModal: false });
					}}
					onCreate={(goal) => {
						this.props.updateGoal(goal);
					}}
				/>
			</React.Fragment>
		);
	}
}

export default withTranslation(["goals", "common"], {
	withRef: true,
})(GoalViewSection);
