import React, { Component } from "react";
import { TextField, FormLayout, Button, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";

class WidgetIframe extends Component {
	constructor(props) {
		super(props);
		this.state = {
			url: props.widget.options.url,
			showSettings: !props.widget.options.url ? true : props.showSettings,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings != this.state.showSettings) {
			this.setState({ showSettings: props.showSettings });
		}
	}

	updateUrl() {
		this.props.widget.options = { url: this.state.url };
		this.setState({ saving: true });
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
				this.setState({ saving: false }, this.props.onCloseSettings);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	render() {
		if (this.state.showSettings) {
			return (
				<div
					style={{
						padding: 15,
						height: "100%",
						width: "100%",
						overflow: "scroll",
					}}
				>
					<FormLayout>
						<TextField
							type="url"
							label={this.props.t("widget.fields.url.label", "URL att visa")}
							placeholder="https://"
							value={this.state.url}
							onChange={(url) => {
								this.setState({ url });
							}}
						/>
						<LegacyStack>
							<Button loading={this.state.saving} primary onClick={this.updateUrl.bind(this)}>
								{this.props.t("common.actions.save", "Spara")}
							</Button>
							{this.props.widget.options.url ? (
								<Button
									plain
									onClick={() => {
										this.setState(
											{
												url: this.props.widget.options.url,
												showSettings: false,
											},
											this.props.onCloseSettings
										);
									}}
								>
									{this.props.t("common.actions.cancel", "Avbryt")}
								</Button>
							) : null}
						</LegacyStack>
					</FormLayout>
				</div>
			);
		}
		return <iframe title={this.state.url} src={this.state.url} width="100%" frameBorder="0" height="100%" />;
	}
}
export default withTranslation(["widget", "common"], { withRef: true })(WidgetIframe);
