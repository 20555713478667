import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { WithTranslation, withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import truncateString from "src/js/Utilities/truncateString";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import ImportModal from "../Import/ImportModal";
import { ImportMinor } from "@shopify/polaris-icons";
import IndexTable from "src/js/components/IndexTable";

type ArticlesListProps = {
	history: any;
} & WithTranslation;

const ArticleList = ({ history, t }: ArticlesListProps) => {
	const ref = React.useRef<any>();
	const [importModalIsOpen, setImportModalIsOpen] = React.useState(false);

	const handleBulkDisabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/articles.json", { ids, disabled: 1 });
		toastr.success(t("articles.responses.bulk_disabled", "{{count}} artiklar inaktiverade", { count: result.data?.articles?.length }));
		ref.current?.reload();
	}, [t]);

	const handleBulkEnabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/articles.json", { ids, disabled: 0 });
		toastr.success(t("articles.responses.bulk_enabled", "{{count}} artiklar aktiverade", { count: result.data?.articles?.length }));
		ref.current?.reload();
	}, [t]);

	const renderItem = useCallback(
		(item: ArticleType) => {
			return (
				<ItemCard
					media={item.image?.url || ""}
					title={<>{item.title}</>}
					leftColumn={[
						{
							label: t("articles.status", "Status"),
							value: item.disabled ? (
								<Badge color={Colors.red}>{t("common.inactive", "Inaktiv")}</Badge>
							) : (
								<Badge color={Colors.green}>{t("common.active", "Aktiv")}</Badge>
							),
						},
						{
							label: t("articles.articleNumber", "Artikelnummer"),
							value: item.number,
						},
						{
							label: t("articles.description", "Beskrivning"),
							value: truncateString(item.description ?? "", 20),
						},
						{
							label: t("articles.categories", "Kategorier"),
							value: item.categories_display,
						},
						{
							label: t("articles.pricelists", "Prislistor"),
							value: item.pricelists_display,
						},
					]}
					rightColumn={
						[
							/* {
							label: t("articles.purchasePrice", "Inköpspris"),
							value: currencyFormatter({ value: item.in_price ?? 0 }),
						}, */
						]
					}
				/>
			);
		},
		[t]
	);

	const filters = [];
	return (
		<>
			<IndexTable
				title={t("articles.title", "Artiklar") as string}
				history={history}
				primaryAction={{
					content: t("articles.newArticle", "Ny artikel"),
					url: "/admin/articles/create",
				}}
				secondaryActions={[
					{
						content: t("articles.import", "Importera") as string,
						onAction: () => setImportModalIsOpen(true),
						icon: ImportMinor,
					},
				]}
				ref={ref}
				promotedBulkActions={[
					{
						content: t("articles.bulkDisable", "Inaktivera") as string,
						onAction: handleBulkDisabled,
					},
					{
						content: t("articles.bulkEnable", "aktivera") as string,
						onAction: handleBulkEnabled,
					},
				]}
				defaultFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				onClick={(item) => {
					history.push(`/admin/articles/${item.id}`, {
						data: item,
					});
				}}
				filters={filters}
				resourceUrl="/api/articles.json"
				resourceName={{
					singular: "artikel",
					plural: "artiklar",
				}}
				resourceHandle="articles"
				columns={[
					{
						handle: "",
						label: "",
						render: renderItem,
					},
				]}
			/>

			<ImportModal open={importModalIsOpen} onClose={() => setImportModalIsOpen(false)} />
		</>
	);
};
export default withTranslation(["articles", "common"])(ArticleList);
