/* eslint-disable camelcase */
import { Badge, LegacyCard, PageActions, ResourceItem, LegacyStack } from "@shopify/polaris";
import { toastr } from "react-redux-toastr";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import API from "../../API";
import OrderRowInput from "../OrderRowInput";
import FortnoxTable from "./FortnoxTable";
import Banner from "../banner";
import Money from "../Money";
import BringModalToFront from "../BringModalToFront";
import { store } from "../../store/index.js";
import Page from "../page";
import FortnoxResourceListWithPagination from "./FortnoxResourceListWithPagination";
import Modal from "src/js/components/modal";

class FortnoxQuoteView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showActionModal: false,
			integration: props.integration || {},
			customer: props.customer || {},
			form: this.initForm(),
		};
	}

	initForm() {
		return {
			OurReference: store.getState().user.name,
			...(this.props.form || {}),
			CustomerNumber: this.props?.customer?.CustomerNumber,
			DeliveryAddress1: this.props?.customer?.DeliveryAddress1 || this.props?.customer?.Address1 || "",
			DeliveryAddress2: this.props?.customer?.DeliveryAddress2 || this.props?.customer?.Address1 || "",
			DeliveryZipCode: this.props?.customer?.DeliveryZipCode || this.props?.customer?.ZipCode || "",
			DeliveryCity: this.props?.customer?.DeliveryCity || this.props?.customer?.City || "",
			DeliveryCountry: this.props?.customer?.DeliveryCountry || this.props?.customer?.Country || "",
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.customer) {
			this.setState({
				customer: props.customer,
				form: {
					...this.state.form,
					CustomerNumber: props?.customer?.CustomerNumber,
					DeliveryAddress1: props?.customer?.DeliveryAddress1 || "",
					DeliveryAddress2: props?.customer?.DeliveryAddress2 || "",
					DeliveryZipCode: props?.customer?.DeliveryZipCode || "",
					DeliveryCity: props?.customer?.DeliveryCity || "",
					DeliveryCountry: props?.customer?.DeliveryCountry || "",
				},
			});
		}
	}

	updateForm(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	viewOffer(quote) {
		if (this?.state?.integration?.id && this?.state?.integration?.integration_id && quote.DocumentNumber) {
			// this.setState({ fetchingOffer: true });
			API.get(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/quotes/${quote.DocumentNumber}.json`)
				.then((response) => {
					this.setState({
						form: {
							...(response.data || {}),
							...this.state.form,
							OfferRows: [].concat(response?.data?.OfferRows || [], [{}]),
							CustomerNumber: this.state.form.customer?.CustomerNumber,
						},
						manage: true,
					});
				})
				.catch((error) => {
					toastr.error("Error", error?.response?.data?.message || "Okänt error!");
				})
				.finally(() => {
					// this.setState({ fetchingOffer: false });
				});
		}
	}

	convertOffer(action, payload = {}) {
		const { integration_id, id } = this?.state?.integration || {};
		const { DocumentNumber } = this?.state?.form || {};
		if (id && integration_id && DocumentNumber) {
			this.setState({ actionLoading: true });
			API.put(`/api/integrations/${integration_id}/${id}/quotes/${DocumentNumber}/create/${action}.json`, payload)
				.then((response) => {
					this.setState({ showActionModal: false });
				})
				.catch((error) => {})
				.finally(() => {
					this.setState({ actionLoading: false });
				});
		}
	}

	saveOffer() {
		this.state.form = { ...this.state.form, OfferRows: (this?.state?.form?.OfferRows || []).filter((item) => Object.keys(item).length) };
		this.setState({ saving: true });

		if (this?.state?.form?.DocumentNumber) {
			API.put(
				`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/quotes/${this.state.form.DocumentNumber}.json`,
				this.state.form
			)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					toastr.error("Error", error?.response?.data?.message || "Okänt error!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		} else {
			API.post(`/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/quotes.json`, this.state.form)
				.then((response) => {
					this.setState({ manage: false, form: { OurReference: this.state.form?.OurReference || "" } }, this.resourceList?.refresh);
				})
				.catch((error) => {
					toastr.error("Fel", error?.response?.data?.message || "Okänt fel!");
				})
				.finally(() => {
					this.setState({ saving: false });
				});
		}
	}

	render() {
		const { form, integration } = this.state;
		const commonFields = ["ArticleNumber", "Description", (row, value) => ({ ...row, Price: value.SalesPrice || null }), "Unit"];
		const created = form.OrderReference && form.OrderReference !== "0";

		return (
			<div style={{ paddingTop: 30 }}>
				<Page
					title={
						this.state.manage
							? !form.DocumentNumber
								? this.props.t("fortnox.quote.titles.new", "Fortnox - Ny offert")
								: this.props.t("fortnox.quote.title", "Fortnox - Offert #") + form.DocumentNumber
							: this.props.t("fortnox.quote.actions.create", "Fortnox offert")
					}
					primaryAction={
						this.state.manage
							? {
									content: this.props.t("common.actions.save", "Spara"),
									loading: this.state.saving,
									onAction: this.saveOffer.bind(this, true),
									disabled: this.state.saving || created,
							  }
							: {
									content: this.props.t("fortnox.quote.actions.create", "Skapa ny offert"),
									onAction: () => {
										this.setState({
											manage: true,
											form: this.initForm(),
										});
									},
							  }
					}
					backAction={
						this.state.manage
							? {
									content: this.props.t("fortnox.quote.breadcrumb", "Offerter"),
									onAction: () => {
										this.setState({ manage: false, form: { OurReference: form.OurReference || "" } });
									},
							  }
							: undefined
					}
				>
					{this.state.manage ? (
						<div>
							<div style={{ marginBottom: 10 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th style={{ width: 100 }}>{this.props.t("fortnox.quote.labels.our_reference", "Vår referens")}</th>
											<th style={{ width: 100 }}>{this.props.t("fortnox.quote.labels.your_reference", "Er referens")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput
													value={form.OurReference}
													onChange={this.updateForm.bind(this, "OurReference")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													value={form.YourReference}
													onChange={this.updateForm.bind(this, "YourReference")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ paddingBottom: 5 }}>
								<table className="fortnox">
									<thead>
										<tr>
											<th colSpan={2}>{this.props.t("fortnox.common.labels.delivery_address", "Leveransadress")}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.address_1", "Address 1")}
													value={form.DeliveryAddress1}
													onChange={this.updateForm.bind(this, "DeliveryAddress1")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.address_2", "Address 2")}
													value={form.DeliveryAddress2}
													onChange={this.updateForm.bind(this, "DeliveryAddress2")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
										<tr>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.zip", "Postnummer")}
													value={form.DeliveryZipCode}
													onChange={this.updateForm.bind(this, "DeliveryZipCode")}
													integration={this.props.integration}
												/>
											</td>
											<td>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.city", "Ort")}
													value={form.DeliveryCity}
													onChange={this.updateForm.bind(this, "DeliveryCity")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
										<tr>
											<td colSpan={2}>
												<OrderRowInput
													label={this.props.t("fortnox.common.labels.country", "Land")}
													value={form.DeliveryCountry}
													onChange={this.updateForm.bind(this, "DeliveryCountry")}
													integration={this.props.integration}
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div style={{ paddingBottom: 20 }}>
								<FortnoxTable
									columns={[
										{
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											resource: "Articles",
											translationHandle: "fortnox.quote.labels.artnr",
											label: "Artikelnr",
											type: "autoComplete",
											handle: "ArticleNumber",
											updateFields: commonFields,
											props: {
												style: { width: 140 },
											},
										},
										{
											translationHandle: "fortnox.quote.labels.term",
											label: "Benämning",
											type: "autoComplete",
											resource: "Articles",
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/articles.json`,
											handle: "Description",
											updateFields: commonFields,
										},
										{
											translationHandle: "fortnox.quote.labels.count",
											label: "Antal",
											type: "input",
											handle: "Quantity",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.common.labels.unit",
											label: "Enhet",
											type: "autoComplete",
											resource: "Units",
											handle: "Unit",
											mapper: (data) => data.map((value) => ({ ...value, Unit: value.Code })),
											resourceUrl: `/api/integrations/${this.state.integration.integration_id}/${this.state.integration.id}/units.json`,
											props: {
												style: { width: 65 },
											},
										},
										{
											translationHandle: "fortnox.quote.labels.a_price",
											label: "À-Pris",
											handle: "Price",
											type: "input",
											props: {
												style: { width: 70 },
												className: "text-right",
											},
										},
										{
											translationHandle: "fortnox.invoice.labels.sum",
											label: "Rad summa",
											handle: (row) => {
												return row.Price * row.Quantity;
											},
											type: "text",
											skuffix: this.state.form.currency || "SEK",
											props: {
												style: { width: 100 },
												className: "text-right",
											},
											summary: form.OfferRows?.reduce((acc, row) => {
												acc += parseFloat(row.Total || 0) || parseFloat(row.Price || 0) * parseFloat(row.Quantity || 0);

												return acc;
											}, 0),
										},
									]}
									data={form.OfferRows || Array.from(Array(1)).map(() => ({}))}
									onChange={(data) => {
										this.state.form.OfferRows = data.reduce((acc, curr) => {
											if (Object.keys(curr).length) {
												acc.push({ ...curr });
											}
											return acc;
										}, []);
										this.setState({ form: this.state.form });
									}}
								/>
							</div>
							{form.Cancelled ? (
								<Banner title={this.props.t("fortnox.quote.statuses.shredded", "Ordern är makulerad")} />
							) : form.InvoiceReference && form.InvoiceReference != "" && form.InvoiceReference != "0" ? (
								<Banner
									status="success"
									title={this.props.t("fortnox.quote.statuses.shredded", "Faktura #{{invoiceReference}} skapad", {
										invoiceReference: form.InvoiceReference,
									})}
								/>
							) : (
								<PageActions
									primaryAction={
										form.DocumentNumber && {
											content: this.props.t("fortnox.common.create.order", "Skapa order"),
											onAction: () => this.setState({ showActionModal: true }),
											disabled: created,
										}
									}
									secondaryActions={
										form.DocumentNumber
											? [
													{
														content: "Förhandsgranska pdf",
														onAction: () =>
															window.open(
																`/api/integrations/${integration.integration_id}/${integration.id}/quotes/${form.DocumentNumber}/preview.pdf`,
																"_blank"
															),
													},
											  ]
											: []
									}
								/>
							)}
						</div>
					) : (
						<div>
							{this.state.customer ? (
								<LegacyCard title={this.props.t("fortnox.quote.card.customer_info.title", "Kundinformation från fortnox")} sectioned>
									<p>
										{this.props.t("fortnox.quote.labels.customer_number", "Kundnummer")}: {this.state?.customer?.CustomerNumber || "-"}
									</p>
									<p>
										{this.props.t("fortnox.quote.labels.submit_invoice", "Leveranssätt faktura")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice || "-"}
									</p>
									<p>
										{this.props.t("fortnox.quote.labels.invoice_template", "Fakturamall")}:{" "}
										{this?.props?.customer?.DefaultDeliveryTypes?.Invoice != "DEFAULTTEMPLATE"
											? this?.state?.customer?.DefaultTemplates?.Invoice
											: "Standard"}
									</p>
								</LegacyCard>
							) : null}
							<FortnoxResourceListWithPagination
								ref={(ref) => {
									this.resourceList = ref;
								}}
								resourceHandle="quotes"
								resource="Offers"
								customer={this.props?.customer}
								integration={this.state.integration}
								renderItem={(offer) => (
									<ResourceItem key={offer.DocumentNumber} onClick={this.viewOffer.bind(this, offer)}>
										<LegacyStack alignment="center">
											<h3>
												#{offer.DocumentNumber} - {offer.OfferDate}
											</h3>
											{offer.Sent ? (
												<Badge status="success">{this.props.t("fortnox.statuses.customer.sent", "Skickad till kund")}</Badge>
											) : (
												<Badge>{this.props.t("fortnox.statuses.customer.notsent", "Ej skickad till kund")}</Badge>
											)}
											{offer.Cancelled && <Badge>{this.props.t("fortnox.statuses.shredded", "Makulerad")}</Badge>}
										</LegacyStack>
										<Money amount={offer.Total} currency={offer.Currency} />
									</ResourceItem>
								)}
							/>
						</div>
					)}
				</Page>
				<Modal
					open={this.state.showActionModal}
					title="Bekräftelse"
					primaryAction={{ content: "Ja", onAction: this.convertOffer.bind(this, "order", {}) }}
					secondaryActions={[
						{
							content: "Nej",
							onAction: () => this.setState({ showActionModal: false }),
						},
					]}
					onClose={() => this.setState({ showActionModal: false })}
					loading={this.state.actionLoading}
				>
					<BringModalToFront />
					<Modal.Section>
						<p>Är du säker på att du vill skapa en order ifrån denna offert?</p>
					</Modal.Section>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["fortnox", "common"], { withRef: true })(FortnoxQuoteView);
