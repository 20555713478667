import { Pagination, ResourceList, Spinner, type UnstableBulkActionsProps } from "@shopify/polaris";
import React, { useCallback, useImperativeHandle, useRef, useState } from "react";
import API from "src/js/API";
import { UseQueryResult } from "@tanstack/react-query";
import styled, { css } from "styled-components";
import MyFilters from "src/js/components/MyFilters";
import debounce from "lodash/debounce";
import useQuery from "src/js/hooks/useQuery";
import { toastr } from "src/js/components/toastr";
import { StyledDocumentItem } from "../views/Workspaces/components/BoardUploadSheet";
import getDefaultFilters from "./IndexTable/getDefaultFilters";
import { ItemCardWrapper } from "./ItemCard/styles";
import uniqBy from "lodash/uniqBy";
import { store } from "../store";
import LimitField from "./LimitField";

//! Kinda DEPRECATED, use IndexTable with 1 kolumn (empty label and handle) instead.

type PromotedActions = UnstableBulkActionsProps["promotedActions"];
type Actions = UnstableBulkActionsProps["actions"];

interface CardListProps {
	renderItem: (item: any) => React.ReactNode;
	// resouce from result.data[resource] and as fallback for url (t.ex ${resourceHandle || resource}.json)
	resourceUrl: string;
	// resouce in url (t.ex ${resourceHandle}.json)
	resourceHandle?: string;
	params?: any;
	resourceName?: { singular: string; plural: string };
	filters?: any[];
	additionalAction?: any;
	limit?: number;
	promotedBulkActions?: PromotedActions;
	bulkActions?: Actions;
	onClick?: (item: any) => void;
	appliedFilters?: any[];
	sortOptions?: any[];
	showHeader?: boolean;
	prefix?: string | React.ReactNode;

	renderKey?: string;
	useDefaultRenderKey?: boolean;
}

const CardList = React.forwardRef(
	(
		{
			renderItem: propsRenderItem,
			resourceUrl,
			resourceHandle,
			params: propsParams,
			resourceName,
			filters: propsFilters,
			additionalAction,
			limit: propsLimit,
			promotedBulkActions: propsPromotedBulkActions,
			bulkActions,
			onClick,
			appliedFilters: propsAppliedFilters = [],
			renderKey,
			sortOptions,
			showHeader = true,
			useDefaultRenderKey = false,
			prefix,
		}: CardListProps,
		ref
	) => {
		const [appliedFilters, setAppliedFilters] = useState(propsAppliedFilters || []);
		const [selectedItems, setSelectedItems] = useState<any[]>([]);
		const [search, setSearch] = useState("");
		const [params = {}, setParams] = useState<any>({
			...(propsParams || {}),
			offset: 0,
			sort: sortOptions?.[0] ? sortOptions[0].value : null,
		});
		const [limit, setLimit] = useState(propsLimit || 10);
		const previousDataLimit = useRef(limit);
		const [loadings, setLoadings] = useState<{ [key: number]: boolean }>({});
		const [count, setCount] = useState<number>(0);

		const handleFiltersChange = useCallback((appliedFilters) => {
			setAppliedFilters(appliedFilters);
			setOffset(0);
		}, []);

		const delayedUpdateParams = debounce((newParams) => {
			setParams((c) => ({
				...c,
				...newParams,
			}));
		}, 350);

		const handleSearchChange = useCallback(
			(search) => {
				setSearch(search);
				setOffset(0);

				if (!search) {
					setParams(({ q, ...rest }) => ({
						...rest,
					}));
				} else {
					delayedUpdateParams({
						q: search,
					});
				}
			},
			[delayedUpdateParams]
		);

		const setOffset = (offset) => {
			setParams((c) => ({
				...c,
				offset,
			}));
		};

		const getParams = () => {
			return {
				...(propsParams || {}),
				limit,
				...params,
				...appliedFilters.reduce((acc, val: any) => {
					acc[val.key] = val.value;
					return acc;
				}, {}),
			};
		};

		const fetch = async () => {
			try {
				const result = await API.get(resourceUrl, {
					params: getParams(),
				});

				return {
					data: (resourceHandle ? result?.data?.[resourceHandle] : result?.data) || [],
					count: result?.data?.count,
				};
			} catch (error) {
				toastr.error(error);
				throw error;
			}
		};
		const queryKey = [
			resourceHandle,
			limit,
			...((Object.entries(params)?.map((arr) => arr.join("_")) || []) as string[]),
			...((appliedFilters?.map((val: any) => `${val.key}_${val.value}`) || []) as string[]),
		].filter(Boolean);

		const {
			data: queryData,
			isFetching,
			refetch,
			dataUpdatedAt,
		}: UseQueryResult<any> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: {
				data: [],
				count: null,
			},
			onSuccess: (data) => {
				previousDataLimit.current = data?.data?.length || data?.count || limit;
				setCount(data?.count || 0);
			},
		});

		const { data = [] } = queryData || { data: { items: [] } };

		const onNext = () => {
			setOffset(params.offset + limit);
		};

		const onPrevious = () => {
			setOffset(params.offset - limit);
		};

		useImperativeHandle(ref, () => ({
			refetch: () => {
				if (params.offset === 0) refetch();
				else setOffset(0);
			},
			reload: () => {
				if (params.offset === 0) refetch();
				else setOffset(0);
			},
			getSelectedItems: () => {
				return selectedItems;
			},
			getParams,
			getCount: () => {
				return count;
			},
		}));

		const handleSetSelectedItems = (selectedItems) => {
			setSelectedItems(selectedItems);
		};

		const handleOnClick = (id: string | undefined) => {
			if (onClick && id !== undefined) {
				const item = data.find((item) => item.id === id);
				onClick(item);
			}
		};

		const renderItem = (item) => {
			return (
				<ResourceList.Item id={item.id as string} onClick={() => handleOnClick(item.id)}>
					{propsRenderItem(item)}
				</ResourceList.Item>
			);
		};

		const pagination = (
			<Pagination
				label={
					<div style={{ position: "relative" }}>
						{isFetching && !!count && (
							<div style={{ position: "absolute", inset: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
								<Spinner size="small" />
							</div>
						)}
						<span>{`Visar ${Math.min(params.offset + 1, count)} - ${Math.min(params.offset + limit, count)} av ${count}`}</span>
					</div>
				}
				hasNext={count > params.offset + limit}
				hasPrevious={!!(params.offset > 0 && count)}
				onNext={onNext}
				onPrevious={onPrevious}
			/>
		);

		const filters = uniqBy([...(propsFilters || []), ...getDefaultFilters(resourceHandle || "")], "key");

		const filterControl = (
			<MyFilters
				filters={filters}
				appliedFilters={appliedFilters}
				onFiltersChange={handleFiltersChange}
				searchValue={search}
				onSearchChange={handleSearchChange}
				additionalAction={additionalAction}
				prefix={prefix}
			/>
		);

		const promotedBulkActions = propsPromotedBulkActions?.map((action: any, index) => {
			if (action.title || action.actions) return action;

			return {
				...action,
				content: (
					<div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
						{action.content}
						{loadings?.[index] && <Spinner size="small" />}
					</div>
				) as unknown as string,

				onAction: async () => {
					setLoadings((c) => ({ ...c, [index]: true }));
					try {
						const res = await action.onAction();
						if (res !== false) refetch();
					} catch (error) {
						toastr.error(error);
					} finally {
						setLoadings((c) => ({ ...c, [index]: false }));
					}
				},
			};
		});

		return (
			<CardListWrapper loading={isFetching} clickable={!!onClick}>
				<ResourceList
					// showHeader={showHeader} // also removes bulk actions, but we want to keep them
					key={renderKey || (useDefaultRenderKey && dataUpdatedAt) || undefined} // This closes the filter sheet..
					// loading={isFetching}

					promotedBulkActions={promotedBulkActions}
					bulkActions={bulkActions}
					filterControl={showHeader ? filterControl : undefined}
					items={data || []}
					renderItem={renderItem}
					resourceName={resourceName}
					idForItem={(item, index) => item?.DocumentNumber || item.id || index}
					resolveItemId={(item) => item.id}
					totalItemsCount={count || 1}
					hasMoreItems={(count || 0) > params.offset + limit}
					emptyState={
						isFetching &&
						!data?.length && (
							<>
								{[...Array(previousDataLimit.current)].map((item, index) => {
									return <StyledDocumentItem key={index} style={{ height: "100px", backgroundColor: "var(--main2)" }} />;
								})}
							</>
						)
					}
					selectedItems={selectedItems}
					onSelectionChange={handleSetSelectedItems}
					sortValue={params.sort}
					sortOptions={sortOptions}
					onSortChange={(sort) => {
						setParams((c) => ({
							...c,
							sort,
						}));
					}}
				/>

				<Footer>
					{pagination}
					{store.getState().user.roles.includes("ROLE_ADMIN") && <LimitField value={limit} onChange={setLimit} />}
				</Footer>
			</CardListWrapper>
		);
	}
);
export default CardList;

const Footer = styled.div`
	height: 100px;
	text-align: center;

	display: flex;
	flex-direction: column;
	width: max-content;
	margin: auto;
	gap: 1rem;
	margin-bottom: 3rem;

	nav .Polaris-ButtonGroup {
		justify-content: center;
	}
`;

export const CardListWrapper = styled.div.attrs({
	className: "CardListWrapper",
})<{ loading?: boolean; clickable?: boolean }>`
	transition: opacity 250ms;

	${({ loading }) =>
		loading &&
		css`
			opacity: 0.75;
		`}

	${({ clickable }) =>
		clickable &&
		css`
			${ItemCardWrapper} {
				cursor: pointer;
			}
		`}


	.Polaris-ResourceItem__ListItem {
		margin-bottom: 0.625rem;
		transition: filter 250ms;
		/* .Polaris-Box {
			padding: 0;
		} */

		&:hover {
			filter: brightness(1.2);
		}
	}

	${ItemCardWrapper} {
		margin-bottom: 0;

		&:hover {
			filter: unset;
		}
	}

	&&& {
		.Polaris-Filters-ConnectedFilterControl.Polaris-Filters-ConnectedFilterControl--right {
			border-radius: 6px;
		}

		.Polaris-BulkActions__PaginatedSelectAll {
			display: none;
		}

		.Polaris-Tag {
			background: var(--main2);
		}
	}

	.Polaris-ResourceList {
		border-top: none;
	}
	.Polaris-ResourceList__FiltersWrapper {
		padding-inline: 0;
	}

	&&&& .Polaris-TextField__Backdrop {
		border-radius: 6px;
	}

	.Polaris-ResourceItem__Container {
		padding: 0;
	}

	.Polaris-ResourceItem__ListItem {
		border: none;
	}

	.Polaris-ResourceItem {
		&.Polaris-ResourceItem--selected {
			background: none !important;
			background-color: none !important;

			${ItemCardWrapper} {
				filter: brightness(1.25);
			}
		}

		&:hover {
			background: none !important;
		}
	}

	.Polaris-ResourceList__HeaderWrapper {
		padding-left: 0;

		.Polaris-ResourceList__HeaderContentWrapper {
			left: 0;

			.Polaris-CheckableButton {
				padding-left: 12px;
			}
		}
	}
`;
