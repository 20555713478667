import React, { useContext, useEffect, useState } from "react";
import { FormLayout, Button, Icon, Spinner } from "@shopify/polaris";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { MobileAcceptMajor } from "@shopify/polaris-icons";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store/index.js";
import useQuery from "../hooks/useQuery";
import FortnoxGlobalContext from "../views/Fortnox/ContextProvider/FortnoxGlobalContext";
import Modal from "src/js/components/modal";

const IntegrationModal = ({ type, category = null, integrations: propsIntegrations = [], open, onClose, noPopup = false }) => {
	const [selectedIntegration, setSelectedIntegration] = useState(false);
	const [messageSent, setMessageSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const fortnoxContext = useContext(FortnoxGlobalContext);

	const fetchIntegrations = async () => {
		try {
			return await API.get(`/api/available_integrations.json?${new URLSearchParams({ type, category })}`).then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					throw new Error(result.data.error);
				}

				// Temporärt bara visa fortnox sålänge (sprint: 3943)
				return result.data.integrations.filter((i) => i.type === "fortnox" || i.type === "microsoft365");
			});
		} catch (error) {
			toastr.error(error);
		}
	};

	const queryKey = ["integrations", JSON.stringify({ type, category })];
	const { data: integrations, isFetching } = useQuery({
		queryKey,
		queryFn: fetchIntegrations,
		enabled: open && !propsIntegrations?.length,
		initialData: propsIntegrations || [],
	});

	useEffect(() => {
		setSelectedIntegration(false);
		setMessageSent(false);
	}, [open]);

	const getIntegrationImage = (integration) => {
		return <img alt={integration.title} style={{ maxWidth: "100%", height: 150, width: 150, objectFit: "contain" }} src={integration.icon} />;
	};

	const addIntegration = (integration) => () => {
		const { handle } = store.getState().account;
		if (["fortnox", "fortnox_invoice"].includes(integration.id)) {
			fortnoxContext.openWindow({
				params: {
					type: "fortnox",
					title: integration.title,
					integration_id: integration.id,
				},
			});

			return;
		}

		if (handle) {
			window.location.href = `/api/integration/${integration.id}/auth`;
			return;
		}

		setSelectedIntegration(integration);
	};

	const sendMail = () => {
		setLoading(true);

		try {
			API.post("/api/integrations/apply.json", {
				title: selectedIntegration.title,
			}).then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					throw new Error(result.data.error);
				}

				setMessageSent(true);
			});
		} catch (e) {
			toastr.error("Ansökan misslyckades");
		} finally {
			setLoading(false);
		}
	};

	const content = (
		<FormLayout>
			{isFetching && !!integrations?.lenth && <Spinner />}
			{!messageSent ? (
				!selectedIntegration ? (
					<ScrollingCarousel show={2.5} slide={2} swiping className="scrolling-carousel">
						{integrations?.map((integration, index) => (
							<a
								key={integration.id || index}
								onClick={addIntegration(integration)}
								style={{
									display: "block",
									width: 150,
									textAlign: "center",
									color: "#f0f0f0",
									marginInline: 10,
								}}
							>
								{getIntegrationImage(integration)}
								{integration.title}
							</a>
						))}
					</ScrollingCarousel>
				) : (
					<div>
						<div style={{ width: 150 }}>
							<div>{getIntegrationImage(selectedIntegration)}</div>
						</div>
						<p style={{ marginTop: 10, marginBottom: 10 }}> Du har valt{selectedIntegration.title}</p>
						<Button primary onClick={sendMail} loading={loading}>
							{" "}
							Ansök{" "}
						</Button>
					</div>
				)
			) : (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						padding: 15,
						width: "100%",
					}}
				>
					<div>
						<Icon source={MobileAcceptMajor} color="green" />
						<p style={{ marginTop: 10, textAlign: "center" }}>Din ansökan har skickats till oss. Vi kontaktar dig inom kort. </p>
					</div>
				</div>
			)}
		</FormLayout>
	);

	if (noPopup) {
		return (
			<div>
				{content}
				<Button onClick={onClose}>Stäng</Button>
			</div>
		);
	}

	const secondaryActions = [
		{
			content: "Stäng",
			onAction: onClose,
		},
	];

	return (
		<div>
			<Modal open={open} onClose={onClose} title="Lägg till integration" secondaryActions={secondaryActions}>
				<Modal.Section>{content}</Modal.Section>
			</Modal>
		</div>
	);
};
export default IntegrationModal;
