import React, { Component } from "react";
import {  Labelled, Select, TextField, FormLayout, Spinner, ActionList, Icon, Button, Checkbox } from "@shopify/polaris";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import BoardSelector from "./components/BoardSelector.js";
import Popover from "../../components/Popover";
import Modal from "src/js/components/modal";

class ColumnCreator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { type: "text", title: "", options: {} },
			saving: false,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/boards/" + this.props.board.id + "/columns.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("column.creator.responses.created", "Skapade kolumn"));
				this.state.form.title = "";
				this.state.form.type = null;
				this.setState({
					saving: false,
					showColumnModal: false,
					form: this.state.form,
					active: false,
				});
				const newBoard = Object.assign({}, this.props.board);
				newBoard.columns.push(result.data.column);
				store.dispatch({ type: "UPDATE_BOARD", board: newBoard });

				const view = document.querySelector(".board-container");
				setTimeout(() => {
					$(".board-container").animate(
						{
							scrollLeft: view.scrollWidth + 300,
						},
						500
					);
				}, 0);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	quickCreate(title, type) {
		this.setState({ active: false, form: { type, title, options: {} } }, this.saveForm.bind(this));
	}

	fetchLinkColumns(boardId) {
		API.get(`/api/boards/${boardId}.json`, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.state.form.connected_column_id = result.data.board.columns[0].id + "";
				this.setState({ mirror_columns: result.data.board.columns });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	changeLinkColumn(v) {
		if (v) {
			for (let i = 0; i < this.props.board.columns.length; i++) {
				const column = this.props.board.columns[i];
				if (column.id + "" == v) {
					this.state.form.link_column = column;
					this.setState({
						form: this.state.form,
						link_column_id: v,
						mirror_columns: [],
					});
					if (column.type == "rowlink") {
						this.fetchLinkColumns(column.connected_board_id);
					} else if (column.type == "contact") {
						const contactColumns = [
							{
								id: "",
								title: this.props.t("column.mirror.options.choose", "Välj.."),
							},
							{
								id: "company",
								title: this.props.t("column.mirror.options.company", "Företagsnamn"),
							},
							{
								id: "person",
								title: this.props.t("column.mirror.options.person", "Kontaktperson"),
							},
							{
								id: "orgnr",
								title: this.props.t("column.mirror.options.orgnr", "Organisationsnummer"),
							},
							{
								id: "email",
								title: this.props.t("column.mirror.options.email", "E-post"),
							},
							{
								id: "phone",
								title: this.props.t("column.mirror.options.phone", "Telefon"),
							},
							{
								id: "note",
								title: this.props.t("column.mirror.options.note", "Anteckning"),
							},
							{
								id: "reference",
								title: this.props.t("column.mirror.options.reference", "Eget id"),
							},
							{
								id: "tags",
								title: this.props.t("column.mirror.options.tags", "Taggar"),
							},
							{
								id: "parent_tags",
								title: this.props.t("column.mirror.options.parent_tags", "Företags taggar"),
							},
						];
						for (let x = 0; x < store.getState().contact_metafields.length; x++) {
							contactColumns.push({
								id: "meta_" + store.getState().contact_metafields[x].id,
								title: store.getState().contact_metafields[x].title,
							});
						}
						this.setState({ mirror_columns: contactColumns });
					} else if (column.type == "visitor") {
						const contactColumns = [
							{
								id: "",
								title: this.props.t("column.mirror.options.choose", "Välj.."),
							},
							{
								id: "domain",
								title: this.props.t("column.mirror.options.domain", "Domän"),
							},
							{
								id: "email",
								title: this.props.t("column.mirror.options.email", "E-post"),
							},
							{
								id: "device_type",
								title: this.props.t("column.mirror.options.device_type", "Typ av enhet"),
							},
							{
								id: "city",
								title: this.props.t("column.mirror.options.city", "Ort"),
							},
							{
								id: "country",
								title: this.props.t("column.mirror.options.country", "Land"),
							},
							{
								id: "num_hits",
								title: this.props.t("column.mirror.options.num_hits", "Antal träffar"),
							},
							{
								id: "first_url",
								title: this.props.t("column.mirror.options.first_url", "Första url"),
							},
							{
								id: "first_title",
								title: this.props.t("column.mirror.options.first_title", "Första titel"),
							},
							{
								id: "time_spent",
								title: this.props.t("column.mirror.options.time_spent", "Tid på sidan"),
							},
							{
								id: "referrer",
								title: this.props.t("column.mirror.options.referrer", "Referenssida"),
							},
							{
								id: "user_agent",
								title: this.props.t("column.mirror.options.user_agent", "User agent"),
							},
							{
								id: "ip",
								title: this.props.t("column.mirror.options.ip", "FöretagIPsnamn"),
							},
						];

						this.setState({ mirror_columns: contactColumns });
					}
					break;
				}
			}
		} else {
			this.state.form.link_column = null;
			this.setState({
				form: this.state.form,
				link_column_id: v,
				mirror_columns: [],
			});
		}
	}

	render() {
		const rowlinkOptions = [
			{
				value: "",
				label: this.props.t("column.mirror.action.choose", "Välj länk"),
			},
		];

		for (let i = 0; i < this.props.board.columns.length; i++) {
			const column = this.props.board.columns[i];
			if (column.type == "rowlink" || column.type == "contact" || column.type == "visitor") {
				rowlinkOptions.push({ value: column.id + "", label: column.title });
			}
		}

		const statusOptions = [
			{
				value: "",
				label: this.props.t("column.status.action.choose", "Välj statuskolumn"),
			},
		];

		for (let i = 0; i < this.props.board.columns.length; i++) {
			const column = this.props.board.columns[i];
			if (column.type == "status") {
				statusOptions.push({ value: column.id + "", label: column.title });
			}
		}

		return (
			<>
				<div style={{ textAlign: "center", width: "100%" }}>
					{this.state.saving ? (
						<Spinner size="small" />
					) : (
						<Popover
							// fixed
							active={this.state.active}
							activator={
								this.state.saving ? (
									<Spinner size="small" />
								) : (
									<a
										onClick={() => {
											this.setState((c) => ({ active: !c.active }));
										}}
										style={{ cursor: "pointer" }}
									>
										<Icon source={CirclePlusOutlineMinor} color="inkLighter" />
									</a>
								)
							}
							onClose={() => {
								this.setState({ active: false });
							}}
						>
							<ActionList
								items={[
									{
										content: this.props.t("column.creator.options.text", "Text"),
										onAction: this.quickCreate.bind(this, "Text", "text"),
									},
									{
										content: this.props.t("column.creator.options.number", "Siffror"),
										onAction: this.quickCreate.bind(this, "Siffror", "number"),
									},
									{
										content: this.props.t("column.creator.options.tags", "Taggar"),
										onAction: this.quickCreate.bind(this, "Taggar", "tags"),
									},
									{
										content: this.props.t("column.creator.options.person", "Person"),
										onAction: this.quickCreate.bind(this, "Person", "person"),
									},
									{
										content: this.props.t("column.creator.options.status", "Status"),
										onAction: this.quickCreate.bind(this, "Status", "status"),
									},
									{
										content: this.props.t("column.creator.options.datetime", "Datum"),
										onAction: this.quickCreate.bind(this, "Datum", "datetime"),
									},
									{
										content: this.props.t("column.creator.options.other", "Andra kolumner"),
										onAction: () => {
											this.setState({ showColumnModal: true, active: false });
										},
									},
								]}
							/>
						</Popover>
					)}
				</div>
				<Modal
					open={this.state.showColumnModal}
					onClose={() => {
						this.state.form.title = "";
						this.state.form.type = null;
						this.setState({ showColumnModal: false, form: this.state.form });
					}}
					title={this.props.t("column.creator.actions.create", "Ny kolumn")}
					preventAutofocus
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showColumnModal: false });
							},
						},
					]}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
						disabled: !this.state.form.title || !this.state.form.type,
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("column.fields.title.label", "Namn på kolumn")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<Select
								label={this.props.t("column.fields.type.label", "Typ")}
								options={[
									{
										value: "text",
										label: this.props.t("column.fields.type.options.text", "Text"),
									},
									{
										value: "number",
										label: this.props.t("column.fields.type.options.number", "Siffror"),
									},
									{
										value: "tags",
										label: this.props.t("column.fields.type.options.tags", "Taggar"),
									},
									{
										value: "person",
										label: this.props.t("column.fields.type.options.person", "Person"),
									},
									{
										value: "checkbox",
										label: this.props.t("column.fields.type.options.checkbox", "Checkbox"),
									},
									{
										value: "checklist",
										label: this.props.t("column.fields.type.options.checklist", "Checklista"),
									},
									{
										value: "status",
										label: this.props.t("column.fields.type.options.status", "Status"),
									},
									{
										value: "timetrack",
										label: this.props.t("column.fields.type.options.timetrack", "Tidtagare"),
									},
									{
										value: "datetime",
										label: this.props.t("column.fields.type.options.datetime", "Datum"),
									},
									{
										value: "timeline",
										label: this.props.t("column.fields.type.options.timeline", "Tidslinje"),
									},
									{
										value: "rowlink",
										label: this.props.t("column.fields.type.options.rowlink", "Länkade rader"),
									},
									{
										value: "mirror",
										label: this.props.t("column.fields.type.options.mirror", "Länkade kolumner"),
									},
									{
										value: "contact",
										label: this.props.t("column.fields.type.options.contact", "Kontakt"),
									},
									{
										value: "dropdown",
										label: this.props.t("column.fields.type.options.dropdown", "Dropdown"),
									},
									{
										value: "button",
										label: this.props.t("column.fields.type.options.button", "Knapp"),
									},
									{
										value: "link",
										label: this.props.t("column.fields.type.options.link", "Länk"),
									},
									{
										value: "ticket",
										label: this.props.t("column.fields.type.options.ticket", "Supportärende"),
									},
									{
										value: "created_at",
										label: this.props.t("column.fields.type.options.created_at", "Skapat datum"),
									},
									{
										value: "board_number",
										label: this.props.t("column.fields.type.options.board_number", "Löpnummer"),
									},
									{
										value: "category",
										label: this.props.t("column.fields.type.options.category", "Kategori"),
									},
									{
										value: "company_number",
										label: this.props.t("column.fields.type.options.company_number", "Organisationsnummer"),
									},
									{
										value: "formula",
										label: this.props.t("column.fields.type.options.formula", "Uträkning"),
									},
									{
										value: "visitor",
										label: this.props.t("column.fields.type.options.visitor", "Besökare"),
									},
									{
										value: "phone",
										label: this.props.t("column.fields.type.options.phone", "Telefonnummer"),
									},
									{
										value: "upload",
										label: this.props.t("column.fields.type.options.upload", "Filer"),
									},
									{
										value: "rowlinkfrom",
										label: this.props.t("column.fields.type.options.rowlink_from", "Rader som länkar till"),
									},
								]}
								value={this.state.form.type}
								onChange={this.updateForm.bind(this, "type")}
							/>
							{this.state.form.type === "button" && (
								<TextField
									label={this.props.t("column.creator.fields.button_text.label", "Knapp text")}
									value={this.state.form.button_text}
									onChange={this.updateForm.bind(this, "button_text")}
								/>
							)}
							{this.state.form.type == "mirror" ? (
								<Select
									label={this.props.t("column.creator.fields.link_column_id.label", "Radlänk")}
									onChange={this.changeLinkColumn.bind(this)}
									value={this.state.link_column_id}
									options={rowlinkOptions}
								/>
							) : null}
							{this.state.form.type == "mirror" && this.state.link_column_id ? (
								<Select
									label={this.props.t("column.creator.fields.connected_column_id.label", "Kolumn att visa")}
									onChange={this.updateForm.bind(this, "connected_column_id")}
									value={this.state.form.connected_column_id}
									options={this.state.mirror_columns.map((column) => ({
										value: column.id + "",
										label: column.title,
									}))}
								/>
							) : null}
							{this.state.form.type == "rowlink" ? (
								<Labelled label={this.props.t("column.fields.connected_board.label", "Koppla till")}>
									{this.state.showBoardSelector ? (
										<BoardSelector
											includeSelf
											onSelect={(board) => {
												this.state.form.connected_board = board;
												this.setState({
													form: this.state.form,
													showBoardSelector: false,
												});
											}}
											board={this.props.board}
										/>
									) : (
										<Button
											onClick={() => {
												this.setState({
													showBoardSelector: !this.state.showBoardSelector,
												});
											}}
										>
											{this.state.form.connected_board
												? this.state.form.connected_board.title
												: this.props.t("column.creator.fields.connected_board.actions.choose", "Välj board att koppla till")}
										</Button>
									)}
								</Labelled>
							) : null}
							{this.state.form.type == "rowlink" ? (
								<Checkbox
									label={this.props.t("column.creator.fields.add_link.label", "Lägg även till länkkolumn på kopplad board")}
									checked={this.state.form.add_link}
									onChange={this.updateForm.bind(this, "add_link")}
								/>
							) : null}
							{this.state.form.type == "ticket" ? (
								<Select
									label={this.props.t("column.creator.fields.ticket_connected_column_id", "Status kolumn")}
									onChange={this.updateForm.bind(this, "connected_column_id")}
									value={this.state.form.connected_column_id}
									options={statusOptions}
								/>
							) : null}
						</FormLayout>
					</Modal.Section>
				</Modal>
			</>
		);
	}
}
export default withTranslation(["column"], { withRef: true })(ColumnCreator);
