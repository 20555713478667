import React, { Component } from "react";
import { FormLayout, ChoiceList, Banner, TextField, Spinner } from "@shopify/polaris";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import Colors from "../Colors";
import Button from "./Button";
import { ArrowLeftMinor } from "@shopify/polaris-icons";
import Modal from "src/js/components/modal";

class BoardTemplateModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			board_templates: [],
			open: props.open,
			form: { template_id: props.templateId || "", type: "private", include_rows_in_template: false },
			saving: false,
			loading: false,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({
				open: props.open,
				form: {
					template_id: props.templateId || "",
					title: this.props.t("board.template.actions.new", "Ny board"),
					type: "private",
					include_rows_in_template: false,
				},
			});
			if (props.open) {
				this.fetchTemplates();
			}
		}
	}

	fetchTemplates() {
		if (!this.props.templateId) this.setState({ loading: true });

		API.get("/api/board_templates.json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ board_templates: result.data.board_templates, loading: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = field === "type" ? value[0] : value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.post("/api/workspaces/" + (this.props.workspace?.id || this.props.workspace) + "/templates.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ saving: false });
					return;
				}
				toastr.success(this.props.t("board.template.responses.created", "Skapade projekt"));
				this.setState({ saving: false });
				this.props.onCreate(result.data.board, this.state.form.type);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getBoardImage({ label, template_image_url: templateImageUrl } = {}) {
		return <img alt="" draggable="false" style={{ maxWidth: "100%", height: "auto" }} src={templateImageUrl || "/assets/project2.png"} />;
	}

	setTemplate(value) {
		const template = this.state.board_templates.find((t) => t.id == value);

		this.state.form.template_id = value;
		this.state.form.include_rows_in_template = template?.include_rows_in_template || false;
		this.setState({ form: this.state.form });
	}

	render() {
		const templateOptions = this.state.board_templates.map((i = {}) => ({
			value: (i.id || i.value) + "",
			label: i.title || i.label,
			template_image_url: i.template_image_url,
		}));

		const selectedTemplate = templateOptions.find((t) => t.value === this.state.form.template_id);

		return (
			<Modal
				large
				size="large"
				open={this.state.open}
				onClose={this.props.onClose}
				title={
					<div style={{ display: "flex", gap: "0.6250rem" }}>
						{this.state.form.template_id && (
							<Button onClick={() => this.setTemplate(null)} icon={ArrowLeftMinor}>
								{this.props.t("common.actions.back", "Tillbaka")}
							</Button>
						)}
						<h2 className="Polaris-Text--root Polaris-DisplayText Polaris-DisplayText--sizeSmall">
							{this.props.t("board.modals.add.title", "Ny board")}
						</h2>
					</div>
				}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
					disabled: !this.state.form.template_id || !this.state.form.title,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				{!this.state.form.template_id ? (
					<Modal.Section>
						<FormLayout>
							<ScrollingCarousel show={2.5} slide={2} swiping={false} className="scrolling-carousel">
								{templateOptions && templateOptions.length
									? templateOptions.map((opt, index) => (
											<div
												key={index}
												onMouseDown={(e) => {
													e.stopPropagation();
												}}
												onClick={this.setTemplate.bind(this, opt.value)}
												className="item"
											>
												<div style={{ paddingLeft: 10, paddingRight: 10 }}>{this.getBoardImage(opt)}</div>
												{opt.label}
											</div>
									  ))
									: this.state.loading && <Spinner size="large" />}
							</ScrollingCarousel>
						</FormLayout>
					</Modal.Section>
				) : (
					this.state.loading && <Spinner size="large" />
				)}

				{this.state.form.template_id ? (
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("board.modals.add.fields.title.label", "Namn på board")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<ChoiceList
								choices={[
									{
										label: this.props.t("board.modals.add.fields.title.options.public.label", "Publik"),
										value: "public",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("board.modals.add.fields.title.options.public.title", "Publik board")}>
													{this.props.t("board.modals.add.fields.title.options.public.description", "Alla som har ett konto kommer åt boarden")}
												</Banner>
											) : null,
									},
									{
										label: this.props.t("board.modals.add.fields.title.options.public_read.label", "Publik (endast läs)"),

										value: "public_read",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("board.modals.add.fields.title.options.public_read.title", "Publik board (endast läs)")}>
													{this.props.t(
														"board.modals.add.fields.title.options.public_read.description",
														"Alla kan se boarden, men endast kopplade medlemmar har skrivrättigheter"
													)}
												</Banner>
											) : null,
									},
									{
										label: this.props.t("board.modals.add.fields.title.options.shareable.label", "Delad"),
										value: "shareable",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("board.modals.add.fields.title.options.shareable.title", "Delad board")}>
													{this.props.t(
														"board.modals.add.fields.title.options.shareable.description",
														"Det går att dela boarden med medarbetare eller utomstående, men alla som ska se den måste vara inbjudna"
													)}
												</Banner>
											) : null,
									},
									{
										label: this.props.t("board.modals.add.fields.title.options.private.label", "Privat"),
										value: "private",
										renderChildren: (isSelected) =>
											isSelected ? (
												<Banner title={this.props.t("board.modals.add.fields.title.options.private.title", "Privat board")}>
													{this.props.t("board.modals.add.fields.title.options.private.description", "Enbart ägaren (du) kan se boarden")}
												</Banner>
											) : null,
									},
								]}
								selected={[this.state.form.type]}
								onChange={this.updateForm.bind(this, "type")}
							/>
							{selectedTemplate && (
								<React.Fragment>
									<span style={{ opacity: 0.5 }}>Mall:</span> <span>{selectedTemplate.label}</span>
									<p style={{ opacity: 0.5 }}>
										Denna mall
										<span
											style={{
												fontWeight: "bold",
												color: this.state.form.include_rows_in_template ? Colors.green : Colors.red,
											}}
										>
											{this.state.form.include_rows_in_template ? " inkluderar" : " inkluderar inte"}
										</span>{" "}
										board rader
									</p>
								</React.Fragment>
							)}
						</FormLayout>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(BoardTemplateModal);
