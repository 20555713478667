import React, { Component } from "react";
import { Layout, LegacyCard, Text, ResourceList, OptionList, FormLayout } from "@shopify/polaris";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { connect } from "react-redux";
import Badge from "src/js/components/Badge";
import API from "../../API";
import { store } from "../../store";
import ContactActivityModal from "../../components/ContactActivityModal";
import { toastr } from "../../components/toastr.js";
import MetafieldModal from "../../components/MetafieldModal.js";
import TaskTypeModal from "../../components/TaskTypeModal.js";
import { getcontactTabsOptions } from "./Utilities";
import Switch from "src/js/components/Switch";

class ContactSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentTaskType: {
				title: "",
				type: "return",
			},
			currentMetafield: {
				title: "",
				type: "input",
				options: [],
			},
		};
		this.abortController = new AbortController();
	}

	componentDidMount() {}

	renderMetafield(item) {
		return (
			<ResourceList.Item id={item.id} onClick={this.openMetafieldModal.bind(this, item)}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						{item.searchable && <Badge status="success">{this.props.t("contact.settings.metafields.searchable", "Sökbar")}</Badge>}
						<span className="CustomerListItem__Location" />
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
			</ResourceList.Item>
		);
	}

	openMetafieldModal(item) {
		this.setState({ openMetafieldModal: true, currentMetafield: item });
	}

	closeMetafieldModal() {
		this.setState({ openMetafieldModal: false });
	}

	closeTaskTypeModal() {
		this.setState({ openTaskTypeModal: false });
	}

	updateField(field, value, silent = false) {
		this.abortController?.abort();
		this.abortController = new AbortController();

		const account = store.getState().account;
		account[field] = value;
		store.dispatch({ type: "UPDATE_ACCOUNT", account });
		store.dispatch({ type: "SET_ACCOUNT", account });

		this.setState({ [field]: value });
		const params = {};
		params[field] = value;

		API.put("/api/account.json", params, {
			signal: this.abortController.signal,
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				store.dispatch({ type: "UPDATE_ACCOUNT", account: result.data.account });
				store.dispatch({ type: "SET_ACCOUNT", account: result.data.account });
				if (!silent) toastr.success(this.props.t("common.response.account.updated", "Account updated"));
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	renderActivity(item) {
		return (
			<ResourceList.Item id={item.id} onClick={this.openContactActivityModal.bind(this, item)}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.title}</h3>
						<span className="CustomerListItem__Location" />
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
			</ResourceList.Item>
		);
	}

	openContactActivityModal(item) {
		this.setState({ openContactActivityModal: true, currentActivity: item });
	}

	closeContactActivityModal() {
		this.setState({ openContactActivityModal: false, currentActivity: null });
	}

	render() {
		const contactTabsOptions = getcontactTabsOptions();
		const contactTabsOptionsValues = contactTabsOptions?.map((co) => co.value);
		const selecteContactTabs = store.getState().account.contact_tabs?.filter((ct) => contactTabsOptionsValues.includes(ct));

		return (
			<div>
				<Page
					title={this.props.t("contact.settings.title", "Kontakthantering")}
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				>
					<Layout>
						<Layout.AnnotatedSection
							title={this.props.t("contact.settings.activities.title", "Aktiviteter")}
							description={this.props.t("contact.settings.activities.description", "Fyll i aktiviteter för påminnelser, kontakter och ringlistor")}
						>
							<LegacyCard
								title="Aktiviteter"
								actions={[
									{
										content: this.props.t("contact.settings.activities.actions.new", "Ny aktivitet"),
										onAction: this.openContactActivityModal.bind(this, null),
									},
								]}
							>
								<ResourceList
									resourceName={{ singular: this.props.t("common.terms.field", "fält"), plural: this.props.t("common.terms.field", "fält") }}
									loading={this.state.loading}
									items={store.getState().contact_activities}
									renderItem={this.renderActivity.bind(this)}
								/>
								{store.getState().contact_activities.length ? null : (
									<LegacyCard.Section>
										<Text variation="subdued">{this.props.t("contact.settings.error.empty", "Inga aktiviteter att visa")}</Text>
									</LegacyCard.Section>
								)}
							</LegacyCard>
						</Layout.AnnotatedSection>
						<Layout.AnnotatedSection
							title={this.props.t("contact.settings.metafields.title", "Extra fält för kontakter")}
							description={this.props.t("contact.settings.metafields.description", "Fyll i egen data som passar ditt företag")}
						>
							<LegacyCard
								title={this.props.t("contact.settings.metafields.title2", "Extra-fält")}
								actions={[
									{
										content: this.props.t("contact.settings.metafields.actions.new", "Nytt fält"),
										onAction: this.openMetafieldModal.bind(this, { title: "", type: "input", options: [] }),
									},
								]}
							>
								<ResourceList
									resourceName={{ singular: this.props.t("common.terms.field", "fält"), plural: this.props.t("common.terms.field", "fält") }}
									loading={this.state.loading}
									items={store.getState().contact_metafields}
									renderItem={this.renderMetafield.bind(this)}
								/>
								{store.getState().contact_metafields.length ? null : (
									<LegacyCard.Section>
										<Text variation="subdued">{this.props.t("contact.settings.metafields.errors.empty", "Inga extrafält att visa")}</Text>
									</LegacyCard.Section>
								)}
							</LegacyCard>
						</Layout.AnnotatedSection>
						{store.getState().user.roles.some((r) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(r)) && (
							<Layout.AnnotatedSection title={this.props.t("contact.settings.contact_tabs.title", "Kontakt menyval")}>
								<LegacyCard>
									<OptionList
										title="Kontakt menyval"
										allowMultiple
										options={contactTabsOptions}
										selected={selecteContactTabs?.length ? selecteContactTabs || [] : contactTabsOptions?.map((co) => co.value) || []}
										onChange={(value) => {
											if (!value?.length) return;
											this.updateField("contact_tabs", value, true);
										}}
									/>
								</LegacyCard>
							</Layout.AnnotatedSection>
						)}
						<Layout.AnnotatedSection title={this.props.t("contact.settings.rest.title", "Övriga inställningar")}>
							<LegacyCard sectioned>
								<FormLayout>
									<div>
										<Text>{this.props.t("contact.settings.rest.search_is_text", "Bolagssök är")} </Text>
										<Text variation="strong">
											{store.getState().account.bolags_search_enabled
												? this.props.t("common.statuses.activated", "aktiverad")
												: this.props.t("common.statuses.disabled", "inaktiverad")}
										</Text>
									</div>
									<Switch
										checked={store.getState().account.bolags_search_enabled}
										onChange={(value) => {
											this.updateField("bolags_search_enabled", value, true);
										}}
									/>

									<div>
										<Text>{this.props.t("contact.settings.rest.allow_duplicates_text", "Tillåt dubletter")} </Text>
										<Text variation="strong">
											{store.getState().account.allow_duplicate_orgnr
												? this.props.t("common.terms.yes", "ja")
												: this.props.t("common.terms.no", "nej")}
										</Text>
									</div>
									<Switch
										checked={store.getState().account.allow_duplicate_orgnr}
										onChange={(value) => {
											this.updateField("allow_duplicate_orgnr", value, true);
										}}
									/>

									{store.getState().user.roles.some((r) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(r)) && (
										<>
											<div>
												<Text>
													{this.props.t("contact.settings.rest.allow_sync_contacts_to_phone.title", "Tillåt att kontakter synkas till mobilerna")}{" "}
												</Text>
												<Text variation="strong">
													{store.getState().account.allow_sync_contacts_to_phone
														? this.props.t("common.terms.yes", "ja")
														: this.props.t("common.terms.no", "nej")}
												</Text>
											</div>
											<Switch
												checked={store.getState().account.allow_sync_contacts_to_phone}
												onChange={(value) => {
													this.updateField("allow_sync_contacts_to_phone", value, true);
												}}
											/>
										</>
									)}
								</FormLayout>
							</LegacyCard>
						</Layout.AnnotatedSection>
					</Layout>
				</Page>
				<MetafieldModal
					open={this.state.openMetafieldModal}
					metafield={this.state.currentMetafield}
					onCreate={(type) => {
						this.closeMetafieldModal();
					}}
					onClose={this.closeMetafieldModal.bind(this)}
				/>
				<ContactActivityModal
					open={this.state.openContactActivityModal}
					activity={this.state.currentActivity}
					onCreate={(type) => {
						this.closeContactActivityModal();
					}}
					onClose={this.closeContactActivityModal.bind(this)}
				/>
				<TaskTypeModal
					open={this.state.openTaskTypeModal}
					type={this.state.currentTaskType}
					onCreate={(type) => {
						this.closeTaskTypeModal();
					}}
					onClose={this.closeTaskTypeModal.bind(this)}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	account: state.account,
	contact_metafields: state.contact_metafields,
	contact_activities: state.contact_activities,
});

export default connect(mapStateToProps)(withTranslation(["contact", "common"], { withRef: true })(ContactSettings));
