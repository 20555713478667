import React, { Component } from "react";
import { Text, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import TriggerCondition from "./TriggerCondition.js";
import TriggerAction from "./TriggerAction.js";
import Modal from "src/js/components/modal";

class TriggerModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: props.open,
			options: [],
			form: { conditions: [], actions: [] },
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open });
			if (!props.trigger) {
				this.setState({
					options: [],
					focusedCondition: 1,
					form: {
						enabled: true,
						title: `+ ${(this.props.board && this.props.board.singular) || "rad"}`,
						event: null,
						conditions: [{ field: "board", value: this.props.board.id, inclusive: false }],
						actions: [],
					},
				});
			} else {
				this.setState({
					options: [],
					focusedCondition: null,
					focusedAction: null,
					form: props.trigger,
				});
			}
		}
	}

	saveForm() {
		this.setState({ saving: true });
		const data = Object.assign({}, this.state.form);
		data.title = $(".trigger-description").text();
		if (data.id) {
			API.put("/api/boards/" + this.props.board.id + "/triggers/" + data.id + ".json", data, {
				params: {},
			})
				.then((result) => {
					this.props.onCreate(result.data.trigger);
					toastr.success(this.props.t("trigger.responses.updated", "Uppdaterade trigger"));
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		} else {
			API.post("/api/boards/" + this.props.board.id + "/triggers.json", data, {
				params: {},
			})
				.then((result) => {
					this.props.onCreate(result.data.trigger);
					toastr.success(this.props.t("trigger.responses.created", "Skapade ny trigger"));
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	changeEvent(event) {
		let focusedCondition = null;
		let focusedAction = 0;
		this.state.form.event = event;
		if (event == "board_row.value.date_arrive") {
			focusedCondition = 1;
			focusedAction = null;
			this.state.form.conditions.push({
				field: "timespan",
				value: null,
				inclusive: false,
			});
			this.state.form.conditions.push({
				field: "column",
				value: null,
				inclusive: false,
			});
		} else if (event == "board_row.value.update" || event == "board_row.click_button") {
			this.state.form.conditions.push({
				field: "column",
				value: null,
				inclusive: false,
			});
			focusedCondition = 1;
			focusedAction = null;
		} else if (event == "board_row.move_group") {
			this.state.form.conditions.push({
				field: "group",
				value: null,
				inclusive: false,
			});
			focusedCondition = 1;
			focusedAction = null;
		} else if (event == "board_row.value.change_status") {
			focusedCondition = 1;
			focusedAction = null;
			this.setState({
				optionTitle: this.props.t("trigger.terms.choose_type", "Välj typ"),
				optionCustom: false,
				options: [
					{
						label: this.props.t("trigger.terms.change_to", "ändras till"),
						onAction: () => {
							this.state.form.conditions.push({
								field: "column",
								value: null,
								inclusive: false,
							});
							this.state.form.conditions.push({
								field: "new_status",
								value: null,
								inclusive: false,
							});
							this.setState({
								form: this.state.form,
								focusedCondition,
								focusedAction,
							});
						},
					},
					{
						label: this.props.t("trigger.terms.from_something_to_something", "från något till något"),
						onAction: () => {
							this.state.form.conditions.push({
								field: "column",
								value: null,
								inclusive: false,
							});
							this.state.form.conditions.push({
								field: "old_status",
								value: null,
								inclusive: false,
							});
							this.state.form.conditions.push({
								field: "new_status",
								value: null,
								inclusive: false,
							});
							this.setState({
								form: this.state.form,
								focusedCondition,
								focusedAction,
							});
						},
					},
				],
			});
		}
		this.state.form.actions.push({ action: null });
		this.setState({ form: this.state.form, focusedCondition, focusedAction });
	}

	focusNext() {
		if (this.state.focusedCondition) {
			if (this.state.form.conditions.length - 1 > this.state.focusedCondition) {
				this.setState({
					focusedCondition: this.state.focusedCondition + 1,
					focusedAction: null,
				});
			} else {
				this.setState({ focusedCondition: null, focusedAction: 0 });
			}
		} else if (this.state.focusedAction) {
			if (this.state.form.actions.length - 1 > this.state.focusedAction) {
				this.setState({
					focusedCondition: null,
					focusedAction: this.state.focusedAction + 1,
				});
			} else {
				this.setState({ focusedCondition: null, focusedAction: null });
			}
		}
	}

	render() {
		return (
			<Modal
				large
				open={this.props.open}
				onClose={this.props.onClose}
				title={
					!this.props.trigger
						? this.props.t("trigger.actions.new", "Ny automatisering")
						: this.props.t("trigger.actions.update", "Uppdatera automatisering")
				}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
					disabled: !this.state.form.event,
				}}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				<div style={{ padding: 50, height: 400 }}>
					<div
						style={{
							height: 150,
							borderBottom: "1px solid #eee",
							marginBottom: 15,
							paddingBottom: 15,
						}}
					>
						<div
							style={{
								flex: 1,
								height: 135,
								display: "flex",
								alignItems: "flex-end",
								flexDirection: "row",
							}}
						>
							<div className="trigger-description">
								<Text variant="heading4xl" fontWeight="regular">
									<LegacyStack spacing="tight">
										<TriggerCondition
											event={this.state.form.event}
											groups={this.props.groups}
											board={this.props.board}
											focused={this.state.focusedCondition === 1}
											onFocus={() => {
												this.setState({
													optionTitle: "",
													options: [],
													focusedCondition: 1,
													focusedAction: null,
												});
											}}
											onUpdateCondition={(condition, focusNext = true) => {
												this.state.form.conditions[1] = condition;
												this.setState({ form: this.state.form });
												if (focusNext) this.focusNext();
											}}
											onChangeEvent={this.changeEvent.bind(this)}
											onRemove={() => {
												this.state.form.event = null;
												if (this.state.form.conditions.length > 1) {
													this.state.form.conditions.splice(1, 1);
												}
												this.state.form.actions = [];
												this.setState({ form: this.state.form });
											}}
											condition={this.state.form.conditions.length > 1 ? this.state.form.conditions[1] : null}
											updateOptions={(optionTitle, options, custom) => {
												this.setState({
													optionTitle,
													optionCustom: custom,
													options,
												});
											}}
										/>
										{this.state.form.conditions.map((condition, index) => {
											if (index > 1) {
												return (
													<TriggerCondition
														key={index}
														event={this.state.form.event}
														extraCondition={this.state.form.conditions[1]}
														groups={this.props.groups}
														board={this.props.board}
														focused={this.state.focusedCondition === index}
														onFocus={() => {
															this.setState({
																optionTitle: "",
																options: [],
																focusedCondition: index,
																focusedAction: null,
															});
														}}
														onUpdateCondition={(condition, focusNext = true) => {
															this.state.form.conditions[index] = condition;
															this.setState({ form: this.state.form });
															if (focusNext) this.focusNext();
														}}
														onChangeEvent={this.changeEvent.bind(this)}
														onRemove={() => {
															this.state.form.event = null;
															if (this.state.form.conditions.length > 1) {
																this.state.form.conditions.splice(index, 1);
															}
															this.state.form.actions = [];
															this.setState({ form: this.state.form });
														}}
														condition={condition}
														updateOptions={(optionTitle, options, custom) => {
															this.setState({
																optionTitle,
																optionCustom: custom,
																options,
															});
														}}
													/>
												);
											}
											return null;
										})}
										{this.state.form.actions.map((action, index) => (
											<TriggerAction
												key={index}
												event={this.state.form.event}
												groups={this.props.groups}
												board={this.props.board}
												focused={this.state.focusedAction === index}
												onFocus={() => {
													this.setState({
														optionTitle: "",
														options: [],
														focusedCondition: null,
														focusedAction: index,
													});
												}}
												action={action}
												onRemove={() => {
													this.state.form.actions.splice(index, 1);
													this.setState({ form: this.state.form });
												}}
												onUpdateAction={(action) => {
													this.state.form.actions[index] = action;
													this.setState({ form: this.state.form });
												}}
												updateOptions={(optionTitle, options, custom) => {
													this.setState({
														optionTitle,
														optionCustom: custom,
														options,
													});
												}}
											/>
										))}
									</LegacyStack>
								</Text>
							</div>
						</div>
					</div>

					<div style={{ height: 150 }}>
						<div style={{ marginBottom: 10 }}>
							<Text variation="subdued">{this.state.optionTitle}</Text>
						</div>
						{this.state.optionCustom}
						<LegacyStack>
							{this.state.options.map((option, index) => {
								if (option) {
									return (
										<button
											key={index}
											onClick={option.onAction}
											className="Polaris-Button Polaris-Button--outline"
											onMouseOver={option.onMouseOver}
											onMouseOut={option.onMouseOut}
										>
											<span className="Polaris-Button__Content">{option.label}</span>
										</button>
									);
								}
								return null;
							})}
						</LegacyStack>
					</div>
				</div>
			</Modal>
		);
	}
}
export default withTranslation(["trigger", "common"], { withRef: true })(TriggerModal);
