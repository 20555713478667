import React, { Component } from "react";
import { PageActions, Checkbox, Layout, LegacyCard, FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import ConditionForm from "./ConditionForm.js";
import ActionForm from "./ActionForm.js";
import SkeletonPage from "src/js/components/Skeletons/Page";

class TriggersEdit extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: props.match.params.id,
			loading: true,
			saving: false,
			form: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/triggers/" + id + ".json")
			.then((result) => {
				this.setState({ loading: false, form: result.data.trigger });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		if (field == "event") {
			formState.conditions = [];
			formState.actions = [];
		}
		formState[field] = value;
		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/triggers/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("triggers.automations.responses.saved", "Automation sparad"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	updateConditions(conditions) {
		const form = this.state.form;
		form.conditions = conditions;
		this.setState({ form });
	}

	updateActions(actions) {
		const form = this.state.form;
		form.actions = actions;
		this.setState({ form });
	}

	render() {
		if (this.state.loading) {
			return <SkeletonPage />;
		}
		return (
			<Page
				title={this.state.form.title}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{ content: this.props.t("common.actions.save", "Spara"), loading: this.state.saving, onAction: this.saveForm.bind(this) }}
			>
				<Layout>
					<Layout.AnnotatedSection
						title={this.props.t("triggers.automations.sections.user_details.title", "User details")}
						description={this.props.t("triggers.automations.sections.user_details.description", "Fill in details about the user")}
					>
						<LegacyCard sectioned>
							<FormLayout>
								<TextField
									label={this.props.t("triggers.automations.sections.user_details.fields.title.label", "Rubrik")}
									value={this.state.form.title}
									onChange={this.updateForm.bind(this, "title")}
								/>
								<Checkbox
									checked={this.state.form.enabled}
									label={this.props.t("triggers.automations.sections.user_details.fields.enabled.label", "Aktiv")}
									onChange={this.updateForm.bind(this, "enabled")}
								/>
								<Select
									label={this.props.t("triggers.automations.sections.user_details.fields.event.label", "Händelse")}
									options={[
										{
											value: "mail.received",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.mail.received.label", "E-post - mottaget"),
										},
										{
											value: "projects.create",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.projects.create.label", "Projekt - skapat"),
										},
										{
											value: "projects.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.update.label",
												"Projekt - uppdaterat"
											),
										},
										{
											value: "projects.tasks.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.create.label",
												"Uppdrag - skapat"
											),
										},
										{
											value: "projects.tasks.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.update.label",
												"Uppdrag - uppdaterat"
											),
										},
										{
											value: "projects.tasks.delegate",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.delegate.label",
												"Uppdrag - delegerat"
											),
										},
										{
											value: "projects.tasks.undelegate",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.undelegate.label",
												"Uppdrag - avdelegerat"
											),
										},
										{
											value: "projects.tasks.checkin",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.checkin.label",
												"Uppdrag - incheckning"
											),
										},
										{
											value: "projects.tasks.checkout",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.checkout.label",
												"Uppdrag - utcheckning"
											),
										},
										{
											value: "projects.tasks.logs.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.logs.create.label",
												"Uppdrag - registrering"
											),
										},
										{
											value: "projects.tasks.logs.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.logs.update.label",
												"Uppdrag - ändra registrering"
											),
										},
										{
											value: "projects.tasks.complete",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.complete.label",
												"Uppdrag - avslutat"
											),
										},
										{
											value: "projects.tasks.verified",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.verified.label",
												"Uppdrag - verifierat"
											),
										},
										{
											value: "projects.tasks.billed",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.projects.tasks.billed.label",
												"Uppdrag - fakturerad"
											),
										},
										{
											value: "tasks.create",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.tasks.create.label", "Påminnelse - skapad"),
										},
										{
											value: "tasks.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.tasks.update.label",
												"Påminnelse - uppdaterad"
											),
										},
										{
											value: "tasks.remind",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.tasks.remind.label",
												"Påminnelse - skicka påminnelse"
											),
										},
										{
											value: "tasks.due",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.tasks.due.label", "Påminnelse - inträffad"),
										},
										{
											value: "tasks.complete",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.tasks.complete.label",
												"Påminnelse - avslutad"
											),
										},
										{
											value: "contacts.create",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.contacts.create.label", "Kontakt - skapad"),
										},
										{
											value: "contacts.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.contacts.update.label",
												"Kontakt - uppdaterad"
											),
										},
										{
											value: "contacts.remove",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.contacts.remove.label",
												"Kontakt - borttagen"
											),
										},
										{
											value: "comments.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.comments.create.label",
												"Kommentar - skapad"
											),
										},
										{
											value: "comments.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.comments.update.label",
												"Kommentar - ändrad"
											),
										},
										{
											value: "deals.create",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.deals.create.label", "Affär - skapad"),
										},
										{
											value: "deals.update",
											label: this.props.t("triggers.automations.sections.user_details.fields.event.option.deals.update.label", "Affär - uppdaterad"),
										},
										{
											value: "tickets.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.tickets.create.label",
												"Supportärende - skapad"
											),
										},
										{
											value: "tickets.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.tickets.update.label",
												"Supportärende - uppdaterad"
											),
										},
										{
											value: "posts.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.posts.create.label",
												"Blogginlägg - skapad"
											),
										},
										{
											value: "posts.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.posts.update.label",
												"Blogginlägg - uppdaterad"
											),
										},
										{
											value: "posts.publish",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.posts.publish.label",
												"Blogginlägg - publicerad"
											),
										},
										{
											value: "posts.comment",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.posts.comment.label",
												"Blogginlägg - tog emot kommentar"
											),
										},
										{
											value: "posts.remove",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.posts.remove.label",
												"Blogginlägg - borttaget"
											),
										},
										{
											value: "board_row.create",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.create.label",
												"Board rad - skapad"
											),
										},
										{
											value: "board_row.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.update.label",
												"Board rad - uppdaterad"
											),
										},
										{
											value: "board_row.remove",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.remove.label",
												"Board rad - borttagen"
											),
										},
										{
											value: "board_row.click_button",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.click_button.label",
												"Board rad - knapptryck"
											),
										},
										{
											value: "board_row.move_group",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.move_group.label",
												"Board rad - flyttad grupp"
											),
										},
										{
											value: "board_row.move_board",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.move_board.label",
												"Board rad - flyttad board"
											),
										},
										{
											value: "board_row.comment",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.comment.label",
												"Board rad - kommentar"
											),
										},
										{
											value: "board_row.value.update",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.value.update.label",
												"Board rad - ändra värde"
											),
										},
										{
											value: "board_row.value.date_arrive",
											label: this.props.t(
												"triggers.automations.sections.user_details.fields.event.option.board_row.value.date_arrive.label",
												"Board rad - datum inträffar"
											),
										},
									]}
									onChange={this.updateForm.bind(this, "event")}
									value={this.state.form.event}
								/>
							</FormLayout>
						</LegacyCard>
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={this.props.t("triggers.automations.sections.user2_details.title", "User details")}
						description={this.props.t("triggers.automations.sections.user_details.description", "Fill in details about the user")}
					>
						<ConditionForm event={this.state.form.event} conditions={this.state.form.conditions} onUpdate={this.updateConditions.bind(this)} />
					</Layout.AnnotatedSection>
					<Layout.AnnotatedSection
						title={this.props.t("triggers.automations.sections.user3_details.title", "User details")}
						description={this.props.t("triggers.automations.sections.user_details.description", "Fill in details about the user")}
					>
						<ActionForm event={this.state.form.event} actions={this.state.form.actions} onUpdate={this.updateActions.bind(this)} />
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}
export default withTranslation(["triggers", "common"], { withRef: true })(TriggersEdit);
