import { Badge, LegacyCard, Checkbox, Spinner } from "@shopify/polaris";
import React, { Component } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { store } from "../../store";
import BoardHelper from "../Workspaces/BoardHelper";
import { toastr } from "../../components/toastr";
import BoardEmptyScreen from "../Workspaces/BoardViews/CallList/BoardEmptyScreen";
import ProfileAvatar from "../../components/ProfileAvatar";
import Money from "../../components/Money";
import CellStatus from "../Workspaces/CellStatus";

class LatestContactDeals extends Component {
	constructor(props) {
		super(props);
		this.state = { loading: props.loading };
		this.onFetch = this.onFetch.bind(this, props.contact?.id);
	}

	componentDidMount() {
		if ("rows" in this.props) {
			this.setState({ rows: this.props.rows, loading: this.props.loading }, this.updateRows.bind(this));
		} else {
			window.addEventListener("refreshDeals", this.onFetch, false);
			this.onFetchBoard();
		}

		if ("board" in this.props) {
			this.setState({ board: this.props.board });
		}
	}

	componentWillUnmount() {
		window.removeEventListener("refreshDeals", this.onFetch, false);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ("rows" in props) {
			this.setState({ rows: props.rows, loading: props.loading }, this.updateRows.bind(this));
		}
		if ("board" in props) {
			this.setState({ board: props.board });
		}

		this.setState({ loading: props.loading });
	}

	onFetch(id = this.props.contact?.id) {
		this.setState({ loading: true });
		BoardHelper.fetchRows(
			[store.getState().account.sales_board_id],
			null,
			(rows) => {
				this.setState({ rows, loading: false }, this.updateRows.bind(this));
			},
			id
				? {
						contact_id: id,
						also_archived: this.state.also_archived ? 1 : 0,
				  }
				: {}
		);
	}

	onFetchBoard() {
		if (this.state.board) {
			this.onFetch();
			return;
		}

		API.get(`/api/boards/${store.getState().account.sales_board_id}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}

				store.dispatch({ type: "SET_BOARDS", boards: [result.data.board] });
				// this.setState({ board: result.data.board });

				this.onFetch();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	updateRows() {
		const dealRows = BoardHelper.formatSalesBoardRows(this.state.rows, store.getState().boards[store.getState().account.sales_board_id]);

		this.setState({ dealRows });
	}

	render() {
		const statusColumn = store
			.getState()
			.boards[store.getState().account.sales_board_id]?.columns.find((col) => col.title.toLowerCase() === "affärsstatus");

		return (
			<div className="contact_deals">
				<LegacyCard sectioned>
					<h2 className="card_title" style={{ flex: 1 }}>
						{this.props.t("contact.latest_deals.title", "Senaste affärer")}
						<div style={{ display: "flex", alignItems: "center", position: "absolute", right: 10, top: 0, marginTop: "-0.2500rem" }}>
							{this.state.loading && (
								<div style={{ marginRight: 5 }}>
									<Spinner size="small" />
								</div>
							)}

							<Checkbox
								label={this.props.t("contact.latest_deals.also_archived", "Visa även arkiverade")}
								checked={this.state.also_archived}
								onChange={(checked) => {
									this.setState({ also_archived: checked }, this.onFetch);
								}}
							/>
						</div>
					</h2>

					<>
						<StyledTable loading={String(this.state.loading)}>
							<thead>
								<tr>
									<th>{this.props.t("contact.deals.labels.person", "Person")}</th>
									<th>{this.props.t("contact.deals.labels.affär", "Affär")}</th>
									<th>{this.props.t("contact.deals.labels.status", "Status")}</th>
									<th>{this.props.t("contact.deals.labels.date", "Datum")}</th>
									<th>{this.props.t("contact.deals.labels.omsattning", "Omsättning")}</th>
								</tr>
							</thead>
							<tbody>
								{this.state.dealRows
									?.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1))
									?.slice(0, 4)
									?.map((row) => {
										return (
											<tr key={row.key}>
												<td style={{ paddingBlock: 8 }}>
													<ProfileAvatar size={30} user={store.getState().users.find((u) => String(u.id) === String(row.person))} />
												</td>
												<td>
													{row.title}
													{row.archived && <Badge>{this.props.t("row.terms.archived", "Arkiverad")}</Badge>}
												</td>
												<td className="flex">
													<CellStatus
														inheritColor
														opacity={0.25}
														style={{ borderRadius: "100vh" }}
														column={statusColumn}
														// onChange={this.updateCell.bind(this, statusColumn)}
														// onUpdateColumn={BoardHelper.updateColumn}
														value={row?.status?.id}
													/>
												</td>
												<td>{moment(row.date).format("YYYY-MM-DD")}</td>
												<td>
													<Money amount={row.value} />
												</td>
											</tr>
										);
									})}
								{this.state.loading && (
									<tr>
										<td>
											<Spinner />
										</td>
										<td />
										<td />
										<td />
										<td />
									</tr>
								)}
							</tbody>
						</StyledTable>
						{this.state.dealRows?.length > 4 && !this.state.loading && (
							<div className="showmore">
								<button
									style={{ border: "none", background: "transparent", outline: "none" }}
									onClick={() => {
										this.props.setTab("dealings");
									}}
								>
									{this.props.t("common.actions.showmore", "Visa mer")}
								</button>
							</div>
						)}

						{!this.state.dealRows?.length && !this.state.loading && (
							<BoardEmptyScreen noheader resource={this.props.t("row.deal.plural", "affärer")} />
						)}
					</>
				</LegacyCard>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	users: state.users,
});

export default connect(mapStateToProps)(withTranslation(["contact", "board", "common"], { withRef: true })(LatestContactDeals));

const StyledTable = styled.table`
	width: 100%;
	border-spacing: 0;

	th {
		padding: 10px 0;
	}

	th,
	td {
		text-align: start;
	}

	th,
	td {
		font-size: 13px;

		&:nth-child(2) {
			text-align: start;
		}

		&:not(:last-of-type):not(:first-of-type):not(:nth-child(2)) {
			text-align: center;
		}

		&:last-of-type {
			text-align: end;
		}

		&:first-of-type {
			text-align: start;
			width: 80px;
		}
	}

	tbody {
		opacity: 1;
		transition: opacity 250ms;

		tr {
			.Polaris-Spinner {
				height: 100%;
				display: block;
				display: flex;
				align-items: center;

				svg {
					height: 40px;
					width: 40px;
					padding: 6px;
				}
			}

			&:nth-child(odd) {
				background-color: var(--main1);
			}

			td {
				padding: 3px 0;

				&.flex {
					> * {
						display: flex;
						justify-content: center;
					}
				}
			}
		}
	}

	${({ loading }) =>
		loading === "true" &&
		css`
			tbody {
				opacity: 0.5;
			}
		`}

	.board-status {
		width: max-content;
		padding-inline: 1.25rem;
	}
`;
