import { ArchiveMajor, CirclePlusMajor, CircleRightMajor, DeleteMajor } from "@shopify/polaris-icons";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { store } from "src/js/store";
import { toastr } from "src/js/components/toastr";
import API from "src/js/API";
import ActionsBar from "src/js/components/page/ActionsBar";
import BoardSelector from "../components/BoardSelector";
import BoardHelper from "../BoardHelper";
import TaskSheet from "../../Agenda/TaskSheet";
import CalendalSheet from "../components/CalendarModal/CalendalSheet";

const RowModalFormActions = ({ t, row, board, onClose, onComplete, onUpdateRow }) => {
	const [showReminderModal, setShowReminderModal] = useState<boolean>(false);
	const [showCalendarModal, setShowCalendarModal] = useState<boolean>(false);
	const [isMovingRowToGroup, setIsMovingRowToGroup] = useState<boolean>(false);

	const isCalendar = store.getState().user.calendar_board && store.getState().user.calendar_board.id == board?.id;
	if (!row || isCalendar) return null;

	const moveRowToGroup = async (group, force = false) => {
		setIsMovingRowToGroup(true);
		return await API.post("/api/boards/" + row.board_id + "/rows/" + row.id + "/move.json", { group, force }, { params: {} })
			.then((result) => {
				if (result.data.unmapped) {
					toastr.error(result.data.error);
					onClose({ action: "alert", message: result.data.error });

					return;
				}
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(t("row.responses.moved", "Flyttade rad") + " " + row.title);
				BoardHelper.removeRow(row.id);
				onClose({ action: "REMOVE_BOARD_ROW", row });
			})
			.catch((error) => {
				console.error(error);
				toastr.error(error);
			})
			.finally(() => {
				setIsMovingRowToGroup(false);
			});
	};

	const archiveRow = async () => {
		return await API.put("/api/boards/" + row.board_id + "/rows/" + row.id + ".json", { archived: true }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(t("row.responses.archived", "Arkiverade rad") + " " + result.data.row.title);
				BoardHelper.removeRow(result.data.row.id);
				onClose();
			})
			.catch((error) => {
				console.error(error);
				toastr.error(error);
			});
	};

	const removeRow = async () => {
		// this.setState({ removing: true, showContext: false });
		row.removed = true;
		BoardHelper.removeRow(row.id);
		return await API.delete("/api/boards/" + row.board_id + "/rows/" + row.id + ".json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				const row = result.data.row;
				onClose({ action: "REMOVE_BOARD_ROW", row });
				if (onUpdateRow) onUpdateRow(row);
				toastr.success(t("row.responses.removed", "Tog bort rad") + " " + row.title);
			})
			.catch((error) => {
				console.error(error);
				toastr.error(error);
			});
	};

	const secondaryActions = [
		{
			content: t("row.actions.move_to_board", "Flytta till board"),
			icon: CircleRightMajor,
			loading: isMovingRowToGroup,
			confirmation: {
				title: t("row.actions.move_to_board", "Flytta till board"),
				content: <BoardSelector onSelect={moveRowToGroup} board={BoardHelper.getBoard(row.board_id)} requireGroup />,
				cancel: t("common.actions.cancel", "Avbryt"),
				type: "popup",
				showButtons: false,
				sectioned: false,
			},
		},
		{
			content: t("row.terms.create_task", "Ny uppgift"),
			icon: CirclePlusMajor,
			onAction: () => {
				setShowReminderModal(true);
			},
			enabled: store.getState().user.todo_board && row?.id && String(board?.id) === String(store.getState().account.sales_board_id),
		},
		{
			content: t("row.terms.create_calendar_event", "Nytt kalenderinlägg"),
			icon: CirclePlusMajor,
			onAction: () => {
				setShowCalendarModal(true);
			},
			enabled: store.getState().user.calendar_board && row?.id && String(board?.id) === String(store.getState().account.sales_board_id),
		},

		{
			content: t("row.terms.archive", "Arkivera") + " " + (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
			icon: ArchiveMajor,
			destructive: true,
			onAction: archiveRow,
			confirmation: {
				title: t("row.terms.archive", "Arkivera") + " " + (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
				content: null,
				// content: t("row.responses.archive_confirmation", "Är du säker på att du vill arkivera {{singular}}?", {
				// 	singular: (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
				// }),
				cancel: t("common.actions.cancel", "Avbryt"),
				type: "popup",
			},
		},

		{
			content: t("row.terms.remove", "Ta bort") + " " + (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
			icon: DeleteMajor,
			destructive: true,
			onAction: removeRow,
			confirmation: {
				title: t("row.terms.remove", "Ta bort") + " " + (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
				content: t("row.responses.remove_confirmation", "Är du säker på att du vill ta bort {{singular}}?", {
					singular: (board && board.singular.toLowerCase()) || t("row.singular", "rad"),
				}),
				cancel: t("common.actions.cancel", "Avbryt"),
				type: "popup",
			},
		},
	];

	return (
		<>
			<ActionsBar secondaryActions={secondaryActions} style={{ opacity: 0.75, marginBottom: "0.5000rem" }} />

			{store.getState().user.todo_board && String(board?.id) === String(store.getState().account.sales_board_id) && (
				<TaskSheet
					open={showReminderModal}
					onComplete={(row) => setShowReminderModal(false)}
					onClose={() => setShowReminderModal(false)}
					options={{
						contact: (() => {
							const contactColumn = board?.columns.find((c) => c.type === "contact");
							const contactIds = row?.column_values?.[contactColumn?.id]?.value;
							return contactIds;
						})(),
					}}
					optionsByTitle={{
						affär: [row?.id].filter(Boolean),
					}}
				/>
			)}

			{store.getState().user.calendar_board && row?.id && String(board.id) === String(store.getState().account.sales_board_id) && (
				<CalendalSheet
					open={showCalendarModal}
					attendees={(() => {
						const contactColumn = board?.columns.find((c) => c.type === "contact");
						const contactIds = row?.column_values?.[contactColumn?.id]?.value;
						const contacts = contactIds
							?.map((contactId) => {
								const contact = store.getState().board_contacts[String(contactId)];
								if (!contact) return null;
								return {
									email: contact.email,
									name: contact.name,
									type: "required",
								};
							})
							?.filter((c) => c);

						return contacts || [];
					})()}
					row={(() => {
						const column = store
							.getState()
							.user.calendar_board?.columns?.find(
								(column) => column.type == "rowlink" && column.connected_board_id == store.getState().account.sales_board_id
							);

						const contactColumn = board?.columns?.find((c) => c.type === "contact");
						const contactId = row?.column_values?.[contactColumn?.id]?.value?.[0];

						const calendarContactColumn = store.getState().user.calendar_board?.columns.find((c) => c.type === "contact");

						const newRow = {
							column_values: {
								[column?.id]: {
									column_id: column?.id,
									value: [row.id],
								},
								[calendarContactColumn?.id]: {
									column_id: calendarContactColumn?.id,
									value: [contactId * 1],
								},
							},
						};

						return newRow;
					})()}
					board={store.getState().user.calendar_board}
					onClose={(row) => {
						setShowCalendarModal(false);
					}}
					onComplete={(row) => {
						setShowCalendarModal(false);
						onComplete?.();
						// const refreshEvent = new CustomEvent("contact_refresh_upcoming", {});
						// window.dispatchEvent(refreshEvent);
					}}
				/>
			)}
		</>
	);
};
export default withTranslation(["row", "common"])(RowModalFormActions);
