import { Checkbox, FormLayout, OptionList } from "@shopify/polaris";
import React, { useCallback, useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { BlogMajor, CustomersMajor, DeleteMajor, LegalMajor, OrdersMajor, ReceiptMajor, RefreshMajor, ThemesMajor } from "@shopify/polaris-icons";
import { connect } from "react-redux";
import { store } from "src/js/store";
import { toastr } from "src/js/components/toastr";
import Banner from "src/js/components/banner";
import API from "src/js/API";
import Colors, { colorToRgba } from "src/js/Colors";
import useQuery from "src/js/hooks/useQuery";
import FortnoxGlobalContext, { FortnoxGlobalContextProps } from "../ContextProvider/FortnoxGlobalContext";
import Button from "src/js/components/Button";
import Settings from "src/js/components/Settings/Settings";

const FortnoxSettings = ({ t, history }) => {
	const { openWindow, handleReconnect, handleDiconnect, pipelineSettings, isLoadingPipelineSettings, refetchPipelines } =
		useContext<FortnoxGlobalContextProps>(FortnoxGlobalContext);
	const [syncCustomerData, setSyncCustomerData] = React.useState<any>({
		types: [],
		filter: "active",
	});
	const [needReconnect, setNeedReconnect] = React.useState<boolean>(false);

	useEffect(() => {
		// Check if we need to reconnect
		(async () => {
			if (store.getState().account?.fortnox_integration && !store.getState().account?.fortnox_integration?.removed) {
				try {
					await API.get("/api/fortnox/customers.json", { params: { limit: 1 } });
					setNeedReconnect(false);
				} catch (e) {
					toastr.error(e);
					setNeedReconnect(true);
				}
			}
		})();
	}, []);

	const fetchPipelineSettings = useCallback(async () => {
		const result = await API.get("/api/pipeline_settings/fortnox_customer.json");
		return result.data.pipeline_settings;
	}, []);

	useEffect(() => {
		(async () => {
			const result = await fetchPipelineSettings();
			setSyncCustomerData({
				...(result?.options || {}),
				types: result?.options?.types || [],
			});
		})();
	}, [fetchPipelineSettings]);

	const handleChangeSyncCustomerData = (key) => (value) => {
		setSyncCustomerData((prev) => ({
			...prev,
			[key]: value,
		}));
	};

	const { data: account, refetch: refreshAccount } = useQuery({
		queryKey: [`account_${store.getState().account?.id}`],
		queryFn: async () => {
			const result = await API.get("/api/account.json");
			// if (result.data) store.dispatch({ type: "SET_ACCOUNT", account: result.data });
			return result.data || null;
		},
		enabled: !!store.getState().account?.id,
		initialData: store.getState().account,
		refetchOnWindowFocus: true,
	});

	const onDisconnect = async () => {
		await handleDiconnect();
		refreshAccount();
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getScopesArray = (integration = account?.fortnox_integration) => {
		return integration?.authentication?.scope?.split(" ");
	};

	const getScopes = (type: string | null) => {
		switch (type) {
			case "order":
				return "customer order offer article invoice print settings companyinformation";
			case "invoice":
				return "customer invoice article invoice print settings companyinformation";
			default:
				return account?.fortnox_integration?.authentication?.scopes || "customer invoice article invoice print settings companyinformation";
		}
	};

	const handleAddOrderModule = () => {
		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: getScopes("order"),
				type: "fortnox",
				title: "Fortnox",
				integration_id: "fortnox",
			},
		});
	};

	const handleAddFortnox = () => {
		openWindow({
			url: "/api/fortnox/connect",
			params: {
				scopes: getScopes("invoice"),
				type: "fortnox",
				title: "Fortnox",
				integration_id: "fortnox",
			},
		});
	};

	const handleSyncFortnoxCustomersToContacts = async (page = 1) => {
		return API.post("/api/fortnox/customers/sync.json", { options: syncCustomerData })
			.then(async (result) => {
				toastr.success(t("fortnox.settings.sync_contacts.success1", "Kontakter synkas"));

				setSyncCustomerData({
					...(result?.data?.pipeline_settings?.options || {}),
					types: result.data.pipeline_settings?.options?.types,
				});
			})
			.catch((error) => {
				toastr.error(error);
				console.error(error);
			});
	};

	const handleEnableDashboardAndSync = async (page = 1) => {
		try {
			await API.post("/api/fortnox/dashboard.json");
			toastr.success(t("fortnox.settings.enabled_dashboard.success1", "Dashboard aktiverad"));

			refetchPipelines();
		} catch (e) {
			toastr.error(e);
			console.error(e);
		}
	};

	const handledDisableDashboardAndSync = async (page = 1) => {
		try {
			await API.delete("/api/fortnox/dashboard.json");
			toastr.success(t("fortnox.settings.disabled_dashboard.success1", "Dashboard Avaktiverad"));

			refetchPipelines();
		} catch (e) {
			toastr.error(e);
			console.error(e);
		}
	};

	const handledResyncDashboardAndSync = async (page = 1) => {
		try {
			await API.post("/api/fortnox/dashboard/sync.json");
			toastr.success(t("fortnox.settings.sync_dashboard.success1", "Dashboards synkas igen"));
		} catch (e) {
			toastr.error(e);
			console.error(e);
		}
	};

	const pipelineSettingsExclCustomer = pipelineSettings?.filter((setting) => setting?.pipeline !== "App\\ETLBundle\\Entity\\FortnoxCustomerPipeline");

	const items = [
		{
			title: t("fortnox.settings.add_order_module.title", "Lägg till ordermodul"),
			description: t("fortnox.settings.add_order_module.description", "Lägg till ordermodul för hantering av ordrar och offerter"),
			icon: OrdersMajor,
			onAction: handleAddOrderModule,
			backgroundColor: colorToRgba(Colors.blue, 0.15),
			enabled: getScopesArray() && !getScopesArray()?.includes("order"),
		},
		{
			title: t("fortnox.settings.resync.title", "Återanslut"),
			description: t("fortnox.settings.resync.description", "Återanslut till Fortnox, vi har tappat kontakten med Fortnox."),
			icon: RefreshMajor,
			onAction: handleReconnect,
			backgroundColor: colorToRgba(Colors.red, 0.15),
			enabled: account?.fortnox_integration?.authentication?.removed || !account?.fortnox_integration?.authentication?.access_token || needReconnect,
		},
		{
			title: t("fortnox.settings.sync_costomers.title", "Synka/importera kunder ifrån Fortnox"),
			description: t("fortnox.settings.sync_costomers.description1", "Synka/importera kunder ifrån Fortnox"),
			icon: CustomersMajor,
			onAction: handleSyncFortnoxCustomersToContacts,
			enabled: !!account?.fortnox_integration,
			disabled: needReconnect,
			tooltip: needReconnect && t("fortnox.settings.sync_costomers.tooltip", "Återanslut till Fortnox för att synka kunder"),

			confirmation: {
				title: t("fortnox.settings.sync_costomers.confirmation.title1", "Synka kunder ifrån Fortnox"),
				content: (
					<>
						<FormLayout>
							<div>
								<p>{t("fortnox.settings.sync_contacts.confirmation.description", "Synka/importera kunder ifrån Fortnox")}</p>
								<p>{t("fortnox.settings.sync_contacts.confirmation.description2", "En notis skickas när synken är klar.")}</p>
							</div>
							<Checkbox
								label={t("fortnox.settings.sync_contacs.options.include_inactive.label", "Inkludera inaktiva kunder")}
								checked={syncCustomerData.filter === "inactive" || !syncCustomerData.filter}
								onChange={(checked) => handleChangeSyncCustomerData("filter")(checked ? "inactive" : "active")}
							/>

							<OptionList
								title={t("fortnox.settings.sync_contacs.options.title", "Vilka typer av kunder vill ni synka?")}
								options={[
									{ label: t("fortnox.settings.sync_contacs.options.company.label", "Företag"), value: "COMPANY" },
									{ label: t("fortnox.settings.sync_contacs.options.private.label", "Privat personer"), value: "PRIVATE" },
								]}
								selected={syncCustomerData.types}
								allowMultiple
								onChange={handleChangeSyncCustomerData("types")}
							/>
						</FormLayout>
					</>
				),
				confirm: t("fortnox.settings.sync_contacts.confirmation.confirm", "Synka"),
				cancel: t("fortnox.settings.sync_contacts.confirmation.cancel", "Avbryt"),
			},
		},
		{
			backgroundColor: colorToRgba(Colors.accent, 0.25),
			loading: isLoadingPipelineSettings,

			title: t("fortnox.settings.enabled_dashboard.title", "Aktivera Fortnox dashboard"),
			description: t("fortnox.settings.enabled_dashboard.description1", "Aktivera Fortnox dashboard för fakturor, ordrar, offerter och avtal"),
			icon: ThemesMajor,
			onAction: handleEnableDashboardAndSync,
			enabled: !!account?.fortnox_integration && pipelineSettingsExclCustomer && !pipelineSettingsExclCustomer?.length,
			disabled: needReconnect,
			confirmation: {
				title: t("fortnox.settings.enabled_dashboard.confirmation.title1", "Aktivera Fortnox dashboard"),

				content: (
					<>
						<FormLayout>
							<p>
								{t(
									"fortnox.settings.enabled_dashboard.confirmation.description",
									"Aktivera Fortnox dashboard för fakturor, ordrar, offerter och avtal"
								)}
							</p>
							<p>
								{t(
									"fortnox.settings.enabled_dashboard.confirmation.description1",
									"Fortnox datan kommer börja synkas/importeras till dashboarden. Ni kommer att få en notis när synken är klar."
								)}
							</p>
						</FormLayout>
					</>
				),

				confirm: t("fortnox.settings.enabled_dashboard.confirmation.confirm", "Aktivera"),
				cancel: t("fortnox.settings.enabled_dashboard.confirmation.cancel", "Avbryt"),
			},
		},
		{
			backgroundColor: colorToRgba(Colors.red, 0.25),
			loading: isLoadingPipelineSettings,

			title: t("fortnox.settings.disabled_dashboard.title", "Avaktivera Fortnox dashboard"),
			description: t("fortnox.settings.disabled_dashboard.description1", "Avaktivera Fortnox dashboard"),
			tooltip: {
				content: t("fortnox.settings.sync_dashboard.tooltip", "Synka"),
				icon: <Button plain icon={RefreshMajor} onClick={handledResyncDashboardAndSync} />,
			},
			icon: ThemesMajor,
			onAction: handledDisableDashboardAndSync,
			enabled: !!account?.fortnox_integration && pipelineSettingsExclCustomer && !!pipelineSettingsExclCustomer?.length,
			disabled: needReconnect,
			confirmation: {
				title: t("fortnox.settings.disabled_dashboard.confirmation.title1", "Avaktivera Fortnox dashboard"),

				content: (
					<>
						<FormLayout>
							<p>
								{t(
									"fortnox.settings.disabled_dashboard.confirmation.description",
									"Avaktivera Fortnox dashboard och sluta synka/importera Fortnox datan till dashboarden"
								)}
							</p>
						</FormLayout>
					</>
				),

				confirm: t("fortnox.settings.disabled_dashboard.confirmation.confirm", "Avaktivera"),
				cancel: t("fortnox.settings.disabled_dashboard.confirmation.cancel", "Avbryt"),
			},
		},
		{
			title: t("fortnox.settings.disconnect.title", "Koppla bort Fortnox"),
			description: t("fortnox.settings.disconnect.description", "Koppla bort Fortnox. Du kan ansluta igen när som helst."),
			icon: DeleteMajor,
			onAction: onDisconnect,
			enabled: !!account?.fortnox_integration,
			backgroundColor: colorToRgba(Colors.red, 0.25),
			destructive: true,

			confirmation: {
				destructive: true,
				title: t("fortnox.settings.disconnect.confirmation.title", "Koppla bort Fortnox"),
				content: (
					<p>{t("fortnox.settings.disconnect.confirmation.description", "Säker ni vill koppla bort Fortnox? Du kan ansluta igen när som helst.")}</p>
				),
				confirm: t("fortnox.settings.disconnect.confirmation.confirm", "Koppla bort Fortnox"),
				cancel: t("fortnox.settings.disconnect.confirmation.cancel", "Avbryt"),
			},
		},
	].filter((item) => !("enabled" in item) || item.enabled);

	const sections = [
		{
			title: t("fortnox.sections.settings.title", "Inställningar"),
			items,
			// roles: ["ROLE_FORTNOX"],
		},
		{
			title: t("fortnox.sections.views.title", "Vyer"),
			// roles: ["ROLE_FORTNOX"],
			items: [
				{
					title: t("fortnox.sections.views.invoices.title", "Fakturor"),
					description: t("fortnox.sections.views.invoices.description", "Lista för fakturor"),
					icon: ReceiptMajor,
					path: "/admin/fortnox/invoices",
				},
				{
					title: t("fortnox.sections.views.orders.title", "Ordrar"),
					description: t("fortnox.sections.views.orders.description", "Lista för ordrar"),
					icon: OrdersMajor,
					path: "/admin/fortnox/orders",
					enabled: getScopesArray()?.includes("order"),
				},
				{
					title: t("fortnox.sections.views.offers.title", "Offerter"),
					description: t("fortnox.sections.views.offers.description", "Lista för offerter"),
					icon: BlogMajor,
					path: "/admin/fortnox/offers",
					enabled: getScopesArray()?.includes("order"),
				},
				{
					title: t("fortnox.sections.views.billing_contracts.title", "Avtalsfakturering"),
					description: t("fortnox.sections.views.billing_contracts.description", "Lista för avtalsfaktureringar"),
					icon: LegalMajor,
					path: "/admin/fortnox/contracts",
				},
				{
					title: t("fortnox.sections.views.articles.title", "Artiklar"),
					description: t("fortnox.sections.views.articles.description", "Lista av artiklar"),
					icon: LegalMajor,
					path: "/admin/fortnox/articles",
				},
			],
		},
	];

	return (
		<Page title={t("fortnox.settings.title", "Fortnox inställningar och vyer")}>
			{!account?.fortnox_integration ? (
				<Banner
					title={t("fortnox.settings.banner.title", "Anslut till Fortnox")}
					status="warning"
					actions={[
						{
							content: t("fortnox.settings.banner.button", "Anslut till Fortnox"),
							onAction: handleAddFortnox,
						},
					]}
				>
					<p>{t("fortnox.settings.banner", "Du har inte anslutit till Fortnox än. Klicka på knappen nedan för att ansluta.")}</p>
				</Banner>
			) : (
				<Settings sections={sections} />
			)}
		</Page>
	);
};

export default connect((state: any) => ({
	account: state.account,
}))(withTranslation(["fortnox", "common"])(FortnoxSettings));
