import React, { useEffect, useState } from "react";
import { Trans, useTranslation, withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Page from "src/js/components/page";
import SkeletonPage from "src/js/components/Skeletons/Page";
import useQuery from "src/js/hooks/useQuery";
import AxiconGraphs from "../axiconGraphs/AxiconGraphs";
import { Layout } from "@shopify/polaris";
import { JobsMajor } from "@shopify/polaris-icons";
import ContactCard from "src/js/components/ContactCard";
import transformToContact from "./transformToContact";
import styled from "styled-components";
import Banner from "src/js/components/banner";
import API from "src/js/API";
import ContactContactsList from "../ContactContactsList";
import useContact from "src/js/hooks/useContact";
import { toastr } from "src/js/components/toastr";

const ContactImport = () => {
	const { orgnr: searchOrgnr }: any = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const { axiconData, axiconError, axiconIsInitialLoading: isFetchingAxicon } = useContact({ contact: { orgnr: searchOrgnr } });

	const [form, setForm] = useState<Partial<ContactType>>({});

	const { data: existingContacts } = useQuery({
		queryKey: ["contact", "orgnr", searchOrgnr],
		queryFn: async () => {
			try {
				const res = await API.get(`/api/contacts.json`, {
					params: {
						company_number: searchOrgnr,
						is_company: true,
					},
				});

				return res.data.contacts;
			} catch (e) {
				console.error(e);
			}
		},
	});

	useEffect(() => {
		if (axiconData) setForm(transformToContact({ is_company: true, ...axiconData }));
	}, [axiconData]);

	const handleImport = async () => {
		try {
			const submitForm = {
				...form,
				childs: form.childs
					?.filter((child) => child.selected)
					?.map((child) => {
						delete child.id;
						return child;
					}),
			};

			const result = await API.post("/api/contacts.json", submitForm);
			toastr.success(t("contacts.companies.import.success", "Företaget har importerats"));

			history.push(`/admin/contacts/${result.data.contact.id}`);
		} catch (error) {
			toastr.error(error);
		}
	};

	const isLoading = isFetchingAxicon;
	const orgnr = form?.orgnr || searchOrgnr;

	if (isLoading) return <SkeletonPage title={t("contacts.companies.import.title", "Importera kontakt med orgnr: {{orgnr}}", { orgnr }) as string} />;
	if (!isLoading && axiconError) return <Banner title={t("contacts.companies.import.error", "Kunde inte hitta företaget")} status="critical">
		<p>{axiconError}</p>
	</Banner>

		return (
			<Page
				title={t("contacts.companies.import.title", "Importera kontakt med orgnr: {{orgnr}}", { orgnr }) as string}
				loading={isLoading}
				primaryAction={{
					content: t("contacts.companies.import.action", "Importera") as string,
					onAction: handleImport,
					enabled: !existingContacts?.length,
					confirmation: {
						title: t("contacts.companies.import.confirmation.title", "Importera företaget?"),
						content: (
							<Trans
								i18nKey={"contacts.companies.import.confirmation.content"}
								defaults="Vill du importera företaget <b>{{name}}</b> ({{orgnr}})?"
								components={{ b: <strong /> }}
								values={{ orgnr, name: form.name }}
							/>
						),

						confirm: t("contacts.companies.import.confirmation.confirm", "Ja, importera"),
						cancel: t("contacts.companies.import.confirmation.cancel", "Avbryt"),
					},
				}}
			>
				<Wrapper className="contact-wrapper">
					<Layout>
						<Layout.Section>
							{!!existingContacts?.length && (
								<Banner
									style={{ marginBottom: "1.875rem" }}
									type="error"
									title={t("contacts.companies.import.warning.title", "Bolaget finns redan importerat") as string}
									icon={JobsMajor}
									actions={[
										{
											content: t("contacts.companies.import.warning.action", "Visa företagen ({{count}})", {
												count: existingContacts.length,
											}) as string,
											onAction: () => {
												history.push(`/admin/contacts/companies?` + new URLSearchParams({ company_number: orgnr }).toString());
											},
											primary: true,
										},
									]}
								>
									<p>
										{
											t("contacts.companies.import.warning.text", "{{count}}st företag finns redan med samma orgnr.", {
												count: existingContacts.length,
											}) as string
										}
									</p>
									<p>
										{existingContacts
											?.slice(0, 3)
											.map((contact: Partial<ContactType>, index) => contact.name)
											.join(", ")}
										{existingContacts?.length > 3 && `...+(${existingContacts.length - 3})`}
									</p>
								</Banner>
							)}

							<ContactCard contact={form} onCreateNoSave={setForm} onChange={setForm} />

							<AxiconGraphs contact={{ orgnr }} />
						</Layout.Section>

						<Layout.Section secondary>
							<ContactContactsList
								import
								key={JSON.stringify(
									form.childs?.map((c) => {
										const { selected, ...rest } = c;
										return rest;
									})
								)}
								expanded
								contact={form}
								items={form.childs}
								onChange={(children) => {
									setForm(() => ({
										...form,
										childs: children,
									}));
								}}
								selectedContacts={form.childs?.filter((c) => c.selected)?.map((i) => i.id) || []}
								handleContactChange={(ids) => {
									setForm(() => ({
										...form,
										childs: form.childs?.map((child) => ({
											...child,
											selected: ids.includes(child.id),
										})),
									}));
								}}
							/>
						</Layout.Section>
					</Layout>
				</Wrapper>
			</Page>
		);
};
export default withTranslation(["contacts", "common"])(ContactImport);

const Wrapper = styled.div``;
