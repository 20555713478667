import React, { useCallback } from "react";
import ItemCard from "src/js/components/ItemCard";
import { WithTranslation, withTranslation } from "react-i18next";
import Badge from "src/js/components/Badge";
import Colors from "src/js/Colors";
import Page from "src/js/components/page";
import truncateString from "src/js/Utilities/truncateString";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import IndexTable from "src/js/components/IndexTable";

type PricelistsListProps = {
	history: any;
} & WithTranslation;

const PricelistList = ({ history, t }: PricelistsListProps) => {
	const ref = React.useRef<any>();

	const handleBulkDisabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/pricelists.json", { ids, disabled: 1 });
		toastr.success(t("categories.responses.bulk_disabled", "{{count}} prislistor inaktiverade", { count: result.data?.pricelists?.length }));
		ref.current?.reload();
	}, [t]);

	const handleBulkEnabled = useCallback(async () => {
		const ids = ref.current.getSelectedItems();
		const result = await API.put("/api/pricelists.json", { ids, disabled: 0 });
		toastr.success(t("pricelists.responses.bulk_enabled", "{{count}} prislistor aktiverade", { count: result.data?.pricelists?.length }));
		ref.current?.reload();
	}, [t]);

	const renderItem = useCallback(
		(item: PricelistType) => {
			return (
				<ItemCard
					onClick={() => {
						history.push(`/admin/pricelists/${item.id}`, {
							data: item,
						});
					}}
					title={
						<>
							{item.title}
							{item.disabled ? (
								<Badge color={Colors.red}>{t("common.inactive", "Inaktiv")}</Badge>
							) : (
								<Badge color={Colors.green}>{t("common.active", "Aktiv")}</Badge>
							)}
						</>
					}
					leftColumn={[
						{
							label: t("pricelists.description", "Beskrivning"),
							value: truncateString(item.description ?? "", 20),
						},
						{
							label: t("pricelists.articles", "Artiklar"),
							value: item.articles_display ?? "Inga artiklar",
						},
						{
							label: t("pricelists.customers", "Kunder"),
							value: item.contacts_display ?? "Inga kunder",
						},
						{
							label: t("pricelists.users", "Användare"),
							value: item.users_display ?? "Inga användare",
						},
					]}
					rightColumn={[
						{
							label: t("pricelists.description", "Lägg till kunder automatisk"),
							value: item.add_new_contacts_automatically ? "Ja" : "Nej",
						},
						{
							label: t("pricelists.description", "Lägg till användare automatisk"),
							value: item.add_new_users_automatically ? "Ja" : "Nej",
						},
					]}
				/>
			);
		},
		[history, t]
	);

	const filters = [];
	return (
		<Page>
			<IndexTable
				title={t("pricelists.title", "Prislistor") as string}
				history={history}
				primaryAction={{
					content: t("pricelists.newPricelist", "Ny prislista"),
					url: "/admin/pricelists/create",
				}}
				ref={ref}
				promotedBulkActions={[
					{
						content: t("categories.bulkDisable", "Inaktivera") as string,
						onAction: handleBulkDisabled,
					},
					{
						content: t("categories.bulkEnable", "aktivera") as string,
						onAction: handleBulkEnabled,
					},
				]}
				defaultFilters={[
					{
						key: "disabled",
						value: "0",
					},
				]}
				filters={filters}
				resourceUrl="/api/pricelists.json"
				resourceName={{
					singular: "prislista",
					plural: "prislistor",
				}}
				resourceHandle="pricelists"
				columns={[
					{
						handle: "",
						label: "",
						render: renderItem,
					},
				]}
			/>
		</Page>
	);
};
export default withTranslation(["pricelists", "common"])(PricelistList);
