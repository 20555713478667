import React, { Component } from "react";
import { LegacyCard, DropZone } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../API";
import { toastr } from "./toastr.js";
import Attachment from "./Attachment.js";
import Modal from "src/js/components/modal";

class UploadModal extends Component {
	constructor(props) {
		super(props);

		this.state = { saving: false, files: [], open: props.open };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, files: [], saving: false });
		}
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.props.returnBase64) {
			this.props.onSelect(this.state.files);
			return;
		}

		const files = this.state.files.map((file) => {
			return { ...file, public: this.props.public || false };
		});

		API.post("/api/uploads.json", { files, as: this.props.as })
			.then(async (result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (!this.props.disabledToast) toastr.success(this.props.t("common.upload.responses.uploaded", "Laddade upp filer"));
				await this.props.onSelect(result.data.uploads);
				this.setState({ saving: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.files;
		files.push({ name: file.name, type: file.type, size: file.size, data });
		this.setState({ files });
	}

	updateFile(index, file) {
		this.state.files[index] = file;
		this.setState({ files: this.state.files });
	}

	componentDidUpdate() {
		$(".upload-modal-wrapper").closest("[data-portal-id]").addClass("modal-top");
	}

	render() {
		// const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.t("common.upload.actions.choose", "Välj fil")}
				secondaryActions={[].concat(this.props.secondaryActions || []).concat([
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				])}
				primaryAction={{
					content: this.props.t("common.actions.choose", "Välj"),
					disabled: !this.state.files.length,
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
					...(this.props.primaryAction || {}),
				}}
			>
				{this.props.children && <Modal.Section>{this.props.children}</Modal.Section>}
				<div className="upload-modal-wrapper">
					<DropZone
						openFileDialog={this.state.openFileDialog}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
						}}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (let i = 0; i < acceptedFiles.length; i++) {
								const reader = new FileReader();
								reader.onload = this.addFile.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
						}}
						allowMultiple={this.props.allowMultiple}
					>
						{this.state.files.length ? (
							<LegacyCard sectioned>
								{this.state.files.map((file, index) => (
									<Attachment editable onChange={this.updateFile.bind(this, index)} key={index} file={file} />
								))}
							</LegacyCard>
						) : (
							<DropZone.FileUpload
								actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
								actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
							/>
						)}
					</DropZone>
				</div>
			</Modal>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(UploadModal);
UploadModal.defaultProps = {
	allowMultiple: true,
};
