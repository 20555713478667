/* eslint-disable quotes */
import React, { Component } from "react";
import { Button, Select, Labelled, LegacyStack, Tooltip, Text, Scrollable } from "@shopify/polaris";
import moment from "moment";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import Accordion from "./components/Accordion.js";
import BoardHelper from "./BoardHelper.js";
import Sheet from "../../components/sheet";

class WidgetChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSettings: props.showSettings,
		};
		this.state.data = this.calculateData(props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.showSettings != this.state.showSettings) {
			this.setState({ showSettings: props.showSettings });
		}
		this.setState({ data: this.calculateData(props) });
	}

	getColumnOptions(board, types) {
		const arr = [];
		if (types.indexOf("board") >= 0) {
			arr.push({ value: "board", label: this.props.t("widget.column.options.board.label", "Board") });
		}
		if (types.indexOf("rows") >= 0) {
			arr.push({ value: "rows", label: this.props.t("widget.column.options.rows.label", "Antal rader") });
		}
		if (types.indexOf("group") >= 0) {
			arr.push({ value: "group", label: this.props.t("widget.column.options.group.label", "Grupper") });
		}
		if (types.indexOf("title") >= 0) {
			arr.push({ value: "title", label: this.props.t("widget.column.options.title.label", "Namn") });
		}
		if (types.indexOf("datetime") >= 0) {
			arr.push({ value: "created_at", label: this.props.t("widget.column.options.datetime.label", "Rad skapades") });
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (types.indexOf(board.columns[i].type) >= 0) {
				arr.push({ value: board.columns[i].id + "", label: board.columns[i].title });
			}
		}
		arr.push({ value: "", label: this.props.t("widget.column.options.none.label", "Inget värde") });
		return arr;
	}

	updateOptions() {
		this.setState({ data: this.calculateData(this.props) });
		API.put(
			"/" +
				"api" +
				(this.props.dashboard ? "/dashboards/" + this.props.dashboard.id : "/boards/" + this.props.board.id) +
				"/widgets/" +
				this.props.widget.id +
				".json",
			{ options: this.props.widget.options },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.props.onUpdate(result.data.widget);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getLabel(props, row, value, axis, axisType) {
		let label = "";
		if (value == "none") {
			label = this.props.t("widget.column.options.none.label", "Inget värde");
		} else {
			if (axisType == "number" || axisType == "datetime" || axisType == "created_at") {
				label = value;
			} else if (axis == "board") {
				const board = this.getBoards().find((board) => board.id == row.board_id);
				label = board.title;
			} else if (axis == "group") {
				const group = props.groups.find((group) => group.id == row.group_id);
				label = group.title;
			} else if (axis == "title") {
				label = row.value;
			} else {
				const board = this.getBoards().find((board) => board.id == row.board_id);
				const column = board.columns.find((column) => column.id == axis);
				label = BoardHelper.getLabelFromValue({ value }, board, column).title;
			}
		}
		return label;
	}

	getDate(props, row) {
		const boardId = row.board_id;
		const xAxis = props.widget.options.x_axis[boardId];
		if (xAxis == "created_at") {
			return moment(row.created_at);
		} else if (xAxis in row.column_values) {
			return moment(row.column_values[xAxis].value);
		} else {
			return null;
		}
	}

	calculateData(props) {
		let stacked = false;
		if (
			[
				"stacked-line",
				"100p-stacked-line",
				"stacked-bar",
				"100p-stacked-bar",
				"horizontal-stacked-bar",
				"100p-horizontal-stacked-bar",
				"stacked-area",
				"100p-stacked-area",
			].indexOf(props.widget.options.type) >= 0
		) {
			stacked = true;
		}

		let type;
		if (["pie", "donut"].indexOf(props.widget.options.type) >= 0) {
			type = "pie";
		} else if (["line", "stacked-line", "100p-stacked-line"].indexOf(props.widget.options.type) >= 0) {
			type = "line";
		} else if (["smooth-line"].indexOf(props.widget.options.type) >= 0) {
			type = "spline";
		} else if (["bar", "stacked-bar", "100p-stacked-bar"].indexOf(props.widget.options.type) >= 0) {
			type = "column";
		} else if (["horizontal-bar", "horizontal-stacked-bar", "100p-horizontal-stacked-bar"].indexOf(props.widget.options.type) >= 0) {
			type = "bar";
		} else if (["area", "stacked-area", "100p-stacked-area"].indexOf(props.widget.options.type) >= 0) {
			type = "areaspline";
		}

		let yAxisTitle;
		if (props.widget.options.y_axis_type == "rows") {
			yAxisTitle = this.props.t("widget.terms.number_of_rows", "Antal rader");
		} else if (props.widget.options.y_axis_type == "number") {
			yAxisTitle = this.props.t("widget.terms.sum", "Summa");
		} else if (props.widget.options.y_axis_type == "timetrack") {
			yAxisTitle = this.props.t("widget.terms.hours", "Timmar");
		}

		const values = [];
		const categories = [];
		const stacks = [];
		const stackInfo = [];
		let rows = [];

		if (props.widget.options.x_axis_type == "datetime") {
			rows = props.rows.sort((a, b) => {
				const adate = this.getDate(props, a);
				const bdate = this.getDate(props, b);

				return adate.unix() - bdate.unix();
			});
		} else {
			rows = props.rows;
		}

		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];
			const boardId = row.board_id;
			const yAxis = props.widget.options.y_axis[boardId];
			let yAxisValue = 0;
			let stackAxis = 0;
			let xAxisValue = ["none"];
			let stackAxisValue = ["none"];
			if (yAxis) {
				yAxisValue = this.getYAxisValue(row, yAxis, props.widget.options.y_axis_type, null);
			}

			const xAxis = props.widget.options.x_axis[boardId];
			if (xAxis) {
				xAxisValue = this.getAxisValue(props, row, xAxis, props.widget.options.x_axis_type, props.widget.options.x_axis_group_by);
			}

			if (stacked) {
				stackAxis = props.widget.options.stack_axis[boardId];

				if (!stackAxis) {
					stackAxisValue = ["none"];
				} else {
					stackAxisValue = this.getAxisValue(props, row, stackAxis, props.widget.options.stack_axis_type, props.widget.options.stack_axis_group_by);
				}
			} else {
				stackAxis = ["none"];
			}

			for (let z = 0; z < xAxisValue.length; z++) {
				for (let s = 0; s < stackAxisValue.length; s++) {
					const valueIndex = values.findIndex((val) => val.xAxis == xAxisValue[z] && val.stackAxis == stackAxisValue[s]);
					if (valueIndex < 0) {
						values.push({ xAxis: xAxisValue[z], stackAxis: stackAxisValue[s], value: yAxisValue });
					} else {
						values[valueIndex].value += yAxisValue;
					}

					const categoryIndex = categories.findIndex((val) => val.handle == xAxisValue[z]);
					if (categoryIndex < 0) {
						categories.push({
							title: this.getLabel(props, row, xAxisValue[z], xAxis, props.widget.options.x_axis_type),
							handle: xAxisValue[z],
							value: yAxisValue,
						});
					} else {
						categories[categoryIndex].value += yAxisValue;
					}

					const stackIndex = stacks.indexOf(stackAxisValue[s]);
					if (stackIndex < 0) {
						stacks.push(stackAxisValue[s]);
						stackInfo.push({
							title: this.getLabel(props, row, stackAxisValue[s], stackAxis, props.widget.options.stack_axis_type),
							value: stackAxisValue[s],
						});
					}
				}
			}
		}

		const series = [];

		if (type == "pie") {
			const serie = {
				name: yAxisTitle,
				data: [],
			};
			if (props.widget.options.type == "donut") {
				serie.innerSize = "75%";
			}
			for (let s = 0; s < categories?.length; s++) {
				serie.data.push([categories[s].title + "", categories[s].value]);
			}
			series.push(serie);
		} else {
			for (let i = 0; i < stacks.length; i++) {
				const serie = {
					name: stackInfo[i].title,
					data: [],
					color: {
						linearGradient: {
							x1: 1,
							x2: 0,
							y1: 0,
							y2: 0,
						},
						stops: [
							[0, "#FD5DEF"],
							[1, "#5EC3FF"],
						],
					},
				};
				if (!stacked) {
					serie.showInLegend = false;
					serie.name = yAxisTitle;
				}
				for (let s = 0; s < categories?.length; s++) {
					const value = values.find((val) => val.xAxis == categories[s].handle && val.stackAxis == stacks[i]);
					if (value) {
						serie.data.push(value.value);
					} else {
						serie.data.push(0);
					}
				}
				series.push(serie);
			}
		}

		const plotOptions = {
			series: {},
		};

		if (type == "areaspline") {
			plotOptions.areaspline = {
				lineColor: {
					linearGradient: {
						x1: 0,
						x2: 0,
						y1: 0,
						y2: 1,
					},
					stops: [
						[0, "#5AC57D"],
						[1, "#4991CC"],
					],
				},
				lineWidth: 3,
				marker: {
					enabled: false,
				},
			};
			if (props.widget.options.type == "stacked-area") {
				plotOptions.area.stacking = "normal";
			} else if (props.widget.options.type == "100p-stacked-area") {
				plotOptions.area.stacking = "percent";
			}
		}

		if (type == "line") {
			plotOptions.line = {};
			if (props.widget.options.type == "stacked-line") {
				plotOptions.line.stacking = "normal";
			} else if (props.widget.options.type == "100p-stacked-line") {
				plotOptions.line.stacking = "percent";
			}
		}

		if (type == "spline") {
			plotOptions.spline = {};
		}

		if (type == "column") {
			plotOptions.column = {};
			if (props.widget.options.type == "stacked-bar") {
				plotOptions.column.stacking = "normal";
			} else if (props.widget.options.type == "100p-stacked-bar") {
				plotOptions.column.stacking = "percent";
			}
		}
		if (type == "pie") {
			plotOptions.pie = {
				borderColor: "transparent",
				allowPointSelect: true,
				cursor: "pointer",
				dataLabels: {
					enabled: false,
				},
				showInLegend: true,
			};
		}
		if (type == "bar") {
			plotOptions.bar = {};
			if (props.widget.options.type == "horizontal-stacked-bar") {
				plotOptions.bar.stacking = "normal";
			} else if (props.widget.options.type == "100p-horizontal-stacked-bar") {
				plotOptions.bar.stacking = "percent";
			}
		}

		const data = {
			title: null,
			colors: [
				{
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, "#4991CC"],
						[1, "#357FBC"],
					],
				},
				{
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, "#5AC57D"],
						[1, "#17B84C"],
					],
				},
				{
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, "#edab51"],
						[1, "#cf913a"],
					],
				},
				{
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, "#e477b9"],
						[1, "#cc60a1"],
					],
				},
				{
					linearGradient: {
						x1: 1,
						x2: 0,
						y1: 0,
						y2: 0,
					},
					stops: [
						[0, "#ffd66b"],
						[1, "#deb752"],
					],
				},
			],
			credits: {
				enabled: false,
			},
			tooltip: {
				borderRadius: 5,
				padding: 15,
				shadow: true,
			},
			chart: {
				type,
			},
			yAxis: {
				title: {
					text: type == "pie" ? yAxisTitle : null,
				},
			},
			xAxis: {
				crosshair: {
					width: 2,
					dashStyle: "shortdot",
					className: "highcharts-crosshair",
				},
				categories: categories && categories.map((category) => category.title),
			},
			series,
			plotOptions,
		};

		if (props.widget.options.y_axis_type == "timetrack") {
			data.yAxis.labels = {
				formatter() {
					return Math.ceil((this.value / 3600) * 100) / 100 + "h";
				},
			};
			data.tooltip = {
				pointFormatter() {
					return (
						'<span style="color: ' +
						this.series.color +
						'">\u25CF</span> ' +
						this.series.name +
						": <b>" +
						Math.ceil((this.y / 3600) * 100) / 100 +
						"h</b><br/>"
					);
				},
			};
		}
		/*
    if ((!stacked) && (type != 'pie')) {
      data.tooltip = {
        formatter: function() {
         return this.x + ', ' + this.y;
        }
      };
    }
    */
		return data;
	}

	getYAxisValue(row, axis, type) {
		let value = 0;

		if (axis == "rows") {
			value = 1;
		} else if (axis) {
			for (let s = 0; s < row.values.length; s++) {
				if (row.values[s].column_id == axis) {
					if (type == "timetrack") {
						if (row.values[s].value && row.values[s].value.duration) {
							value = parseInt(row.values[s].value.duration); // TODO: Add active time aswell
						}
					} else if (row.values[s].value) {
						value = row.values[s].value;
					}
					break;
				}
			}
		}
		return value;
	}

	groupDate(dateString, groupBy) {
		const mom = moment(dateString);
		if (groupBy == "day") {
			return mom.format("D MMM");
		} else if (groupBy == "week") {
			return mom.format("YYYY") + " V" + mom.isoWeek();
		} else if (groupBy == "month") {
			return mom.format("YYYY-MM");
		} else if (groupBy == "quarter") {
			return mom.format("YYYY") + " Q" + mom.quarter();
		} else if (groupBy == "year") {
			return mom.format("YYYY");
		}
		return mom.format("YYYY-MM-DD");
	}

	getAxisValue(props, row, axis, type, groupBy) {
		let values = ["none"];
		if (type == "number") {
			values = ["0"];
		} else if (axis == "board") {
			const board = this.getBoards().find((board) => board.id == row.board_id);
			values = [board.title];
		} else if (axis == "group") {
			const group = props.groups.find((group) => group.id == row.group_id);
			values = [group.title];
		} else if (axis == "title") {
			values = [row.title];
		} else if (axis == "created_at") {
			values = [this.groupDate(row.created_at, groupBy)];
		} else if (axis) {
			for (let s = 0; s < row.values?.length; s++) {
				if (row.values[s].column_id == axis) {
					if (type == "datetime") {
						if (row.values[s].value) {
							values = [this.groupDate(row.values[s].value.datetime, groupBy)];
						}
					} else if (type == "person") {
						if (row.values[s].value.length) {
							values = row.values[s].value;
						}
					} else if (type == "status") {
						if (row.values[s].value || row.values[s].value == 0 || row.values[s].value == "0") {
							values = [row.values[s].value];
						}
					} else if (type == "dropdown") {
						if (row.values[s].value) {
							values = row.values[s].value;
						}
					} else if (type == "tags") {
						if (row.values[s].value.length) {
							values = row.values[s].value;
						}
					} else if (type == "number") {
						if (row.values[s].value) {
							values = [row.values[s].value];
						} else {
							values = [0];
						}
					}
					break;
				}
			}
		}
		return values;
	}

	changeType(type) {
		this.props.widget.options.type = type;
		this.updateOptions();
	}

	getBoards() {
		if (this.props.dashboard) {
			return this.props.dashboard.boards || [];
		} else {
			return [this.props.board];
		}
	}

	resetAxis() {
		const axis = {};
		for (let i = 0; i < this.getBoards().length; i++) {
			const boardId = this.getBoards()[i].id;
			axis[boardId] = "";
		}
		return axis;
	}

	render() {
		return (
			<React.Fragment>
				<HighchartsReact
					containerProps={{ style: { height: "100%", padding: this.state.data.chart.type == "pie" ? 0 : 20 } }}
					highcharts={Highcharts}
					options={this.state.data}
				/>
				<Sheet
					open={this.state.showSettings}
					title={this.props.t("widget.modals.settings.title", "Inställningar")}
					onClose={this.props.onCloseSettings}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							height: "calc(100% - 56px)",
						}}
					>
						<Scrollable style={{ height: "100%" }}>
							<Accordion label={this.props.t("widget.modals.settings.fields.type.label", "Typ av diagram")} expanded>
								<LegacyStack vertical>
									<Labelled label={this.props.t("widget.modals.settings.fields.type.label2", "Populärast")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.type.options.pie", "Cirkeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "pie"} onClick={this.changeType.bind(this, "pie")}>
													<img alt="" src="/assets/images/chart-types/v2-pie-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.type.options.bar", "Stapeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "bar"} onClick={this.changeType.bind(this, "bar")}>
													<img alt="" src="/assets/images/chart-types/v2-bar-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.type.options.line", "Linjediagram")}>
												<Button outline pressed={this.props.widget.options.type == "line"} onClick={this.changeType.bind(this, "line")}>
													<img alt="" src="/assets/images/chart-types/v2-line-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.type.options.stacked_bar", "Staplat stapeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "stacked-bar"} onClick={this.changeType.bind(this, "stacked-bar")}>
													<img alt="" src="/assets/images/chart-types/v2-stacked-bar-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>

									<Labelled label={this.props.t("widget.modals.settings.fields.pie.label", "Cirkeldiagram")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.pie.options.pie", "Cirkeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "pie"} onClick={this.changeType.bind(this, "pie")}>
													<img alt="" src="/assets/images/chart-types/v2-pie-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.pie.options.donut", "Munkdiagram")}>
												<Button outline pressed={this.props.widget.options.type == "donut"} onClick={this.changeType.bind(this, "donut")}>
													<img alt="" src="/assets/images/chart-types/v2-donut-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>

									<Labelled label={this.props.t("widget.modals.settings.fields.line.label", "Linjediagram")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.line.options.line", "Linjediagram")}>
												<Button outline pressed={this.props.widget.options.type == "line"} onClick={this.changeType.bind(this, "line")}>
													<img alt="" src="/assets/images/chart-types/v2-line-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.line.options.smooth_line", "Jämt linjediagram")}>
												<Button outline pressed={this.props.widget.options.type == "smooth-line"} onClick={this.changeType.bind(this, "smooth-line")}>
													<img alt="" src="/assets/images/chart-types/v2-smooth-line-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.line.options.stacked_line", "Staplat linjediagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "stacked-line"}
													onClick={this.changeType.bind(this, "stacked-line")}
												>
													<img alt="" src="/assets/images/chart-types/v2-stacked-line-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.line.options.100p_stacked_line", "100% Staplat linjediagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "100p-stacked-line"}
													onClick={this.changeType.bind(this, "100p-stacked-line")}
												>
													<img alt="" src="/assets/images/chart-types/v2-100p-stacked-line-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>

									<Labelled label={this.props.t("widget.modals.settings.fields.bar.label", "Stapeldiagram")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.bar.options.bar", "Stapeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "bar"} onClick={this.changeType.bind(this, "bar")}>
													<img alt="" src="/assets/images/chart-types/v2-bar-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.bar.options.stacked_bar", "Staplat stapeldiagram")}>
												<Button outline pressed={this.props.widget.options.type == "stacked-bar"} onClick={this.changeType.bind(this, "stacked-bar")}>
													<img alt="" src="/assets/images/chart-types/v2-stacked-bar-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.bar.options.100p-stacked-bar", "100% Staplat stapeldiagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "100p-stacked-bar"}
													onClick={this.changeType.bind(this, "100p-stacked-bar")}
												>
													<img alt="" src="/assets/images/chart-types/v2-100p-stacked-bar-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>

									<Labelled label={this.props.t("widget.modals.settings.fields.horizontal_bar.label", "Kolumndiagram")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.horizontal_bar.options.horizontal_bar", "Kolumndiagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "horizontal-bar"}
													onClick={this.changeType.bind(this, "horizontal-bar")}
												>
													<img alt="" src="/assets/images/chart-types/v2-horizontal-bar-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip
												content={this.props.t("widget.modals.settings.fields.horizontal_bar.options.horizontal_stacked_bar", "Staplat kolumndiagram")}
											>
												<Button
													outline
													pressed={this.props.widget.options.type == "horizontal-stacked-bar"}
													onClick={this.changeType.bind(this, "horizontal-stacked-bar")}
												>
													<img alt="" src="/assets/images/chart-types/v2-horizontal-stacked-bar-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip
												content={this.props.t(
													"widget.modals.settings.fields.horizontal_bar.options.100p_horizontal_stacked_bar",
													"100% Staplat kolumndiagram"
												)}
											>
												<Button
													outline
													pressed={this.props.widget.options.type == "100p-horizontal-stacked-bar"}
													onClick={this.changeType.bind(this, "100p-horizontal-stacked-bar")}
												>
													<img alt="" src="/assets/images/chart-types/v2-100p-horizontal-stacked-bar-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>

									<Labelled label={this.props.t("widget.modals.settings.fields.area.label", "Areadiagram")}>
										<LegacyStack>
											<Tooltip content={this.props.t("widget.modals.settings.fields.area.options.area", "Areadiagram")}>
												<Button outline pressed={this.props.widget.options.type == "area"} onClick={this.changeType.bind(this, "area")}>
													<img alt="" src="/assets/images/chart-types/v2-area-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.area.options.stacked_area", "Staplat areadiagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "stacked-area"}
													onClick={this.changeType.bind(this, "stacked-area")}
												>
													<img alt="" src="/assets/images/chart-types/v2-stacked-area-chart-color.svg" />
												</Button>
											</Tooltip>
											<Tooltip content={this.props.t("widget.modals.settings.fields.area.options.100p_stacked_area", "100% Staplat areadiagram")}>
												<Button
													outline
													pressed={this.props.widget.options.type == "100p-stacked-area"}
													onClick={this.changeType.bind(this, "100p-stacked-area")}
												>
													<img alt="" src="/assets/images/chart-types/v2-100p-stacked-area-chart-color.svg" />
												</Button>
											</Tooltip>
										</LegacyStack>
									</Labelled>
								</LegacyStack>
							</Accordion>
							<Accordion label={this.props.t("widget.modals.settings.fields.x_axis.label", "X-axel")}>
								<Select
									label={this.props.t("widget.modals.settings.fields.x_axis.label2", "Kolumntyp")}
									options={[
										{ value: "board", label: this.props.t("widget.modals.settings.fields.x_axis.options.board", "Board") },
										{ value: "datetime", label: this.props.t("widget.modals.settings.fields.x_axis.options.datetime", "Datum") },
										{ value: "dropdown", label: this.props.t("widget.modals.settings.fields.x_axis.options.dropdown", "Dropdown") },
										{ value: "group", label: this.props.t("widget.modals.settings.fields.x_axis.options.group", "Grupper") },
										{ value: "person", label: this.props.t("widget.modals.settings.fields.x_axis.options.person", "Person") },
										{ value: "title", label: this.props.t("widget.modals.settings.fields.x_axis.options.title", "Namn") },
										{ value: "number", label: this.props.t("widget.modals.settings.fields.x_axis.options.number", "Siffror") },
										{ value: "status", label: this.props.t("widget.modals.settings.fields.x_axis.options.status", "Status") },
										{ value: "tags", label: this.props.t("widget.modals.settings.fields.x_axis.options.tags", "Taggar") },
									]}
									value={this.props.widget.options.x_axis_type}
									onChange={(v) => {
										if (v == "datetime") {
											this.props.widget.options.x_axis_group_by = "day";
										}
										this.props.widget.options.x_axis_type = v;
										this.props.widget.options.x_axis = this.resetAxis();
										this.updateOptions();
									}}
								/>
								{this.props.widget.options.x_axis_type == "datetime" ? (
									<Select
										label={this.props.t("widget.modals.settings.fields.datetime.label", "Gruppera på")}
										options={[
											{ value: "day", label: this.props.t("widget.modals.settings.fields.datetime.opions.day", "Dag") },
											{ value: "week", label: this.props.t("widget.modals.settings.fields.datetime.opions.week", "Vecka") },
											{ value: "month", label: this.props.t("widget.modals.settings.fields.datetime.opions.month", "Månad") },
											{ value: "quarter", label: this.props.t("widget.modals.settings.fields.datetime.opions.quarter", "Kvartal") },
											{ value: "year", label: this.props.t("widget.modals.settings.fields.datetime.opions.year", "År") },
										]}
										value={this.props.widget.options.x_axis_group_by}
										onChange={(v) => {
											this.props.widget.options.x_axis_group_by = v;
											this.updateOptions();
										}}
									/>
								) : null}
								{this.getBoards().map((board) => (
									// Board, Group, Date, Dropdown, Person, Row title, Number, Status, Tags
									<div key={board.id} style={{ marginTop: 15 }}>
										<div style={{ marginBottom: 5 }}>
											<Text variant="headingSm" el="h3">{board.title}</Text>
										</div>
										<Select
											options={this.getColumnOptions(board, [this.props.widget.options.x_axis_type])}
											value={this.props.widget.options.x_axis[board.id]}
											onChange={(v) => {
												this.props.widget.options.x_axis[board.id] = v;
												this.updateOptions();
											}}
										/>
									</div>
								))}
							</Accordion>
							{[
								"stacked-line",
								"100p-stacked-line",
								"stacked-bar",
								"100p-stacked-bar",
								"horizontal-stacked-bar",
								"100p-horizontal-stacked-bar",
								"stacked-area",
								"100p-stacked-area",
							].indexOf(this.props.widget.options.type) >= 0 ? (
								<Accordion label={this.props.t("widget.modals.settings.fields.stack_on.label", "Stapla på")}>
									<Select
										label={this.props.t("widget.modals.settings.fields.stack_on.label2", "Kolumntyp")}
										options={[
											{ value: "board", label: this.props.t("widget.modals.settings.fields.stack_on.options.board", "Board") },
											{ value: "datetime", label: this.props.t("widget.modals.settings.fields.stack_on.options.datetime", "Datum") },
											{ value: "dropdown", label: this.props.t("widget.modals.settings.fields.stack_on.options.dropdown", "Dropdown") },
											{ value: "group", label: this.props.t("widget.modals.settings.fields.stack_on.options.group", "Grupper") },
											{ value: "person", label: this.props.t("widget.modals.settings.fields.stack_on.options.person", "Person") },
											{ value: "title", label: this.props.t("widget.modals.settings.fields.stack_on.options.title", "Namn") },
											{ value: "number", label: this.props.t("widget.modals.settings.fields.stack_on.options.number", "Siffror") },
											{ value: "status", label: this.props.t("widget.modals.settings.fields.stack_on.options.status", "Status") },
											{ value: "tags", label: this.props.t("widget.modals.settings.fields.stack_on.options.tags", "Taggar") },
										]}
										value={this.props.widget.options.stack_axis_type}
										onChange={(v) => {
											if (v == "datetime") {
												this.props.widget.options.stack_axis_group_by = "day";
											}
											this.props.widget.options.stack_axis_type = v;
											this.props.widget.options.stack_axis = this.resetAxis();
											this.updateOptions();
										}}
									/>
									{this.props.widget.options.stack_axis_type == "datetime" ? (
										<Select
											label={this.props.t("widget.modals.settings.fields.datetime.label", "Gruppera på")}
											options={[
												{ value: "day", label: this.props.t("widget.modals.settings.fields.datetime.opions.day", "Dag") },
												{ value: "week", label: this.props.t("widget.modals.settings.fields.datetime.opions.week", "Vecka") },
												{ value: "month", label: this.props.t("widget.modals.settings.fields.datetime.opions.month", "Månad") },
												{ value: "quarter", label: this.props.t("widget.modals.settings.fields.datetime.opions.quarter", "Kvartal") },
												{ value: "year", label: this.props.t("widget.modals.settings.fields.datetime.opions.year", "År") },
											]}
											value={this.props.widget.options.stack_axis_group_by}
											onChange={(v) => {
												this.props.widget.options.stack_axis_group_by = v;
												this.updateOptions();
											}}
										/>
									) : null}
									{this.getBoards().map((board) => (
										// Row count, Number, Time tracking
										<div key={board.id} style={{ marginTop: 15 }}>
											<div style={{ marginBottom: 5 }}>
												<Text variant="headingSm" el="h3">{board.title}</Text>
											</div>
											<Select
												options={this.getColumnOptions(board, [this.props.widget.options.stack_axis_type])}
												value={this.props.widget.options.stack_axis[board.id]}
												onChange={(v) => {
													this.props.widget.options.stack_axis[board.id] = v;
													this.updateOptions();
												}}
											/>
										</div>
									))}
								</Accordion>
							) : null}
							<Accordion label={this.props.t("widget.modals.settings.fields.y_axis.label", "Y-axel")}>
								<Select
									label={this.props.t("widget.modals.settings.fields.y_axis.label2", "Kolumntyp")}
									options={[
										{ value: "rows", label: this.props.t("widget.modals.settings.fields.y_axis.options.rows", "Antal rader") },
										{ value: "number", label: this.props.t("widget.modals.settings.fields.y_axis.options.number", "Siffror") },
										{ value: "timetrack", label: this.props.t("widget.modals.settings.fields.y_axis.options.timetrack", "Tidtagare") },
									]}
									value={this.props.widget.options.y_axis_type}
									onChange={(v) => {
										this.props.widget.options.y_axis_type = v;
										this.props.widget.options.y_axis = this.resetAxis();
										this.updateOptions();
									}}
								/>
								{this.getBoards().map((board) => (
									// Row count, Number, Time tracking
									<div key={board.id} style={{ marginTop: 15 }}>
										<div style={{ marginBottom: 5 }}>
											<Text variant="headingSm" el="h3">{board.title}</Text>
										</div>
										<Select
											options={this.getColumnOptions(board, [this.props.widget.options.y_axis_type])}
											value={this.props.widget.options.y_axis[board.id]}
											onChange={(v) => {
												this.props.widget.options.y_axis[board.id] = v;
												this.updateOptions();
											}}
										/>
									</div>
								))}
							</Accordion>
							{/* <Accordion label="Målvärden" /> */}
						</Scrollable>
					</div>
				</Sheet>
			</React.Fragment>
		);
	}
}
export default withTranslation(["widget", "common"], { withRef: true })(WidgetChart);
