import React, { Component } from "react";
import { Button, LegacyStack, Spinner, ActionList } from "@shopify/polaris";
import httpBuildQuery from "http-build-query";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import Modal from "src/js/components/modal";

class SyncCalendarModal extends Component {
	constructor(props) {
		super(props);

		this.state = { open: props.open, calendars: [], listCalendars: false };
	}

	componentDidMount() {
		const params = new URLSearchParams(window.location.search);
		if (params.get("addmscalendar") && params.get("addmscalendar") == this.props.column.id) {
			this.setState({ type: "ms" });
			this.props.onOpen();
			this.fetchOffice365Calendars();
		}
		if (params.get("addgscalendar") && params.get("addgscalendar") == this.props.column.id) {
			this.setState({ type: "gs" });
			this.props.onOpen();
			this.fetchGoogleCalendars();
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, completed: false });
			if (!props.open) {
				this.setState({ listCalendars: false });
			}
		}
	}

	fetchOffice365Calendars() {
		this.setState({ listCalendars: true, loadingCalendars: true });
		API.get("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/mscalendars.json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({
					loadingCalendars: false,
					calendars: result.data.calendars,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingCalendars: false });
			});
	}

	fetchGoogleCalendars() {
		this.setState({ listCalendars: true, loadingCalendars: true });
		API.get("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/gscalendars.json", {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({
					loadingCalendars: false,
					calendars: result.data.calendars,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loadingCalendars: false });
			});
	}

	attachCalendar(id) {
		if (this.state.type == "ms") {
			this.attachOffice365Calendar(id);
		} else {
			this.attachGoogleCalendar(id);
		}
	}

	attachOffice365Calendar(id) {
		this.setState({ savingCalendar: true });
		API.post("/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/microsoft/calendars/" + id + ".json", {}, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				if (this.props.noRedirect) {
					toastr.success(this.props.t("calendar.status.connected", "Kalender kopplad"));
					if (this.props.onClose) this.props.onClose();
					return;
				}
				window.location.href = "/admin/workspaces/none/boards/" + this.props.board.id;
				this.setState({ savingCalendar: false });
				/*
				this.props.onUpdateColumn(result.data.column);
				this.setState({ savingCalendar: false });
				this.props.onClose();
				*/
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ savingCalendar: false });
			});
	}

	attachGoogleCalendar(id) {
		this.setState({ savingCalendar: true });
		API.post(
			"/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/google/calendars.json",
			{ calendar_id: id },
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (this.props.noRedirect) {
					toastr.success(this.props.t("calendar.status.connected", "Kalender kopplad"));
					if (this.props.onClose) this.props.onClose();
					return;
				}
				this.setState({ completed: true });
				// top.location.href = "/admin/workspaces/none/boards/" + this.props.board.id;
				/*
				this.props.onUpdateColumn(result.data.column);
				this.setState({ savingCalendar: false });
				this.props.onClose();
				*/
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ savingCalendar: false });
			});
	}

	getCalendarOptions() {
		return this.state.calendars.map((calendar) => ({
			content: calendar.name,
			onAction: this.attachCalendar.bind(this, calendar.id),
		}));
	}

	setupOffice365() {
		window.location.href =
			"/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/authms?" + httpBuildQuery({}).replace(/\+/g, "%20");
	}

	setupGoogle() {
		window.location.href =
			"/api/boards/" + this.props.board.id + "/columns/" + this.props.column.id + "/authgoogle?" + httpBuildQuery({}).replace(/\+/g, "%20");
	}

	render() {
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.props.title || this.props.t("calendar.syncModal.columnSync", "Synka kolumn med kalender")}
				secondaryActions={[
					{
						content: this.props.t("close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
			>
				{this.state.listCalendars ? (
					this.state.loadingCalendars ? (
						<Modal.Section>
							<Spinner />
						</Modal.Section>
					) : this.state.savingCalendar ? (
						<Modal.Section>
							<Spinner />
							<p>{this.props.t("calendar.syncModal.status.loading", "Kopplar kalender och laddar in kalenderinlägg")}</p>
						</Modal.Section>
					) : this.state.completed ? (
						<Modal.Section>
							<p>{this.props.t("calendar.syncModal.status.success", "Din kalendar håller på att kopplas, en notis skickas när den är klar.")}</p>
						</Modal.Section>
					) : (
						<ActionList items={this.getCalendarOptions()} />
					)
				) : null}
				{!this.state.listCalendars ? (
					<Modal.Section>
						<LegacyStack>
							<Button onClick={this.setupOffice365.bind(this)}>
								<img src="/assets/images/office365-logo.png" style={{ height: 50 }} alt="Office 365" />
								<div>Office 365</div>
							</Button>
							<Button onClick={this.setupGoogle.bind(this)}>
								<img src="/assets/images/Google-Calendar-Logo.png" style={{ height: 50 }} alt="Google Calendar" />
								<div>Google Calendar</div>
							</Button>
						</LegacyStack>
					</Modal.Section>
				) : null}
			</Modal>
		);
	}
}

export default withTranslation(["calendar", "common"], { withRef: true })(SyncCalendarModal);
