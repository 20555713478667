/* eslint-disable react/no-this-in-sfc */
import React, { Component } from "react";
import { LegacyCard, Spinner, FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";

import { toastr } from "../../components/toastr.js";
import SearchField from "../../components/search_field.js";

class EmailCampaignCreate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				title: "",
				type: "newsletter",
				template: null,
				audiences: [],
			},
		};
	}

	componentDidMount() {
		this.setState({ loading: true });
		API.get("/api/email_templates.json", { params: { is_template: 1 } }).then((result) => {
			if (result.data.error) {
				toastr.error(result.data.error);
				return;
			}

			this.setState({ email_templates: result.data.email_templates, loading: false });
		});
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	saveForm() {
		this.props.history.replace("/admin/email_campaigns/new/template" + (this.state.form.template ? "/" + this.state.form.template.id : ""), {
			email_campaign: this.state.form,
		});
	}

	selectTemplate(template) {
		if (!template || !template.id) {
			template = null;
		}
		this.state.form.template = template;
		this.setState({ form: this.state.form });
	}

	renderTemplate({ template = {}, index } = {}) {
		const { id, title, template_thumbnail: templateThumbnail } = template;
		return (
			<button
				onClick={this.selectTemplate.bind(this, template)}
				style={{
					borderColor: this.state.form.template && id === this.state.form.template.id ? "#38aee6" : "transparent",
					boxShadow: "none",
					backgroundColor: "transparent",
					cursor: "pointer",
					borderRadius: "5px",
				}}
			>
				<p style={{ textAlign: "center", color: "#ffffff", marginBottom: "5px" }}>{title}</p>
				<img
					alt=""
					src={templateThumbnail}
					style={{ width: "150px", backgroundColor: title.includes("full bred") ? "#ffffff" : "rgb(231,231,231)" }}
				/>
			</button>
		);
	}

	render() {
		const templates =
			this.state.email_templates && this.state.email_templates.length
				? this.state.email_templates.reduce(
						(acc, template) => {
							if (!template) return acc;
							if ([35, 36, 37, 38, 39, 40, 41, 42, 43].includes(parseInt(template.id))) {
								acc.standard.push(template);
							} else {
								acc.featured.push(template);
							}
							return acc;
						},
						{ standard: [], featured: [] }
				  )
				: { standard: [], featured: [] };

		return (
			<Page
				title={this.props.t("email_campaign.actions.new", "Ny e-postkampanj")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{
					content: this.props.t("common.actions.create", "Skapa"),
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
					disabled: !this.state.form || !this.state.form.title || !this.state.form.template,
				}}
			>
				<LegacyCard title={this.props.t("email_campaign.title", "Kampanj")} sectioned>
					<FormLayout>
						<TextField
							label={this.props.t("email_campaign.fields.title.label", "Namn på kampanj")}
							value={this.state.form.title}
							onChange={this.updateForm.bind(this, "title")}
						/>
						<Select
							label={this.props.t("email_campaign.fields.type.label", "Typ av kampanj")}
							options={[
								{ label: this.props.t("email_campaign.type.options.newsletter.label", "Nyhetsbrev"), value: "newsletter" },
								{ label: this.props.t("email_campaign.type.options.transactional.label", "Transaktionsmail"), value: "transactional" },
							]}
							value={this.state.form.type}
							onChange={this.updateForm.bind(this, "type")}
						/>
					</FormLayout>
				</LegacyCard>
				<LegacyCard title={this.props.t("email_campaign.audiences.title", "Målgrupper")}>
					<LegacyCard.Section>
						<SearchField
							placeholder={this.props.t("email_campaign.audiences.search.placeholder", "Sök efter målgrupper att lägga till")}
							resourceName={{
								singular: this.props.t("email_campaign.audiences.singular", "målgrupp"),
								plural: this.props.t("email_campaign.audiences.plural", "målgrupper"),
							}}
							resource="audiences.json"
							label_handle="title"
							resource_handle="audiences"
							renderLabel={(o) => o.title + " (" + o.count + ")"}
							onSelect={(aud) => {
								this.state.form.audiences.push(aud);
								this.setState({ form: this.state.form });
							}}
						/>
					</LegacyCard.Section>
					{this.state.form.audiences.map((aud, key) => (
						<LegacyCard.Section key={key}>
							{aud.title} ({aud.count})
						</LegacyCard.Section>
					))}
				</LegacyCard>

				<LegacyCard title={this.props.t("email_campaign.template.title", "Välj mall")} sectioned>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "50px",
						}}
					>
						<div>
							<h2 style={{ fontWeight: "bold", marginBottom: "0.6250rem" }}>Utvalda{this.state.loading && <Spinner size="small" />}</h2>
							{templates.featured.map((template, index) =>
								this.renderTemplate({
									template,
									index,
								})
							)}
						</div>
						<div>
							<h2 style={{ fontWeight: "bold", marginBottom: "0.6250rem" }}>Standard{this.state.loading && <Spinner size="small" />}</h2>
							{templates.standard.map((template, index) =>
								this.renderTemplate({
									template,
									index,
								})
							)}
						</div>
					</div>
				</LegacyCard>
			</Page>
		);
	}
}
export default withTranslation(["email_campaign", "common"], { withRef: true })(EmailCampaignCreate);
