import React, { useState } from "react";
import Tabs from "src/js/components/Tabs";
import { checkUserRoles } from "src/js/Utilities";
import styled from "styled-components";

const DocumentListsTabsWrapper = ({ tabs: propsTabs }) => {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	const handledTabChange = (selectedTabIndex) => {
		setSelectedTabIndex(selectedTabIndex);
	};

	const tabs = propsTabs?.filter((i) => (!("enabled" in i) || i.enabled) && checkUserRoles(i.roles));

	if (tabs?.length <= 1) return tabs?.[0]?.content;

	const tab = tabs?.[selectedTabIndex];
	const tabsFormatted = tabs?.map((t) => ({ ...t, content: t.title }));

	return (
		<Wrapper>
			<Tabs tabs={tabsFormatted} selected={selectedTabIndex} onSelect={handledTabChange} />
			<ContentWrapper key={tab.id}>{tab?.content}</ContentWrapper>
		</Wrapper>
	);
};
export default DocumentListsTabsWrapper;

const Wrapper = styled.div``;
const ContentWrapper = styled.div``;
