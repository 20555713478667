import React, { Component } from "react";
import { LegacyCard, Spinner, Text, ResourceList, ExceptionList, Icon, Select, Scrollable } from "@shopify/polaris";
import moment from "moment";
import { ChecklistMajor, CalendarMajor, UploadMajor, SearchMajor } from "@shopify/polaris-icons";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import axios from "axios";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import ExpandableSection from "../views/Contacts/ExpandableSection.js";
import BoardExpandableSection from "./BoardExpandableSection.js";
import Upload from "./Upload.js";
import { getIconByString } from "../views/Workspaces/components/IconSelector.js";
import RowModal from "../views/Workspaces/components/RowModal";
import ColumnActionModal from "../views/Workspaces/components/ColumnActionModal";
import Modal from "./modal";
import NumberBadge from "./NumberBadge";
import Colors from "../Colors";
import ContractModal from "../views/Workspaces/ContractModal";
import RowColumnForm from "../views/Workspaces/components/RowColumnForm";
import ModalBoxes from "./ModalBoxes";
import { BoardIcon, ContractIcon, Document2Icon } from "../icons";
import Sheet from "./sheet";
import CalendarModalContent from "../views/Workspaces/components/CalendarModal/CalendarModalContent.js";
import BoardHelper from "../views/Workspaces/BoardHelper.js";
import TaskSheet from "../views/Agenda/TaskSheet";
import TextField from "./TextField";

class RelatedResources extends Component {
	constructor(props) {
		super(props);
		this.boardsel = null;
		// nextweek.setDate(nextweek.getDate() + 7);

		this.state = {
			id: props.id,
			loading: true,
			boards: [],
			uploads: [],
			uploadFilters: {},
			documentGroups: [],
		};

		const activeDealStages = [];
		for (let i = 0; i < store.getState().stages.length; i++) {
			if (store.getState().stages[i].type == "incoming" || store.getState().stages[i].type == "in_progress") {
				activeDealStages.push(store.getState().stages[i].id);
			}
		}

		this.state.activeDealStages = activeDealStages;

		this.refreshContactResources = this.refreshContactResources.bind(this);
	}

	refreshContactResources() {
		this.fetchResources(this.state.id, true);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.id != this.state.id) {
			this.setState({ id: props.id, loading: true });
			this.fetchResources(props.id);
			this.fetchContactDocuments(props.id);
			props.setSecondaryActions?.(this.getShortcutActions(props));
		} else if (
			props?.shortcuts?.length > this.props?.shortcuts?.length ||
			props.contact?.fortnox_customer_id != this.props.contact?.fortnox_customer_id
		) {
			props.setSecondaryActions?.(this.getShortcutActions(props));
		}
	}

	componentDidMount() {
		this.fetchResources(this.state.id);
		this.fetchContactDocuments(this.state.id);
		this.props.setSecondaryActions?.(this.getShortcutActions(this.props));

		window.addEventListener("refreshContactResources", this.refreshContactResources);
	}

	componentWillUnmount() {
		window.removeEventListener("refreshContactResources", this.refreshContactResources);
	}

	fetchResources(id, silent) {
		this.setState((c) => ({ id, loading: silent ? c.loading : true }));
		API.get("/api/contacts/" + id + "/resources.json")
			.then((result) => {
				this.setState({
					loading: false,
					boards: result.data.boards,
					// deal_count: result.data.deal_count,
					// deals: [],
					// project_count: result.data.project_count,
					// projects: [],
					// ticket_count: result.data.ticket_count,
					// tickets: [],
					upload_count: result.data.upload_count,
					uploads: [],
					// task_count: result.data.task_count,
				});
				if (this.props.setCounts) {
					const counts = result.data.boards.reduce((acc, board) => Object.assign(acc, { [board.id]: board.count }), {});
					this.props.setCounts(counts);
				}
			})

			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	fetchUploads(id) {
		this.setState({ id, loadingUploads: true });
		API.get("/api/contacts/" + id + "/uploads.json")
			.then((result) => {
				this.setState({ loadingUploads: false, uploads: result.data.uploads });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	uploadFile(upload) {
		API.post("/api/contacts/" + this.state.id + "/comments.json", { files: [upload] })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (this.props.onUpload) this.props.onUpload(result.data.comment);
				this.fetchUploads(this.state.id);
				this.fetchResources(this.state.id, true);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	renderUpload(item) {
		const exceptions = [];

		const exceptionList = exceptions.length ? (
			<div className="CustomerListItem__Exceptions">
				<ExceptionList items={exceptions} />
			</div>
		) : null;

		return (
			<ResourceList.Item id={item.id} url={item.url}>
				<div className="CustomerListItem__Main">
					<div className="CustomerListItem__Profile">
						<h3 className="CustomerListItem__Title">{item.filename}</h3>
						<span className="CustomerListItem__Location">
							<Text variant="bodySm">{(item.size / 1048576).toFixed(2)} MB</Text>
						</span>
					</div>
					<div className="CustomerListItem__Orders">
						<span className="CustomerListItem__OrderCount" />
						<span className="CustomerListItem__TotalSpent" />
					</div>
				</div>
				{moment(item.created_at).format("YYYY-MM-DD")}
				{exceptionList}
			</ResourceList.Item>
		);
	}

	clickShortcut(shortcut) {
		if (
			shortcut.board_column &&
			shortcut.board_column.type === "upload" &&
			shortcut.board_column.options &&
			shortcut.board_column.options.integration &&
			shortcut.board_column.options.integration.type === "scrive"
		) {
			this.toggleContractModal(shortcut.board_column, shortcut.board);
		} else if (shortcut.board_column) {
			this.openColumnModal(shortcut.board_column, shortcut.board);
		} else if (shortcut.board) {
			this.openRowModal(shortcut.board);
		}
	}

	openRowModal(board) {
		this.setState({ board, showRowModal: true });
		this.boardsel?.close?.();
	}

	openColumnModal(column, board) {
		this.setState({ column, colboard: board, showColumnModal: true });
	}

	toggleContractModal(column, board) {
		this.setState((c) => ({ showContractModal: !c.showContractModal, column, colboard: board }));
	}

	closeContractModal() {
		this.setState({ showContractModal: false });
	}

	getShortcutActions(props = this.props) {
		if (props?.contact?.removed) return [];
		const secondaryActions = [];
		if (store.getState().user.todo_board) {
			secondaryActions.push({
				icon: ChecklistMajor,
				onAction: () => {
					this.setState({ showReminderModal: true });
				},
				content: props.t("common.related_resources.actions.new_task", "Ny uppgift"),
			});
		}
		if (store.getState().user.calendar_board) {
			secondaryActions.push({
				icon: CalendarMajor,
				onAction: () => {
					this.setState({ showCalendarModal: true });
				},
				content: props.t("common.related_resources.actions.new_calendar_event", "Nytt kalenderinlägg"),
			});
		}
		/*
		secondaryActions.push({
			icon: GrammarMajor,
			onAction: () => {
				this.boardsel.open();
			},
			content: (
				<BoardSelectorActionButton
					onSelect={this.openRowModal.bind(this)}
					ref={(b) => {
						this.boardsel = b;
					}}
				/>
			),
		});
		*/
		if (store.getState().shortcuts) {
			for (let i = 0; i < store.getState().shortcuts.length; i++) {
				const shortcut = store.getState().shortcuts[i];

				secondaryActions.push({
					icon: getIconByString(shortcut.board.icon),
					content: shortcut.title,
					onAction: this.clickShortcut.bind(this, shortcut),
				});
				/*
				for (let x = 0; x < shortcut.board.columns.length; x++) {
					if (shortcut.board.columns[x].shortcut_title) {
						secondaryActions.push({
							icon: ,
							content: shortcut.board.columns[x].shortcut_title,
							onAction: this.clickShortcut.bind(this, Object.assign({ board_column: shortcut.board.columns[x] }, shortcut)),
						});
					}
				}
				*/
			}
		}

		if (store.getState().account.upload_board_id || store.getState().account.sales_board_id) {
			secondaryActions.push({
				icon: UploadMajor,
				content: props.t("common.related_resources.actions.new_document", "Nytt dokument"),
				onAction: () => this.setState({ openDocumentModal: true }),
			});
		}

		if (props.contact?.orgnr) {
			const orgnr = props.contact?.orgnr.replace(/[^0-9]/g, "");
			secondaryActions.push({
				icon: <img src="/assets/logos/allabolag-logo.jpg" alt="Allabolag" />,
				content: props.t("common.related_resources.actions.allabolag", "Allabolag"),
				onAction: () => window.open(`https://www.allabolag.se/${orgnr}`),
			});
		}

		return secondaryActions;
	}

	// toggleActiveStage(stage, event) {
	// 	event.stopPropagation();
	// 	if (this.state.activeDealStages.indexOf(stage.id) >= 0) {
	// 		this.state.activeDealStages.splice(this.state.activeDealStages.indexOf(stage.id), 1);
	// 	} else {
	// 		this.state.activeDealStages.push(stage.id);
	// 	}
	// 	this.setState({ activeDealStages: this.state.activeDealStages });
	// }

	// getSalesBoard() {
	// 	let shortcut = store.getState().shortcuts.find((shortcut) => shortcut.board && shortcut.board.id == store.getState().account.sales_board_id);
	// 	if (shortcut) {
	// 		return shortcut.board;
	// 	}
	// 	return null;
	// }

	// getSalesUploadColumn(title) {
	// 	let board = this.getSalesBoard();
	// 	if (board) {
	// 		let col = board.columns.find((col) => col.type == "upload" && col.title == title);
	// 		if (col) {
	// 			return col;
	// 		}
	// 	}
	// 	return null;
	// }

	fetchContactDocuments(id = this.state.id) {
		if (!id) return null;
		API.get(`/api/documents/${id}/count.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				this.setState({
					documentGroups: result.data.document_groups.map((gr) => {
						const board = result.data.boards.find((board) => board.id === gr.board_id);
						const column = board.columns.find((column) => column.id === gr.column_id);

						return Object.assign(gr, { board, column });
					}),
				});
			})
			.catch((error) => {
				if (!axios.isCancel(error) && error?.code !== "ECONNABORTED") {
					console.error("error:", error);
				}
				toastr.error(error);
			});
	}

	getDocumentGroupDetails(group) {
		switch (group.column_title?.toLowerCase()) {
			case "dokument":
				return {
					icon: <BoardIcon />,
					text: "Här hittar du alla övriga dokument, du kan även ladda upp filer, bilder och övrigt matrial.",
				};
			case "offerter":
				return {
					icon: <Document2Icon />,
					text: "Välj eller skap offert  offerter kopplade till det här företaget. ",
				};
			case "avtal":
				return {
					icon: <ContractIcon />,
					text: "Skapa nytt avtal eller se befintliga.",
				};
			default:
				return {};
		}
	}

	updateCell(row, column, data) {
		BoardHelper.onUpdateVpalue(row, column, data);
	}

	render() {
		if (this.state.loading) {
			return (
				<LegacyCard sectioned>
					<Spinner />
				</LegacyCard>
			);
		}

		const row = this.state.row || {
			title: "",
			column_values: {},
		};

		const modal = (
			<React.Fragment>
				<Modal
					large
					open={this.state.openDocumentModal}
					onClose={() => this.setState({ openDocumentModal: false })}
					title={this.props.t("common.related_resources.documents.title", "Nytt dokument")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ openDocumentModal: false });
							},
						},
					]}
				>
					<Modal.Section>
						<ModalBoxes
							items={[
								...(this.state.documentGroups?.filter(
									(group) => group.board_id === store.getState().account.sales_board_id || group.board_id === store.getState().account.upload_board_id
								) || []),
							].flatMap((group) => {
								const { icon, text } = this.getDocumentGroupDetails(group);

								return {
									icon,
									title: group.column_title,
									text,
									onAction: () => {
										this.setState({
											column: group.column,
											colboard: group.board,
											showContractModal: group.board_id === store.getState().account.sales_board_id,
											showRestDocumentModal: group.board_id === store.getState().account.upload_board_id,
											openDocumentModal: false,
										});
									},
								};
							})}
						/>
					</Modal.Section>
				</Modal>
				<Modal
					open={this.state.showRestDocumentModal}
					onClose={() => this.setState({ showRestDocumentModal: false, recentlyUploadRestdocumentRow: null })}
					title={this.props.t("common.related_resources.titles.rest_documents.title", "Övriga dokument")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => this.setState({ showRestDocumentModal: false, recentlyUploadRestdocumentRow: null }),
						},
					]}
				>
					<Modal.Section>
						<RowColumnForm
							onCreate={(recentlyUploadRestdocumentRow) => {
								this.setState({ recentlyUploadRestdocumentRow });

								// calling event instead of function, because contact tabs count is also triggerd on this event
								const event = new CustomEvent("refreshContactResources");
								window.dispatchEvent(event);
							}}
							contact={this.props.contact}
							column={this.state.column}
							board={this.state.colboard}
							row={this.state.recentlyUploadRestdocumentRow}
							history={this.props.history}
						/>
					</Modal.Section>
				</Modal>
				{this.state.board ? (
					<RowModal
						open={this.state.showRowModal}
						board={this.state.board}
						contact={this.props.contact}
						onUpdateRow={() => {}}
						onUpdateValue={() => {}}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ showRowModal: false });
						}}
						onCreate={() => {
							this.fetchResources(this.state.id, true);
						}}
					/>
				) : null}
				{this.state.column ? (
					<ColumnActionModal
						open={this.state.showColumnModal}
						column={this.state.column}
						board={this.state.colboard}
						contact={this.props.contact}
						onUpdateRow={() => {}}
						onUpdateValue={() => {}}
						defaultValues={[]}
						getContact={() => {}}
						onClose={() => {
							this.setState({ showColumnModal: false });
						}}
					/>
				) : null}

				<TaskSheet
					open={this.state.showReminderModal}
					onClose={() => {
						this.setState({ showReminderModal: false });
					}}
					options={{
						contact: [this.props.id],
					}}
					onComplete={() => {
						this.setState({ showReminderModal: false });
						const refreshEvent = new CustomEvent("refreshTasks", {});
						window.dispatchEvent(refreshEvent);
					}}
				/>

				{store.getState().user.calendar_board ? (
					<Sheet
						open={this.state.showCalendarModal}
						onClose={(row) => {
							this.setState({ showCalendarModal: false });
						}}
						title={this.props.t("calendar.actions.new", "Nytt kalenderinlägg")}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								height: "calc(100% - 56px)",
							}}
						>
							<Scrollable style={{ flex: 1 }}>
								<CalendarModalContent
									row={row}
									contact={this.props.contact}
									board={store.getState().user.calendar_board}
									updateCell={this.updateCell.bind(this, row)}
									// changeRowTitle={this.changeRowTitle.bind(this, row)}
									onClose={(row) => {
										this.setState({ showCalendarModal: false });
									}}
									onComplete={(row) => {
										this.setState({ showCalendarModal: false });
										const refreshEvent = new CustomEvent("refreshTasks", {});
										window.dispatchEvent(refreshEvent);
									}}
								/>
							</Scrollable>
						</div>
					</Sheet>
				) : null}
				<ContractModal
					open={this.state.showContractModal}
					onClose={this.closeContractModal.bind(this)}
					onOpen={() => {
						this.setState({ showContractModal: true });
					}}
					column={this.state.column}
					board={this.state.colboard}
					contact={this.props.contact}
					onCreate={(row) => {
						// calling event instead of function, because contact tabs count is also triggerd on this event
						const event = new CustomEvent("refreshContactResources");
						window.dispatchEvent(event);
					}}
				/>

				<Upload
					open={this.state.openUpload}
					onClose={() => {
						this.setState({ openUpload: false });
					}}
					files={[]}
					width={0}
					height={0}
					onUpload={this.uploadFile.bind(this)}
				/>
			</React.Fragment>
		);

		if (!this.state.boards.length) {
			return modal;
		}

		const uploads = (() => {
			if (!this.state.uploads) return [];
			if (!Object.values(this.state.uploadFilters).filter((i) => i).length) return this.state.uploads;

			return this.state.uploads.filter(({ filename = "", url = "", type, from }) => {
				if (this.state.uploadFilters.search) {
					return filename.toLowerCase().includes(this.state.uploadFilters.search && this.state.uploadFilters.search.toLowerCase());
				}

				const typeMatch = this.state.uploadFilters.type
					? (type && (type && type.split("/").pop()) === this.state.uploadFilters.type) || filename.split(".").pop() === this.state.uploadFilters.type
					: true;

				const fromMatch = this.state.uploadFilters.from ? from && from === this.state.uploadFilters.from : true;
				return typeMatch && fromMatch;
			});
		})();

		return (
			<LegacyCard>
				{this.state.boards
					.filter((board) => {
						if ([store.getState().account.support_board_id, store.getState().account.sales_board_id].some((id) => board.id == id)) return false;

						return board.id != store.getState().account.upload_board_id && (!this.props.board_id || board.id == this.props.board_id);
					})
					.map((board, key) => (
						<div key={key} className="board-container-small">
							<BoardExpandableSection
								key={key}
								board={board}
								onlyRowsWithContactId={board.type == "single" ? this.state.id : null}
								history={this.props.history}
								fetchResources={this.fetchResources.bind(this, this.state.id)}
							/>
						</div>
					))}
				{modal}
				{this.state.upload_count && store.getState().account.id == 15 ? (
					<ExpandableSection
						type="uploads"
						onCreate={() => this.setState({ openUpload: true })}
						resourceName={{
							singular: this.props.t("common.related_resources.documents.singular", "dokument"),
							plural: this.props.t("common.related_resources.documents.plural", "dokument"),
						}}
						onExpand={() => {
							if (!this.state.uploads.length) {
								this.fetchUploads(this.state.id);
							}
						}}
						loading={this.state.loadingUploads}
						count={<NumberBadge color={Colors.yellow}>{this.state.upload_count}</NumberBadge>}
					>
						<div className="uploads-wrapper__header">
							<TextField
								prefix={<Icon source={SearchMajor} />}
								onChange={(v) => {
									this.state.uploadFilters.search = v;
									this.setState({ uploadFilters: this.state.uploadFilters });
								}}
								value={this.state.uploadFilters.search}
							/>
							<div className="selects-wrapper">
								<div className="select">
									<span>{this.props.t("common.related_resources.terms.source", "Källa")}:</span>
									<Select
										options={[{ value: "", label: this.props.t("common.related_resources.terms.all", "Alla") }].concat(
											Array.from(new Set(this.state.uploads && this.state.uploads.map(({ from } = {}) => from)))
												.filter((i) => i)
												.map((v) => ({
													value: v,
													label: v,
												}))
										)}
										onChange={(v) => {
											this.state.uploadFilters.from = v;
											this.setState({ uploadFilters: this.state.uploadFilters });
										}}
										value={this.state.uploadFilters.from}
									/>
								</div>
								<div className="select">
									<span>{this.props.t("common.related_resources.terms.type", "Typ")}:</span>
									<Select
										options={[{ value: "", label: this.props.t("common.related_resources.terms.all", "Alla") }].concat(
											Array.from(
												new Set(
													this.state.uploads &&
														this.state.uploads.map(({ url = "", type, filename = "" } = {}) =>
															type ? type.split("/").pop() : filename.split(".").pop()
														)
												)
											).map((v) => ({
												value: v,
												label: v,
											}))
										)}
										onChange={(v) => {
											this.state.uploadFilters.type = v;
											this.setState({ uploadFilters: this.state.uploadFilters });
										}}
										value={this.state.uploadFilters.type}
									/>
								</div>
							</div>
						</div>
						{this.state.uploadFilters.type && (!uploads || !uploads.length) && (
							<div className="no-found">
								{this.props.t("common.related_resources.emtpty.text", "Hittade inga")}
								<b>{this.state.uploadFilters.type}</b>.
							</div>
						)}
						<ResourceList
							resourceName={{
								singular: this.props.t("common.related_resources.documents.singular", "dokument"),
								plural: this.props.t("common.related_resources.documents.plural", "dokument"),
							}}
							items={uploads}
							renderItem={this.renderUpload.bind(this)}
						/>
					</ExpandableSection>
				) : null}
			</LegacyCard>
		);
	}
}

export default connect(
	(state) => ({
		shortcuts: state.shortcuts,
		user: state.user,
	}),
	{}
)(withTranslation(["contact", "common"], { withRef: true })(RelatedResources));
