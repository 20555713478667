import { CustomersMajor } from "@shopify/polaris-icons";
import React from "react";
import Colors, { colorToRgba } from "src/js/Colors";
import { currencyFormatter } from "src/js/Utilities";
import BarGraph from "src/js/components/Graphs/BarGraph";
import LineGraph from "src/js/components/Graphs/LineGraph";
import IconWithBackround from "src/js/components/IconWithBackround";
import OptionList from "src/js/components/OptionList/OptionList";
import StatsBoxWrapper from "src/js/components/statsBoxes";
import useContact from "src/js/hooks/useContact";
import { DollarIcon } from "src/js/icons";
import styled from "styled-components";

type AxiconGraphsProps = {
	contact?: ContactType | Partial<ContactType>;
};

const AxiconGraphs = ({ contact: propsContact }: AxiconGraphsProps) => {
	const { axiconData: data, axiconError, axiconIsLoading: isRefetching } = useContact({ contact: propsContact });

	const profitMargin = data?.graphs?.series?.find((series) => series.handle === "profit_margin")?.data.slice(-1)[0];
	const boxes = [
		{
			title: "Anställda",
			text: data?.graphs?.series?.find((series) => series.handle === "employees")?.data.slice(-1)[0],
			helpText: "",
			icon: CustomersMajor,
			color: Colors.blue,
		},
		{
			title: "Vinstmarginal (%)",
			text: `${profitMargin}%`,
			helpText: "",
			icon: <DollarIcon />,
			color: profitMargin >= 0 ? Colors.green : Colors.red,
		},
	];

	return (
		<Wrapper>
			<AxiconBarGraph error={axiconError} loading={isRefetching} graphs={data?.graphs} />
			<StatsBoxWrapper
				style={{ justifyContent: "flex-start", margin: 0 }}
				perRow={2}
				items={boxes?.map((stat: any) => ({
					title: stat.title,
					text: stat.text,
					helpText: stat.helpText,
					loading: isRefetching,
					icon: stat.icon && (
						<IconWithBackround
							// icon={stat.icon}
							icon={React.isValidElement(stat.icon) ? React.cloneElement(stat.icon, { color: stat.color }) : stat.icon}
							backgroundColor={colorToRgba(stat.color, 0.2)}
						/>
					),
				}))}
			/>
			<AxiconLineGraph error={axiconError} loading={isRefetching} graphs={data?.graphs} />
		</Wrapper>
	);
};
export default AxiconGraphs;

const Wrapper = styled.div`
	margin-top: 1.875rem;

	display: grid;
	gap: 1.875rem;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`;

const AxiconLineGraph = ({ loading, graphs, error }) => {
	const graphDataSeriesOptions = [
		{ label: "Anställda", value: "employees" },
		{ label: "Vinst", value: "profit" },
		{ label: "Vinstmarginal", value: "profit_margin" },
		{ label: "Omsättning", value: "turnover" },
	];
	const [selectedGraphData, setSelectedGraphData] = React.useState(["employees"]);

	const graphData = graphs?.series?.filter((series) => selectedGraphData.includes(series.handle)) || [];

	return (
		<LineGraph
			error={error}
			loading={loading}
			data={{
				series: graphData,
				labels: graphs?.labels,
			}}
			showTotal={false}
			legendFormatter={() => ""}
			// title="Anställda, vinst, vinstmarginal & omsättning"
			headerRight={
				<OptionList
					allowMultiple
					popover
					selected={selectedGraphData}
					onChange={(selected) => {
						setSelectedGraphData(selected);
					}}
					options={graphDataSeriesOptions}
				/>
			}
		/>
	);
};

const AxiconBarGraph = ({ loading, graphs, error }) => {
	const selectedGraphs = graphs?.series?.filter((series) => ["turnover", "profit"].includes(series.handle)) || [];

	return (
		<>
			<BarGraph
				legendFormatter={() => ""}
				showTotal={false}
				error={error}
				loading={loading}
				data={{
					series: selectedGraphs,
					labels: graphs?.labels,
				}}
				// title="Omsättning & vinst/förlust"
				// settings={{ dataLabels: true }}
				formatter={(value) => {
					return currencyFormatter({
						value,
						currency: "SEK",
						maximumFractionDigits: 0,
					});
				}}
			/>
		</>
	);
};
