import React from "react";
import { LoadingBox, FlexDiv } from "./styles";
import styled from "styled-components";
import SkeletonPage from "./Page";

const SkeletonContact = ({ contact = { is_company: true } }, id) => {
	const tabsBarSkeleton = (
		<LoadingBox width={"100%"} style={{ padding: 10, opacity: 0.75, paddingBottom: 0 }}>
			<FlexDiv gap={20} margin={2} style={{ marginBottom: 0 }}>
				<LoadingBox width={150} height={100} loading={false} style={{ background: "var(--primary)", marginRight: 20 }} />

				{[...Array(7)]?.map((_, index) => (
					<LoadingBox key={index} width={150} height={100} loading={false} style={{ background: "var(--tertiary)", opacity: 0.5 }} />
				))}
			</FlexDiv>
		</LoadingBox>
	);

	const companySkeleton = (
		<Adiv>
			{tabsBarSkeleton}
			<div className="contact_dashboard" style={{ paddingTop: "5rem", height: "75%" }}>
				<div className="contact-statsbox-wrapper">
					<LoadingBox style={{ background: "var(--tertiary)" }} />
					<LoadingBox style={{ background: "var(--tertiary)" }} />
					<LoadingBox style={{ background: "var(--tertiary)" }} />
					<LoadingBox style={{ background: "var(--tertiary)" }} />
				</div>

				<LoadingBox style={{ background: "var(--tertiary)" }} />
				<LoadingBox style={{ background: "var(--tertiary)" }} />
				<LoadingBox style={{ background: "var(--tertiary)" }} />
				<LoadingBox style={{ background: "var(--tertiary)" }} />
				<LoadingBox height="150%" style={{ background: "var(--tertiary)" }} />
				<LoadingBox height="150%" style={{ background: "var(--tertiary)", gridArea: "deals" }} />
			</div>
		</Adiv>
	);

	const personSkeleton = (
		<Layout>
			<LayoutSection>
				<Adiv>
					<LoadingBox height={235} style={{ marginBottom: "1rem" }} />
					{tabsBarSkeleton}
					<LoadingBox height={100} style={{ marginBlock: "2rem" }} />
				</Adiv>
			</LayoutSection>
			<LayoutSection className="Polaris-Layout__Section--secondary">
				<LoadingBox height={"15%"} width={"100%"} />
				<LoadingBox height={"10%"} width={"100%"} />
			</LayoutSection>
		</Layout>
	);

	return (
		<SkeletonPage key={id}>
			<Container>{contact?.is_company ? companySkeleton : personSkeleton}</Container>
		</SkeletonPage>
	);
};
export default SkeletonContact;

const Container = styled.div`
	height: 95%;
	position: relative;
`;
const Adiv = styled.div`
	height: 75%;
`;

const Layout = styled.div.attrs({ className: "Polaris-Layout" })`
	height: 100%;
`;

const LayoutSection = styled.div.attrs<{ secondary?: boolean }>(({ secondary, className }) => ({
	className: `
  Polaris-Layout__Section
  ${secondary ? "Polaris-Layout__Section--secondary" : ""}
  ${className || ""}

  `,
}))`
	display: flex;
	gap: 1rem;
	flex-direction: column;

	height: 100%;
`;
