import React, { Component } from "react";
import { LegacyStack, Icon, Select, Button, DataTable } from "@shopify/polaris";
import { EditMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../API";
import { store } from "../../store";

import BoardHelper from "./BoardHelper.js";
import RowSheet from "./components/RowSheet";
import SimpleCellText from "./SimpleCellText";
import SimpleCellOptions from "./SimpleCellOptions";
import SimpleCellPerson from "./SimpleCellPerson";
import SimpleSortButton from "./SimpleSortButton";

import { toastr } from "../../components/toastr.js";
import CellContact from "./CellContact";
import CellTimeline from "./CellTimeline";
import CellDateTime from "./CellDateTime";
import CellUpload from "./CellUpload";

class BoardViewSimpleList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showRowSheet: false,
			editingRow: null,
			rowContainerClass: "simple-row-list",
			columns: [],
			rows: [],
		};

		const handle = "view" + this.props.view.id;
		for (let i = 0; i < this.getAvailableColumns().length; i++) {
			if (i < this.getMaxColumns()) {
				let index = i;
				if (index >= 0) {
					if (
						handle in store.getState().column_selection &&
						i in store.getState().column_selection[handle] &&
						store.getState().column_selection[handle][i]
					) {
						index = store.getState().column_selection[handle][i];
					}
				}

				const col = this.getAvailableColumns()[index];
				this.state.columns.push(col);
			}
		}
	}

	getAvailableColumns() {
		const arr = [
			{
				title: this.props.t("board.available_columns.title", "Rubrik"),
				type: "title",
				id: "title",
				index: 0,
			},
			{
				title: this.props.t("board.available_columns.group", "Grupp"),
				type: "group",
				id: "group",
				index: 1,
			},
		];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			arr.push(Object.assign({ index: i + 2 }, this.props.board.columns[i]));
		}
		return arr;
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.updateRows(props);
	}

	changeColumn(index, i) {
		const handle = "view" + this.props.view.id;
		const col = this.getAvailableColumns()[i];
		this.state.columns[index] = col;
		this.setState({ columns: this.state.columns });
		store.dispatch({
			type: "UPDATE_COLUMN_SELECTION",
			handle,
			index,
			selection: i,
		});
	}

	sortRows(a, b) {
		if (this.props.sorting.length > 0) {
			let sortValue = 0;
			for (let i = 0; i < this.props.sorting.length; i++) {
				sortValue = BoardHelper.getSortValue(a, b, this.props.sorting[i]);
				if (sortValue !== 0) {
					return sortValue;
				}
			}
			return sortValue;
		}
		const apos = BoardHelper.getGroup(a.group_id) ? BoardHelper.getGroup(a.group_id).position : 999999;
		const bpos = BoardHelper.getGroup(b.group_id) ? BoardHelper.getGroup(b.group_id).position : 999999;
		if (apos == bpos) {
			return a.position - b.position;
		}
		return apos - bpos;
	}

	updateRows(props) {
		this.setState({
			rows: props.rows.sort(this.sortRows.bind(this)).slice(props.offset, props.offset + props.limit),
		});
	}

	componentDidMount() {
		this.onVerticalScroll = this.updateVerticalScroll.bind(this);
		$("." + this.props.containerClass).on("scroll", this.onVerticalScroll);
		this.updateRows(this.props);
	}

	componentWillUnmount() {
		$("." + this.props.containerClass).off("scroll", this.onVerticalScroll);
	}

	updateVerticalScroll() {
		let offsetTop = $("." + this.props.boardClass).offset().top;
		if (offsetTop < 0) {
			offsetTop = 0;
		}
		let scrollTop = offsetTop + $("." + this.state.rowContainerClass).offset().top * -1;
		if (scrollTop < 0) {
			scrollTop = 0;
		}
		if (this.list) {
			this.list.scrollTo(scrollTop);
		}
	}

	getMaxColumns() {
		const width = $("." + this.props.boardClass).width();
		if (width > 1700) {
			return 6;
		} else if (width > 1500) {
			return 5;
		} else if (width > 1300) {
			return 4;
		}
		return 3;
	}

	updateCell(row, column, value) {
		if (column.id == "title") {
			row.title = value;
			store.dispatch({ type: "UPDATE_BOARD_ROW", row });

			API.put("/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json", { title: value }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
					}
				})
				.catch((error) => {
					toastr.error(error);
				});
		} else if (column.id == "group") {
			row.group_id = value * 1;
			store.dispatch({ type: "UPDATE_BOARD_ROW", row });

			API.put("/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json", { group_id: value }, { params: {} })
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
					}
				})
				.catch((error) => {
					toastr.error(error);
				});
		} else {
			if (column.type == "status" && value !== null) {
				value *= 1;
			}
			BoardHelper.onUpdateValue(row, column, { value });
		}
	}

	renderCell(row, column) {
		if (column.id == "title") {
			return (
				<div className="list-item-cell">
					<SimpleCellText onChange={this.updateCell.bind(this, row, column)} value={row.title} />
				</div>
			);
		}
		if (column.id == "group") {
			return (
				<div className="list-item-cell">
					<SimpleCellOptions
						onChange={this.updateCell.bind(this, row, column)}
						value={row.group_id}
						options={this.props.groups.map((group) => ({
							color: group.color,
							label: group.title,
							value: group.id,
						}))}
					/>
				</div>
			);
		}
		if (column.type == "status") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0].value;
			} else {
				value = null;
			}
			return (
				<div className="list-item-cell">
					<SimpleCellOptions
						allowNull
						onChange={this.updateCell.bind(this, row, column)}
						value={value}
						options={column.options.statuses.map((status, index) => ({
							color: status.color,
							label: status.label,
							value: status.id,
						}))}
					/>
				</div>
			);
		}
		if (column.type == "person") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0];
			} else {
				value = { value: [] };
			}
			return (
				<div className="list-item-cell" style={{ marginTop: -5 }}>
					<SimpleCellPerson board={this.props.board} column={column} onChange={this.updateCell.bind(this, row, column)} value={value.value} />
				</div>
			);
		}
		if (column.type == "contact") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0];
			} else {
				value = { value: [] };
			}
			return (
				<div className="list-item-cell" style={{ marginTop: -5 }}>
					<CellContact column={column} value={value.value} onChange={({ value } = {}) => this.updateCell(row, column, value)} />
				</div>
			);
		}
		if (column.type == "timeline") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0];
			} else {
				value = { value: [] };
			}
			return (
				<div className="list-item-cell" style={{ marginTop: -5 }}>
					<CellTimeline
						onFocus={() => {}}
						onBlur={() => {}}
						column={column}
						value={value.value}
						onChange={({ value } = {}) => this.updateCell(row, column, value)}
						textAlign="left"
					/>
				</div>
			);
		}
		if (column.type == "datetime") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0];
			} else {
				value = { value: [] };
			}
			return (
				<div className="list-item-cell" style={{ marginTop: -5 }}>
					<CellDateTime
						onFocus={() => {}}
						onBlur={() => {}}
						column={column}
						value={value.value}
						onChange={({ value } = {}) => this.updateCell(row, column, value)}
						textAlign="left"
					/>
				</div>
			);
		}

		if (column.type == "upload") {
			const values = BoardHelper.getColumnValues(row, column);
			let value;
			if (values.length > 0) {
				value = values[0];
			} else {
				value = { value: [] };
			}
			return (
				<div className="list-item-cell" style={{ marginTop: -5 }}>
					<CellUpload
						onFocus={() => {}}
						onBlur={() => {}}
						column={column}
						value={value.value}
						onChange={({ value } = {}) => this.updateCell(row, column, value)}
						textAlign="left"
						board={this.props.board}
						history={this.props.history}
					/>
				</div>
			);
		}
		const values = BoardHelper.getColumnValues(row, column);
		let value;
		if (values.length > 0) {
			value = values[0];
			if (value.value) {
				if (column.type == "text" || column.type == "number") {
					<div className="list-item-cell">
						<SimpleCellText onChange={this.updateCell.bind(this, row, column)} value={value.value} />
					</div>;
				}
				// if (column.type == "datetime") {
				// 	return (
				// 		<div className="list-item-cell">
				// 			<span>{value.value.datetime}</span>
				// 		</div>
				// 	);
				// } else {
				return (
					<div className="list-item-cell">
						<span>{typeof value.value == "string" ? value.value : JSON.stringify(value.value)}</span>
					</div>
				);
				// }
			}
		}
		return <div className="list-item-cell">–</div>;
	}

	clickRow(row) {
		this.setState({ showRowSheet: true, editingRow: row });
	}

	renderRow(row, index) {
		const content = [];
		for (let i = 0; i < this.state.columns.length; i++) {
			if (i < this.getMaxColumns()) {
				content.push(this.renderCell(row, this.state.columns[i]));
			}
		}
		content.push(
			<div className="visible-hover">
				<Button plain onClick={this.clickRow.bind(this, row)}>
					<Icon source={EditMajor} />
				</Button>
			</div>
		);
		return content;
		// return (
		// 	<div key={row.id} style={style}>
		// 		<div
		// 			onClick={() => {
		// 				this.setState({ showRowSheet: true, editingRow: row });
		// 			}}
		// 		>
		// 			<div style={{ position: "absolute", right: 15, top: 20 }}>
		// 				<Icon source={MobileHorizontalDotsMajor} />
		// 			</div>
		// 			<LegacyStack spacing="none" wrap={false}>
		// 				{content}
		// 			</LegacyStack>
		// 		</div>
		// 	</div>
		// );
	}

	render() {
		const headings = [];
		for (let i = 0; i < this.state.columns.length; i++) {
			if (i < this.getMaxColumns()) {
				const options = [];
				for (let x = 0; x < this.getAvailableColumns().length; x++) {
					options.push({
						label: this.getAvailableColumns()[x].title,
						value: x + "",
					});
				}
				headings.push(
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
						className="list-header"
					>
						<LegacyStack spacing="extraTight">
							<Select options={options} value={this.state.columns[i].index + ""} onChange={this.changeColumn.bind(this, i)} />
							<SimpleSortButton
								column_id={this.state.columns[i].id}
								column_type={this.state.columns[i].type}
								sorting={this.props.sorting}
								onChangeSorting={this.props.onChangeSorting}
							/>
						</LegacyStack>
					</div>
				);
			}
		}

		headings.push("");

		return (
			<div>
				<div className={this.state.rowContainerClass + " datatable"}>
					<DataTable columnContentTypes={headings.map((h) => "text")} headings={headings} rows={this.state.rows.map(this.renderRow.bind(this))} />
					<RowSheet
						open={this.state.showRowSheet}
						row={this.state.editingRow ? BoardHelper.getRow(this.state.editingRow.id) : null}
						board={BoardHelper.getBoard(this.props.board.id)}
						initialTabIndex={0}
						onUpdateRow={() => {}}
						onClose={() => {
							this.setState({ showRowSheet: false });
						}}
					/>
				</div>
			</div>
		);
	}
}

export default withTranslation(["board"], { withRef: true })(BoardViewSimpleList);
