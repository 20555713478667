import React, { Component } from "react";
import { TextContainer, Autocomplete, Icon, LegacyStack, Spinner, OptionList, KeypressListener } from "@shopify/polaris";
import axios from "axios";
import { SearchMajor, CirclePlusMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import styled from "styled-components";
import API from "../API";
import { toastr } from "./toastr.js";
import { store } from "../store";

class SearchField extends Component {
	constructor(props) {
		super(props);

		const pcs = props?.resource?.split("/");
		const resourceHandle = pcs?.length && pcs[pcs.length - 1];

		const resourceName = props.resourceName
			? props.resourceName
			: {
					singular: props.resource_handle ? props.resource_handle : resourceHandle,
					plural: props.resource_handle ? props.resource_handle : resourceHandle,
			  };

		const randNum = Math.ceil(Math.random() * 10000001);

		this.state = {
			myId: randNum,
			selected: props.selected && Array.isArray(props.selected) ? props.selected : [],
			limit: 25,
			value: "",
			tags: props.tags ? props.tags : false,
			active: false,
			loading: true,
			resource: props.resource,
			timeout: null,
			cancelToken: null,
			results: [],
			resourceName,
			id_handle: props.id_handle ? props.id_handle : "id",
			label_handle: props.label_handle ? props.label_handle : "title",
			resource_handle: props.resource_handle ? props.resource_handle : resourceHandle,
		};

		if (this.props.value) {
			this.state.value = this.props.value;
		}
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			setTimeout(this.focusInput.bind(this), 5);
		}
	}

	focusInput() {
		$("#searchfield" + this.state.myId + " input").focus();
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	clearInput() {
		this.setState({ value: "" });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if ("value" in props && !this.state.active) {
			this.state.value = props.value;
			this.setState({ resource: props.resource, value: this.state.value });
		} else {
			this.setState({ resource: props.resource });
		}
	}

	update(value) {
		if (this.props.items) {
			this.setState(
				{
					loading: false,
					value,
					results: [],
				},
				this.doSearch.bind(this, 0)
			);
			return;
		}

		if (value?.substr?.(-1) == ",") {
			this.commaEvent();
			return;
		}
		this.cancelRequest();
		clearTimeout(this.state.timeout);
		const newState = { value, loading: true, timeout: setTimeout(this.doSearch.bind(this), 500) };
		if (!this.state.active) {
			newState.active = true;
		}
		this.setState(newState);
		if (this.props.onChange) this.props.onChange(value);
	}

	createCancelToken(c) {
		this.setState({ cancelToken: c });
	}

	doSearch() {
		if (this.props.items) {
			const filterRegex = new RegExp(this.state.value, "i");
			const resultOptions = this.props.items.filter((option) => option.fullname && option.fullname.match(filterRegex));

			this.setState({ results: resultOptions.slice(0, 4) });
			return;
		}
		const CancelToken = axios.CancelToken;
		const query = this.state.value;
		const params = Object.assign({}, this.props.params, store.getState().authentication.defaultParams);
		if (this.props.searchHandle !== undefined) {
			if (this.props.searchHandle && query) {
				if (Array.isArray(this.props.searchHandle)) {
					this.props.searchHandle.forEach((searchHandle) => {
						params[searchHandle] = query;
					});
				} else {
					params[this.props.searchHandle] = query;
				}
			}
		} else if (query) {
			params.q = query;
		}
		params.offset = 0;
		params.limit = this.state.limit;

		if (this.state.resource == "categories") {
			params.type = "custom";
		}

		if (this.state.resource == "google_address") {
			API.get("/api/google/addresses.json", {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					this.setState({ loading: false, results: result.data, cancelToken: null });
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line no-console
						console.debug("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		} else {
			API.get("/api/" + this.state.resource + (this.state.resource.indexOf(".json") >= 0 ? "" : ".json"), {
				cancelToken: new CancelToken(this.createCancelToken.bind(this)),
				params,
			})
				.then((result) => {
					const resourceHandle = this.state.resource_handle;
					this.setState({
						loading: false,
						results: result.data[resourceHandle],
						cancelToken: null,
					});
					this.props.onResultFetched?.(result.data);
				})
				.catch((error) => {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line no-console
						console.debug("Request canceled");
					} else {
						toastr.error(error);
					}
				});
		}
	}

	cancelRequest() {
		if (this.state.cancelToken) {
			this.state.cancelToken();
			this.setState({ cancelToken: null });
		}
	}

	selectAddress(item) {
		const CancelToken = axios.CancelToken;

		API.get("/api/google/addresses/" + item.id + ".json", {
			cancelToken: new CancelToken(this.createCancelToken.bind(this)),
		})
			.then((result) => {
				this.props.onSelect(result.data);
			})
			.catch((error) => {
				if (!axios.isCancel(error)) {
					toastr.error(error);
				}
			});
	}

	selectItem(selection) {
		const selectedId = selection[0];
		const options = [...(this.props.options || []), ...(this.state.results || [])];

		const item =
			selectedId === null
				? {
						[this.state.id_handle]: null,
						// [this.state.label_handle]: this.state.value,
						[this.state.label_handle]: selectedId,
				  }
				: options.find((opt) => opt[this.state.id_handle] === selectedId) || null;

		const value = (this.props.keepValue && item?.[this.state.label_handle]) || "";

		this.state.value = value;
		this.setState({
			value,
			active: false,
			loading: false,
			results: [],
			selected: selection,
		});

		if (this.state.resource == "google_address") {
			this.selectAddress(item);
		} else {
			this.props.onSelect(item);
		}

		if (this.props.blurOnSelect) setTimeout(this.blur.bind(this), 1);
	}

	blur() {
		/*
      May be buggy!
    */
		if (this.props.onBlur) setTimeout(() => this.props.onBlur(this.state.value), 99);

		setTimeout(() => {
			this.setState({ active: false, loading: false, value: this.props.value });
		}, 100);
	}

	focus(e) {
		if (this.props.disabled || this.props.readOnly) return;
		this.setState({ active: true });
		this.update(this.state.value);
		this.props?.onFocus?.(e);
	}

	enterEvent() {
		// if (this.state.tags && this.state.value.trim()) {
		// 	let tag = this.state.value.trim();
		// 	this.cancelRequest();
		// 	let item = {};
		// 	item[this.state.id_handle] = null;
		// 	item[this.state.label_handle] = tag;
		// 	this.setState({
		// 		value: "",
		// 		active: false,
		// 		loading: false,
		// 		results: [],
		// 		selection: [],
		// 	});
		// 	this.props.onSelect(item);
		// }
	}

	commaEvent() {
		// if (this.state.tags && this.state.value.trim()) {
		// 	let tag = this.state.value.trim();
		// 	if (tag.substr(-1) == ",") {
		// 		tag = tag.substr(0, tag.length - 1);
		// 	}
		// 	if (!tag) {
		// 		return;
		// 	}
		// 	this.cancelRequest();
		// 	let item = {};
		// 	item[this.state.id_handle] = null;
		// 	item[this.state.label_handle] = tag;
		// 	this.setState({
		// 		value: "",
		// 		active: false,
		// 		loading: false,
		// 		results: [],
		// 		selection: [],
		// 	});
		// 	this.props.onSelect(item);
		// }
	}

	inResults() {
		for (let i = 0; i < this.state.results.length; i++) {
			if (this.state.results[i][this.state.label_handle].toLowerCase() == this.state.value.toLowerCase()) {
				return true;
			}
		}
		return false;
	}

	clearValue() {
		this.state.value = "";
		this.setState({ value: "" });
		this.selectItem([null]);
	}

	render() {
		const activator = (
			<Autocomplete.TextField
				label={this.props.label}
				prefix={
					this.props.prefix !== undefined ? (
						React.isValidElement(this.props.prefix) || this.props.prefix === null ? (
							this.props.prefix
						) : (
							<Icon source={this.props.prefix} />
						)
					) : (
						<Icon source={SearchMajor} color="inkLighter" />
					)
				}
				suffix={this.props.suffix}
				autoComplete="off"
				placeholder={this.props.placeholder}
				onFocus={this.focus.bind(this)}
				onBlur={this.blur.bind(this)}
				error={this.props.error}
				type="search"
				value={this.state.value}
				onChange={this.update.bind(this)}
				clearButton={!!this.props.clearOption && this.state.value}
				onClearButtonClick={this.props.clearOption ? this.clearValue.bind(this) : undefined}
				disabled={this.props.disabled}
				readOnly={this.props.readOnly}
			/>
		);

		const options = this.props.options || [];
		if (this.props.clearOption && typeof this.props.clearOption == "string") {
			options.push({
				value: null,
				label: this.props.clearOption,
			});
		}
		if (this.props.allowCreate || (this.state.tags && this.state.value && !this.inResults())) {
			if (this.state.tags) {
				options.push({
					value: null,
					label: (
						<LegacyStack>
							<Icon source={CirclePlusMajor} /> <div>{this.props.t("searches.add_value", "Lägg till {{ value }}", { value: this.state.value })}</div>
						</LegacyStack>
					),
				});
			} else {
				options.push({
					value: null,
					label: (
						<LegacyStack>
							<Icon source={CirclePlusMajor} />{" "}
							<div>{this.props.t("searches.create_new_object", "Skapa ny {{ object }}", { object: this.state.resourceName.singular })}</div>
						</LegacyStack>
					),
				});
			}
		}
		if (this.state.results) {
			for (let i = 0; i < this.state.results.length; i++) {
				if (this.props.renderLabel) {
					options.push({ value: this.state.results[i][this.state.id_handle], label: this.props.renderLabel(this.state.results[i]) });
				} else {
					options.push({
						value: this.state.results[i][this.state.id_handle],
						label: this.state.results[i][this.state.label_handle],
					});
				}
			}
		}

		const emptyState = (
			<React.Fragment>
				<Icon source={SearchMajor} />
				<div style={{ textAlign: "center" }}>
					<TextContainer>
						{this.props.t("searches.no_object_found", "Inga {{ object }} hittades", { object: this.state.resourceName.plural })}
					</TextContainer>
				</div>
			</React.Fragment>
		);

		if (this.props.inline) {
			return (
				<div id={"searchfield" + this.state.myId}>
					<LegacyStack vertical spacing="tight">
						{activator}
						{this.state.loading ? <Spinner size="small" color="teal" /> : null}
						<div style={{ marginLeft: -15, marginRight: -15 }}>
							<OptionList options={options} selected={this.state.selected} onChange={this.selectItem.bind(this)} />
						</div>
					</LegacyStack>
					<KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} />
				</div>
			);
		}

		return (
			<Wrapper id={"searchfield" + this.state.myId} style={this.props.style}>
				<Autocomplete
					actionBefore={this.props.actionBefore}
					options={options}
					selected={this.state.selected}
					onSelect={this.selectItem.bind(this)}
					loading={this.state.loading}
					disabled={this.props.disabled}
					emptyState={emptyState}
					textField={activator}
				/>
				<KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} />
			</Wrapper>
		);
	}
}

export default withTranslation(["common"], { withRef: true })(SearchField);

SearchField.defaultProps = {
	keepValue: true,
};

const Wrapper = styled.div`
	.Polaris-TextField__Prefix {
		display: flex;
	}
`;
