/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import firebase from "firebase/app";

import { Frame, ContextualSaveBar, Loading } from "@shopify/polaris";
import introJs from "intro.js";
import { withTranslation } from "react-i18next";
import moment from "moment";
import getRoutes from "./routes";
import { store } from "./store";

import { toastr } from "./components/toastr";
import MyTopBar from "./components/MyTopBar";
import MainMenu from "./components/MainMenu";
import "firebase/messaging";
import Announcements from "./views/Announcements/Announements";
import "./redirectToMobileStore";
import "intro.js/introjs.css";
import SocketProvider from "./SocketProvider";
import API from "./API";
import i18n from "./i18n";
import Clippy from "./components/Clippy";
import { NotificationProvider } from "./ContextProviders/NotificationContext";
import { FortnoxGlobalContextProvider } from "./views/Fortnox/ContextProvider/FortnoxGlobalContext";
import { ProgressJobProvider } from "./components/ProgressJob/ProgressJobContext";
import { ThemeProvider } from "./views/Dashboard/ThemeToggle";
import PDFContextProvider from "./PDF/PDFContext";
import RouteWithTitle from "src/RouteWithTitle";
import BrowserWarningBanner from "./BrowserWarningBanner";
import { WorkspacesContextProvider } from "./components/Workspaces/WorkspaceContext";

Object.defineProperty(String.prototype, "trans", {
	value() {
		return this;
	},
	enumerable: false,
});

moment.updateLocale(store.getState().user.locale || "sv", {
	relativeTime: {
		future: i18n.t("moment.future", "om {{v}}", { v: "%s" }),
		past: i18n.t("moment.future", "{{v}} sedan", { v: "%s" }),
		s(number, withoutSuffix, key, isFuture) {
			return i18n.t("moment.mm", "{{v}} minuter", { v: `00:${(number < 10 ? "0" : "") + number}` });
			// return "00:" + (number < 10 ? "0" : "") + number + " minuter";
		},
		m: i18n.t("moment.m", "01:00 minuter"),
		mm(number, withoutSuffix, key, isFuture) {
			return i18n.t("moment.mm", "{{v}} minuter", { v: `${(number < 10 ? "0" : "") + number}:00` });
			// return (number < 10 ? "0" : "") + number + ":00 minuter";
		},
		h: i18n.t("moment.h", "en timme"),
		hh: i18n.t("moment.hh", "{{v}} timmar", { v: "%d" }),
		d: i18n.t("moment.d", "en dag"),
		dd: i18n.t("moment.dd", "{{v}} dagar", { v: "%d" }),
		M: i18n.t("moment.M", "en månad"),
		MM: i18n.t("moment.MM", "{{v}} månader", { v: "%d" }),
		y: i18n.t("moment.y", "ett år"),
		yy: i18n.t("moment.yy", "{{v}} år", { v: "%d" }),
	},
});

introJs().setOptions({
	tooltipClass: "introjs_tooltip",
});

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.onMessage = (evt) => {
			if (evt.data == "reload") {
				window.location.reload();
			}
		};
		this.handleMessage = this.onMessage.bind(this);
	}

	componentDidMount() {
		try {
			this.registerPushNotifications();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log("Couldnt initialize notifications", e);
		}

		if (window.addEventListener) {
			window.addEventListener("message", this.handleMessage, false);
		} else {
			window.attachEvent("onmessage", this.handleMessage);
		}
	}

	registerPushNotifications() {
		if ("serviceWorker" in navigator) {
			const firebaseConfig = {
				apiKey: "AIzaSyAGhaNlzzRBBSPRRFKsmtb8-ejNClbvsf8",
				authDomain: "kund24.firebaseapp.com",
				databaseURL: "https://kund24.firebaseio.com",
				projectId: "kund24",
				storageBucket: "kund24.appspot.com",
				messagingSenderId: "423094108806",
				appId: "1:423094108806:web:10518a36a08fca09ca4707",
			};
			// Initialize Firebase
			firebase.initializeApp(firebaseConfig);
			this.messaging = firebase.messaging();
			this.messaging.usePublicVapidKey("BESKQEJgdqWy4Jw9OXwzDuDjXnV9_LBG4pLPf9pKce8ouBfPACjkf6b2u0UsEw_OsJTysUyRiEufj3LEjl24pg4");
			this.messaging.onMessage((payload) => {
				// eslint-disable-next-line no-console
				console.log("Message received. ", payload);
				if (!Object.hasOwn(payload.data, "chat_id")) {
					toastr.message(payload.notification.body);
				}
			});
			this.messaging.onTokenRefresh(() => {
				this.messaging
					.getToken()
					.then((refreshedToken) => {
						// eslint-disable-next-line no-console
						console.log("Token refreshed.");
						this.sendTokenToServer(refreshedToken);
					})
					.catch((err) => {
						// eslint-disable-next-line no-console
						console.log("Unable to retrieve refreshed token ", err);
					});
			});
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					// eslint-disable-next-line no-console
					console.log("Notification permission granted.");
					this.messaging
						.getToken()
						.then((currentToken) => {
							if (currentToken) {
								this.sendTokenToServer(currentToken);
							} else {
								// Show permission request.
								// eslint-disable-next-line no-console
								console.log("No Instance ID token available. Request permission to generate one.");
								// Show permission UI.
							}
						})
						.catch((err) => {
							// eslint-disable-next-line no-console
							console.log("An error occurred while retrieving token. ", err);
						});
				} else {
					// eslint-disable-next-line no-console
					console.log("Unable to get permission to notify.");
				}
			});
		}
	}

	sendTokenToServer(pushSubscription) {
		API.post("/api/me/push_tokens.json", { token: pushSubscription })
			.then((result) => {})
			.catch((error) => {
				// toastr.error(error);
			});
	}

	render() {
		const introslide = <Announcements fetchNewsletters history={this.props.history} />;

		const isDirty = store.getState().savable;

		const contextualSaveBarMarkup = isDirty ? (
			<ContextualSaveBar
				message="Unsaved changes"
				saveAction={{
					onAction: this.handleSave,
				}}
				discardAction={{
					onAction: this.handleDiscard,
				}}
			/>
		) : null;

		const loadingMarkup = store.getState().loading ? <Loading /> : null;

		const topBar = (
			<MyTopBar
				onNavigationToggle={(state) => {
					this.setState({ showMobileNavigation: !this.state.showMobileNavigation });
				}}
				history={this.props.history}
				location={this.props.location}
			/>
		);

		return (
			<ThemeProvider>
				<WorkspacesContextProvider>
					<SocketProvider history={this.props.history}>
						<ProgressJobProvider history={this.props.history}>
							<NotificationProvider>
								<FortnoxGlobalContextProvider>
									<PDFContextProvider>
										<Frame
											topBar={topBar}
											navigation={
												<MainMenu
													onCloseMenu={() => {
														this.setState({ showMobileNavigation: false });
													}}
													location={this.props.location}
													history={this.props.history}
												/>
											}
											showMobileNavigation={this.state.showMobileNavigation}
											onNavigationDismiss={() => {
												this.setState({ showMobileNavigation: !this.state.showMobileNavigation });
											}}
										>
											<BrowserWarningBanner />
											{introslide}
											{contextualSaveBarMarkup}
											{loadingMarkup}
											<Clippy history={this.props.history} />
											<Switch>
												{getRoutes().map((prop, key) => {
													if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;

													return (
														<RouteWithTitle
															path={prop.path}
															component={prop.component}
															key={key}
															exact={prop.exact}
															route={prop}
															roles={prop.roles}
														/>
													);
												})}
											</Switch>
										</Frame>
									</PDFContextProvider>
								</FortnoxGlobalContextProvider>
							</NotificationProvider>
						</ProgressJobProvider>
					</SocketProvider>
				</WorkspacesContextProvider>
			</ThemeProvider>
		);
	}
}

export default withTranslation(["app", "common"], { withRef: true })(App);
