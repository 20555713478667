import React, { Component } from "react";
import { FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import { store } from "../store";
import { toastr } from "./toastr.js";
import RemoveModal from "./remove_modal.js";
import API from "../API";
import getContactActivityTypes from "../Utilities/getContactActivityTypes";
import Modal from "src/js/components/modal";

class ContactActivityModal extends Component {
	constructor(props) {
		super(props);
		this.defaultForm = { type: "comment", title: "" };
		this.state = { open: props.open, form: props.activity || this.defaultForm, saving: false };
	}

	componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.activity || this.defaultForm });
		}
	}

	updateForm(field, value) {
		var formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/contact_activities/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("task.responses.updated", "Uppdaterade aktivitet"));
					this.setState({ saving: false });
					store.dispatch({ type: "UPDATE_CONTACT_ACTIVITY", contact_activity: result.data.contact_activity });
					this.props.onCreate(result.data.contact_activity);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/contact_activities.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("task.responses.created", "Skapade aktivitet"));
					this.setState({ saving: false });
					store.dispatch({ type: "ADD_CONTACT_ACTIVITY", contact_activity: result.data.contact_activity });
					this.props.onCreate(result.data.contact_activity);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	doRemove() {
		this.setState({ removing: true });
		API.delete("/api/contact_activities/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				store.dispatch({ type: "REMOVE_CONTACT_ACTIVITY", id: this.state.form.id });
				toastr.success(this.props.t("task.responses.removed", "Aktivitet borttagen"));
				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	render() {
		var secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN")) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={this.state.form.id ? this.props.t("task.actions.change", "Ändra aktivitet") : this.props.t("task.actions.new", "Ny aktivitet")}
					secondaryActions={secondaryActions}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						disabled: !this.state.form.title,
						onAction: this.saveForm.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("task.activities.fields.title.label", "Namn")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<Select
								label={this.props.t("task.activities.fields.type.label", "Typ")}
								options={getContactActivityTypes()}
								onChange={(type) => {
									this.state.form.type = type;
									this.setState({ form: this.state.form });
								}}
								value={this.state.form.type}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.doRemove.bind(this)}
					numItems={1}
					objectName={this.state.form.title}
					resourceName={{
						singular: this.props.t("task.activities.singular", "aktivitet"),
						plural: this.props.t("task.activities.plural", "aktiviteter"),
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["task", "common"], { withRef: true })(ContactActivityModal);
