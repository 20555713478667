import React, { Component } from "react";
import { PageActions, Layout, LegacyCard, FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";

import { toastr } from "../../components/toastr.js";
import AdAccountSelector from "../../components/AdAccountSelector.js";
import SkeletonPage from "src/js/components/Skeletons/Page";

class AdCampaignEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: props.match.params.id,
			saving: false,
			loading: true,
			form: null,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.setState({ id: props.match.params.id });
			this.fetchItem(props.match.params.id);
		}
	}

	componentDidMount() {
		this.fetchItem(this.state.id);
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	fetchItem(id) {
		this.setState({ id, loading: true });
		API.get("/api/ans_campaigns/" + id + ".json")
			.then((result) => {
				if (result.data.ad_campaign && result.data.ad_campaign.budget) {
					result.data.ad_campaign.budget /= 100;
				}
				this.setState({ loading: false, form: result.data.ad_campaign });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	saveForm() {
		this.setState({ saving: true });
		API.put("/api/ans_campaigns/" + this.state.id + ".json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("ad_campaign.response.updated", "Uppdaterade kampanj"));
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	goPrevious() {
		const currentIndex = this.getCurrentPaginationIndex();
		const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
		this.props.history.push(lastStack.items[currentIndex - 1].path, this.props.location.state);
	}

	goNext() {
		const currentIndex = this.getCurrentPaginationIndex();
		const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
		this.props.history.push(lastStack.items[currentIndex + 1].path, this.props.location.state);
	}

	getCurrentPaginationIndex() {
		if (
			this.props.location.state.stacks &&
			this.props.location.state.stacks.length &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items.length > 1
		) {
			const lastStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
			for (let i = 0; i < lastStack.items.length; i++) {
				if (lastStack.items[i].path == this.props.location.pathname + this.props.location.search + "") {
					return i;
				}
			}
		}
		return null;
	}

	render() {
		if (this.state.loading) {
			return <SkeletonPage />;
		}

		let pagination = null;
		if (
			this.props.location.state &&
			this.props.location.state.stacks &&
			this.props.location.state.stacks.length &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items &&
			this.props.location.state.stacks[this.props.location.state.stacks.length - 1].items.length > 1
		) {
			const currentStack = this.props.location.state.stacks[this.props.location.state.stacks.length - 1];
			const currentIndex = this.getCurrentPaginationIndex();
			const hasPrevious = currentIndex > 0;
			const hasNext = currentIndex < currentStack.items.length - 1;
			pagination = {
				hasPrevious,
				onPrevious: this.goPrevious.bind(this),
				hasNext,
				onNext: this.goNext.bind(this),
			};
		} else {
			pagination = null;
		}

		const form = this.state.form || {};

		return (
			<Page
				title={form.title}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				pagination={pagination}
				primaryAction={{
					content: this.props.t("common.actions.update", "Uppdatera"),
					loading: this.state.saving,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Layout>
					<Layout.AnnotatedSection
						title={this.props.t("ad_campaign.user.title", "Användaruppgifter")}
						description={this.props.t("ad_campaign.user.description", "Fyll i användaruppgifter")}
					>
						<LegacyCard title={this.props.t("ad_campaign.user.card_title", "Uppgifter")}>
							<LegacyCard.Section>
								<FormLayout>
									<TextField
										label={this.props.t("common.fields.name.label", "Namn")}
										disabled
										value={form.title}
										onChange={this.updateForm.bind(this, "title")}
									/>
									<AdAccountSelector
										disabled
										required
										label={this.props.t("ad_accounts.title", "Annonskonto")}
										ad_account={form.ad_account}
										onChange={(value) => {
											this.state.form.ad_account = value;
											this.state.form.ad_campaign = null;
											this.state.form.type = "search";
											this.setState({ form: this.state.form });
										}}
									/>
									<Select
										label="Status"
										options={[
											{ value: "ENABLED", label: this.props.t("common.status.active", "Aktiv") },
											{ value: "PAUSED", label: this.props.t("common.status.paused", "Pausad") },
										]}
										onChange={(value) => {
											this.state.form.status = value;
											this.setState({ form: this.state.form });
										}}
										value={form.status}
									/>
									<TextField
										label={this.props.t("ad_campaign.budget_label", "Daglig budget")}
										type="currency"
										suffix="kr"
										value={form.budget + ""}
										onChange={this.updateForm.bind(this, "budget")}
									/>
								</FormLayout>
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.AnnotatedSection>
				</Layout>
				<PageActions
					primaryAction={{
						content: this.props.t("common.actions.update", "Uppdatera"),
						loading: this.state.saving,
						onAction: this.saveForm.bind(this),
					}}
				/>
			</Page>
		);
	}
}

export default withTranslation(["ad_campaign", "common"], { withRef: true })(AdCampaignEdit);
