import React, { Component } from "react";
import { Icon, LegacyStack, Button, ActionList } from "@shopify/polaris";
import { CirclePlusMinor, CircleCancelMinor, SearchMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import MyPopover from "../../components/Popover";

class CellTags extends Component {
	constructor(props) {
		super(props);
		this.state = { query: "", value: props.value || [] };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!props.summary) {
			this.setState({ value: props.value || [] });
		}
	}

	addTag(tag) {
		if (this.state.value.indexOf(tag.id) < 0) {
			this.state.value.push(tag.id);
			this.props.onChange({ value: this.state.value });
			this.setState({ value: this.state.value });
		}
		this.setState({ active: false }, this.props.onBlur);
	}

	removeTag(tag) {
		const index = this.state.value.indexOf(tag.id);
		if (index >= 0) {
			this.state.value.splice(index, 1);
			this.setState({ value: this.state.value });
			this.props.onChange({ value: this.state.value });
		}
	}

	createTag() {
		if (!this.state.query || this.state.saving) {
			return;
		}
		this.setState({ saving: true });
		API.post("/api/boards/" + this.props.column.board_id + "/tags.json", { title: this.state.query }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					this.setState({ saving: false });
					toastr.error(result.data.error);
					return;
				}
				this.setState({ saving: false });
				store.dispatch({ type: "ADD_BOARD_TAG", tag: result.data.tag });
				this.addTag(result.data.tag);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
		this.setState({ active: false, query: "" }, this.props.onBlur);
	}

	getTag(tagId) {
		const board = store.getState().boards[this.props.board.id];
		if (board && board.tags) {
			for (let i = 0; i < board.tags.length; i++) {
				if (board.tags[i].id == tagId) {
					return board.tags[i];
				}
			}
			return null;
		}
		return null;
	}

	getUnusedTagOptions() {
		const tags = [];
		const filterRegex = new RegExp(this.state.query, "i");
		for (let i = 0; i < store.getState().boards[this.props.board.id].tags.length; i++) {
			if (
				this.state.value.indexOf(store.getState().boards[this.props.board.id].tags[i].id) < 0 &&
				store.getState().boards[this.props.board.id].tags[i].title.match(filterRegex)
			) {
				tags.push({
					content: store.getState().boards[this.props.board.id].tags[i].title,
					onAction: this.addTag.bind(this, store.getState().boards[this.props.board.id].tags[i]),
				});
			}
		}
		return tags;
	}

	render() {
		const tags = this.state.value.map((tagId) => this.getTag(tagId)).filter((i) => i);

		const overflowingTags = 0;
		return (
			<MyPopover
				fixed
				fluidContent
				fullHeight
				active={this.state.active}
				activator={
					<div
						className={this.state.active ? "expandedCell" : ""}
						style={{
							height: this.state.active ? "auto" : this.props.height,
							minHeight: this.props.height,
							width: "100%",
							textAlign: "center",
							cursor: "pointer",
							paddingTop: 5,
						}}
						onClick={() => {
							if (!this.state.active) {
								this.setState({ active: true }, () => {
									setTimeout(() => {
										this.input.focus();
									}, 100);
									this.props.onFocus();
								});
							} else {
								this.setState({ active: false }, this.props.onBlur);
							}
						}}
					>
						<LegacyStack spacing="none" wrap={false}>
							{!overflowingTags && !this.state.active ? (
								<div className="board-display-hover" style={{ paddingRight: 3 }}>
									<Icon source={CirclePlusMinor} color="blue" />
								</div>
							) : null}
							<LegacyStack.Item fill>
								<LegacyStack spacing="extraTight" vertical={this.state.active} distribution="center">
									{tags.map((tag) => (
										<span key={tag.id} style={{ color: tag.color, fill: tag.color }}>
											<span>#{tag.title}</span>
											{this.state.active ? (
												<span className="clear-button-inline" onClick={this.removeTag.bind(this, tag)}>
													<Icon source={CircleCancelMinor} />
												</span>
											) : null}
										</span>
									))}
								</LegacyStack>
							</LegacyStack.Item>
							{overflowingTags ? (
								<div
									style={{
										backgroundColor: "#303030",
										color: "#dedede",
										height: 28,
										width: 28,
										borderRadius: 15,
										padding: 3,
										paddingLeft: 5,
										paddingRight: 5,
										fontSize: 12,
									}}
								>
									+{overflowingTags}
								</div>
							) : null}
						</LegacyStack>
					</div>
				}
				onClose={() => {
					this.setState({ active: false }, this.props.onBlur);
				}}
			>
				<div style={{ width: 250, position: "relative" }}>
					<span style={{ position: "absolute", left: 7, top: 10 }}>
						<Icon source={SearchMinor} color="inkLighter" />
					</span>
					<input
						ref={(input) => {
							this.input = input;
						}}
						onKeyDown={(event) => {
							if (event.key === "Enter") {
								this.createTag();
							} else if (event.key === "Escape") {
								this.setState({ active: false, query: "" }, this.props.onBlur);
							}
						}}
						className="bgInput"
						style={{ paddingLeft: 30 }}
						placeholder={this.props.t("cell.tags.placeholder", "Sök taggar...")}
						type="text"
						value={this.state.query}
						onChange={(event) => {
							this.setState({ query: event.target.value });
						}}
					/>
				</div>
				{this.state.active ? (
					<div
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						<ActionList items={this.getUnusedTagOptions()} />
					</div>
				) : null}
				{this.state.query ? (
					<div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
						<Button fullWidth loading={this.state.saving} disabled={!this.state.query} primary onClick={this.createTag.bind(this)}>
							{this.props.t("cell.tags.action", "Skapa ny tagg")}
						</Button>
					</div>
				) : null}
			</MyPopover>
		);
	}
}
export default withTranslation(["board"], { withRef: true })(CellTags);
