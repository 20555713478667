import React, { Component } from "react";
import { Text, Icon, Collapsible, Spinner, Button, ResourceItem, LegacyStack, Badge } from "@shopify/polaris";
import {
	CalendarMinor,
	OrdersMajor,
	GrammarMajor,
	EmailMajor,
	CircleAlertMajor,
	BehaviorMajor,
	AttachmentMajor,
	PhoneOutMajor,
	CirclePlusMajor,
	ChevronRightMinor,
} from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import { store } from "../../store";

class ExpandableSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	render() {
		let title = this.props.type;
		let icon = CalendarMinor;

		let items = [];

		if (this.props.type == "tasks") {
			title = this.props.t("common.terms.reminders", "Påminnelser");
			icon = CalendarMinor;
		} else if (this.props.type == "deals") {
			title = this.props.t("common.terms.deals", "Affärer");

			icon = OrdersMajor;
			const addedStages = [];
			const stages = {};
			for (let i = 0; i < items.length; i++) {
				const stageId = items[i].stage.id;
				if (addedStages.indexOf(stageId) < 0) {
					addedStages.push(stageId);
					stages[stageId] = 0;
				}
				stages[stageId]++;
			}

			for (let i = 0; i < store.getState().stages.length; i++) {
				if (addedStages.indexOf(store.getState().stages[i].id) >= 0) {
					items.push(
						<span
							className="Polaris-Badge"
							style={{
								color: store.getState().stages[i].color_hsl.brightness >= 0.8 ? "#000" : "#fff",
								backgroundColor: store.getState().stages[i].color,
							}}
						>
							{stages[store.getState().stages[i].id]}
						</span>
					);
				}
			}
		} else if (this.props.type == "tickets") {
			title = this.props.t("common.terms.tickets", "Ärenden");
			icon = CircleAlertMajor;
			const addedStatuses = [];
			const statuses = {};
			for (let i = 0; i < items.length; i++) {
				const status = items[i].status;
				if (addedStatuses.indexOf(status) < 0) {
					addedStatuses.push(status);
					statuses[status] = 0;
				}
				statuses[status]++;
			}

			items = [];

			// eslint-disable-next-line no-restricted-syntax
			for (const statu in statuses) {
				let stat = null;
				if (statu == "open") {
					// let title = 'Öppen';
					stat = "attention";
					// let icon = ChatMajor;
				} else if (statu == "pending") {
					// let title = 'Väntar på svar';
					stat = "info";
					// let icon = CircleDotsMajor;
				} else if (statu == "closed") {
					// let title = 'Avslutad';
					stat = "success";
					// let icon = CircleTickMajor;
				} else if (statu == "removed") {
					// let title = 'Borttagen';
					stat = "warning";
					// let icon = CircleCancelMajor;
				} else if (statu == "spam") {
					// let title = 'Markerat som spam';
					stat = "default";
					// let icon = CircleDisabledMajor;
				}
				items.push(<Badge status={stat}>{statuses[statu]}</Badge>);
			}
		} else if (this.props.type == "emails") {
			title = this.props.t("expandable_section.open_emails", "Öppna emails");
			icon = EmailMajor;
		} else if (this.props.type == "private_emails") {
			title = this.props.t("expandable_section.private_emails", "Privata email");
			icon = EmailMajor;
		} else if (this.props.type == "projects") {
			title = this.props.t("expandable_section.projects", "Projekt");
			icon = GrammarMajor;
		} else if (this.props.type == "sessions") {
			title = this.props.t("expandable_section.sessions", "Hemsidebesök");
			icon = BehaviorMajor;
		} else if (this.props.type == "call_lists") {
			title = this.props.t("expandable_section.call_lists", "Ringlistor");
			icon = PhoneOutMajor;
		} else if (this.props.type == "uploads") {
			title = this.props.t("expandable_section.uploads", "Dokument");
			icon = AttachmentMajor;
		}
		return (
			<div className="Polaris-ResourceList__ItemWrapper">
				<ResourceItem
					onClick={() => {
						if (!this.state.open && this.props.onExpand) {
							this.props.onExpand();
						}
						this.setState({ open: !this.state.open });
					}}
				>
					<Text variant="headingMd" as="h2">
						<LegacyStack>
							<LegacyStack.Item fill>
								<LegacyStack spacing="loose">
									<Icon source={icon} />
									<LegacyStack spacing="tight">
										<span>{title}</span>
										<span style={{ transition: "transform 250ms", display: "flex", transform: `rotate(${this.state.open ? 90 : 0}deg)` }}>
											<Icon source={ChevronRightMinor} />
										</span>
									</LegacyStack>
								</LegacyStack>
							</LegacyStack.Item>
							{this.props.onCreate ? (
								this.props.loading ? (
									<Spinner size="small" color="teal" />
								) : (
									<div
										style={{ paddingTop: 2 }}
										onClick={(e) => {
											e.stopPropagation();
										}}
									>
										<Button plain onClick={this.props.onCreate}>
											<Icon source={CirclePlusMajor} />
										</Button>
									</div>
								)
							) : null}
							{this.props.count}
						</LegacyStack>
					</Text>
				</ResourceItem>
				<Collapsible open={this.state.open}>
					{this.props.loading ? (
						<ResourceItem>
							<LegacyStack>
								<Spinner size="small" /> <span>laddar {this.props.resourceName.plural}...</span>
							</LegacyStack>
						</ResourceItem>
					) : (
						this.props.children
					)}
				</Collapsible>
			</div>
		);
	}
}

export default withTranslation(["expandable_section", "common"], { withRef: true })(ExpandableSection);
