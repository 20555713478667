import React, { Component } from "react";
import { FormLayout, Checkbox, TextField, Select, LegacyStack } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";

import { toastr } from "./toastr.js";
import { store } from "../store";
import RemoveModal from "./remove_modal.js";
import Modal from "src/js/components/modal";

class AddressModal extends Component {
	constructor(props) {
		super(props);
		this.state = { saving: false, form: props.address };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.address });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	saveContact() {
		const form = this.state.form;

		this.setState({ saving: true });

		if (this.state.form.id) {
			API.put("/api/contacts/" + this.props.contact.id + "/addresses/" + this.state.form.id + ".json", form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("contact.address.responses.changed", "Arbetsställe ändrat"));
					this.setState({ form: result.data.address });
					this.props.onUpdate(result.data.address);
					this.props.onClose();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/contacts/" + this.props.contact.id + "/addresses.json", this.state.form)
				.then((result) => {
					this.setState({ saving: false });
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("contact.address.responses.created", "Arbetsställe skapat"));
					if (this.props.onCreate) {
						this.props.onCreate(result.data.address);
					}
					this.props.onClose();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	removeAddress() {
		this.setState({ removing: true });
		API.delete("/api/contacts/" + this.props.contact.id + "/addresses/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });

				toastr.success(this.props.t("contact.address.responses.removed", "Arbetsställe borttaget"));

				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	render() {
		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN")) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.props.open}
					onClose={this.props.onClose}
					title={this.state.form.id ? this.state.form.title : this.props.t("contact.modals.address.title", "Nytt arbetsställe")}
					secondaryActions={secondaryActions}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						onAction: this.saveContact.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("contact.modals.address.fields.title.label", "Namn på arbetsställe")}
								onChange={this.updateForm.bind(this, "title")}
								value={this.state.form.title}
							/>
							<TextField
								label={this.props.t("contact.modals.address.fields.email.label", "E-post")}
								onChange={this.updateForm.bind(this, "email")}
								value={this.state.form.email}
							/>
							<TextField
								label={this.props.t("contact.modals.address.fields.phone.label", "Telefon")}
								onChange={this.updateForm.bind(this, "phone")}
								value={this.state.form.phone}
							/>
							<Select
								label={this.props.t("contact.modals.address.fields.country.label", "Land")}
								options={store.getState().countries}
								onChange={this.updateForm.bind(this, "country")}
								value={this.state.form.country}
							/>
						</FormLayout>
					</Modal.Section>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("contact.modals.address.fields.address1.label", "Gatuadress")}
								onChange={this.updateForm.bind(this, "address1")}
								value={this.state.form.address1}
							/>
							<TextField
								label={this.props.t("contact.modals.address.fields.address2.label", "CO/adress och annat")}
								onChange={this.updateForm.bind(this, "address2")}
								value={this.state.form.address2}
							/>
							<LegacyStack distribution="fillEvenly">
								<TextField
									label={this.props.t("contact.modals.address.fields.zip.label", "Postnummer")}
									onChange={this.updateForm.bind(this, "zip")}
									value={this.state.form.zip}
								/>
								<TextField
									label={this.props.t("contact.modals.address.fields.city.label", "Ort")}
									onChange={this.updateForm.bind(this, "city")}
									value={this.state.form.city}
								/>
							</LegacyStack>
						</FormLayout>
					</Modal.Section>
					<Modal.Section>
						<FormLayout>
							<Checkbox
								label={this.props.t("contact.modals.address.fields.use_postal.label", "Har särskild postadress")}
								checked={this.state.form.use_postal}
								onChange={this.updateForm.bind(this, "use_postal")}
							/>
							{this.state.form.use_postal ? (
								<TextField
									label={this.props.t("contact.modals.address.fields.postal_address1.label", "Adress för post")}
									onChange={this.updateForm.bind(this, "postal_address1")}
									value={this.state.form.postal_address1}
								/>
							) : null}
							{this.state.form.use_postal ? (
								<TextField
									label={this.props.t("contact.modals.address.fields.postal_address2.label", "CO/adress och annat")}
									onChange={this.updateForm.bind(this, "postal_address2")}
									value={this.state.form.postal_address2}
								/>
							) : null}
							{this.state.form.use_postal ? (
								<LegacyStack distribution="fillEvenly">
									<TextField
										label={this.props.t("contact.modals.address.fields.postal_zip.label", "Postnummer")}
										onChange={this.updateForm.bind(this, "postal_zip")}
										value={this.state.form.postal_zip}
									/>
									<TextField
										label={this.props.t("contact.modals.address.fields.postal_city.label", "Ort")}
										onChange={this.updateForm.bind(this, "postal_city")}
										value={this.state.form.postal_city}
									/>
								</LegacyStack>
							) : null}
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.removeAddress.bind(this)}
					numItems={1}
					objectName={this.state.form.name}
					resourceName={{
						singular: this.props.t("contact.address.singular", "arbetsställe"),
						plural: this.props.t("contact.address.plural", "arbetsställen"),
					}}
				/>
			</div>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />
export default withTranslation(["contact", "common"], { withRef: true })(AddressModal);
