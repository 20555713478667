import React, { Component } from "react";
import { LegacyStack, ActionList, ButtonGroup, Tooltip, Text, TextField, Button, Icon, DropZone } from "@shopify/polaris";
import { AttachmentMajor, MentionMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../../API";
import Attachment from "../../../components/Attachment.js";
import MemberAvatar from "./MemberAvatar";
import { toastr } from "../../../components/toastr.js";
import { store } from "../../../store";

import Popover from "../../../components/Popover.js";

class CommentInput extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign(
			{
				comment: (props.comment && props.comment.content) || "",
				mentions: [],
				files: [],
				nextStatusIndex: null,
				focused: false,
				randomid: "rndcommentinp" + Math.round(Math.random() * 1000),
			},
			props.comment
		);
		this.onPasteEvent = this.onPaste.bind(this);
		this.onKeydownEvents = this.keydownEvents.bind(this);
	}

	componentDidMount() {
		if ($("#" + this.state.randomid + " .Polaris-TextField__Input")[0]) {
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].addEventListener("paste", this.onPasteEvent);
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].addEventListener("keydown", this.onKeydownEvents);
		}
	}

	componentWillUnmount() {
		if ($("#" + this.state.randomid + " .Polaris-TextField__Input")[0]) {
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].removeEventListener("paste", this.onPasteEvent);
			$("#" + this.state.randomid + " .Polaris-TextField__Input")[0].removeEventListener("keydown", this.onKeydownEvents);
		}
	}

	keydownEvents(e) {
		if (this.props.multiline && e.key === "Enter" && e.shiftKey) return false;
		if (e.key === "Enter") {
			this.enterEvent(e);
		}
	}

	onPaste(e) {
		if (!e.clipboardData) {
			return;
		}
		const items = e.clipboardData.items;

		if (items == undefined) {
			return;
		}

		for (let i = 0; i < items.length; i++) {
			// Skip content if not image
			if (items[i].type.indexOf("image") >= 0) {
				// Retrieve image on clipboard as blob
				const blob = items[i].getAsFile();

				if (blob) {
					e.preventDefault();
					const reader = new FileReader();
					reader.onload = this.addFile.bind(this, blob);
					reader.readAsDataURL(blob);
				}
			}
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.ticket != this.props.ticket) {
			this.setState({ nextStatusIndex: null });
		}
	}

	addFile(file, event) {
		const data = event.target.result;
		const files = this.state.files;
		files.push({ name: file.name, size: file.size, data });
		this.state.files = files;
		this.setState({ files: this.state.files });
		if (this.props.onChange) this.props.onChange({ files: this.state.files });
	}

	removeFile(index, file) {
		this.state.files.splice(index, 1);
		this.setState({ files: this.state.files });
		if (this.props.onChange) this.props.onChange({ files: this.state.files });
	}

	removeMention(index, mention) {
		this.state.mentions.splice(index, 1);
		this.setState({ mentions: this.state.mentions });
		if (this.props.onChange) this.props.onChange({ mentions: this.state.mentions });
	}

	addMention(user) {
		if (this.props.addInlineMention) {
			this.state.comment = this.state.comment + " @" + user.name;
			this.setState({ comment: this.state.comment.trim() + " " });
			return;
		}
		for (let i = 0; i < this.state.mentions.length; i++) {
			if (this.state.mentions[i].id == user.id) {
				return;
			}
		}
		this.state.mentions.push(user);
		this.setState({ mentions: this.state.mentions });
		if (this.props.onChange) this.props.onChange({ mentions: this.state.mentions });
	}

	updateFile(index, file) {
		this.state.files[index] = file;
		this.setState({ files: this.state.files });
		if (this.props.onChange) this.props.onChange({ files: this.state.files });
	}

	updateComment(comment) {
		this.setState({ memberPopoverActive: false, comment });
		if (this.props.onChange) this.props.onChange({ comment, content: comment });
	}

	saveForm() {
		if (this.props.comment && this.props.comment.id && this.props.saveable) {
			this.setState({ loading: true });

			API.put(
				`/api/boards/${this.state.board.id}/rows/${this.state.row.id}/comments/${this.state.id}.json`,
				{
					files: this.state.files,
					comment: this.state.comment,
					mentions: this.state.mentions,
				},
				{ params: {} }
			)
				.then((result) => {
					if (result.data.error) {
						console.error("error:", result.data.error);
						toastr.error(result.data.error);
						this.setState({ loading: false });
						return;
					}
					this.setState((c) =>
						Object.assign(c, result.data.comment, {
							comment: result.data.comment.content,
							loading: false,
						})
					);
					toastr.success(this.props.t("comment.responses.updated", "Kommentar uppdaterad"));

					if (this.props.afterSave) this.props.afterSave(result.data.comment, this.props.index);
				})
				.catch((error) => {
					console.error("error:", error);
					toastr.error(error);
					this.setState({ loading: false });
				});

			return;
		}
		if (this.props.onSave) {
			this.props.onSave({
				files: this.state.files,
				comment: this.state.comment,
				content: this.state.comment,
				mentions: this.state.mentions,
				board: this.props.board,
				user: store.getState().user,
			});
			this.setState({
				saving: false,
				comment: "",
				files: [],
				mentions: [],
			});

			return;
		}
		this.setState({ saving: true });
		API.post(
			"/api/boards/" + this.props.board.id + "/rows/" + this.props.row.id + "/comments.json",
			{
				files: this.state.files,
				comment: this.state.comment,
				mentions: this.state.mentions,
			},
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({
					saving: false,
					comment: "",
					files: [],
					mentions: [],
				});
				this.props.onCreate(result.data.comment);
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	eventPosted(statusColumn, nextStatusIndex, result) {
		if (result.data.error) {
			toastr.error(result.data.error);
			return;
		}
		if (statusColumn && nextStatusIndex !== null) {
			/*
        Update column
      */
			this.updateValue(this.props.row, statusColumn, { value: nextStatusIndex }, false);
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].type == "person") {
				this.updateValue(this.props.row, this.props.board.columns[i], { value: [store.getState().user.id] }, true);
			}
		}
		this.setState({
			saving: false,
			comment: "",
			files: [],
			mentions: [],
		});
		this.props.onCreate(result.data.event, result.data.ticket);
	}

	updateValue(row, column, data, onlyempty = true) {
		// Update single row
		let found = false;
		for (let i = 0; i < row.values.length; i++) {
			if (row.values[i].column_id == column.id) {
				found = true;
				row.values[i] = Object.assign(row.values[i], data);
				break;
			}
		}

		if (!found) {
			row.values.push(Object.assign({ column_id: column.id }, data));
		} else if (onlyempty) {
			return;
		}

		if (this.props.onUpdateRow) this.props.onUpdateRow(row);

		API.post("/api/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/values.json", data, {
			params: {},
		})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				if (this.props.onUpdateRow) this.props.onUpdateRow(result.data.row);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateTicket() {
		this.setState({ saving: true });
		const statusColumn = this.getTicketStatusColumn();
		API.post("/api/tickets/" + this.props.ticket.id + "/events.json", {
			message: this.state.comment,
			files: this.state.files,
		})
			.then(this.eventPosted.bind(this, statusColumn, this.state.nextStatusIndex))
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	getTicketColumn() {
		for (let i = 0; i < this.props.board.columns.length; i++) {
			for (let s = 0; s < this.props.row.values.length; s++) {
				if (this.props.row.values[s].column_id == this.props.board.columns[i].id && this.props.row.values[s].value) {
					for (let x = 0; x < this.props.row.values[s].value.length; x++) {
						if (this.props.row.values[s].value[x] == this.props.ticket.id) {
							return this.props.board.columns[i];
						}
					}
				}
			}
		}
		return null;
	}

	getTicketStatusColumn() {
		const ticketColumn = this.getTicketColumn();
		if (ticketColumn) {
			for (let i = 0; i < this.props.board.columns.length; i++) {
				if (this.props.board.columns[i].id == ticketColumn.connected_column_id) {
					return this.props.board.columns[i];
				}
			}
		}
		return null;
	}

	setNextStatus(index) {
		this.setState({ nextStatusIndex: index, statusPopoverActive: false });
	}

	getTicketButton() {
		const ticketStatusColumn = this.getTicketStatusColumn();
		let currentStatus = null;
		if (ticketStatusColumn) {
			if (this.state.nextStatusIndex !== null) {
				currentStatus = this.state.nextStatusIndex;
			} else {
				currentStatus = 2;
				for (let i = 0; i < this.props.row.values.length; i++) {
					if (this.props.row.values[i].column_id == ticketStatusColumn.id) {
						if (this.props.row.values[i].value !== null && currentStatus != this.props.row.values[i].value) {
							this.state.nextStatusIndex = currentStatus;
							this.setState({ nextStatusIndex: currentStatus });
						}
					}
				}
			}
		}
		return (
			<ButtonGroup segmented>
				<Button onClick={this.updateTicket.bind(this)} loading={this.state.saving}>
					{this.props.t("comment.action.send_with_status", "Skicka och sätt status")}
				</Button>
				{ticketStatusColumn ? (
					<Popover
						fixed
						active={this.state.statusPopoverActive}
						activator={
							<div
								onClick={() => {
									this.setState({
										statusPopoverActive: !this.state.statusPopoverActive,
									});
								}}
								style={{
									backgroundColor: ticketStatusColumn.options.statuses.find((s) => s.id == currentStatus).color,
								}}
							>
								{ticketStatusColumn.options.statuses.find((s) => s.id == currentStatus).label}
							</div>
						}
						onClose={() => {
							this.setState({ statusPopoverActive: false });
						}}
					>
						{ticketStatusColumn.options.statuses.map((status) => (
							<div
								key={status?.id}
								onClick={this.setNextStatus.bind(this, status.id)}
								style={{
									padding: 5,
									cursor: "pointer",
									backgroundColor: status.color,
									color: "#fff",
								}}
							>
								{status.label}
							</div>
						))}
					</Popover>
				) : null}
			</ButtonGroup>
		);
	}

	enterEvent(e) {
		if (this.state.focused && this.props.submitOnEnter) {
			e.preventDefault();
			e.stopPropagation();
			this.saveForm();
		}
	}

	render() {
		const SubmiteButton = this.props.SubmitButton;
		const users = (() => {
			if (this.props.board?.type === "public") {
				return store.getState().users.filter((user) => user.id !== store.getState().user.id);
			}
			return this.props.board?.members?.filter((member) => member.user.id != store.getState().user.id).map((mem) => mem.user) || [];
		})();

		const options = users.map((user) => ({
			onAction: this.addMention.bind(this, user),
			content: (
				<LegacyStack>
					<MemberAvatar user={user} />
					<LegacyStack vertical spacing="none">
						<div>{user.title || user.name}</div>
						<Text variation="subdued">{user.email}</Text>
					</LegacyStack>
				</LegacyStack>
			),
		}));

		return (
			<div style={{ width: "100%" }} id={this.state.randomid}>
				<TextField
					multiline={!this.props.submitOnEnter || this.props.multiline}
					placeholder={
						this.props.ticket
							? this.props.t("comment.placeholder1", "Svara...")
							: this.props.chat
							? this.props.t("comment.placeholder2", "Meddelande...")
							: this.props.t("comment.placeholder", "Kommentar...")
					}
					autoFocus={!!this.props.initialTabIndex || this.props.autoFocus}
					focused={!!this.props.initialTabIndex || this.props.autoFocus}
					onFocus={() => {
						this.setState({ focused: true });
					}}
					onBlur={() => {
						this.setState({ focused: false });
					}}
					suffix={
						<div className="commentInput-suffix" spacing="extraTight" style={{ display: "flex", alignItems: "center", gap: "0.6250rem" }}>
							<Tooltip preferredPosition="above" content={this.props.t("comment.actions.upload", "Ladda upp fil")}>
								<Button
									plain
									size="slim"
									onClick={() => {
										this.setState({ openFileDialog: true });
									}}
								>
									<Icon color="skyDark" source={AttachmentMajor} />
								</Button>
							</Tooltip>
							{!this.props.ticket && options.length > 0 ? (
								<Popover
									fixed
									fluidContent
									fullHeight
									active={this.state.memberPopoverActive}
									activator={
										<Tooltip preferredPosition="above" content="Nämn en medarbetare">
											<Button
												plain
												size="slim"
												onClick={() => {
													this.setState({
														memberPopoverActive: !this.state.memberPopoverActive,
													});
												}}
											>
												<Icon color="skyDark" source={MentionMajor} />
											</Button>
										</Tooltip>
									}
									onClose={() => {
										this.setState({ memberPopoverActive: false });
									}}
								>
									<ActionList items={options} />
								</Popover>
							) : null}
							{this.props.comment && this.props.saveable && (
								<Button
									onClick={this.saveForm.bind(this)}
									loading={this.state.saving || this.state.loading}
									size="slim"
									disabled={!this.state.files.length && !this.state.comment}
									primary
								>
									{this.props.t("common.actions.save", "Spara")}
								</Button>
							)}
							{(!this.props.ticket && !this.props.onChange) || SubmiteButton ? (
								SubmiteButton ? (
									<SubmiteButton
										onClick={this.saveForm.bind(this)}
										loading={this.state.saving || this.props.loading}
										disabled={!this.state.files.length && !this.state.comment}
									/>
								) : (
									<Button
										onClick={this.saveForm.bind(this)}
										loading={this.state.saving || this.props.loading}
										size="slim"
										disabled={!this.state.files.length && !this.state.comment}
										primary
									>
										{this.props.t("common.actions.ok", "OK")}
									</Button>
								)
							) : null}
						</div>
					}
					value={this.state.comment}
					onChange={this.updateComment.bind(this)}
				/>
				<div style={{ width: 50, height: 50, display: "none" }}>
					<DropZone
						openFileDialog={this.state.openFileDialog}
						onFileDialogClose={() => {
							this.setState({ openFileDialog: false });
						}}
						onDrop={(files, acceptedFiles, rejectedFiles) => {
							for (let i = 0; i < acceptedFiles.length; i++) {
								const reader = new FileReader();
								reader.onload = this.addFile.bind(this, acceptedFiles[i]);
								reader.readAsDataURL(acceptedFiles[i]);
							}
						}}
					>
						<DropZone.FileUpload
							actionTitle={this.props.t("common.upload.actions.choose", "Välj fil")}
							actionHint={this.props.t("common.upload.actions.helptext", "Eller dra och släpp en fil")}
						/>
					</DropZone>
				</div>
				{!!this.state.mentions.length && (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "0.3125rem",
							wrap: "nowWrap",
							marginTop: "12px",
						}}
					>
						{this.state.mentions.map((user, index) => (
							<MemberAvatar key={index} user={user} removeMention={this.removeMention.bind(this, index)} />
						))}
					</div>
				)}

				{this.state.files.length ? (
					<div style={{ paddingTop: 5 }}>
						{this.state.files.map((file, index) => (
							<Attachment
								editable
								onRemove={this.removeFile.bind(this, index)}
								onChange={this.updateFile.bind(this, index)}
								key={index}
								file={file}
							/>
						))}
					</div>
				) : null}
				{this.props.ticket ? this.getTicketButton() : null}
				{/* <KeypressListener handler={this.enterEvent.bind(this)} keyCode={13} keyEvent="keydown" /> */}
			</div>
		);
	}
}
export default withTranslation(["comment", "common"], { withRef: true })(CommentInput);
