import React, { useCallback, useEffect, useRef, useState } from "react";
import { toastr } from "../components/toastr";
import API from "../API";
import { store } from "../store";
import debugSocket from "../Utilities/debugSocket";
import { getRefreshToken } from "../Utilities/Utilities";

const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);
	const [loading, setLoading] = useState(false);
	const timeout = useRef(null);

	const unreadNotifications = [...notifications].filter((n) => n.unread);
	const mentionedNotifications = [...notifications].filter((n) => n.content.includes("nämnde dig"));

	const fetch = useCallback(async () => {
		const params = {
			limit: 20,
		};
		const result = await API.get("/api/notifications.json", { params })
			.then((result) => {
				setNotifications(result.data?.notifications);
				return result.data?.notifications;
			})
			.catch((error) => {
				toastr.error(error);
			})
			.finally(() => {
				setLoading(false);
				clearTimeout(timeout.current);
				timeout.current = setTimeout(fetch, 100000);
			});

		return result;
	}, []);

	const readSingelAction = useCallback((id) => {
		setNotifications((notifications) => {
			const index = notifications.findIndex((n) => n.id == id);
			const notification = notifications[index];
			if (index >= 0) {
				notifications.splice(index, 1, { ...notification, unread: false });
			}

			return [...notifications];
		});

		API.post(`/api/notifications/read/singel/${id}.json`, {})
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
				}
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}, []);

	useEffect(() => {
		setLoading(true);
		fetch();

		return () => {
			clearTimeout(timeout.current);
		};
	}, [fetch]);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const notificationId = searchParams.get("notification_id");
		const chatId = searchParams.get("chat_id");
		if (notificationId) readSingelAction(notificationId);

		if (chatId) {
			const refreshToken = getRefreshToken();
			const accId = searchParams.get("acc_id");
			const accountId = accId || store.getState().account.id;

			window.location = `https://chatdirect.sellfinity.com/${accountId}/chat/${chatId}?refresh_token=${refreshToken}&acc_id=${accountId}`;
		}

		window.socket?.on("notification.read", (notification) => {
			debugSocket("notification.read", notification);
			setNotifications((notifications) => {
				const index = notifications.findIndex((n) => n.id == notification.id);
				if (index >= 0) {
					notifications.splice(index, 1, notification);
				}

				return [...notifications];
			});
		});

		return () => {
			clearTimeout(timeout.current);
		};
	}, [readSingelAction]);

	const readAllAction = () => {
		setNotifications((notises) => notises?.map((n) => ({ ...n, unread: false })));

		API.post("/api/notifications/read/" + notifications[0].id + ".json", {})
			.then((result) => {})
			.catch((error) => {});
	};

	const refresh = () => fetch();

	return (
		<NotificationContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				notifications,
				notifications_unread: unreadNotifications,
				notifications_mentioned: mentionedNotifications,
				loading,
				refresh,
				readAllAction,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationContext;
