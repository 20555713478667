import {
	IndexTable as PolarisIndexTable,
	LegacyCard,
	useIndexResourceState,
	Pagination,
	Spinner,
	EmptyState,
	HorizontalStack,
} from "@shopify/polaris";
import type { ComplexAction, PageProps, IndexFiltersProps as PolarisIndexFiltersProps } from "@shopify/polaris";
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import httpBuildQuery from "http-build-query";
import { debounce, uniqBy } from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";
import { colorToRgba } from "src/js/Colors";
import { store } from "../../store";
import API from "../../API";
import IndexTableRow from "./IndexTableRow";
import { toastr } from "../toastr";
import IndexFilters from "./IndexFilters";
import { columnsToHeadings, columnsToSortOptions, generateTotalRow, getDirectionFromSort, getHandleFromSort, getSearchParams } from "./Utilities";

import type { AppliedFilters, BulkActions, Filters, IndexTableColumn, PromotedBulkActions, SavedSearch, SortValue } from "./types";
import StatsBoxWrapper from "../statsBoxes";
import IconWithBackround from "../IconWithBackround";
import LimitField from "./LimitField";
import Page from "../page";
import useQuery from "src/js/hooks/useQuery";
import getDefaultFilters from "src/js/components/IndexTable/getDefaultFilters";
import { ItemCardWrapper } from "../ItemCard/styles";
import Button from "../Button";

type IndexTableProps = {
	columns: IndexTableColumn[];
	resourceUrl: string;
	resourceHandle: string;
	filterKey?: string;
	params?: any;
	resourceName: { singular: string; plural: string };
	renderCell?: (row: any, column: any) => any;
	onUpdateParams?: (params: any) => void;
	onItemsFetched?: (items: any[]) => void;
	onResultFetched?: (items: any[]) => void;
	onClickRow?: (row: any) => void;
	onClick?: (row: any) => void;
	filters?: Filters;
	noHeader?: boolean;
	selector?: any;
	savedSearchHandle?: string;
	defaultSavedSearches?: SavedSearch[];
	limit?: number;
	noPagination?: boolean;
	history: any;
	backAction?: PageProps["backAction"];
	title?: PageProps["title"];
	primaryAction?: PageProps["primaryAction"];
	inlinePrimaryAction?: PageProps["primaryAction"];
	secondaryActions?: PageProps["secondaryActions"]; // MenuActionDescriptor[] | React.ReactNode;
	actionGroups?: PageProps["actionGroups"];
	noTopPagination?: boolean;
	extraHeader?: any;
	footer?: any;
	extraFooter?: any;
	onFiltersChange?: (filters: any[]) => void;
	defaultSort?: string;
	emptyState?: any;
	promotedBulkActions?: PromotedBulkActions;
	bulkActions?: BulkActions;
	defaultFilters?: any[];
	additionalMetadata?: PageProps["additionalMetadata"];
	titleMetadata?: PageProps["titleMetadata"];
	subtitle?: PageProps["subtitle"];
	onSelectionChange?: (selection: any[]) => void;
	onSelectionChangeInclRow?: (selection: any[]) => void;
	items?: any[];
	setRefreshHandler?: (refresh: any) => any;
	showTotal?: boolean;
	image?: string;
	loading?: boolean;
	stats?: any[];
	titleHidden?: boolean;
	selectOnRow?: boolean;
	selectable?: boolean;
	idHandle?: string;

	itemPadding?: string | number;
	additionalAction?: any;

	urlParamsEnabled?: boolean;
	tabsEnabled?: boolean;
	searchFiltersEnabled?: boolean;

	filterPrimaryAction?: PolarisIndexFiltersProps["primaryAction"];
};

const IndexTable = React.forwardRef(
	(
		{
			columns,
			resourceUrl,
			onItemsFetched,
			onResultFetched,
			resourceHandle,
			filterKey = resourceHandle,
			params: propsParams = {},
			resourceName,
			renderCell,
			onUpdateParams,
			promotedBulkActions = [],
			bulkActions = [],
			emptyState,
			onClickRow,
			onClick,
			filters = [],
			noHeader,
			savedSearchHandle = resourceHandle,
			defaultSavedSearches,
			limit: propsLimit = 50,
			noPagination,
			history,
			backAction,
			title,
			primaryAction,
			inlinePrimaryAction,
			secondaryActions,
			actionGroups,
			noTopPagination,
			extraHeader,
			footer,
			extraFooter,
			onFiltersChange,
			defaultSort: propsDefaultSort,
			defaultFilters,
			additionalMetadata,
			titleMetadata,
			subtitle,
			onSelectionChange,
			onSelectionChangeInclRow,
			items: propsItems,
			setRefreshHandler,
			loading: propsLoading,
			stats,
			// extra componen to ther right of the filters/search
			selector,
			// show a bottom row with the totals of each column (only works with number) EXPERIMENTAL
			showTotal,
			// image for the empty state
			image = "/assets/images/empty_state/NoResults.png",
			titleHidden,
			selectOnRow, // if true, you can select a row by clicking on it
			selectable = !!(promotedBulkActions?.length || bulkActions?.length || onSelectionChange || onSelectionChangeInclRow || selectOnRow),
			idHandle: propsIdHandle = "id",
			itemPadding,
			additionalAction: propsAdditionalAction,
			filterPrimaryAction,

			urlParamsEnabled = true,
			tabsEnabled = true,
			searchFiltersEnabled = true,
		}: IndexTableProps,
		ref
	) => {
		const getDefaultSortValue = (): string => {
			if (propsDefaultSort) return propsDefaultSort;

			return `${String(columns.find((column) => column.sortable)?.handle || "ID")}_${"DESC"}`;
		};

		const defaultSortValue: string = getDefaultSortValue();
		const params = getSearchParams(history.location.search, { defaultSortValue, urlParamsEnabled });
		// const [items, setItems] = useState<any[]>(propsItems || []);
		// const [count, setCount] = useState<number>(0);
		const [limit, setLimit] = useState<number>(propsLimit);
		// const [loading, setLoading] = useState<boolean>(Boolean(resourceUrl));
		const [searchValue, setSearchValue] = useState<string>(params?.searchValue || "");
		const [appliedFilters, setAppliedFilters] = useState<AppliedFilters>(
			(params?.appliedFilters?.length ? params?.appliedFilters : defaultFilters) || []
		);
		const [sortValue, setSortValue] = useState<SortValue>(params?.sortValue || "");
		const [offset, setOffset] = useState<number>(params?.offset || 0);
		const [count, setCount] = useState<number>(0);
		const controller = useRef(new AbortController());
		const indexFiltersRef = useRef<any>(null);
		const idHandle = propsIdHandle || "id";
		const onClickFunc = onClick || onClickRow;

		// useDeepCompareEffect(() => {
		// 	if (propsItems) setItems(propsItems || []);

		// 	return () => {};
		// }, [propsItems]);

		// const updateFromUrlParams = useCallback(() => {
		// 	const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(history.location.search, { defaultSortValue });

		// 	setOffset(offset);
		// 	setSortValue(sortValue);
		// 	setSearchValue(searchValue);
		// 	setAppliedFilters(appliedFilters);
		// }, []);

		// useEffect(() => {
		// 	updateFromUrlParams();
		// }, [updateFromUrlParams]);

		const fetchParams = useMemo(
			() => ({
				...(propsParams || {}),
				...(appliedFilters?.reduce((acc: any, { key, value }) => ({ ...acc, [key]: value }), {}) || {}),
				limit,
				q: searchValue,
				offset,
				sort: sortValue,
			}),
			[offset, appliedFilters, searchValue, sortValue, limit, propsParams]
		);

		const handleFetch = useCallback(async () => {
			controller.current.abort();
			controller.current = new AbortController();

			if (onUpdateParams) onUpdateParams(Object.assign({}, fetchParams));

			if (!resourceUrl) return;

			// setLoading(true);

			return await API.get(resourceUrl, { params: fetchParams, signal: controller.current.signal })
				.then((result) => {
					if (onItemsFetched) {
						onItemsFetched(result.data[resourceHandle]);
					}
					if (onResultFetched) {
						onResultFetched(result.data);
					}

					return result.data;
				})
				.catch((error) => {
					toastr.error(error);
					throw error;
				});
		}, [resourceUrl, resourceHandle, onUpdateParams, onItemsFetched, onResultFetched, fetchParams]);

		const queryKeyParams = { ...fetchParams };
		delete queryKeyParams.limit;
		const queryKey = [resourceUrl, queryKeyParams];
		const {
			data,
			isFetching: loading,
			refetch,
		} = useQuery({
			queryKey,
			queryFn: handleFetch,
			enabled: !propsItems,
			initialData: propsItems || null,
			onSuccess: (data) => {
				const items = data?.[resourceHandle] || [];
				setCount(data?.count || items?.length || 0);
			},
		});

		const items =
			propsItems?.filter((i) => {
				const search = new RegExp(searchValue, "i");
				return search.test(i.title) || search.test(i.content) || search.test(i.name);
			}) ||
			data?.[resourceHandle] ||
			[];

		const onSortChange = (sort: SortValue) => {
			setSortValue(sort);
			setOffset(0);
			updateSearch(appliedFilters, searchValue, sort, 0);
		};

		const changeTab = (tab: any) => {
			setAppliedFilters(tab.filters);
			setSearchValue(tab.search);
			setOffset(0);
			clearSelection();

			// this.setState({ appliedFilters: tab.filters, searchValue: tab.search, offset: 0 });
			updateSearch(tab.filters, tab.search, sortValue, 0);
		};

		const updateSearch = useCallback(
			(filters: AppliedFilters, search: string, sorting: SortValue, offset: number) => {
				const params = {
					offset,
					...(filters?.reduce((acc: any, { key, value }) => ({ ...acc, [key]: value }), {}) || {}),
					q: search,
					sort: sorting,
				};

				if (onUpdateParams) onUpdateParams(Object.assign({}, params));

				if (urlParamsEnabled) history.replace(history.location.pathname + "?" + httpBuildQuery(params).replace(/\+/g, "%20"));
				else {
					const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(httpBuildQuery(params).replace(/\+/g, "%20"), {
						defaultSortValue,
					});
					setSearchValue(searchValue);
					setAppliedFilters(appliedFilters);
					setSortValue(sortValue);
					setOffset(offset);
				}
			},
			[history, onUpdateParams, urlParamsEnabled, defaultSortValue]
		);

		const handleSearch = useMemo(
			() =>
				debounce(
					(searchValue) => {
						// Clear filters and select first tab ("alla") to search through all tabs
						setAppliedFilters([]);
						setOffset(0);
						// indexFiltersRef.current?.setSelectedTabIndex(0);

						updateSearch([], searchValue, sortValue, 0);
					},
					100,
					{ maxWait: 1000, leading: false, trailing: true }
				),
			[sortValue, updateSearch]
		);

		const handleSearchChange = (searchValue: string) => {
			setSearchValue(searchValue);
			setOffset(0);
			handleSearch(searchValue);
		};

		const onChangeFilters = (appliedFilters: AppliedFilters) => {
			setAppliedFilters(appliedFilters);
			setOffset(0);

			if (onFiltersChange) {
				onFiltersChange(appliedFilters);
			}
			updateSearch(appliedFilters, searchValue, sortValue, 0);
			clearSelection();
		};

		const handleFiltersClearAll = () => {
			setAppliedFilters([]);
			setOffset(0);
			setSearchValue("");

			if (onFiltersChange) {
				onFiltersChange(appliedFilters);
			}

			updateSearch([], "", sortValue, 0);
		};

		const goPrevious = () => {
			const nextOffset = offset - limit;

			if (offset > 0) {
				setOffset(nextOffset);
				updateSearch(appliedFilters, searchValue, sortValue, nextOffset);
			}
		};

		const goNext = () => {
			const nextOffset = offset + limit;
			if (nextOffset < count) {
				setOffset(nextOffset);

				updateSearch(appliedFilters, searchValue, sortValue, nextOffset);
			}
		};

		const { selectedResources, allResourcesSelected, handleSelectionChange, clearSelection } = useIndexResourceState(items);

		useDeepCompareEffect(() => {
			if (onSelectionChange) {
				onSelectionChange(selectedResources);
			}
			if (onSelectionChangeInclRow) {
				onSelectionChangeInclRow(items.filter((item) => selectedResources.includes(item?.[idHandle])));
			}
		}, [selectedResources]);

		useDeepCompareEffect(() => {
			// const { offset, sortValue, searchValue, appliedFilters } = getSearchParams(history.location.search, { defaultSortValue });
			if (setRefreshHandler) setRefreshHandler(handleFetch);

			// handleFetch(appliedFilters, searchValue, sortValue, offset);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [resourceUrl, history.location.search, limit]);

		useImperativeHandle(ref, () => ({
			getItems: () => {
				return items;
			},
			// setItems: (items: any[]) => {
			// 	return setItems(items);
			// },
			clearSelection: () => {
				clearSelection();
			},
			getSelection: () => {
				return selectedResources;
			},
			getSelectedItems: () => {
				return selectedResources;
			},
			getParams: () => {
				return fetchParams;
			},
			getCount: () => {
				return count;
			},
			refetch: () => {
				if (params.offset === 0) refetch();
				else setOffset(0);
			},
			reload: () => {
				if (params.offset === 0) refetch();
				else setOffset(0);
			},
			// handleFetch: ({ appliedFilters = [], searchValue = "", sortValue = "", offset = 0 }) =>
			// 	handleFetch(appliedFilters, searchValue, sortValue, offset),
			onChangeFilters: (appliedFilters = []) => onChangeFilters(appliedFilters),
		}));

		const handleOnClick = (item: any) => {
			const onClickFunc = onClick || onClickRow;
			if (onClickFunc && !selectOnRow) {
				onClickFunc(item);
				return;
			}
			handleSelectionChange("single" as any, !selectedResources?.includes(item?.[idHandle]), item?.[idHandle]);
		};

		// const handleManuallyToggleSelect = (item) => {
		// 	console.log("item.id:", item.id);
		// 	console.log("selectedResources:", selectedResources);
		// 	console.log("!selectedResources?.includes(item.id):", !selectedResources?.includes(item.id));
		// 	handleSelectionChange("single" as any, !selectedResources?.includes(item.id), item.id);
		// };

		const rowMarkup = items?.map((item, index) => {
			const id = item?.[idHandle];
			return (
				<IndexTableRow
					key={id}
					selectedResources={selectedResources}
					// toggleSelect={handleManuallyToggleSelect}
					columns={columns}
					item={item}
					renderCell={renderCell}
					index={index}
					id={id}
					onClick={handleOnClick}
				/>
			);
		});

		const hasPrevious = offset > 0;
		const hasNext = offset + limit < count;

		const allFilters = uniqBy([...(filters || []), ...getDefaultFilters(filterKey || "")], "key");

		const filterComponent = !noHeader && (
			<IndexFilters
				ref={indexFiltersRef}
				sortOptions={columnsToSortOptions(columns, propsDefaultSort)}
				resource={savedSearchHandle}
				resourceName={resourceName}
				defaultSavedSearches={defaultSavedSearches}
				onChange={changeTab}
				filters={allFilters?.map((filter) => ({
					...filter,
					disabled: Object.entries(propsParams)?.find(([key, value]) => key === filter.key && value),
				}))}
				appliedFilters={appliedFilters}
				onChangeFilters={onChangeFilters}
				search={searchValue}
				onSearchChange={handleSearchChange}
				loading={loading}
				onSortChange={onSortChange}
				sortValue={sortValue}
				defaultFilters={defaultFilters}
				handleFiltersClearAll={handleFiltersClearAll}
				selected={history?.location?.state?.tab}
				tabsEnabled={tabsEnabled}
				searchFiltersEnabled={searchFiltersEnabled}
				primaryAction={filterPrimaryAction}
			/>
		);

		const extendedPromotedBulkActions = promotedBulkActions.map((action) => {
			return {
				...action,
				content: action.loading ? (
					<HorizontalStack gap="1" align="center">
						{action.content} {action.loading && <Spinner size="small" />}
					</HorizontalStack>
				) : (
					action.content
				),
				onAction: action.onAction ? () => action?.onAction?.(selectedResources) : null,
			};
		});

		const extendedBulkActions = bulkActions.map((action) => {
			return {
				...action,
				content: action.loading ? (
					<HorizontalStack gap="1" align="center">
						{action.content} {action.loading && <Spinner size="small" />}
					</HorizontalStack>
				) : (
					action.content
				),
				onAction: action.onAction ? () => action.onAction?.(selectedResources) : null,
			};
		});

		const statsHeader = stats && (
			<StatsBoxWrapper
				style={{ justifyContent: "flex-start" }}
				perRow="auto-fill"
				items={stats?.map((stat) => ({
					title: stat.loading ? <Spinner size="small" /> : stat.title,
					text: stat.text,
					helpText: stat.helpText,
					icon: stat.icon && (
						<IconWithBackround icon={React.cloneElement(stat.icon, { color: stat.color })} backgroundColor={colorToRgba(stat.color, 0.2)} />
					),
				}))}
			/>
		);

		const additionalAction = (() => {
			if (!propsAdditionalAction) return null;
			if (typeof propsAdditionalAction === "function") return propsAdditionalAction();
			if (React.isValidElement(propsAdditionalAction)) return propsAdditionalAction;

			const { content, onAction, styles, ...rest } = propsAdditionalAction || {};

			return (
				<div style={{ paddingLeft: "8px", ...(styles || {}) }}>
					<Button {...rest} onClick={onAction}>
						{content}
					</Button>
				</div>
			);
		})();

		return (
			<Wrapper
				data-loading={loading}
				data-clickable={!!onClickFunc}
				data-selectable={!!selectable}
				showTablewHeader={columns?.some((c) => c.label) || selectable}
				itemPadding={itemPadding}
				titleHidden={titleHidden}
			>
				<Page
					titleHidden={titleHidden}
					fullWidth
					backAction={backAction}
					title={title}
					primaryAction={primaryAction}
					inlinePrimaryAction={inlinePrimaryAction}
					secondaryActions={secondaryActions as any}
					actionGroups={actionGroups}
					additionalMetadata={additionalMetadata}
					titleMetadata={titleMetadata}
					subtitle={subtitle}
					pagination={
						!noPagination && !noTopPagination
							? {
									hasPrevious,
									onPrevious: goPrevious,
									hasNext,
									onNext: goNext,
							  }
							: undefined
					}
				>
					{statsHeader || null}
					{extraHeader || null}
					<LegacyCard>
						{selector || additionalAction ? (
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ flex: 1 }}>{filterComponent}</div>
								{selector}
								{additionalAction}
							</div>
						) : (
							filterComponent
						)}
						<PolarisIndexTable
							//! when this is false, you can't even click the row to trigger the onClickRow
							// selectable={selectable}
							resourceName={resourceName}
							itemCount={items.length}
							selectedItemsCount={allResourcesSelected ? "All" : selectedResources.length}
							onSelectionChange={handleSelectionChange}
							headings={columnsToHeadings(columns)}
							promotedBulkActions={extendedPromotedBulkActions as PromotedBulkActions}
							bulkActions={extendedBulkActions as BulkActions}
							emptyState={
								loading || propsLoading ? (
									<EmptyState heading={`Hämtar ${resourceName.plural}`} image={image}>
										<Spinner size="small" />
									</EmptyState>
								) : (
									emptyState || (
										<EmptyState
											heading={`Inga ${resourceName.plural} hittades`}
											action={primaryAction as ComplexAction}
											secondaryAction={
												(!noHeader && {
													content: "Rensa alla filters",
													onAction: handleFiltersClearAll,
												}) ||
												undefined
											}
											image={image}
										>
											{!noHeader && <p>Prova att ändra filter eller sökning</p>}
										</EmptyState>
									)
								)
							}
							loading={loading}
							hasMoreItems={store.getState().user.roles.indexOf("ROLE_SUPER_ADMIN") >= 0 && hasNext}
							sortable={columns?.map((column) => column.sortable || false)}
							// eslint-disable-next-line @typescript-eslint/no-unused-vars
							onSort={(headingIndex, dir) => {
								const direction = getDirectionFromSort(sortValue) === "ASC" ? "ascending" : "descending";

								// flip direction
								onSortChange(`${columns[headingIndex].handle.toUpperCase()}_${direction === "ascending" ? "DESC" : "ASC"}`);
							}}
							sortDirection={getDirectionFromSort(sortValue) === "ASC" ? "ascending" : "descending"}
							sortColumnIndex={columns.findIndex((column) => column.handle === getHandleFromSort(sortValue))}
							hasZebraStriping
						>
							{rowMarkup}
							{showTotal && <IndexTableRow key="total" total columns={columns} item={generateTotalRow(items)} renderCell={renderCell} />}
						</PolarisIndexTable>
					</LegacyCard>

					{(!noPagination || footer) && (
						<Footer>
							<LimitField
								value={limit}
								onChange={(v: number) => {
									setLimit(v);
								}}
							/>
							{footer || (
								<>
									{"Visar " +
										(offset + 1) +
										"-" +
										(offset + items.length) +
										" av " +
										count +
										" " +
										(count == 1 ? resourceName.singular : resourceName.plural)}
									<Pagination hasPrevious={hasPrevious} onPrevious={goPrevious} hasNext={hasNext} onNext={goNext} />{" "}
								</>
							)}
						</Footer>
					)}
					{extraFooter}
				</Page>
			</Wrapper>
		);
	}
);

export default IndexTable;

// class="Polaris-IndexTable__TableCell Polaris-IndexTable__TableCell--first"

const Wrapper = styled.div<{ showTablewHeader?: boolean; itemPadding?: string | number; titleHidden?: boolean }>`
	//make the columns abit wider to make it less cramped
	/* .Polaris-IndexTable__TableHeading:not(.Polaris-IndexTable__TableHeading--first) {
		min-width: 225px;
	} */

	th,
	td {
		padding: 1.6rem;
		white-space: nowrap;
		text-align: left;
		transition: background-color 0.2s ease-in-out;
	}

	.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions > .Polaris-IndexTable__StickyTable {
		position: absolute;
		white-space: nowrap;
	}

	.Polaris-IndexTable__SelectAllActionsWrapper {
		background: transparent;
	}

	.Polaris-IndexTable-Checkbox--expanded {
		height: unset;
	}

	${(props) => {
		if (!props["data-selectable"]) {
			return css`
				.Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first,
				.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
					display: none;
				}

				//get the second column to be a bit more to the left
				.Polaris-IndexTable__TableRow td:nth-child(2):not(.Polaris-IndexTable__TableCell--first) {
					padding-left: 1.6rem;
				}

				.Polaris-IndexTable__TableHeading td:nth-child(2):not(.Polaris-IndexTable__TableHeading--first) {
					padding-left: 1.6rem;
				}

				.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second {
					padding-left: 1.6rem;
				}
			`;

			// return css`
			// 	.Polaris-IndexTable__TableCell.Polaris-IndexTable__TableCell--first > *,
			// 	.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first > * {
			// 		display: none;
			// 	}
			// `;
		} else {
			return css`
				.Polaris-IndexTable__IndexTableWrapper.Polaris-IndexTable__IndexTableWrapperWithBulkActions
					.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--second {
					visibility: hidden;
				}
			`;
		}
	}};

	${(props) => {
		if (!props.showTablewHeader) {
			return css`
				table thead tr th {
					padding: 0;

					* {
						display: none;
					}
				}
			`;
		}
	}};

	${(props) => {
		if (props.itemPadding) {
			return css`
				&&& {
					.Polaris-IndexTable__TableCell {
						padding: ${typeof props.itemPadding === "number" ? `${props.itemPadding}px` : props.itemPadding};
					}
				}
			`;
		}
	}};

	${(props) => {
		if (props.titleHidden) {
			return css`
				&&& {
					margin-top: 1rem;
				}
			`;
		}
	}};

	${ItemCardWrapper} {
		background-color: unset;
		padding: unset;

		&:hover {
			filter: unset;
		}
	}

	.Polaris-IndexTable__TableCell {
		/* &,
		* {
			transition: background-color 100ms, background 100ms, color 100ms, all 100ms;
		} */
		b {
			font-weight: 600;
		}

		a {
			text-decoration: underline;
		}
	}

	th.Polaris-IndexTable__TableHeading.Polaris-IndexTable__TableHeading--first {
		width: 80px;

		.Polaris-IndexTable__ColumnHeaderCheckboxWrapper {
			padding-left: 1.6rem;
		}
	}
	.Polaris-BulkActions__ButtonGroupWrapper {
		padding-left: 1.6rem;
		background: var(--main1);
	}

	.Polaris-IndexTable__SelectAllActionsWrapper {
		padding-left: 2.6rem;
		padding-top: 1.1rem;

		.Polaris-CheckableButton__Label {
			margin-left: 24px;
		}
	}

	.Polaris-AlphaFilters__Container {
		.Polaris-Button.Polaris-Button--primary.Polaris-Button--sizeMicro {
			padding: 0.35rem 0.75rem;
		}
	}

	.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--subdued.Polaris-IndexTable__TableRow--disabled {
		background: var(--primary);

		td {
			padding-block: 0.5rem;

			.Polaris-IndexTable-Checkbox__TableCellContentContainer {
				display: none;
			}
		}
	}

	.Polaris-IndexTable__EmptySearchResultWrapper {
		img[src="/assets/images/empty_state/NoResults.png"] {
			/* height: 250px; */
			height: 25vh;
		}
	}

	.Polaris-IndexTable__StickyTableHeader--isSticky {
		padding-top: 56px;

		.Polaris-IndexTable__TableHeading {
			padding-left: 1.6rem;
		}

		.Polaris-IndexTable__TableHeading:first-child {
			padding-left: 2.6rem;

			.Polaris-IndexTable--stickyTableHeadingSecondScrolling {
				padding-left: 6px;
			}
		}
	}

	tbody {
		transition: opacity 0.2s ease-in-out;
	}

	/* .Polaris-IndexTable__TableRow__child {
		padding-left: 2.6rem;
	} */

	${(props) => {
		if (props["data-loading"] === true || props["data-loading"] === "true") {
			return css`
				.Polaris-IndexTable__EmptySearchResultWrapper {
					padding-top: 10rem;
				}

				tbody {
					opacity: 0.6;
				}
			`;
		}
	}}

	${(props) => {
		if (props["data-clickable"] === true || props["data-clickable"] === "true") {
			return css`
				.Polaris-IndexTable__TableRow--unclickable {
					cursor: pointer;
					pointer-events: all;
				}
			`;
		}
	}}


	.Polaris-Filters__AddFilter {
		background: var(--tertiary);

		svg {
			height: 20px;
			width: 20px;

			path {
				fill: var(--accent);
			}
		}
	}
`;

const Footer = styled.div`
	min-height: 100px;
	padding-top: 20px;
	display: flex;
	color: "#ffffff80";
	flex-direction: column;
	align-items: center;
	gap: 1rem;
`;
