import React, { Component } from "react";
import {
	LegacyStack,
	ActionList,
	FormLayout,
	Pagination,
	LegacyCard,
	Icon,
	Checkbox,
	Tooltip,
	TextField,
	Text,
	Button,
	ButtonGroup,
	Spinner,
	Label,
} from "@shopify/polaris";
import {
	ChecklistMajor,
	TeamMajor,
	MobileHorizontalDotsMajor,
	WandMajor,
	SecureMajor,
	ThemesMajor,
	BehaviorMajor,
	DuplicateMinor,
	JobsMajor,
	CircleCancelMajor,
	CircleCancelMinor,
	StarFilledMinor,
	StarOutlineMinor,
	CalendarMajor,
	CustomersMajor,
	ArchiveMajor,
	ExportMinor,
	DataVisualizationMajor,
	ListMajor,
	NoteMajor,
	PlusMinor,
	CategoriesMajor,
	ImportMinor,
	ImportStoreMajor,
	ClipboardMinor,
	CirclePlusOutlineMinor,
	Columns3Major,
	TextBlockMajor,
	FormsMajor,
	ExitMajor,
	SettingsMajor,
} from "@shopify/polaris-icons";
import { Responsive } from "react-grid-layout";
import { Trans, withTranslation } from "react-i18next";
import $ from "jquery";
import Page from "../../components/page";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import BoardFilters from "./components/BoardFilters";
import SavedFilters from "./components/SavedFilters";
import ImportCompanyBoardModal from "./components/ImportCompanyBoardModal.js";
import BoardHelper from "./BoardHelper";
import CellText from "./CellText";
import BoardMembers from "./components/BoardMembers";
import IconSelector from "./components/IconSelector";
import AutomationSheet from "./components/AutomationSheet";
import ContactSelector from "../../components/ContactSelector";
import RowModal from "./components/RowModal";
import ImportModal from "./components/ImportModal.js";
import RemoveModal from "../../components/remove_modal.js";
import Popover from "../../components/Popover";
import DashboardWidget from "./DashboardWidget.js";
import WidgetCreator from "./WidgetCreator";
import BoardTermEdit from "./BoardTermEdit";
import BoardLogg from "../../components/BoardLogg";
import DocumentListSheetContent from "../../components/DocumentListSheetContent";
import BoardAccessModal from "./BoardAccessModal";
import BoardExportModal from "./BoardExportModal";
import Sheet from "../../components/sheet";
import ViewCreator from "./ViewCreator.js";
import { filterDefaultValues } from "./boardutils/BoardUtils";
import CalendalSheet from "./components/CalendarModal/CalendalSheet";
import BoardSettings from "../Board/BoardSettings";
import Badge from "src/js/components/Badge";
import Tabs from "src/js/components/Tabs";
import Modal from "src/js/components/modal";

class BoardHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editing: false,
			widgets: props.board?.widgets,
			favorite: props.favorite,
			showMembersModal: props.location?.state?.openBoardMembers || false,
			tabIndex: 0,
			include_rows_in_template: props.board?.include_rows_in_template,
		};

		this.tabs = [
			{
				id: "link",
				content: this.props.t("board.form.tabs.link.content", "Länk"),
			},
			{
				id: "embedded",
				content: this.props.t("board.form.tabs.embedded.content", "Embedded"),
			},
		];
	}

	componentDidMount() {
		delete this.props.location?.state?.openBoardMembers;
		this.props.history.replace({ state: this.props.location?.state, search: this.props.location.search });
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.board?.id != this.props.board?.id) {
			this.setState({ favorite: props.favorite });

			delete props.location?.state?.openBoardMembers;
			props.history.replace({ state: props.location?.state, search: props.location.search });
		}

		this.setState({ include_rows_in_template: props.board?.include_rows_in_template });
	}

	removeFromTemplates() {
		this.updateBoardTemplate(false);
	}

	addToTemplates() {
		this.updateBoardTemplate(true);
	}

	duplicateBoard() {
		this.setState({ saving: true });
		API.post(
			"/api/workspaces/" + this.props.board.workspace_id + "/templates.json",
			{ template_id: this.props.board?.id, title: this.state.duplicate_title },
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });

				this.setState({ saving: false, showDuplicateModal: false });
				toastr.success(this.props.t("board.responses.duplicated", "Duplicerade board"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	updateBoardTemplate(v) {
		this.setState({ saving: true });
		API.put(
			"/api/boards/" + this.props.board.id + ".json",
			{ template: v, title: this.state.new_template_name, include_rows_in_template: v && this.state.include_rows_in_template },
			{ params: {} }
		)
			.then((result) => {
				this.setState({ saving: false });

				this.props.onUpdateBoard(result.data.board);
				this.setState({ saving: false, showTemplateModal: false });
				toastr.success(this.props.t("board.responses.template.updated", "Board mall updaterad"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	updateContact() {
		this.setState({ saving: true });
		API.put("/api/boards/" + this.props.board.id + ".json", { contact: this.state.contact }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });

				this.props.onUpdateBoard(result.data.board);
				this.setState({ saving: false, showContactModal: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	updateCountRows(group, c) {
		group.count_rows = c;
		store.dispatch({ type: "UPDATE_BOARD_GROUP", group });
		this.setState({ saving: true });
		API.put("/api/boards/" + this.props.board.id + "/groups/" + group.id + ".json", { count_rows: c }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	toggleFavorite() {
		this.state.favorite = !this.state.favorite;
		this.setState({ saving: true, favorite: this.state.favorite });
		API.post("/api/boards/" + this.props.board.id + "/favorite.json", { active: this.state.favorite }, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	changeIcon(icon) {
		this.setState({ saving: true });
		this.props.board.icon = icon;
		API.put("/api/boards/" + this.props.board.id + ".json", { icon }, { params: {} })
			.then((result) => {
				this.setState({ saving: false });

				this.props.onUpdateBoard(result.data.board);
				this.setState({ saving: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	addGroup() {
		this.setState({ popoverActive: false });
		BoardHelper.createGroup(this.props.board.id, "Ny grupp", 0);
	}

	updateTitle(value) {
		if (value.value != this.props.board.title) {
			this.props.board.title = value.value;
			this.props.onUpdateBoard(this.props.board);
			API.put("/api/boards/" + this.props.board.id + ".json", { title: value.value }, { params: {} })
				.then((result) => {
					this.setState({ saving: false });

					this.props.onUpdateBoard(result.data.board);
					this.setState({ saving: false });
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	removeBoard() {
		this.setState({ removing: true });
		API.delete("/api/boards/" + this.props.board.id + ".json", { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					this.setState({ removing: false });
					return;
				}

				this.setState({ removing: false, showRemoveModal: false });
				window.location.href = "/admin";
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	onCreateWidget(widget) {
		if (!this.state.widgets) {
			this.state.widgets = [widget];
		} else {
			this.state.widgets.push(widget);
		}
		this.setState({ widgets: this.state.widgets });
	}

	toggleMeAsDefaultFilter() {
		const v = !this.props.board.me_as_person_filter;
		this.props.board.me_as_person_filter = v;
		this.props.onUpdateBoard(this.props.board);

		if (v && !this.props.filters.find((f) => f.key === `person-${this.props.board.id}`)) {
			this.props.filters.unshift({
				column_id: "person",
				board_id: this.props.board.id,
				key: `person-${this.props.board.id}`,
				value: [store.getState().user.id],
			});
			window.location.hash = JSON.stringify(this.props.filters);

			this.props.onChangeFilters(this.props.filters);
		}

		API.put(
			`/api/boards/${this.props.board.id}.json`,
			{
				me_as_person_filter: v,
			},
			{ params: {} }
		)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
				}

				this.props.onUpdateBoard(result.data.board);
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	onUpdateWidget(widget) {
		if (this.state.widgets) this.state.widgets[this.state.widgets.findIndex((wid) => wid.id == widget.id)] = widget;
		this.setState({ widgets: this.state.widgets });
	}

	onRemoveWidget(widget) {
		this.setState({
			removingWidget: widget,
			removingIndex: this.state.widgets && this.state.widgets.findIndex((wid) => wid.id == widget.id),
		});
	}

	doRemoveWidget() {
		if (this.state.widgets) this.state.widgets.splice(this.state.removingIndex, 1);
		API.delete("/api/boards/" + this.props.board.id + "/widgets/" + this.state.removingWidget.id + ".json", {
			params: {},
		})
			.then((result) => {
				// this.setState({form:result.data.dashboard});
			})
			.catch((error) => {
				toastr.error(error);
			});
		this.setState({ widgets: this.state.widgets, removingWidget: null });
	}

	updateLayout(layout) {
		if (!this.state.editing) {
			return;
		}
		API.post("/api/boards/" + this.props.board.id + "/layout.json", { layout }, { params: {} })
			.then((result) => {
				// this.setState({form:result.data.dashboard});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	makeDefaultView() {
		API.post(
			"/api/boards/" + this.props.board.id + "/preferred.json",
			{
				view_id: this.props.view.id,
				filter_id: this.state.currentFilter ? this.state.currentFilter.id : null,
			},
			{ params: {} }
		)
			.then((result) => {
				// this.setState({form:result.data.dashboard});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	openDocumentSheet(documentColumn) {
		this.setState({
			openDocumentSheet: true,
			documentColumn_id: documentColumn.id,
		});
	}

	closeDocumentSheet() {
		this.setState({ openDocumentSheet: false, documentColumn_id: null });
	}

	setClipboard(text, type) {
		window.navigator.clipboard.writeText(text);
		if (type === "link") {
			toastr.info("Länk kopierad till urklipp");
		} else {
			toastr.info("Inbäddad kod kopierad till urklipp");
		}

		const wrapper = document.querySelector(".board_view_form_content");
		if (type === "link") {
			const textfield = wrapper.querySelector("input");
			textfield?.select();
			textfield?.focus();
		}
	}

	onOpenExportModal() {
		this.setState({ exportModalIsOpen: true });
	}

	onCloseExportModal() {
		this.setState({ exportModalIsOpen: false });
	}

	getQueryParam(variable) {
		const params = new URLSearchParams(window.location.search);
		const searchParamVarible = params.get(variable);

		try {
			return JSON.parse(searchParamVarible);
		} catch (error) {
			return searchParamVarible;
		}
	}

	getViewIcon(type) {
		if (type == "calendar") {
			return CalendarMajor;
		} else if (type == "kanban") {
			return Columns3Major;
		} else if (type == "timeline") {
			return TextBlockMajor;
		} else if (type == "form") {
			return FormsMajor;
		}

		return ListMajor;
	}

	toggleShowViewModal() {
		this.setState({
			// showViewList: false,
			showViewModal: !this.state.showViewModal,
		});
	}

	leaveBoard() {
		this.setState({ loadingLeaving: true });
		API.delete(`/api/boards/${this.props.board.id}/leave.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("result.data.error:", result.data.error);
					toastr.error(result.data.error);
				}

				// This is is handle in <Boardview /> through websockets and in socketprovider.
				// setTimeout(() => {
				// 	const board = result.data.board;
				// 	store.dispatch({ type: "REMOVE_BOARD", board });
				// }, 500);
				// toastr.success(this.props.t("board.responses.leave", "Lämnade board {{title}}", { title: result.data.board?.title }));
				// if (this.props.history.length > 1) {
				// 	this.props.history.goBack();
				// } else {
				// 	this.props.history.push("/admin");
				// }
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loadingLeaving: false });
			});
	}

	render() {
		const boardActions = [];

		if (this.props.workspace_id) {
			boardActions.push(
				this.props.board?.template
					? {
							icon: CircleCancelMajor,
							content: this.props.t("board.template.actions.remove", "Ta bort från mallar"),
							onAction: this.removeFromTemplates.bind(this),
					  }
					: {
							icon: ThemesMajor,
							content: this.props.t("board.template.actions.save_as", "Spara som mall"),
							onAction: () => {
								this.setState({ showTemplateModal: true });
							},
					  }
			);

			boardActions.push({
				icon: DuplicateMinor,
				content: this.props.t("board.actions.row_benamning", "Ändra radbenämning"),
				onAction: () => {
					this.setState({ showTermModal: true });
				},
				admin: true,
			});

			boardActions.push({
				icon: DuplicateMinor,
				content: this.props.t("board.actions.duplicate", "Duplicera board"),
				onAction: () => {
					this.setState({
						showDuplicateModal: true,
						duplicate_title: this.props.t("board.terms.copy_of", "Kopia av") + " " + (this.props.board?.title || ""),
					});
				},
			});

			boardActions.push({
				icon: JobsMajor,
				content: this.props.t("board.actions.connect_board_to_company", "Koppla board till företag"),
				onAction: () => {
					this.setState({
						showContactModal: true,
						contact: Object.assign({}, this.props.board?.contact),
					});
				},
			});

			boardActions.push({
				icon: SecureMajor,
				content: this.props.t("board.actions.change_access", "Ändra åtkomst"),
				onAction: () => {
					this.setState({ showTypeModal: true });
				},
			});

			boardActions.push({
				icon: CustomersMajor,
				content: this.props.t("board.actions.change_members", "Hantera medlemmar"),
				onAction: () => {
					this.setState({ showMembersModal: true });
				},
			});

			boardActions.push({
				icon: DataVisualizationMajor,
				content: this.state.editing
					? this.props.t("board.actions.show_widets", "Visa widgets")
					: this.props.t("board.actions.change_widets", "Ändra widgets"),
				onAction: () => {
					this.setState({ editing: !this.state.editing });
				},
			});
		}

		if (this.props.roles.includes("ROLE_BOARD_ADMIN") || this.props.roles.includes("ROLE_BOARD_OWNER")) {
			boardActions.push({
				icon: BehaviorMajor,
				content: this.props.t("board.actions.change_default_values", "Ändra standardvärden"),
				onAction: () => {
					this.setState({ showDefaultValueModal: true });
				},
			});
		}
		boardActions.push({
			icon: TeamMajor,
			content: this.props.t("board.actions.create_group_meet", "Skapa gruppmöte"),
			onAction: () => {
				this.setState({ openReminderModal: true });
			},
		});

		boardActions.push({
			icon: ChecklistMajor,
			content: this.props.t("board.actions.create_new_view", "Skapa ny vy"),
			onAction: () => this.toggleShowViewModal(),
			// onAction: () => this.viewSelectorRef.current.toggleShowViewModal(),
		});

		boardActions.push({
			icon: CustomersMajor,
			content: this.props.board?.me_as_person_filter
				? this.props.t("board.actions.set_default_filter_person_false", "Ta bort dig själv som standard filter")
				: this.props.t("board.actions.set_default_filter_person_true", "Sätt dig själv som standard filter"),
			onAction: this.toggleMeAsDefaultFilter.bind(this),
		});

		boardActions.push({
			icon: ChecklistMajor,
			content: this.props.t("board.actions.set_current_view_default", "Sätt nuvarande vy som standard"),
			onAction: this.makeDefaultView.bind(this),
		});

		boardActions.push({
			icon: ArchiveMajor,
			content: this.props.t("board.actions.show_default_rows", "Visa standard rader"),
			onAction: () => {
				const url = new URL(window.location.href);
				url.searchParams.delete("archived");
				url.searchParams.delete("also_archived");
				window.location = url.href;
			},

			enabled: this.getQueryParam("archived") || this.getQueryParam("also_archived"),
		});

		boardActions.push({
			icon: ArchiveMajor,
			content: this.props.t("board.actions.show_also_archived_rows", "Visa också arkiverade rader"),
			onAction: () => {
				const url = new URL(window.location.href);
				url.searchParams.delete("archived");
				url.searchParams.set("also_archived", 1);
				window.location = url.href;
			},
			enabled: !this.getQueryParam("also_archived"),
		});

		boardActions.push({
			icon: ArchiveMajor,
			content: this.props.t("board.actions.show_archived_rows", "Visa arkiverade rader"),
			onAction: () => {
				const url = new URL(window.location.href);
				url.searchParams.delete("also_archived");
				url.searchParams.set("archived", 1);
				window.location = url.href;
			},
			enabled: !this.getQueryParam("archived"),
		});

		boardActions.push({
			icon: ListMajor,
			content: this.props.t("board.actions.show_logg", "Visa boardlogg"),
			onAction: () => this.setState({ showBoardLogg: true }),
		});

		if (this.props.board?.type !== "public") {
			boardActions.push({
				icon: ExitMajor,
				content: this.props.t("board.actions.leave", "Lämna board"),
				onAction: () => this.setState({ leaveBoardModalIsOpen: true }),
			});
		}

		if (
			(this.props.board?.id != store.getState().account.sales_board_id ||
				!BoardHelper.getWorkspace(this.props.board?.workspace_id) ||
				BoardHelper.getWorkspace(this.props.board?.workspace_id).title != "Servicedesk") &&
			this.props.roles.some((r) => ["ROLE_BOARD_OWNER", "ROLE_BOARD_ADMIN"].includes(r))
		) {
			boardActions.push({
				icon: CircleCancelMinor,
				destructive: true,
				content: this.props.t("common.actions.remove", "Ta bort") + " board",
				onAction: () => this.setState({ showRemoveModal: true }),
			});
		}

		let favoriteButton = null;

		if (this.props.workspace_id) {
			let title;
			let icon;
			let color;
			if (this.state.favorite) {
				title = this.props.t("board.actioons.remove_as_favorie", "Ta bort board som favorit");
				icon = StarFilledMinor;
				color = "yellow";
			} else {
				title = this.props.t("board.actions.add_as_favorite", "Gör denna board till favorit");
				icon = StarOutlineMinor;
				color = "inkLightest";
			}
			favoriteButton = (
				<Tooltip content={title}>
					<a onClick={this.toggleFavorite.bind(this)}>
						<Icon color={color} source={icon} />
					</a>
				</Tooltip>
			);
		}

		let formUrl = "";
		if (this.props.view.type == "form") {
			formUrl = window.location.protocol + "//" + window.location.host + "/guest/forms/" + this.props.view.id;
		}

		const columnTimeline = this.props.board?.columns.find((c) => c.type === "timeline");

		const uploadColumns = this.props.board?.columns.filter(({ type }) => type === "upload");
		const uploadColumnObjects =
			uploadColumns?.reduce((acc, column) => {
				acc[column.id] = Object.assign({ count: 0, items: [], column }, column);
				return acc;
			}, {}) || {};

		const documentOptions = uploadColumnObjects
			? this.props.rows.reduce((acc, row) => {
					// eslint-disable-next-line no-restricted-syntax
					for (const i in acc) {
						const columnId = acc[i].id;

						if (row.column_values && columnId in row.column_values) {
							const values = row.column_values[columnId].value;

							if (values && Array.isArray(values)) {
								acc[i].count += values.length;
								values.forEach((value) => {
									if (!acc[i].items.find((item) => item && item.file && item.file.id === value.id)) {
										acc[i].items.push({ file: value, row, values });
									}
								});
							}
						}
					}

					return acc;
			  }, uploadColumnObjects)
			: {};

		const singular = (this.props.board && this.props.board?.singular) || this.props.t("row.singular", "Rad");
		// const plural = (this.props.board && this.props.board.plural) || "Rader";
		const usr = store.getState().user;
		const userRoles = usr && usr.roles;

		const secondaryActions = [
			{
				icon: ExportMinor,
				content: this.state.exporting ? (
					<div
						style={{
							display: "flex",
							gap: "0.1562rem",
							alignItems: "center",
						}}
					>
						<Spinner size="small" />
						{this.props.t("board.status.exporting", "Exporterar..")}
					</div>
				) : (
					this.props.t("board.actions.export", "Exportera")
				),
				onAction: this.onOpenExportModal.bind(this),

				enabled: store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0,
			},

			{
				icon: WandMajor,
				content: this.props.t("board.actions.automations", "Automatiseringar"),
				onAction: () => {
					this.setState({ automationSheetOpen: true });
				},
			},
			...((documentOptions &&
				Object.values(documentOptions).map((column) => ({
					icon: NoteMajor,
					content: (
						<span style={{ display: "flex", alignItems: "center" }}>
							<span style={{ marginRight: "0.3125rem" }}> {column.title}</span> <Badge>{column.count}</Badge>
						</span>
					),
					onAction: this.openDocumentSheet.bind(this, column),
				}))) ||
				[]),
			{
				icon: SettingsMajor,
				content: this.props.t("board.actions.settings", "Inställningar"),
				onAction: () => {
					this.setState({ boardSettingsIsOpen: true });
				},
				enabled: store.getState().user.roles.indexOf("ROLE_ADMIN") >= 0 && this.props.board?.id == store.getState().account.sales_board_id,
			},
		].filter((s) => !("enabled" in s) || s.enabled);

		return (
			<div className={`board-header${this.state.editing ? " editing" : " not-editing"}`}>
				<div style={{ position: "relative" }}>
					<div
						style={{
							position: "absolute",
							top: 40,
							right: "2.0000rem",
							zIndex: 100,
						}}
					/>
					<Page
						fullWidth
						backAction={{
							content: this.props.t("common.actions.back", "Tillbaka"),
							onAction: () => this.props.history.goBack(),
						}}
						pagination={
							this.props.limit
								? {
										hasPrevious: this.props.offset > 0,
										onPrevious: () => {
											this.props.onUpdatePagination(this.props.offset - this.props.limit, this.props.limit);
										},
										hasNext: this.props.offset + this.props.limit < this.props.filteredRows.length,
										onNext: () => {
											this.props.onUpdatePagination(this.props.offset + this.props.limit, this.props.limit);
										},
								  }
								: null
						}
						title={
							<React.Fragment>
								<div style={{ marginRight: 10, display: "inline-block" }}>
									<IconSelector icon={this.props.board?.icon} onChange={this.changeIcon.bind(this)} />
								</div>
								<span
									className="board-cell"
									style={{
										display: "inline-block",
										position: "relative",
										top: 7,
									}}
									onClick={(e) => {
										e.preventDefault();
									}}
								>
									<CellText onChange={this.updateTitle.bind(this)} onFocus={() => {}} onBlur={() => {}} value={this.props.board?.title} />
								</span>
							</React.Fragment>
						}
						titleMetadata={
							<LegacyStack spacing="extraTight">
								{favoriteButton}

								{this.props.board?.type == "shareable" || this.props.board?.type == "public_read" ? (
									<BoardMembers roles={this.props.roles} board={this.props.board} onUpdateBoard={this.props.onUpdateBoard} />
								) : null}
								{store.getState().user.calendar_board &&
									store.getState().user.calendar_board.id == this.props.board?.id &&
									columnTimeline &&
									columnTimeline.options &&
									!columnTimeline.options.integration && (
										<div>
											<div style={{ marginLeft: "10px" }}>
												<Button onClick={this.props.openCalendarModal} icon={CalendarMajor}>
													{this.props.t("board.actions.sync_outlook", "Synka din Outlook kalender")}
												</Button>
											</div>
										</div>
									)}
								{(this.getQueryParam("archived") || this.getQueryParam("also_archived")) && (
									<span className="showingArchivedBadge">
										<span
											className="icon"
											onClick={() => {
												const url = new URL(window.location.href);
												url.searchParams.delete("archived");
												url.searchParams.delete("also_archived");
												window.location = url.href;
											}}
										>
											<Icon source={CircleCancelMajor} size={12} />
										</span>

										{this.getQueryParam("archived") ? (
											<Badge status="attention">{this.props.t("board.actions.show_archived_short_only", "Visar bara arkiverade")}</Badge>
										) : (
											<Badge status="attention">{this.props.t("board.actions.show_archived_short_also", "Visar också arkiverade")}</Badge>
										)}
									</span>
								)}
							</LegacyStack>
						}
						secondaryActions={secondaryActions}
						actionGroups={[
							{
								icon: MobileHorizontalDotsMajor,
								title: this.props.t("board.terms.rest", "Övrigt"),
								actions: boardActions.filter(
									(action) =>
										((!("enabled" in action) || action.enabled) && !action.admin) ||
										(action.admin && userRoles.some((r) => ["ROLE_ADMIN", "ROLE_BOARD_OWNER", "ROLE_BOARD_ADMIN"].includes(r)))
								),
							},
							{
								icon: this.getViewIcon(this.props.view.type),
								title: this.props.view.title,
								actions: [
									...(this.props.board?.views || []).map((view) => ({
										content: view.title,
										icon: this.getViewIcon(view.type),
										onAction: () => {
											this.props.onChangeView(view, this.props.switchDirection == 0 ? 30 : 0);
										},
									})),
									{
										content: this.props.t("dashboard.view.actions.add", "Lägg till vy"),
										icon: CirclePlusOutlineMinor,
										onAction: this.toggleShowViewModal.bind(this),
									},
								],
							},
						]}
					>
						<div
							style={{
								marginTop:
									this.state.widgets && this.state.widgets.find((widget) => (widget.y == 0 || widget.y == "0") && widget.type != "number") ? 40 : 15,
							}}
						>
							{this.state.editing ? <WidgetCreator board={this.props.board} onCreate={this.onCreateWidget.bind(this)} /> : null}
							<Responsive
								className="layout"
								containerPadding={[0, 0]}
								onLayoutChange={this.updateLayout.bind(this)}
								draggableHandle=".dashboard-drag-handle"
								isResizable={this.state.editing}
								breakpoints={{ xxs: 0 }}
								cols={{ xxs: 12 }}
								margin={[25, 50]}
								width={$("." + this.props.boardClass).width() - 65}
								rowHeight={90}
								verticalCompact
								onBreakpointChange={(newBreakpoint, newCols) => {}}
							>
								{this.state.widgets &&
									this.state.widgets
										.filter((widget) => !widget.options.full_width)
										.map((widget, index) => (
											<div
												key={widget.id + ""}
												data-grid={{
													x: widget.x,
													y: widget.y,
													w: widget.width,
													h: widget.height,
												}}
												className="dashboard-widget"
											>
												<DashboardWidget
													widget={widget}
													editing={this.state.editing}
													board={this.props.board}
													rows={this.props.filteredRows}
													groups={this.props.groups}
													filters={this.props.filters}
													onUpdate={this.onUpdateWidget.bind(this, widget)}
													onRemove={this.onRemoveWidget.bind(this, widget)}
												/>
											</div>
										))}
							</Responsive>
						</div>

						<BoardSettings
							open={this.state.boardSettingsIsOpen}
							onClose={() => this.setState({ boardSettingsIsOpen: false })}
							board={this.props.board}
						/>

						<BoardExportModal
							open={this.state.exportModalIsOpen}
							onClose={this.onCloseExportModal.bind(this)}
							filteredRows={this.props.filteredRows}
							board={this.props.board}
							contact_id={this.props.contact_id}
							filters={this.props.filters}
						/>

						<Modal
							open={this.state.removingWidget}
							onClose={() => {
								this.setState({ removingWidget: null });
							}}
							title={this.props.t("board.modals.remove_widget.title", "Ta bort widget?")}
							primaryAction={{
								content: this.props.t("common.actions.remove", "Ta bort"),
								destructive: true,
								loading: this.state.saving,
								onAction: this.doRemoveWidget.bind(this),
							}}
							secondaryActions={[
								{
									content: this.props.t("common.actions.cancel", "Avbryt"),
									onAction: () => {
										this.setState({ removingWidget: null });
									},
								},
							]}
						>
							<Modal.Section>
								<Trans
									i18nKey="board.modals.remove_widget.text"
									defaults="Är du säker på att du vill ta bort <strong> {{ title }}</strong>? Detta går ej att ångra.99"
									values={{
										title: this.state.removingWidget?.title || "Widget",
									}}
								/>
							</Modal.Section>
						</Modal>
						<Modal
							open={this.state.showTemplateModal}
							onClose={() => {
								this.setState({ showTemplateModal: false });
							}}
							title={this.props.t("board.modals.save_as_template.title", "Spara som mall")}
							primaryAction={{
								content: this.props.t("board.actions.save_as_template", "Spara som mall"),
								loading: this.state.saving,
								onAction: this.addToTemplates.bind(this),
							}}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ showTemplateModal: false });
									},
								},
							]}
						>
							<Modal.Section>
								<Text variant="headingMd" as="h2">
									{this.props.t("board.modals.save_as_template.heading", "Gör om {{title}} till en mall", {
										title: this.props.board?.title,
									})}
								</Text>
								<p style={{ marginBottom: "0.6250rem" }}>
									{this.props.t("board.modals.save_as_template.text", "Alla på detta kontot kan använda denna mallen för att skapa en ny board.")}
								</p>
								<FormLayout>
									<TextField
										label={this.props.t("board.modals.save_as_template.fields.name.label", "Namn på mall")}
										value={this.state.new_template_name}
										onChange={(v) => {
											this.setState({ new_template_name: v });
										}}
									/>
									<Checkbox
										label="Ta även med rader ifrån boarden"
										checked={this.state.include_rows_in_template}
										onChange={(includeRowsInTemplate) => {
											this.setState({ include_rows_in_template: includeRowsInTemplate });
										}}
									/>
								</FormLayout>
							</Modal.Section>
						</Modal>
						<Modal
							open={this.state.showDuplicateModal}
							onClose={() => {
								this.setState({ showDuplicateModal: false });
							}}
							title={this.props.t("board.modals.duplicate_board.title", "Duplicera board")}
							primaryAction={{
								content: this.props.t("board.actions.duplicate_short", "Duplicera"),
								loading: this.state.saving,
								disabled: !this.state.duplicate_title,
								onAction: this.duplicateBoard.bind(this),
							}}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ showDuplicateModal: false });
									},
								},
							]}
						>
							<Modal.Section>
								<TextField
									label={this.props.t("board.modals.duplicate_board.fields.name.label", "Namn på kopia")}
									value={this.state.duplicate_title}
									onChange={(v) => {
										this.setState({ duplicate_title: v });
									}}
								/>
							</Modal.Section>
						</Modal>
						<Modal
							open={this.state.formModalOpen}
							onClose={() => {
								this.setState({ formModalOpen: false });
							}}
							title={this.props.t("board.modals.share.title", "Dela formulär")}
							secondaryActions={[
								{
									content: this.props.t("board.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ formModalOpen: false });
									},
								},
							]}
						>
							<Modal.Section>
								<FormLayout>
									<TextField label={this.props.t("board.modals.share.fields.url.label", "Länk till formulär")} value={formUrl} />
									<TextField
										label={this.props.t("board.modals.share.fields.html_code.label", "HTML kod")}
										value={`<iframe src="${formUrl}" width="650" height="500" style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"></iframe>`}
									/>
								</FormLayout>
							</Modal.Section>
						</Modal>
						<Modal
							open={this.state.showContactModal}
							onClose={() => {
								this.setState({ showContactModal: false });
							}}
							title={this.props.t("board.modals.connect_board_to_company", "Koppla board till företag")}
							primaryAction={{
								content: this.props.t("common.actions.save", "Spara"),
								loading: this.state.saving,
								disabled: this.state.contact == this.props.board?.contact,
								onAction: this.updateContact.bind(this),
							}}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ showContactModal: false });
									},
								},
							]}
						>
							<Modal.Section>
								<ContactSelector
									contact={this.state.contact}
									onChange={(contact) => {
										this.setState({ contact });
									}}
									onCreateContact={() => {}}
									hidePerson
								/>
							</Modal.Section>
						</Modal>
						<BoardAccessModal
							open={this.state.showTypeModal}
							onClose={() => {
								this.setState({ showTypeModal: false });
							}}
							onUpdateBoard={this.props.onUpdateBoard}
							board={this.props.board}
						/>

						<AutomationSheet
							open={this.state.automationSheetOpen}
							onClose={() => {
								this.setState({ automationSheetOpen: false });
							}}
							roles={this.props.roles}
							groups={this.props.groups}
							board={this.props.board}
						/>
						<RowModal
							open={this.state.showDefaultValueModal}
							board={this.props.board}
							groups={this.props.groups}
							editingDefaultValues
							onUpdateBoard={(board) => {
								this.setState({ showDefaultValueModal: false });
								this.props.onUpdateBoard(board);
							}}
							defaultValues={filterDefaultValues(this.props.board?.default_values)}
							onClose={() => {
								this.setState({ showDefaultValueModal: false });
							}}
						/>
						<BoardMembers
							hideTrigger
							roles={this.props.roles}
							show={this.state.showMembersModal}
							onClose={() => this.setState({ showMembersModal: false })}
							board={this.props.board}
							onUpdateBoard={this.props.onUpdateBoard}
						/>
						<Modal
							open={this.state.showTermModal}
							onClose={() => {
								this.setState({ showTermModal: false });
							}}
							title={this.props.t("board.modals.change_row_name.title", "Ändra radbenäming")}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ showTermModal: false });
									},
								},
							]}
						>
							<Modal.Section>
								<BoardTermEdit
									onClose={() => {
										this.setState({ showTermModal: false });
									}}
									onUpdateBoard={this.props.onUpdateBoard}
									board={this.props.board}
								/>
							</Modal.Section>
						</Modal>
						<Modal
							open={this.state.showCountModal}
							onClose={() => {
								this.setState({ showCountModal: false });
							}}
							title={this.props.t("board.modals.count_rows_from_groups.title", "Räkna rader från grupper")}
							secondaryActions={[
								{
									content: this.props.t("common.actions.close", "Stäng"),
									onAction: () => {
										this.setState({ showCountModal: false });
									},
								},
							]}
						>
							{Object.values(store.getState().board_groups).map((group) => (
								<Modal.Section key={group.id}>
									<Checkbox
										label={
											group.title +
											" (" +
											Object.values(store.getState().board_rows).filter((row) => row.group_id == group.id && row.board_id == this.props.board?.id)
												.length +
											")"
										}
										checked={group.count_rows}
										onChange={this.updateCountRows.bind(this, group)}
									/>
								</Modal.Section>
							))}
						</Modal>
						<ImportModal
							open={this.state.showImportModal}
							type="board"
							board={this.props.board}
							onComplete={() => {}}
							onClose={() => {
								this.setState({ showImportModal: false });
							}}
						/>
						<ImportCompanyBoardModal
							redirect
							history={this.props.history}
							open={this.state.showImportCompanyBoardModal}
							board={this.props.board}
							onComplete={() => {
								this.setState({ showImportCompanyBoardModal: false });
							}}
							onClose={() => {
								this.setState({ showImportCompanyBoardModal: false });
							}}
						/>
						<RemoveModal
							open={this.state.showRemoveModal}
							onClose={() => {
								this.setState({ showRemoveModal: null });
							}}
							loading={this.state.removing}
							onConfirm={this.removeBoard.bind(this)}
							numItems={1}
							objectName={this.props.board ? this.props.board?.title : ""}
							resourceName={{
								singular: this.props.t("board.singular", "board"),
								plural: this.props.t("board.plural", "boards"),
							}}
						/>
						<BoardLogg open={this.state.showBoardLogg} board={this.props.board} onClose={() => this.setState({ showBoardLogg: false })} />
						<CalendalSheet
							open={this.state.openReminderModal}
							onClose={() => this.setState({ openReminderModal: false })}
							onCreate={() => this.setState({ openReminderModal: false })}
							attendees={
								this.props.board &&
								this.props.board?.members &&
								Array.isArray(this.props.board?.members) &&
								this.props.board?.members.map((m) => ({ email: m.email })).filter((m) => m)
							}
							board={store.getState().user.calendar_board}
							onComplete={(row) => {
								this.setState({ openReminderModal: false });
							}}
							row={(() => {
								const board = store.getState().user.calendar_board;

								const contactIds = this.props.board?.members?.map((mem) => {
									return mem.user_id || mem.user?.id;
								});

								const calendarContactColumn = board?.columns.find((c) => c.type === "contact");

								const row = {
									column_values: {
										[calendarContactColumn?.id]: {
											column_id: calendarContactColumn?.id,
											value: contactIds || [],
										},
									},
								};

								return row;
							})()}
						/>
						<Sheet open={this.state.openDocumentSheet} onClose={this.closeDocumentSheet.bind(this)} hideHeader>
							<DocumentListSheetContent
								column={documentOptions && this.state.documentColumn_id && documentOptions[this.state.documentColumn_id].column}
								items={documentOptions && this.state.documentColumn_id && documentOptions[this.state.documentColumn_id].items}
								board={this.props.board}
								onClose={this.closeDocumentSheet.bind(this)}
								history={this.props.history}
							/>
						</Sheet>
					</Page>
				</div>
				<div className={`board-header-search-wrapper${this.props.saved_filters?.length ? " showing_tabs" : ""}`}>
					<LegacyCard>
						{/* <BoardDescriptionInput boardID={this.props.board?.id} onUpdateBoard={this.props.onUpdateBoard} board={this.props.board} /> */}
						<div className={`saved-filter-tabs${this.props.saved_filters?.length ? " show" : ""}`}>
							<LegacyStack spacing="none" wrap={false}>
								<LegacyStack.Item fill>
									<SavedFilters
										onSetAction={(v, filter) => {
											this.setState({ filterAction: v, currentFilter: filter });
										}}
										updateSavedFilters={this.props.updateSavedFilters}
										saved_filters={this.props.saved_filters}
										onChangeFilters={this.props.onChangeFilters}
										history={this.props.history}
										board={this.props.board}
										filters={this.props.filters}
									/>
								</LegacyStack.Item>
								{/* this.props.view.type == "table" ? (
							<BoardSorting board={this.props.board} sorting={this.props.sorting} onChangeSorting={this.props.onChangeSorting} />
						) : null */}
							</LegacyStack>
						</div>

						<LegacyCard.Section>
							<div className="board-header-search-inner">
								<div className="board-new-row">
									<ButtonGroup segmented>
										<Button
											primary
											onClick={() => {
												this.props.onCreateRow(`${singular.capitalize()}`, null);
											}}
											disabled={this.props.groups.length < 1}
											icon={PlusMinor}
										>
											{singular.capitalize()}
										</Button>
										<Popover
											fixed
											active={this.state.popoverActive}
											activator={
												<Button
													onClick={() => {
														this.setState({
															popoverActive: !this.state.popoverActive,
														});
													}}
													disclosure
													primary
												/>
											}
											onClose={() => {
												this.setState({
													popoverActive: !this.state.popoverActive,
												});
											}}
										>
											<ActionList
												items={[
													{
														content: `${singular.capitalize()}`,
														disabled: this.props.groups.length < 1,
														onAction: () => {
															this.props.onCreateRow(`+ ${singular.capitalize()}`, null);
														},
														icon: PlusMinor,
													},
													{
														content: this.props.t("board.actions.new_group", "Ny grupp"),
														onAction: this.addGroup.bind(this),
														icon: CategoriesMajor,
													},
													{
														content: this.props.t("board.actions.import", "Importera"),
														onAction: () => {
															this.setState({
																showImportModal: true,
																popoverActive: false,
															});
														},
														icon: ImportMinor,
													},
													{
														content: this.props.t("board.actions.import_bolag_list", "Importera bolagslista"),
														onAction: () => {
															this.setState({
																showImportCompanyBoardModal: true,
																popoverActive: false,
															});
														},
														icon: ImportStoreMajor,
													},
												]}
											/>
										</Popover>
									</ButtonGroup>
								</div>
								{this.props.view.type != "form" ? (
									<div className="board-header-search-filters">
										<BoardFilters
											onChangeFilters={this.props.onChangeFilters}
											filters={this.props.filters}
											boards={[this.props.board]}
											groups={this.props.groups}
											filteredRows={this.props.filteredRows}
											rows={this.props.rows}
											additionalAction={this.state.filterAction}
										/>
									</div>
								) : null}

								{this.props.view.type == "form" ? (
									<Button
										onClick={() => {
											this.setState({ formModalIsOpen: true });
											// window.open(formUrl);
										}}
									>
										{this.props.t("board.actions.show_form", "Visa formulär")}
									</Button>
								) : null}

								{this.props.view.type != "table" && this.props.view.type != "simple" ? (
									<div>
										<Button onClick={this.props.onToggleSettings}>{this.props.t("board.actions.settings", "Inställningar")}</Button>
									</div>
								) : null}
							</div>
						</LegacyCard.Section>

						{this.props.children}
					</LegacyCard>

					{this.props.limit ? (
						<div style={{ height: "100px", paddingTop: 30, textAlign: "center" }}>
							<Pagination
								hasPrevious={this.props.offset > 0}
								onPrevious={() => {
									this.props.onUpdatePagination(this.props.offset - this.props.limit, this.props.limit);
								}}
								hasNext={this.props.offset + this.props.limit < this.props.filteredRows.length}
								onNext={() => {
									this.props.onUpdatePagination(this.props.offset + this.props.limit, this.props.limit);
								}}
							/>
						</div>
					) : null}

					<Modal
						// eslint-disable-next-line quotes
						title={this.props.t("board.actions.leave.title", 'Lämna board "{{title}}"', { title: this.props.board?.title })}
						open={this.state.leaveBoardModalIsOpen}
						onClose={() => {
							this.setState({ leaveBoardModalIsOpen: false });
						}}
						primaryAction={{
							content: this.props.t("common.actions.leave", "Lämna"),
							onAction: this.leaveBoard.bind(this),
							loading: this.state.loadingLeaving,
							icon: ExitMajor,
						}}
						secondaryActions={[
							{
								content: this.props.t("common.actions.cancel", "Avbryt"),
								onAction: () => {
									this.setState({ leaveBoardModalIsOpen: false });
								},
							},
						]}
					>
						<Modal.Section>
							<p>{this.props.t("board.text.confirm_leave.subtext", "Säker du vill lämna board {{title}}?", { title: this.props.board?.title })}</p>
							{this.props.board?.members?.length === 1 && (
								<p>
									{this.props.t("board.text.confirm_leave.last_member", "Du är den sista medlemmen i denna board.", {
										title: this.props.board?.title,
									})}
								</p>
							)}
						</Modal.Section>
					</Modal>

					<Modal
						title={this.props.t("board.form.modal.title", "Välj alternativ")}
						open={this.state.formModalIsOpen}
						onClose={() => {
							this.setState({ formModalIsOpen: false });
						}}
					>
						<Modal.Section>
							<Tabs
								tabs={this.tabs}
								onSelect={(tabIndex) => {
									this.setState({ tabIndex });
								}}
								selected={this.state.tabIndex}
							/>
							<div style={{ paddingBlock: "0.6250rem" }}>
								{this.tabs[this.state.tabIndex]?.id === "link" ? (
									<div className="board_view_form_content">
										<TextField
											label={this.props.t("board.form.tabs.link.content", "Länk")}
											readOnly
											helpText={this.props.t("board.form.fields.link.helptext", "Länk för formuläret")}
											value={formUrl}
										/>
										<Tooltip content={this.props.t("board.form.fields.embed.tooltip", "Kopiera länk")}>
											<Button icon={ClipboardMinor} onClick={this.setClipboard.bind(this, formUrl, "link")} />
										</Tooltip>
									</div>
								) : (
									<div>
										<Label>Embed kod</Label>
										<div className="board_view_form_content">
											<div
												style={{
													border: "1px solid #ffffff6b",
													padding: "0.6250rem",
													background: "var(--main2)",
													borderRadius: 6,
												}}
											>
												<code>
													&lt;iframe src=&quot;{formUrl}&quot; title=&quot;
													{this.props.view?.options?.title}&quot; width=&quot;640&quot; height=&quot;1000&quot; /&gt;
												</code>
											</div>
											<Tooltip content={this.props.t("board.form.fields.embed.tooltip", "Kopiera embedd kod")}>
												<Button
													icon={ClipboardMinor}
													onClick={this.setClipboard.bind(
														this,
														`<iframe src="${formUrl}?embedded=true" title="${this.props.view?.options?.title}" width="640" height="1000" />`,
														"embed"
													)}
												/>
											</Tooltip>
										</div>
									</div>
								)}
							</div>
						</Modal.Section>
					</Modal>
				</div>
				<ViewCreator
					open={this.state.showViewModal}
					board={this.props.board}
					onClose={() => {
						this.setState({ showViewModal: false });
					}}
					onCreate={(view) => {
						this.props.onCreateView(view);
						this.props.onChangeView(view, this.props.switchDirection == 0 ? 30 : 0);
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["board", "row", "common"], { withRef: true })(BoardHeader);
