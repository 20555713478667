import { Button, FormLayout, TextField } from "@shopify/polaris";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { toastr } from "react-redux-toastr";
import Page from "src/js/components/page";
import API from "../../API";

class NewPDF extends Component {
	constructor(props) {
		super(props);
		this.state = { form: {} };
	}

	handleChange(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	gotoPdf(item) {
		item.path = "/admin/pdf_templates/" + item.id;
		this.props.history.push("/admin/pdf_templates/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("pdf.breadcrumb2", "PDFs"),
					item,
				},
			],
		});
	}

	onSave() {
		this.setState({ saving: true });

		API.post("/api/pdf_templates.json", this.state.form)
			.then((result) => {
				this.setState({ saving: false });

				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("pdf.responses.created", "Skapade PDF"));
				this.gotoEditor(result.data.pdf_template);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	gotoEditor(pdf) {
		this.props.history.push(`/admin/pdf_templates/editor/${pdf.id}`);
	}

	render() {
		const form = this.state.form || {};
		return (
			<Page
				title={this.props.t("pdf.new.title", "Ny PDF")}
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				primaryAction={{ content: this.props.t("common.actions.create", "Skapa"), onAction: this.onSave.bind(this), loading: this.state.saving }}
			>
				<FormLayout>
					<TextField
						type="text"
						label={this.props.t("pdf.fields.title.label", "Titel")}
						onChange={this.handleChange.bind(this, "title")}
						value={form.title}
					/>

					<Button primary disabled={!this.state.form.title} loading={this.state.saving} onClick={this.onSave.bind(this)}>
						{this.props.t("common.actions.create", "Skapa")}
					</Button>
				</FormLayout>
			</Page>
		);
	}
}
export default withTranslation(["pdf", "common"], { withRef: true })(NewPDF);
