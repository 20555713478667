import { TextField } from "@shopify/polaris";
import React, { Component } from "react";
import moment from "moment";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { isValid } from "date-fns";
import sv from "date-fns/locale/sv";
import { withTranslation } from "react-i18next";
import API from "../../../../API";
import { toastr } from "../../../../components/toastr";
import { store } from "../../../../store";
import DatepickerHelper from "../../../../components/DatepickerHelper";
import Modal from "src/js/components/modal";

registerLocale("sv", sv);

class WorkTimeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.endTimeMinutesRef = React.createRef();
		this.startTimeMinutesRef = React.createRef();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open !== this.props.open) {
			if (!props.open) {
				this.setState({ start: null, end: null });
			}
			if (props.open) {
				const view = this.props.board && this.props.board.views.find((view) => view.type === "calendar");
				this.setState({
					start: view.options && moment().format("YYYY-MM-DD") + " " + (view.options.workStart || "08:00"),
					end: view.options && moment().format("YYYY-MM-DD") + " " + (view.options.workEnd || "17:00"),
				});
			}
		}
	}

	updateWorkHoursInView() {
		const view = this.props.board && this.props.board.views.find((view) => view.type === "calendar");
		if (Array.isArray(view.options) && !view.options.length) {
			view.options = {};
		}

		view.options.workStart = moment(this.state.start).format("HH:mm");
		view.options.workEnd = moment(this.state.end).format("HH:mm");

		this.setState({ loading: true });
		API.put(`/api/boards/${this.props.board.id}/views/${view.id}.json`, view)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					return;
				}
				const index = this.props.board.views.findIndex((view) => view.id === result.data.view.id);
				this.props.board.views.splice(index, 1, result.data.view);
				store.dispatch({ type: "UPDATE_BOARD", board: this.props.board });

				this.props.onClose();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	getHours(value) {
		const v = DatepickerHelper.getTime(value) && DatepickerHelper.getTime(value).split(":");
		return v && v[0];
	}

	getMinutes(value) {
		const v = DatepickerHelper.getTime(value) && DatepickerHelper.getTime(value).split(":");
		return v && v[1];
	}

	onChangeHours(field, value) {
		const minutes = this.getMinutes(this.state[field]);
		this.onChangeTime(field, `${String(Math.min(23, value)).padStart(2, "0")}:${minutes}`);
		if (value.length >= 2) {
			const input =
				field === "start"
					? this.startTimeMinutesRef.current && this.startTimeMinutesRef.current.querySelector("input")
					: this.endTimeMinutesRef.current && this.endTimeMinutesRef.current.querySelector("input");
			if (input) {
				input.focus();
			}
		}
	}

	onChangeMinutes(field, value) {
		const hours = this.getHours(field === "start" ? this.state.start : this.state.end);
		this.onChangeTime(field, `${hours}:${String(Math.min(59, value)).padStart(2, "0")}`);
	}

	onChangeTime(field, time) {
		const t = moment(time).isValid() ? moment(time).format("HH:mm") : time;
		const dateWithTime = moment(DatepickerHelper.getDate(field === "start" ? this.state.start : this.state.end) + " " + t).format("YYYY-MM-DD HH:mm");
		const value = moment(dateWithTime).isValid()
			? moment(dateWithTime).format("YYYY-MM-DD HH:mm")
			: DatepickerHelper.getDate(field === "start" ? this.state.start : this.state.end) + " " + t;
		this.setState({ [field]: value }, () => {
			const wrapper = document.querySelector(field === "start" ? ".workStart" : ".workEnd");
			if (wrapper) {
				const selected = wrapper.querySelector(".react-datepicker__time-list-item--selected");
				if (selected) selected.scrollIntoView();
			}
		});
	}

	render() {
		const onFocus = (e) => {
			if (e.target) {
				e.target.focus();
				e.target.select();
				setTimeout(() => {
					e.target.select();
				}, 0);
				e.preventDefault();
				e.stopPropagation();
			}
		};
		return (
			<Modal
				title={this.props.t("calendar.modals.worktime.title", "Ändra arbetstid")}
				open={this.props.open}
				onClose={this.props.onClose}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.props.t("common.actions.save", "Spara"),
					onAction: this.updateWorkHoursInView.bind(this),
					loading: this.state.loading,
					disabled: !this.state.start || !this.state.end,
				}}
			>
				<Modal.Section title={this.props.t("calendar.modals.worktime.sections.time.title", "Välj start och slut tid för dina arbetsdagar")}>
					<div className="new_datepicker__popup_content worktime_modal" style={{ display: "flex" }}>
						<div className="workStart" style={{ flex: 1 }}>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<span className="hours">
									<TextField
										onFocus={onFocus}
										max="23"
										type="text"
										value={this.getHours(this.state.start)}
										onChange={this.onChangeHours.bind(this, "start")}
									/>
								</span>
								<span className="minutes" ref={this.startTimeMinutesRef}>
									<TextField
										onFocus={onFocus}
										max="59"
										type="text"
										value={this.getMinutes(this.state.start)}
										onChange={this.onChangeMinutes.bind(this, "start")}
									/>
								</span>
							</div>
							<ReactDatePicker
								inline
								selected={this.state.start && isValid(new Date(this.state.start)) ? new Date(this.state.start) : null}
								onChange={(date) => {
									const start = moment(date).format("YYYY-MM-DD HH:mm");

									this.setState({ start });
								}}
								showTimeSelect
								showTimeSelectOnly
								injectTimes={isValid(new Date(this.state.start)) ? [new Date(moment(this.state.start).format("YYYY-MM-DD HH:mm"))] : []}
								timeIntervals={15}
								timeCaption={this.props.t("calendar.fields.start_time.caption", "Start tid")}
								dateFormat="h:mm"
								locale="sv"
							/>
						</div>
						<div className="workEnd" style={{ flex: 1 }}>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<span className="hours">
									<TextField
										onFocus={onFocus}
										max="23"
										type="text"
										value={this.getHours(this.state.end)}
										onChange={this.onChangeHours.bind(this, "end")}
									/>
								</span>
								<span className="minutes" ref={this.endTimeMinutesRef}>
									<TextField
										onFocus={onFocus}
										max="59"
										type="text"
										value={this.getMinutes(this.state.end)}
										onChange={this.onChangeMinutes.bind(this, "end")}
									/>
								</span>
							</div>
							<ReactDatePicker
								inline
								selected={this.state.end && isValid(new Date(this.state.end)) ? new Date(this.state.end) : null}
								onChange={(date) => {
									const end = moment(date).format("YYYY-MM-DD HH:mm");
									this.setState({ end });
								}}
								showTimeSelect
								injectTimes={isValid(new Date(this.state.start)) ? [new Date(moment(this.state.end).format("YYYY-MM-DD HH:mm"))] : []}
								showTimeSelectOnly
								timeIntervals={15}
								timeCaption={this.props.t("calendar.fields.end_time.caption", "Slut tid")}
								dateFormat="h:mm"
								locale="sv"
							/>
						</div>
					</div>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["calendar", "common"], { withRef: true })(WorkTimeModal);
