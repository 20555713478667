import React, { Component } from "react";
import { ChoiceList, Select, TextField, FormLayout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import Modal from "src/js/components/modal";

class ViewCreator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: { type: "table", title: "", options: {} },
			saving: false,
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ form: { type: "table", title: "", options: {} } });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		if (field == "type") {
			formState.group_by = null;
			formState.color_by = null;
			formState.value_columns = [];

			if (value == "kanban") {
				// Select first valid values
				formState.group_by = this.getColumnOptions(["status"]).length ? this.getColumnOptions(["status"])[0].value : null;
				formState.color_by = this.getColumnOptions(["person"]).length ? this.getColumnOptions(["person"])[0].value : null;
			} else if (value == "calendar") {
				// Select first valid values
				formState.color_by = this.getColumnOptions(["group", "status", "person"]).length
					? this.getColumnOptions(["group", "status", "person"])[0].value
					: null;
			} else if (value == "timeline") {
				// Select first valid values
				formState.group_by = this.getColumnOptions(["group", "status", "person"]).length
					? this.getColumnOptions(["group", "status", "person"])[0].value
					: null;
				formState.color_by = this.getColumnOptions(["group", "status", "person"]).length
					? this.getColumnOptions(["group", "status", "person"])[0].value
					: null;
			}
		}
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.type == "dashboard") {
			API.post(
				"/api/workspaces/" + this.props.board.workspace_id + "/dashboards.json",
				{ title: this.state.form.title, boards: [this.props.board] },
				{ params: {} }
			)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("dashboard.responses.created", "Skapade dashboard"));
					this.setState({ saving: false });
					this.props.onCreate(result.data.view);
					this.props.onClose?.();
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
			return;
		}

		API.post("/api/boards/" + this.props.board.id + "/views.json", this.state.form, { params: {} })
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				toastr.success(this.props.t("dashboard.view.responses.created", "Skapade vy"));
				this.setState({ saving: false });
				this.props.onCreate(result.data.view);
				this.props.onClose?.();
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	getColumnOptions(types) {
		const arr = [];
		if (types.indexOf("group") >= 0) {
			arr.push({
				value: "group",
				label: this.props.t("dashboard.options.groups.label", "Grupper"),
			});
		}
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push({
					value: this.props.board.columns[i].id + "",
					label: this.props.board.columns[i].title,
				});
			}
		}
		return arr;
	}

	render() {
		let disabled = false;

		if (!this.state.form.title) {
			disabled = true;
		}
		if (this.state.form.type == "kanban") {
			if (!this.getColumnOptions(["status"]).length) {
				disabled = true;
			}
		} else if (this.state.form.type == "timeline" || this.state.form.type == "calendar") {
			// if (!this.getColumnOptions(["datetime", "timeline"]).length) {
			// 	disabled = true;
			// }
		}

		return (
			<Modal
				open={this.props.open}
				onClose={this.props.onClose}
				title={this.props.t("dashboard.view.modals.new.title", "Ny vy")}
				preventAutofocus
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
					loading: this.state.saving,
					disabled,
					onAction: this.saveForm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						<TextField
							label={this.props.t("dashboard.view.modals.new.fields.title.label", "Namn på vy")}
							value={this.state.form.title}
							onChange={this.updateForm.bind(this, "title")}
						/>
						<Select
							label={this.props.t("dahboard.view.modals.new.fields.type.label", "Typ")}
							options={[
								// { value: "dashboard", label: "Dashboard" },
								{
									value: "table",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.table", "Lista"),
								},
								{
									value: "kanban",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.kanban", "Kanban"),
								},
								{
									value: "timeline",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.timeline", "Tidslinje"),
								},
								{
									value: "calendar",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.calendar", "Kalender"),
								},
								{
									value: "form",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.form", "Formulär"),
								},
								{
									value: "call_list",
									label: this.props.t("dashboard.view.modals.new.fields.type.options.calllist", "Ringlista"),
								},
							]}
							value={this.state.form.type}
							onChange={this.updateForm.bind(this, "type")}
						/>
						{this.state.form.type == "kanban" ? (
							<Select
								label={this.props.t("dashboard.view.modals.new.fields.group_by.label", "Kanban kolumn")}
								options={this.getColumnOptions(["status"])}
								value={this.state.form.group_by}
								onChange={this.updateForm.bind(this, "group_by")}
							/>
						) : null}
						{this.state.form.type == "kanban" ? (
							<Select
								label={this.props.t("dashboard.view.modals.new.fieldscolor_by.label", "Ansvarig person")}
								options={this.getColumnOptions(["person"])}
								value={this.state.form.color_by}
								onChange={this.updateForm.bind(this, "color_by")}
							/>
						) : null}

						{this.state.form.type == "timeline" || this.state.form.type == "calendar" ? (
							<ChoiceList
								allowMultiple
								label={this.props.t("dashboard.view.modals.new.fields.value_columns.label", "Tidskolumner")}
								choices={this.getColumnOptions(["datetime", "timeline"])}
								selected={this.state.form.value_columns}
								onChange={this.updateForm.bind(this, "value_columns")}
							/>
						) : null}
						{this.state.form.type == "timeline" ? (
							<Select
								label={this.props.t("dashboard.view.modals.new.fields.timeline_group_by.label", "Gruppera på")}
								options={this.getColumnOptions(["group", "status", "person"])}
								value={this.state.form.group_by}
								onChange={this.updateForm.bind(this, "group_by")}
							/>
						) : null}
						{this.state.form.type == "timeline" || this.state.form.type == "calendar" ? (
							<Select
								label={this.props.t("dashboard.view.modals.new.fields.timeline_color_by.label", "Färg från")}
								options={this.getColumnOptions(["group", "status", "person"])}
								value={this.state.form.color_by}
								onChange={this.updateForm.bind(this, "color_by")}
							/>
						) : null}
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["dashboard", "common"], { withRef: true })(ViewCreator);
