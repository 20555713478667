/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import {
	AnalyticsBarStackedMinor,
	CancelMajor,
	CirclePlusMajor,
	CreditCardCancelMajor,
	DuplicateMinor,
	EmailMajor,
	ExternalMinor,
	ImportMinor,
	ReceiptMajor,
	SendMajor,
	VocabularyMajor,
} from "@shopify/polaris-icons";
import { store } from "src/js/store";
import { ButtonGroup, Spinner } from "@shopify/polaris";
import FortnoxInvoiceContext, { FortnoxInvoiceContextProvider } from "./FortnoxInvoiceContext";
import FortnoxPDFView from "../FortnoxPDFView";
import Header from "./Header";
import { downloadPDF } from "../Utilities";
import FortnoxInvoiceInfoView from "./FortnoxInvoiceInfoView";
import FortnoxContactView from "../FortnoxContactView";
import RowSheet from "../../Workspaces/components/RowSheet";
import BoardHelper from "../../Workspaces/BoardHelper";
import DocumentStatusBar from "../../Scrive/Document/DocumentStatusBar";
import Button from "src/js/components/Button";
import CopyModal from "./CopyModal";
import FortnoxCustomerFields from "../FortnoxCustomerFields";
import Badge from "src/js/components/Badge";
import FortnoxPDFUploadsList from "../FortnoxPDFUploadsList";
import { PDFContext } from "src/js/PDF/PDFContext";
import FortnoxDeliveryFields from "../FortnoxDeliveryFields";

const FortnoxInvoice = ({ id: propsId, match, history, t }) => {
	const {
		form,
		pdf,
		id,
		handleSave,
		contact,
		customer,
		row,
		handleOpenRowSelectModal,
		handleSendToScrive,
		upload,
		boardRowValue,
		isFetching,
		handleCancelInvoice,
		isSaving,
		handleCreditInvoice,
		handleSetAsSentInvoice,
		handleBookkeepInvoice,
		handleSendInvoiceAsEmailPre,
		handleSendInvoiceAsEInvoice,
		invoice,
		setForm,
		handleCreatePdfUpload,
		pdfUploads,
		isFetchingPDFUploads,
	} = useContext(FortnoxInvoiceContext);
	const [boardRowIsOpen, setBoardRowIsOpen] = useState(false);
	const { handleOpen: openpDF, handleClose: closePdf } = useContext(PDFContext);

	const handleOpenBoardRow = () => setBoardRowIsOpen(true);
	const handleCloseBoardRow = () => setBoardRowIsOpen(false);

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "pdf",
					content: "PDF",
					render: <FortnoxPDFView history={history} pdf={pdf} loading={isFetching} />,
					enabled: !!id,
				},
				{
					id: "info",
					content: t("fortnox.tabs.base_info", "Grunduppgifter"),
					render: <FortnoxInvoiceInfoView history={history} />,
				},
				{
					id: "customer",
					content: t("fortnox.tabs.customer_info", "Kunduppgifter"),
					render: <FortnoxCustomerFields history={history} setForm={(form: any) => setForm(form)} invoice={form} isSaving={isSaving} />,
				},
				{
					id: "delivery",
					content: t("fortnox.tabs.delivery.overview", "Leveransuppgifter"),
					render: <FortnoxDeliveryFields history={history} setForm={(form: any) => setForm(form)} invoice={form} isSaving={isSaving} />,
				},
				{
					id: "contact",
					content: t("fortnox.tabs.contact", "Kontakt"),
					render: (
						<FortnoxContactView contact={contact} customer={customer} history={history} type="invoice" EmailInformation={invoice?.EmailInformation} />
					),
					enabled: !!contact || !!customer,
				},
				{
					id: "uploads",
					content: (
						<div style={{ display: "flex", gap: "0.3125rem", alignItems: "center" }}>
							{isFetchingPDFUploads && <Spinner size="small" />}
							{t("fortnox.tabs.pdf_uploads", "Sellfinity PDF's")}
							{!!pdfUploads?.files?.length && <Badge status="success">{pdfUploads?.files?.length}</Badge>}
						</div>
					),
					render: <FortnoxPDFUploadsList data={pdfUploads} history={history} isLoading={!!isFetchingPDFUploads} />,
					enabled: !!contact || !!customer,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, id, contact, customer, t, pdf, isFetching, invoice, setForm, form, isSaving, pdfUploads, isFetchingPDFUploads]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;
		try {
			await handleSave(form as FortnoxInvoiceType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);
	const board = store.getState().boards[row?.board_id || store.getState().account.sales_board_id];
	const file = boardRowValue?.value?.find?.((f) => f.id === upload?.id);

	const sendActions = [
		{
			content: t("scrive.actions.prepare1", "Skicka till Scrive"),
			onAction: handleSendToScrive,
			icon: SendMajor,
			enabled: !!row && !upload?.scrive_id,
			confirmation: {
				title: t("fortnox.invoice.actions.scrive.title", "Skicka till Scrive"),
				content: t("fortnox.invoice.actions.scrive.confirmation", "Är du säker på att du vill skicka fakturan till Scrive?"),
				confirm: t("fortnox.invoice.actions.scrive.confirm", "Skicka till Scrive"),
				cancel: t("common.actions.cancel", "Avbryt"),
			},
		},
		{
			content: t("fortnox.actions.send_email", "Skicka som e-post"),
			onAction: () => handleSendInvoiceAsEmailPre(),
			icon: EmailMajor,
			enabled: !!form?.DocumentNumber,
		},
		{
			content: t("fortnox.actions.send_einvoice", "Skicka som e-invoice"),
			onAction: handleSendInvoiceAsEInvoice,
			icon: ReceiptMajor,
			enabled: !!form?.DocumentNumber,
			confirmation: {
				title: t("fortnox.invoice.actions.send_as_einvoice.title", "Skicka som e-invoice"),
				content: t("fortnox.invoice.actions.send_as_einvoice.confirmation", "Är du säker på att du vill skicka fakturan som e-invoice?"),
				confirm: t("fortnox.invoice.actions.send_as_einvoice.confirm", "Skicka som e-invoice"),
				cancel: t("common.actions.cancel", "Avbryt"),
			},
		},
		{
			content: t("fortnox.invoice.actions.setAsSent.title", "Markera som skickad"),
			icon: SendMajor,
			onAction: handleSetAsSentInvoice,
			enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.Sent),
			confirmation: {
				title: t("fortnox.invoice.actions.setAsSent.title", "Markera som skickad"),
				content: t("fortnox.invoice.actions.setAsSent.confirmation", "Är du säker på att du vill markera fakturan som skickad?"),
				confirm: t("fortnox.invoice.actions.setAsSent.confirm", "Markera som skickad"),
				cancel: t("common.actions.cancel", "Avbryt"),
			},
		},
	];

	const handleCreatePdf = async ({ template, pages, name, variables }) => {
		const res = await handleCreatePdfUpload(form as FortnoxInvoiceType, {
			template,
			pages,
			name,
			variables,
		});

		closePdf();
		return res;
	};

	return (
		<Page
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: form?.DocumentNumber
					? t("fortnox.invoice.title", "Fortnox faktura: {{id}}", { id: form?.DocumentNumber || "-" })
					: t("fortnox.invoice.new_title", "Ny Fortnox faktura")
			}
			history={history}
			titleMetadata={
				<>
					{file?.scrive_id && (
						<DocumentStatusBar
							onClick={() => {
								history.push(`/admin/scrive/documents/${file.scrive_id}`, { document: file.scrive_data, edit: true, tabId: "parties" });
							}}
							document={file.scrive_data}
						/>
					)}
				</>
			}
			primaryAction={
				form?.DocumentNumber ? (
					<ButtonGroup>
						<Button
							primary
							disabled={!form || form.Cancelled || form.Booked || isSaving}
							onClick={() => handleSendInvoiceAsEmailPre({ bookkeep: true })}
							connectedDisclosure={{
								actions: [
									...sendActions,
									{
										content: t("fortnox.invoice.actions.bookkeep", "Bokför"),
										icon: VocabularyMajor,
										onAction: handleBookkeepInvoice,
										enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.Booked),
										confirmation: {
											title: t("fortnox.invoice.actions.bookkeep.title", "Bokför Fakturan"),
											content: t("fortnox.invoice.actions.bookkeep.confirmation", "Är du säker på att du vill bokföra fakturan?"),
											confirm: t("fortnox.invoice.actions.bookkeep.confirm", "Bokför Fakturan"),
											cancel: t("common.actions.cancel", "Avbryt"),
										},
									},
								],
							}}
						>
							{t("fortnox.actions.send_and_book", "Skicka och bokför")}
						</Button>
						<Button primary disabled={!form || form.Cancelled || form.Booked} loading={isSaving} onClick={handleSaveForm}>
							{t("fortnox.actions.save", "Spara")}
						</Button>
					</ButtonGroup>
				) : (
					{
						content: form?.DocumentNumber ? t("fortnox.actions.save", "Spara") : t("fortnox.actions.create", "Skapa"),
						onAction: handleSaveForm,
						disabled: !form || form.Cancelled,
						loading: isSaving,
					}
				)
			}
			secondaryActions={[
				{
					content: t("fortnox.actions.download", "Ladda ner"),
					onAction: () => downloadPDF(pdf, `fortnox_invoice_${form?.DocumentNumber}.pdf`),
					disabled: !pdf,
					icon: ImportMinor,
					enabled: !!form?.DocumentNumber,
				},
				{
					content: t("fortnox.actions.download2", "Skapa sellfinity PDF"),
					onAction: () => openpDF({ Fortnox: { invoice: form, customer }, contact }, { saveHandler: handleCreatePdf }),
					icon: ImportMinor,
				},
				{
					isGroup: true,
					title: t("common.actions.row", "{{singular}}", { singular: board?.singular?.capitalize() || "Rad" }),
					icon: AnalyticsBarStackedMinor,
					actions: [
						{
							content: t("fortnox.actions.open_row", "Öppna {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenBoardRow,
							icon: AnalyticsBarStackedMinor,
							enabled: !!row,
						},
						{
							content: t("fortnox.actions.select_row", "Välj {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenRowSelectModal,
							icon: AnalyticsBarStackedMinor,
							enabled: !row,
						},
						{
							content: t("fortnox.actions.change_row", "Ändra {{singular}}", { singular: board?.singular || "rad" }),
							onAction: handleOpenRowSelectModal,
							icon: AnalyticsBarStackedMinor,
							enabled: !!row,
						},
					].filter((a) => !("enabled" in a) || a.enabled),
				},

				{
					content: t("fortnox.invoice.actions.credit", "Kreditera fakturan"),
					icon: CreditCardCancelMajor,
					onAction: handleCreditInvoice,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled && !form?.CreditInvoiceReference && form?.Booked),
					confirmation: {
						title: t("fortnox.invoice.actions.credit.title", "Kreditera Fakturan"),
						content: t("fortnox.invoice.actions.credit.confirmation", "Är du säker på att du vill kreditera fakturan?"),
						confirm: t("fortnox.invoice.actions.credit.confirm", "Kreditera Fakturan"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
				},
				{
					content: t("fortnox.invoice.actions.copy.title", "Kopiera faktura"),
					icon: DuplicateMinor,
					modal: <CopyModal invoice={form} />,
				},
				{
					content: t("fortnox.actions.new_invoice", "Ny faktura"),
					icon: CirclePlusMajor,
					onAction: () => {
						history.push(`/admin/fortnox/contacts/${contact?.id}/invoices/create`, {
							contact,
							customer,
						});
					},
					enabled: !!contact?.id,
				},
				{
					content: t("fortnox.actions.contact_name1", `Kontakt ({{contact}})`, { contact: contact?.name }),
					tooltip: t("fortnox.actions.go_to_contact1", `Gå till kontakt ({{contact}})`, { contact: contact?.name }),
					icon: ExternalMinor,
					onAction: () => {
						history.push(`/admin/contacts/${contact?.id}#fortnox_invoices`, {
							contact,
							customer,
						});
					},
					enabled: !!contact?.id,
				},
				{
					content: t("fortnox.invoice.actions.cancel.title", "Makulera fakturan"),
					icon: CancelMajor,
					onAction: handleCancelInvoice,
					enabled: !!(form?.DocumentNumber && !form?.Cancelled),
					destructive: true,
					confirmation: {
						title: t("fortnox.invoice.actions.cancel.title", "Makulera Fakturan"),
						content: t("fortnox.invoice.actions.cancel.confirmation", "Är du säker på att du vill makulera fakturan?"),
						confirm: t("fortnox.invoice.actions.cancel.confirm", "Makulera Fakturan"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
				},
				{
					content: t("fortnox.order.actions.create_order", "Skapad ifrån Order {{order}}", { order: form?.OrderReference }),
					onAction: () => {
						history.push(`/admin/fortnox/orders/${form?.OrderReference}`);
					},
					icon: ExternalMinor,
					enabled: !!form?.OrderReference,
				},
			]
				.filter((a) => !("enabled" in a) || a.enabled)
				?.map((a) => ({ ...a, disabled: isSaving || a.disabled }))}
		>
			<Header invoice={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
			<RowSheet
				open={boardRowIsOpen}
				row={row}
				board={board}
				initialTabIndex={0}
				onUpdateRow={(row) => {
					BoardHelper.updateRows([row], "update");
				}}
				onClose={handleCloseBoardRow}
			/>
		</Page>
	);
};

export default withTranslation(["fortnox", "common"])((props) => {
	return (
		<FortnoxInvoiceContextProvider {...props}>
			<FortnoxInvoice {...props} />
		</FortnoxInvoiceContextProvider>
	);
});
