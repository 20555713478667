import { FormLayout, TextField } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import React, { Component } from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { store } from "../../../store";
import { toastr } from "../../../components/toastr";
import UploadModal from "../../../components/UploadModal";
import ProfileAvatar from "../../../components/ProfileAvatar";
import UploadProfileAvatar from "../../../components/UploadProfileAvatar";

class EnterUserInfo extends Component {
	constructor(props) {
		super(props);
		this.state = { form: this.getUser() };
	}

	onChange(field, value) {
		this.state.form[field] = value;
		this.setState({ form: this.state.form });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	onSave() {
		API.put("/api/me.json", this.state.form)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				const user = { ...store.getState().user, ...result.data.user };
				this.setState({ form: user });
				store.dispatch({ type: "SET_USER", user });
				// toastr.success("Uppdaterade dina uppgifter");
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			});
	}

	getUser() {
		const user = store.getState().user;
		if (user.name === user.email) {
			return { ...user, name: "" };
		}
		return { ...user };
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	openModal(field) {
		this.setState({ [field]: true });
	}

	closeModal(field) {
		this.setState({ [field]: false });
	}

	// eslint-disable-next-line react/no-unused-class-component-methods
	removeProfileImage() {
		this.state.form.avatar = "";
		this.setState({ form: this.state.form, openUploadImage: false });
		this.saveForm();
	}

	render() {
		return (
			<FormLayout>
				<ProfileAvatarWrapper>
					{/* <ProfileAvatar onClick={this.openModal.bind(this, "openUploadImage")} user={this.state.form} tooltip="Profilbild" size={45} /> */}
					<div className="pp">
						<ProfileAvatar user={store.getState().user} size={45} tooltip={false}>
							<UploadProfileAvatar
								tooltip={null}
								onChange={(user) => {
									// this.contentRef.current.onChange("avatar");
									// eslint-disable-next-line react/no-unused-state
									this.setState({ rnd: Math.random() });
								}}
							/>
						</ProfileAvatar>
					</div>
				</ProfileAvatarWrapper>
				<TextField
					placeholder={this.props.t("user.fields.first_and_lastname", "För- och efternamn")}
					value={this.state.form.name}
					onChange={this.onChange.bind(this, "name")}
				/>
				<TextField
					placeholder={this.props.t("user.fields.email", "E-mail")}
					value={this.state.form.email}
					onChange={this.onChange.bind(this, "email")}
				/>
				<TextField
					placeholder={this.props.t("user.fields.mobile", "Mobilnummer")}
					value={this.state.form.phone}
					onChange={this.onChange.bind(this, "phone")}
				/>

				<UploadModal
					open={this.state.openUploadImage}
					onClose={this.closeModal.bind(this, "openUploadImage")}
					onSelect={(imgs) => {
						if (imgs[0] && imgs[0].url) {
							this.state.form.avatar = imgs[0].url;
							this.setState({ form: this.state.form, openUploadImage: false });
						}
					}}
					allowMultiple={false}
					secondaryActions={
						this.state.form.avatar && [
							{
								content: this.props.t("user.profile.actions.remove", "Ta bort profilbild"),
								onAction: this.onChange.bind(this, "avatar", ""),
								destructive: true,
								icon: DeleteMajor,
							},
						]
					}
					as="profile"
				/>
			</FormLayout>
		);
	}
}
export default withTranslation(["user"], { withRef: true })(EnterUserInfo);

const ProfileAvatarWrapper = styled.div`
	grid-area: right;

	.pp {
		position: relative;
		display: flex;
		justify-content: flex-start;
	}

	.texttt {
		text-align: center;
		font-weight: bold;
		font-size: 2em;
		line-height: 3em;
		margin-top: 1.2500rem;
	}

	.Polaris-Avatar,
	.profile {
		width: inherit;
		height: inherit;
		object-fit: cover;
		border-radius: 50%;
	}

	.update-profile-image__button {
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0.5;
		transition: opacity 250ms;
		transform: translate(25%, 25%);

		&.no_avatar {
			opacity: 0.85;
		}

		&.active,
		&:hover {
			opacity: 1;
		}
	}
`;
