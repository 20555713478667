import React, { Component } from "react";
import {  TextField, Button, ChoiceList, Select } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr";
import Tabs from "src/js/components/Tabs";
import Modal from "src/js/components/modal";

class SavedFilters extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: 0,
			filters: props.filters || [],
			showCustom: false,
			saveOption: "new",
			saveOverwriteValue: "",
		};
	}

	componentDidMount() {
		this.getSelectedTab();
	}

	handleTabChange(selected) {
		if (selected == 0) {
			this.props.onChangeFilters([]);
			return;
		}
		const index = selected - 1;
		if (index < this.props.saved_filters.length) {
			this.props.onChangeFilters(JSON.parse(JSON.stringify(this.props.saved_filters[index].filters)));
		}
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (JSON.stringify(props.filters) != JSON.stringify(this.state.filters)) {
			this.setState({ filters: JSON.parse(JSON.stringify(props.filters)) }, this.getSelectedTab.bind(this));
		}
	}

	saveFilters() {
		this.setState({ saving: true });
		if (this.state.saveOption == "new") {
			API.post(
				"/api/boards/" + this.props.board.id + "/filters.json",
				{ title: this.state.saveNewValue, filters: this.props.filters },
				{ params: {} }
			)
				.then((result) => {
					this.setState({ saving: false, saveFilterModalOpen: false });
					this.props.saved_filters.push(result.data.filter);
					this.props.updateSavedFilters(this.props.saved_filters);
					this.getSelectedTab();
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		} else {
			API.put(
				`/api/boards/${this.props.board.id}/filters/${this.state.saveOverwriteValue}.json`,
				{ title: this.state.saveNewValue, filters: this.props.filters },
				{ params: {} }
			)
				.then((result) => {
					for (let i = 0; i < this.props.saved_filters.length; i++) {
						if (this.props.saved_filters[i].id + "" == result.data.filter.id + "") {
							this.props.saved_filters[i] = result.data.filter;
							break;
						}
					}

					this.props.updateSavedFilters(this.props.saved_filters);

					this.setState({ saving: false, saveFilterModalOpen: false });
					this.getSelectedTab();
				})
				.catch((error) => {
					toastr.error(error);
					this.setState({ saving: false });
				});
		}
	}

	removeFilters() {
		const currentFilterId = this.props.saved_filters[this.state.selected - 1].id;
		this.props.saved_filters.splice(this.state.selected - 1, 1);
		this.getSelectedTab();
		this.setState({ saving: true });
		API.delete("/api/boards/" + this.props.board.id + "/filters/" + currentFilterId + ".json", {}, { params: {} })
			.then((result) => {
				this.setState({ saving: false, saveFilterModalOpen: false });
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ saving: false });
			});
	}

	getSelectedTab() {
		if (this.state.filters.length < 1) {
			this.setState({ selected: 0, showCustom: false });

			this.props.onSetAction(null, null);
		} else {
			const foundIndex = this.props.saved_filters.findIndex((filt) => JSON.stringify(filt.filters) == JSON.stringify(this.state.filters));
			if (foundIndex >= 0) {
				this.setState({ selected: foundIndex + 1, showCustom: false });

				this.props.onSetAction(
					<div style={{ paddingLeft: 8 }}>
						<Button destructive icon={DeleteMajor} onClick={this.removeFilters.bind(this)} />
					</div>,
					this.props.saved_filters[foundIndex]
				);
			} else {
				this.setState({
					selected: this.props.saved_filters.length + 1,
					showCustom: true,
				});
				this.props.onSetAction(
					<div style={{ paddingLeft: 8 }}>
						<Button
							onClick={() => {
								this.setState({ saveFilterModalOpen: true });
							}}
						>
							{this.props.t("common.actions.save", "Spara")}
						</Button>
					</div>,
					null
				);
			}
		}
	}

	handleModalOptionChange(saveOption) {
		this.setState({ saveOption });
	}

	render() {
		const tabs = [];

		tabs.push({
			id: "all",
			content: `${this.props.t("filters.terms.all", "Alla")} ${(this.props.board && this.props.board.plural) || "rader"}`,
			panelID: "all",
		});

		for (let i = 0; i < this.props.saved_filters.length; i++) {
			tabs.push({
				id: this.props.saved_filters[i].id + "",
				content: this.props.saved_filters[i].title,
				panelID: this.props.saved_filters[i].id + "",
			});
		}

		if (this.state.showCustom) {
			tabs.push({
				id: "custom",
				content: this.props.t("filters.terms.own_search", "Egen sökning"),
				panelID: "custom",
			});
		}

		const choices = [
			{
				label: this.props.t("searches.filters.modal.save_as_a_new_filter", "Spara som en nytt filter"),
				value: "new",
				renderChildren: (isSelected) =>
					isSelected && (
						<TextField
							label={this.props.t("searches.filters.modal.new_saved_filter_name", "Namn på nytt filter")}
							autoFocus
							value={this.state.saveNewValue}
							onChange={(newValue) => {
								this.setState({ saveNewValue: newValue });
							}}
						/>
					),
			},
		];

		if (this.props.saved_filters && this.props.saved_filters.length) {
			const options = this.props.saved_filters.map((filter) => ({
				value: filter.id + "",
				label: filter.title,
			}));

			const selected = this.props.saved_filters.find((filter) => String(filter.id) == this.state.saveOverwriteValue);

			if (!selected) {
				this.setState({
					saveOverwriteValue: String(this.props.saved_filters[0].id),
				});
			}

			choices.push({
				label: this.props.t("searches.filters.modal.overwrite_an_existing_filter", " Skriv över ett sparat filter"),
				value: "existing",
				renderChildren: (isSelected) =>
					isSelected && (
						<Select
							label={this.props.t("searches.filters.modal.select_filter_to_overwrite", "Vilket filter vill du skriva över")}
							options={options}
							onChange={(newValue) => {
								this.setState({ saveOverwriteValue: newValue });
							}}
							value={this.state.saveOverwriteValue}
						/>
					),
			});
		}

		return (
			<React.Fragment>
				<Tabs tabs={tabs} selected={this.state.selected} onSelect={this.handleTabChange.bind(this)} />
				<Modal
					open={this.state.saveFilterModalOpen}
					onClose={() => {
						this.setState({ saveFilterModalOpen: false });
					}}
					title={this.props.t("filters.terms.save", "Spara filter")}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						disabled: !!(this.state.saveOption == "new" && !this.state.saveNewValue),
						onAction: this.saveFilters.bind(this),
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ saveFilterModalOpen: false });
							},
						},
					]}
				>
					<Modal.Section>
						<ChoiceList
							choices={choices}
							selected={this.state.saveOption ? [this.state.saveOption] : []}
							onChange={this.handleModalOptionChange.bind(this)}
						/>
					</Modal.Section>
				</Modal>
			</React.Fragment>
		);
	}
}
export default withTranslation(["row", "filters", "common"], { withRef: true })(SavedFilters);
