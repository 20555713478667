// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
	TextField,
	IndexFilters as PolarisIndexFilters,
	Select,
	SortButtonChoice,
	OptionList,
	FormLayout,
	LegacyStack,
	Tag,
	DatePicker,
	Button,
	Checkbox,
	Avatar,
} from "@shopify/polaris";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { IndexFiltersProps as PolarisIndexFiltersProps, AppliedFilterInterface } from "@shopify/polaris";
import { useState, useCallback, useImperativeHandle, forwardRef, useMemo } from "react";
import debounce from "lodash/debounce";
import { useDeepCompareCallback, useDeepCompareEffect } from "use-deep-compare";
import moment from "moment";
import { store } from "../../store";
import API from "../../API";
import { toastr } from "../toastr";
import {
	filterArrayToObject,
	getContactFromId,
	getDateOptions,
	getGroupFromId,
	getProjectFromId,
	getUserFromId,
	isEmpty,
	isSameSearch,
	polarisSortValueTosSortValue,
	sortValueToPolarisSortValue,
} from "./Utilities";
import SavedSearchModal from "./SavedSearchModal";
import type { SavedSearch, SavedSearchFilter, Filters, AppliedFilters } from "./types";
import SearchField from "../search_field";
import OnMount from "src/js/components/OnMount";
import { UsersFilter } from "./filters";
import useQuery from "src/js/hooks/useQuery";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import MyFilterOptionList from "../Filters/MyFilterOptionList";
// import NewDatePicker from "../NewDatePicker";
import ComboboxFilter from "../Filters/ComboBoxFilter";
import { WithTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "../modal";
import NestedChoiceList from "../NestedChoiceList";
import UserRoles from "src/js/views/Users/UserRoles";
import flattenOptions from "../NestedChoiceList/flattenOptions";

type IndexFiltersProps = {
	defaultSavedSearches?: SavedSearch[] | any;
	onChange?: any;
	filters?: Filters;
	appliedFilters?: AppliedFilters;
	onChangeFilters?: any;
	handle?: any;
	resource?: any;
	search?: any;
	onSearchChange?: any;
	loading?: any;

	onSortChange?: any;
	sortValue?: any;
	sortOptions?: any;
	resourceName?: any;
	defaultFilters?: SavedSearchFilter[];
	handleFiltersClearAll: () => void;
	selected?: number;

	tabsEnabled?: boolean;
	searchFiltersEnabled?: boolean;
	primaryAction?: PolarisIndexFiltersProps["primaryAction"];
} & WithTranslation;

type DatePickerOptions = {
	open: boolean;
	filter?: any;
	month?: number;
	year?: number;
	selection?: any;
};

const IndexFilters = forwardRef(
	(
		{
			defaultSavedSearches: propsDefaultSavedSearches = [],
			onChange,
			filters = [],
			appliedFilters = [],
			onChangeFilters,
			handle: propsHandle,
			resource,
			search,
			onSearchChange,
			loading: parentLoading,
			onSortChange,
			sortValue,
			sortOptions,
			defaultFilters,
			handleFiltersClearAll,
			selected: propsSelected,
			primaryAction: propsPrimaryAction,

			tabsEnabled = true,
			searchFiltersEnabled = true,

			t,
		}: IndexFiltersProps,
		ref
	) => {
		const defaultSavedSearches: SavedSearch[] | any = [
			{
				id: "all",
				content: "Alla",
				title: "Alla",
				accessibilityLabel: "Alla",
				panelID: "all-tab",
				filters: defaultFilters || [],
			},
			...(propsDefaultSavedSearches || []),
		];
		const handle = propsHandle || resource;
		// const [savedSeaches, setSavedSeaches] = useState<SavedSearch[]>(defaultSavedSearches || []);
		// const [loading, setLoading] = useState(false);
		const [savingView, setSavingView] = useState(false);
		const [selected, setSelected] = useState(propsSelected || 0);
		const [datePickerOptions, setDatePickerOptions] = useState<DatePickerOptions>({
			open: false,
			filter: null,
			month: new Date().getMonth(),
			year: new Date().getFullYear(),
			selection: null,
		});
		const [filterObject, setFilterObject] = useState(filterArrayToObject(appliedFilters));
		const [viewInEdit, setViewInEdit] = useState<SavedSearch | null>(null);
		const [projects, setProjects] = useState<any>({});

		const storeUsers = useSelector((state: any) => state.users || []);
		const storeGroups = useSelector((state: any) => state.groups || []);

		const queryClient = useQueryClient();

		const setSortChange = (value: (SortButtonChoice["value"] | string)[]) => {
			onSortChange(polarisSortValueTosSortValue(value?.[0]));
		};

		const loadSavedSearches = useDeepCompareCallback(async () => {
			try {
				const result = await API.get("/api/saved_searches/" + handle + ".json");

				return result.data.saved_searches;
			} catch (e) {
				toastr.error(e);
				throw e;
			}
		}, [defaultSavedSearches]);

		const queryKey = [handle];
		const {
			data,
			isFetching: loading,
			refetch,
		} = useQuery({
			queryKey,
			queryFn: loadSavedSearches,
			initialData: [...(defaultSavedSearches || []), ...(store.getState().saved_searches?.filter((s) => s.handle == handle) || [])],
			// enabled: false,

			// onSuccess: (data) => {
			// 	const matchedSearchFromUrlIndex = data.findIndex((savedSearch) => isSameSearch(savedSearch, appliedFilters, search, sortValue));
			// 	if (matchedSearchFromUrlIndex !== -1 && data[matchedSearchFromUrlIndex]) {
			// 		onSelectView(matchedSearchFromUrlIndex);
			// 		// onChange(savedSeaches[matchedSearchFromUrlIndex]);
			// 	}
			// },
		});
		const savedSeaches = [...(defaultSavedSearches || []), ...(data || [])];

		useDeepCompareEffect(() => {
			const matchedSearchFromUrlIndex = savedSeaches.findIndex((savedSearch) => isSameSearch(savedSearch, appliedFilters, search, sortValue));
			if (
				matchedSearchFromUrlIndex !== -1 &&
				savedSeaches[matchedSearchFromUrlIndex] &&
				//First default tab is selected. Without this, when chacning search or filters it might selected another tab is matches and then you can't save the new filters on tab you want to save at. BUT maybe this is the behavior we want? For it to always select the first tab that matches the search/filters?
				selected === 0
			) {
				onSelectView(matchedSearchFromUrlIndex);
				// onChange(savedSeaches[matchedSearchFromUrlIndex]);
			}
		}, [savedSeaches, appliedFilters, search, sortValue]);

		const onSelectView = (index: number) => {
			setSelected(index);
			onChange(savedSeaches[index]);
		};

		const deleteViewReqeust = async ({ data, id }: { data: any; id: number | string }) => {
			await API.delete("/api/saved_searches/" + id + ".json");
			return data;
		};

		const deleteMutation = useMutation(deleteViewReqeust, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				refetch();
			},
		});

		const deleteView = async (index: number, id: number | string) => {
			try {
				const newSavedSearches = [...savedSeaches];
				newSavedSearches.splice(index, 1);
				onSelectView(Math.max(0, index - 1));

				await deleteMutation.mutateAsync({ data: newSavedSearches, id });
			} catch (e) {
				toastr.error(e);
			}
		};

		// useDeepCompareEffect(() => {
		// 	loadSavedSearches(handle);
		// 	const matchedSearchFromUrlIndex = savedSeaches.findIndex((savedSearch) => isSameSearch(savedSearch, appliedFilters, search, sortValue));

		// 	if (matchedSearchFromUrlIndex !== -1) {
		// 		onSelectView(matchedSearchFromUrlIndex);
		// 		// onChange(savedSeaches[matchedSearchFromUrlIndex]);
		// 	}
		// }, [handle, loadSavedSearches]);

		useDeepCompareEffect(() => {
			const filterObj = filterArrayToObject(appliedFilters);
			setFilterObject(filterObj);
		}, [appliedFilters]);

		const tabs = savedSeaches.map((item, index) => ({
			content: item.title,
			index,
			onAction: () => {},
			// id: `${item}-${index}`,
			id: item.id,
			isLocked: index === 0 || defaultSavedSearches?.find((defaultItem: SavedSearch) => item.id === defaultItem.id),
			viewNames: [],
			actions:
				index === 0 || defaultSavedSearches?.find((defaultItem: SavedSearch) => item.id === defaultItem.id)
					? []
					: [
							{
								type: "edit",
								content: "Redigera",
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								onAction: (title: string) => {
									setViewInEdit(item);
								},
							},
							{
								type: "delete",
								content: "Ta bort",
								onAction: () => {},
								onPrimaryAction: async (): Promise<boolean> => {
									deleteView(index, item.id);
									return true;
								},
							},
					  ],
		}));

		const onCreateNewViewRequest = async (title: string) => {
			try {
				setSavingView(true);
				const res = await API.post("/api/saved_searches/" + handle + ".json", {
					title,
					filters: appliedFilters,
					search: search || "",
					// sorting: this.state.sorting,
				});

				return [...savedSeaches, res.data.saved_search];
			} catch (e) {
				toastr.error(e);
				throw e;
			} finally {
				setSavingView(false);
			}
		};

		const createMutation = useMutation(onCreateNewViewRequest, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				refetch();
				return true;
			},
		});

		const onCreateNewView = async (title: string) => {
			await createMutation.mutateAsync(title);
			return true;
		};

		const onHandleSaveRequest = async (view) => {
			setSavingView(true);
			try {
				await API.put(`/api/saved_searches/${view.id}.json`, view);
				const newSavedSearches = [...savedSeaches];
				if (view) {
					const index = savedSeaches.findIndex((savedSearch) => savedSearch.id === view.id);
					if (index >= 0) {
						newSavedSearches[index] = view;
						// setSavedSeaches(newSavedSearches);
					}
				}

				toastr.success("Sparad sökning uppdaterad");

				return newSavedSearches;
			} catch (e) {
				toastr.error(e);
				throw e;
			} finally {
				setSavingView(false);
			}
		};

		const editMutation = useMutation(onHandleSaveRequest, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				refetch();
				return true;
			},
		});

		const onHandleSave = async () => {
			const currentView = savedSeaches[selected];
			const view = { ...currentView, filters: appliedFilters, search: search || "" };
			await editMutation.mutateAsync(view);
			return true;
		};

		const handleChangeFilters = useCallback(
			(filters) => {
				const arr = Object.entries(filters)
					.map(([key, value]) => ({ key, value }))
					.filter(({ value }: any) => !isEmpty(value));

				onChangeFilters(arr);
			},
			[onChangeFilters]
		);
		const handleChangeFiltersDebounced = debounce((filters) => handleChangeFilters(filters), 300);

		const handleSetValue = useCallback(
			(filter: any, delayed = false) =>
				(value: any) => {
					const { key, type } = filter || {};

					if (type == "dateSelector" && (value == "custom" || value.substring(0, 1) == "2" || value.substring(0, 1) == "1")) {
						let now = new Date();
						let fromDate = new Date();
						let toDate = new Date();
						toDate.setDate(toDate.getDate() + 7);
						if (value.substring(0, 1) == "2" || value.substring(0, 1) == "1") {
							if (filter.range) {
								const pcs = value.split(" - ");
								now = new Date(pcs[0]);
								fromDate = new Date(pcs[0]);
								toDate = new Date(pcs[1]);
							} else {
								now = new Date(value);
								toDate = new Date(value);
								fromDate = new Date(value);
							}
						}

						setDatePickerOptions({
							open: true,
							month: now.getMonth(),
							year: now.getFullYear(),
							filter,
							selection: {
								start: fromDate,
								end: toDate,
							},
						});

						return;
						//
					} else if (type == "tag" || type == "contact_tag") {
						value = value.title;
					} else if (type == "contact_tags") {
						const currentValue: any = filterObject[filter?.key]?.split(",") || [];
						if (!currentValue?.includes(value.title)) currentValue.push(value.title);
						value = currentValue.join(",");
					} else if (["resource", "user", "group", "contact", "project"].includes(type)) {
						value = value?.id || value;
					} else if (type == "project") {
						setProjects((c: any) => ({ ...c, [value.id]: value }));
						value = value.id;
					}
					const filters = { ...filterObject };
					filters[key] = value;
					setFilterObject(filters);
					if (delayed) {
						handleChangeFiltersDebounced(filters);
					} else {
						handleChangeFilters(filters);
					}
				},
			[handleChangeFilters, handleChangeFiltersDebounced, filterObject]
		);

		const primaryAction: PolarisIndexFiltersProps["primaryAction"] =
			propsPrimaryAction !== undefined
				? propsPrimaryAction
				: selected <= (defaultSavedSearches?.length || 0) - 1
				? {
						type: "save-as",
						onAction: onCreateNewView,
						disabled: savingView,
						loading: savingView,
				  }
				: {
						type: "save",
						onAction: onHandleSave,
						disabled: savingView,
						loading: savingView,
				  };

		const handleRemoveFilter = useCallback(
			(key: string) => {
				const filters = { ...filterObject };
				delete filters[key];
				setFilterObject(filters);
				handleChangeFilters(filters);
			},
			[filterObject, handleChangeFilters]
		);

		const handleRemoveTag = useCallback(
			(key: string) => (index) => {
				const filters = { ...filterObject };
				const currentValue: any = filters[key]?.split(",") || [];
				currentValue.splice(index, 1);
				filters[key] = currentValue.join(",");
				setFilterObject(filters);
				handleChangeFilters(filters);
			},
			[filterObject, handleChangeFilters]
		);

		const transformedFiltersToComponents = useMemo(() => {
			return filters
				?.map((filter) => ({ ...filter, pinned: filter.pinned ?? filter.shortcut }))
				.map((filter) => {
					// const value: any = appliedFilters.find((appliedFilter) => appliedFilter?.key === filter.key)?.value;
					const value: any = filterObject[filter?.key];

					if (filter.type === "select") {
						return {
							...filter,
							filter: (
								// <Select
								// 	label={filter.label}
								// 	labelHidden
								// 	options={filter.options}
								// 	onChange={handleSetValue(filter)(value)}
								// 	value={appliedFilters.find((appliedFilter) => appliedFilter?.key === filter.key)?.value}
								// />

								<OptionList options={filter.options} selected={value ? [value] : []} onChange={(value) => handleSetValue(filter)(value?.[0])} />
							),
						};
					} else if (filter.type === "options") {
						return {
							...filter,
							filter: (
								<MyFilterOptionList
									filter={filter}
									selected={Array.isArray(value) ? value : value ? [value] : []}
									onChange={(value) => {
										if (filter.allowMultiple) {
											handleSetValue(filter)(value);
										} else {
											handleSetValue(filter)(value?.[0]);
										}
									}}
								/>
							),
						};
					} else if (filter.type === "user_roles") {
						return {
							...filter,
							filter: <UserRoles disabled={false} roles={filter.options} user={{ all_permissions: value }} onChange={handleSetValue(filter)} />,
						};
					} else if (filter.type === "nested_options") {
						return {
							...filter,
							filter: (
								<NestedChoiceList
									options={filter.options}
									selected={Array.isArray(value) ? value : value ? [value] : []}
									onChange={(value) => {
										if (filter.allowMultiple) {
											handleSetValue(filter)(value);
										} else {
											handleSetValue(filter)(value?.[0]);
										}
									}}
									openNestedIfHidden
									// uncheckChildren
								/>
							),
						};
					} else if (filter.type === "date") {
						return {
							...filter,
							hideClearButton: true,
							filter: (
								// <NewDatePicker
								// 	style={{ width: "100%", maxWidth: "100%" }}
								// 	range={filter.range}
								// 	expanded
								// 	optionalTime={filter.optionalTime}
								// 	value={
								// 		filter.range
								// 			? {
								// 					start: this.state[filter.key],
								// 					end: this.state[filter.secondKey],
								// 			  }
								// 			: this.state[filter.key]
								// 	}
								// 	onChange={this.setValue.bind(this, filter.key, `date${filter.range ? "-range" : ""}`)}
								// />
								<>
									<OnMount
										onMount={() => {
											setDatePickerOptions({
												open: true,
												month: new Date(value || undefined).getMonth(),
												year: new Date(value || undefined).getFullYear(),
												filter,
												selection: {
													start: new Date(value || undefined),
													end: new Date(value || undefined),
												},
											});
										}}
									/>
									<Button
										plain
										removeUnderline
										onClick={() => {
											setDatePickerOptions({
												open: true,
												month: new Date(value || undefined).getMonth(),
												year: new Date(value || undefined).getFullYear(),
												filter,
												selection: {
													start: new Date(value || undefined),
													end: new Date(value || undefined),
												},
											});
										}}
									>
										{value ? moment(value).format("YYYY-MM-DD") : "Välj datum"}
									</Button>
								</>
							),
						};
					} else if (filter.type === "dateSelector") {
						const options = getDateOptions(filter, appliedFilters);
						// filter.type = "singlearray";
						return {
							...filter,
							filter: (
								<div style={{ marginLeft: "-1.0000rem", marginRight: "-1.0000rem" }}>
									<OptionList options={options} selected={value ? [value] : []} onChange={(value) => handleSetValue(filter)(value?.[0])} />
								</div>
							),
						};
					} else if (filter.type === "textField" || filter.type === "text") {
						return {
							...filter,
							filter: (
								<FormLayout>
									<Select
										label=""
										labelHidden
										options={[
											{ label: "Är lika med", value: "equals" },
											{ label: "Innehåller", value: "contains" },
											{ label: "Börjar med", value: "starts_with" },
											{ label: "Slutar med", value: "ends_with" },
										]}
									/>
									<TextField label="" labelHidden autoComplete="" value={value} onChange={handleSetValue(filter, true)} />
								</FormLayout>
							),
						};
					}

					// else if (filter.type === "boolean") {
					// 	return {
					// 		...filter,
					// 		filter: (
					// 			<ChoiceList
					// 				title={filter.label}
					// 				choices={[
					// 					{ label: "Ja", value: "1" },
					// 					{ label: "Nej", value: "0" },
					// 				]}
					// 				selected={value ? [value] : []}
					// 				onChange={(value) => handleSetValue(filter)(value?.[0])}
					// 			/>
					// 		),
					// 	};
					// }
					else if (filter.type === "checkbox" || filter.type === "boolean") {
						if (filter.options) {
							return {
								...filter,
								filter: (
									<div style={{ marginLeft: "-0.3125rem", marginRight: "-0.3125rem" }}>
										<OptionList options={filter.options} selected={value ? [value] : []} onChange={(value) => handleSetValue(filter)(value?.[0])} />
									</div>
								),
							};
						} else {
							return {
								...filter,
								filter: (
									<FormLayout>
										<Checkbox label={filter.label} checked={!!value} onChange={handleSetValue(filter)} />
									</FormLayout>
								),
							};
						}
					} else if (filter.type === "contact_tags") {
						return {
							...filter,
							filter: (
								<ComboboxFilter
									onSelect={handleSetValue(filter)}
									value={value?.split(",") || []}
									resource="contacts/tags.json"
									resourceName={{ singular: t("filters.tag.singular", "tagg"), plural: t("filters.tag.plural", "taggar") }}
									resource_handle="tags"
									// id_handle="title"
									label_handle="title"
									onRemove={handleRemoveTag(filter.key)}
									params={filter.params}
								/>
							),
						};
					} else if (filter.type === "contact") {
						return {
							...filter,
							filter: (
								<ComboboxFilter
									onSelect={(contact) => {
										store.dispatch({ type: "UPDATE_BOARD_CONTACT", contact });
										handleSetValue(filter)(contact);
									}}
									value={getContactFromId(value)?.name || null}
									resource="contacts"
									resourceName={{
										singular: t("filters.contact.singular", "kontakt"),
										plural: t("filters.contact.plural", "kontakter"),
									}}
									resource_handle="contacts"
									// id_handle="title"
									label_handle="name"
									onRemove={() => handleRemoveFilter(filter.key)}
								/>
							),
						};
					}

					// else if (filter.type === "contact_person_tag") {
					// 	component = (
					// 		<ComboboxFilter
					// 			onSelect={this.addTag.bind(this, filter.key)}
					// 			value={this.getTags(filter.key)}
					// 			resource="contacts/tags.json?type=person"
					// 			resourceName={{ singular: t("filters.tag.singular", "tagg"), plural: t("filters.tag.plural", "taggar") }}
					// 			resource_handle="tags"
					// 			// id_handle="title"
					// 			label_handle="title"
					// 			onRemove={this.removeTag.bind(this, filter.key)}
					// 		/>
					// 	);
					// }
					else if (filter.type === "groupuser") {
						return {
							...filter,
							filter: (
								<LegacyStack vertical>
									<SearchField
										className="filter-search"
										inline
										autoFocus
										resource="usergroups.json?enabled=1"
										items={[...(storeGroups || []), ...(storeUsers || []).map((user) => ({ ...user, is_group: 0 }))]}
										onSelect={(v) => {
											if (v.is_group == "1" || v.is_group == 1) {
												handleSetValue({ key: "group_id", type: "resource" })(v);
												handleRemoveFilter("user_id");
											} else {
												handleSetValue({ key: "user_id", type: "resource" })(v);
												handleRemoveFilter("group_id");
											}
										}}
										resourceName={{ singular: "användare/grupp", plural: "användare/grupper" }}
										resource_handle="usergroups"
										id_handle="id"
										label_handle="fullname"
									/>
									{value ? <Tag onRemove={() => handleRemoveFilter("user_id")}>{getUserFromId(value).fullname}</Tag> : null}
									{value ? <Tag onRemove={() => handleRemoveFilter("group_id")}>{getGroupFromId(value).fullname}</Tag> : null}
								</LegacyStack>
							),
						};
					} else if (filter.type === "group") {
						return {
							...filter,
							filter: (
								<ComboboxFilter
									options={storeGroups?.map((group) => ({ value: group.id, label: group.fullname }))}
									onSelect={handleSetValue(filter)}
									value={value}
									resourceName={{
										singular: t("filters.groups.singular", "grupp"),
										plural: t("filters.groups.plural", "grupper"),
									}}
								/>
							),
						};
					} else if (filter.type === "user") {
						return {
							...filter,
							filter: (
								<div>
									<MyFilterOptionList
										filter={{
											...filter,
											options: [
												...(filter.additionalOptions || []),
												...(storeUsers?.map((user) => ({
													value: user.id,
													label: user.name,
													media: <Avatar size="small" source={user.avatar} name={user.name} initials={user.initials} />,
												})) || []),
											],
											search: true,
											allowMultiple: false,
										}}
										selected={value ? [value] : []}
										onChange={(value) => handleSetValue(filter)(value?.[0])}
									/>
								</div>
							),
						};

						// return {
						// 	...filter,
						// 	filter: (
						// 		<ComboboxFilter
						// 			options={storeUsers?.map((user) => ({ value: user.id, label: user.name }))}
						// 			onSelect={handleSetValue(filter)}
						// 			value={value}
						// 			onRemove={() => handleRemoveFilter("user_id")}
						// 			resourceName={{
						// 				singular: t("filters.users.singular", "användare"),
						// 				plural: t("filters.users.plural", "användare"),
						// 			}}
						// 		/>
						// 	),
						// };
					} else if (filter.type === "users") {
						return {
							...filter,
							filter: <UsersFilter value={value} onChange={handleSetValue(filter)} />,
						};
					} else if (filter.type === "project") {
						return {
							...filter,
							filter: (
								<LegacyStack vertical>
									<SearchField
										className="filter-search"
										inline
										autoFocus
										resource="projects.json"
										onSelect={handleSetValue(filter)}
										resourceName={{ singular: "projekt", plural: "projekt" }}
										resource_handle="projects"
										id_handle="id"
										label_handle="title"
									/>
									{value ? <Tag onRemove={() => handleRemoveFilter("project_id")}>{getProjectFromId(value, projects)?.title}</Tag> : null}
								</LegacyStack>
							),
						};
					}

					return null;
				})
				.filter(Boolean);
		}, [filters, filterObject, handleRemoveFilter, handleSetValue, projects, t, appliedFilters, storeUsers, storeGroups, handleRemoveTag]);

		const disambiguatedAppliedFilters = Object?.entries(filterObject)?.map(([key, value]: any) => {
			const appliedFilter = appliedFilters.find((filter) => filter.key === key);

			return {
				...(appliedFilter || {}),
				key,
				label: disambiguateLabel(key, value),
				onRemove: () => handleRemoveFilter(key),
			};
		}) as unknown as AppliedFilterInterface[];

		useImperativeHandle(ref, () => ({
			changeTab: (id: string) => {
				return onChange(savedSeaches.find((savedSearch: SavedSearch) => savedSearch.id == id));
			},
			changeTabByIndex: (index: number) => {
				return onChange(savedSeaches[index]);
			},
			setSelectedTabIndex: (index: number) => {
				return setSelected(index);
			},
		}));

		return (
			<>
				<Filter1Wrapper>
					{tabsEnabled && (
						<PolarisIndexFilters
							// sortOptions={sortOptions?.map((option) => ({ ...option, value: sortValueToPolarisSortValue(option.value) }))}
							sortSelected={[sortValueToPolarisSortValue(sortValue)]}
							queryValue={search}
							queryPlaceholder="Sök.."
							onQueryChange={onSearchChange}
							onQueryClear={onSearchChange}
							primaryAction={primaryAction}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							tabs={tabs}
							selected={selected}
							onSelect={onSelectView}
							canCreateNewView
							onCreateNewView={onCreateNewView}
							filters={transformedFiltersToComponents}
							appliedFilters={disambiguatedAppliedFilters}
							onClearAll={handleFiltersClearAll}
							mode={"DEFAULT" as any}
							loading={loading || parentLoading}
							hideQueryField
							hideFilters
						/>
					)}
				</Filter1Wrapper>
				{searchFiltersEnabled && (
					<Filter2Wrapper>
						<PolarisIndexFilters
							sortOptions={sortOptions?.map((option) => ({ ...option, value: sortValueToPolarisSortValue(option.value) }))}
							sortSelected={[sortValueToPolarisSortValue(sortValue)]}
							queryValue={search}
							queryPlaceholder="Sök.."
							onQueryChange={onSearchChange}
							onQueryClear={onSearchChange}
							onSort={setSortChange}
							primaryAction={primaryAction}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore

							tabs={tabs}
							selected={selected}
							onSelect={onSelectView}
							canCreateNewView
							onCreateNewView={onCreateNewView}
							filters={transformedFiltersToComponents}
							appliedFilters={disambiguatedAppliedFilters}
							onClearAll={handleFiltersClearAll}
							mode={"FILTERING" as any}
							setMode={() => {}}
							loading={loading || parentLoading}
						/>
					</Filter2Wrapper>
				)}
				<SavedSearchModal
					onSave={async (view) => {
						const res = await editMutation.mutateAsync(view);
						setViewInEdit(null);
						return res;
					}}
					appliedFilters={appliedFilters}
					search={search}
					open={!!viewInEdit}
					view={viewInEdit}
					onClose={(view?: any) => {
						setViewInEdit(null);
					}}
				/>

				<Modal
					open={datePickerOptions?.open}
					onClose={() => {
						setDatePickerOptions({ open: false });
					}}
					title="Annan period"
					primaryAction={{
						content: "Välj",
						onAction: () => {
							const filters = { ...filterObject };

							if (datePickerOptions?.filter.range) {
								const startKey = datePickerOptions?.filter?.minKey;
								const endKey = datePickerOptions?.filter?.maxKey;

								if (datePickerOptions?.filter?.minKey) {
									filters[startKey] = moment(datePickerOptions?.selection.start).format("YYYY-MM-DD");
									filters[endKey] = moment(datePickerOptions?.selection.end).format("YYYY-MM-DD");
								} else {
									filters[datePickerOptions?.filter?.key] += " - " + moment(datePickerOptions?.selection.end).format("YYYY-MM-DD");
								}
							} else {
								filters[datePickerOptions?.filter?.key] = moment(datePickerOptions?.selection.start).format("YYYY-MM-DD");
							}

							setFilterObject(filters);
							handleChangeFilters(filters);
							setDatePickerOptions({ open: false });
						},
					}}
					secondaryActions={[
						{
							content: "Stäng",
							onAction: () => {
								setDatePickerOptions({ open: false });
							},
						},
					]}
				>
					<Modal.Section>
						{datePickerOptions?.filter ? (
							<DatePicker
								month={datePickerOptions?.month || new Date().getMonth()}
								year={datePickerOptions?.year || new Date().getFullYear()}
								weekStartsOn={1}
								onChange={(selection) => {
									setDatePickerOptions((c) => ({
										...c,
										selection,
									}));
								}}
								onMonthChange={(month, year) => {
									setDatePickerOptions((c) => ({
										...c,
										month,
										year,
									}));
								}}
								selected={datePickerOptions?.selection}
								disableDatesAfter={datePickerOptions?.filter.future || datePickerOptions?.filter.range ? undefined : new Date()}
								disableDatesBefore={datePickerOptions?.filter.future && !datePickerOptions?.filter.range ? new Date() : undefined}
								multiMonth={!!datePickerOptions?.filter.range}
								allowRange={!!datePickerOptions?.filter.range}
							/>
						) : null}
					</Modal.Section>
				</Modal>
			</>
		);

		function disambiguateLabel(key: string, value: string | any[]) {
			const filter = filters.find((filter) => filter.key == key);
			if (!filter) return null;

			if (key == "user_id") {
				const values = Array.isArray(value) ? value : [value];

				value = values.map((v) => getUserFromId(v)?.name || filter?.additionalOptions?.find((option) => option.value == v)?.label).join(", ");
			} else if (key == "group_id") {
				const group = getGroupFromId(value);
				if (group) {
					value = group.fullname || group.name;
				}
			} else if (key == "project_id") {
				value = getProjectFromId(value, projects)?.title || value;
			} else if (["checkbox", "boolean"].includes(filter.type)) {
				value = value ? (t("common.yes", "Ja") as string) : (t("common.no", "Nej") as string);
			} else if (filter.key == key) {
				if (filter.type == "select") {
					for (let s = 0; s < filter.options.length; s++) {
						if (filter.options[s].value == value) {
							if (filter.options[s].labelPlain) {
								value = filter.options[s].labelPlain;
							} else {
								value = filter.options[s].label;
							}
							break;
						}
					}
				} else if (filter.type == "dateSelector") {
					const options: any = getDateOptions(filter);
					for (let s = 0; s < options.length; s++) {
						if (options[s].value == value) {
							if (options[s].labelPlain) {
								value = options[s].labelPlain;
							} else {
								value = options[s].label;
							}
							break;
						}
					}
				}
				if (filter.type === "options") {
					if (filter.allowMultiple) {
						const selected = filter.options.filter((option) => value.includes(option.value));
						value = selected.map((option) => option.label).join(", ");
					} else {
						const selected = filter.options.find((option) => option.value === value);
						if (selected) {
							value = selected.label;
						}
					}
				}

				if (filter.type === "nested_options") {
					const flattendRoleOptions = flattenOptions(filter.options);

					const selected = flattendRoleOptions.filter((option) => value.includes(option.value));
					value = selected.map((option) => option.label).join(", ");
				}

				if (filter.type === "user_roles") {
					const flattendRoleOptions = flattenOptions(filter.options);

					const selected = flattendRoleOptions.filter((option) => value.includes(option.value));
					value = selected.map((option) => option.label).join(", ");
				}

				return `${filter.label} ${filter.operatorText ? " " + filter.operatorText : ""} ${value}`;
			}

			let keyLabel = key;

			if (key == "user_id") {
				keyLabel = "Medarbetare";
			}
			if (key == "group_id") {
				keyLabel = "Grupp";
			}
			if (key == "project_id") {
				keyLabel = "Projekt";
			}
			return `${keyLabel}: ${value}`;
		}
	}
);

export default withTranslation(["filters", "board", "common"], { withRef: true })(IndexFilters);

const Filter1Wrapper = styled.div``;
const Filter2Wrapper = styled.div`
	.Polaris-Button.Polaris-Button--plain.Polaris-Button--sizeMicro {
		display: none;
	}
`;
