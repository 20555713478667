import React, { Component } from "react";
import { Button, Spinner, Tooltip } from "@shopify/polaris";
import { NotificationMajor, VocabularyMajor } from "@shopify/polaris-icons";
import moment from "moment";
import { withTranslation } from "react-i18next";
import IntersectionObserverElement from "./IntersectionObserverElement";
import NotificationsIndex from "../views/Notifications/NotificationsIndex";
import IconWithBackround from "./IconWithBackround";
import Colors, { colorToRgba } from "../Colors";
import { MeHeader } from "../views/Dashboard/HelpBar/SheetWrapper";

moment.locale("de", {
	relativeTime: {
		future: "in %s",
		past: "%s",
		s: "sekunder",
		ss: "%ss",
		m: "en minut",
		mm: "%dm",
		h: "en timme",
		hh: "%dh",
		d: "en dag",
		dd: "%dd",
		M: "en månad",
		MM: "%dM",
		y: "ett år",
		yy: "%dY",
	},
});

class NotificationSheet extends Component {
	constructor(props) {
		super(props);
		// this.state = { notifications: props.notifications, activeTab: props.notifications_unread && props.notifications_unread.length ? 1 : 0 };
		this.state = { activeTab: 1 };
		this.tabs = [
			{ title: this.props.t("common.notifications.tabs.all", "Alla") },
			{ title: this.props.t("common.notifications.tabs.unread", "Olästa") },
			{ title: this.props.t("common.notifications.tabs.mentions", "Jag nämndes") },
		];
	}

	componentDidMount() {
		document.querySelector(".notifications-wrapper").closest(".Polaris-Sheet__Container").classList.add("sheet-medium");
	}

	getTabsNotifications({ notifications, notifications_unread: notificationsUnread, notifications_mentioned: notificationsMentioned } = {}) {
		switch (this.state.activeTab) {
			case 1:
				return notificationsUnread;
			case 2:
				return notificationsMentioned;
			case 0:
			default:
				return notifications;
		}
	}

	render() {
		const { history, notifications, notifications_unread, notifications_mentioned, loading } = this.props;

		const notises = this.getTabsNotifications({ notifications, notifications_unread, notifications_mentioned });

		return (
			<div className="notifications-wrapper">
				<MeHeader
					button={
						this.props.readAllAction && (
							<Tooltip content={this.props.t("common.notifications.tooltips.mark_all_as_read", "Markera alla som lästa")}>
								<Button onClick={this.props.readAllAction} icon={VocabularyMajor}>
									{this.props.t("common.notifications.actions.all_read", "Läst alla")}
								</Button>
							</Tooltip>
						)
					}
				/>

				<div className="big-title">
					<IconWithBackround icon={NotificationMajor} color={Colors.green} backgroundColor={colorToRgba(Colors.green, 0.1)} />
					<h1>{this.props.t("common.terms.notifications", "Notifikationer")}</h1>
				</div>

				<div className="notifications-tabs">
					{this.tabs &&
						this.tabs.map((i, index) => (
							<div
								key={index}
								className={`notifications-tab${this.state.activeTab == index ? " active" : ""}`}
								onClick={() => this.setState({ activeTab: index })}
							>
								{i.title}
							</div>
						))}
				</div>

				<div className="notifications-list noscroll">
					{loading && this.state.activeTab !== 0 && (
						<div className="notifications-list__loading">
							<Spinner />
						</div>
					)}

					<NotificationsIndex items={notises} loading={loading} history={history} />

					<IntersectionObserverElement />
				</div>
			</div>
		);
	}
}
export default withTranslation(["common"], { withRef: true })(NotificationSheet);
