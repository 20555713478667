/* eslint-disable no-unreachable */
import React, { Component } from "react";
import { Spinner } from "@shopify/polaris";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import RowSheetContent from "./components/RowSheetContent.js";
import BoardHelper from "./BoardHelper.js";
import CalendarModalContent from "./components/CalendarModal/CalendarModalContent.js";
import API from "../../API";
import TaskSheet from "../Agenda/TaskSheet/TaskSheet";

class EditRowExternal extends Component {
	constructor(props) {
		super(props);
		this.state = { loadingBoard: true };
	}

	componentDidMount() {
		const { boardId, rowId } = this.props.match?.params || {};
		const searchParams = new URLSearchParams(this.props.location?.search);
		const contactId = searchParams.get("contact_id");
		const date = searchParams.get("date");

		this.state.boardId = boardId;
		this.state.date = date;
		this.state.rowId = rowId;
		this.state.contactId = contactId;
		this.setState({ boardId, rowId, contactId, date });

		this.fetchItem(boardId);
		if (rowId) {
			this.fetchRow(boardId, rowId);
		}

		if (!rowId && contactId) {
			this.fetchContact(contactId);
		}
	}

	fetchContact(id) {
		API.get("/api/contacts/" + id + ".json", { params: {} })
			.then((result) => {
				if (result && result.data.contact) this.setState({ contact: result.data.contact });

				if (result.data.contact)
					store.dispatch({
						type: "SET_BOARD_CONTACTS",
						contacts: [result.data.contact],
					});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchItem(id) {
		this.setState({
			groups: [],
			defaultValues: [],
			loadingBoard: true,
		});
		API.get("/api/boards/" + id + ".json", { params: {} })
			.then((result) => {
				store.dispatch({
					type: "SET_BOARDS",
					boards: [BoardHelper.filterOutBlockedColumns(result.data.board)],
				});
				const defaultValues = result.data.board.default_values;
				if (this.state.contactId) {
					for (let i = 0; i < result.data.board.columns.length; i++) {
						if (result.data.board.columns[i].type == "contact") {
							defaultValues.push({
								value: [this.state.contactId * 1],
								column_id: result.data.board.columns[i].id,
							});
						}
					}
				}
				const personColumnId = result.data.board.columns.find((c) => c.type == "person")?.id;
				const searchParams = new URLSearchParams(this.props.location?.search);
				const userId = searchParams.get("user_id") || store.getState().user.id;

				if (!defaultValues.find((d) => d.column_id == personColumnId) && personColumnId && userId) {
					defaultValues.push({
						value: [parseInt(userId)],
						column_id: personColumnId,
					});
				}

				this.setState({
					loadingBoard: false,
					// favorite: result.data.favorite,
					form: result.data.board,
					board: result.data.board,
					defaultValues,
					// view: result.data.board.views[0],
				});
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	fetchRow(boardId, id) {
		this.setState({ loadingRow: true });
		API.get(`/api/boards/${boardId}/rows/` + id + ".json", {
			params: {},
		})
			.then((result) => {
				store.dispatch({
					type: "SET_BOARD_LINKED_ROWS",
					linked_rows: result.data.linked_rows,
				});
				store.dispatch({
					type: "SET_BOARD_CONTACTS",
					contacts: result.data.contacts,
				});
				store.dispatch({
					type: "SET_BOARD_TICKETS",
					tickets: result.data.tickets,
				});
				store.dispatch({ type: "SET_BOARD_ROWS", rows: [result.data.row] });

				this.setState({ row: result.data.row, loadingRow: false });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	getContent() {
		// return <p>hej</p>;
		switch (parseInt(this.state.boardId)) {
			case store.getState().user.calendar_board_id:
				return (
					<div style={{ background: "var(--modals-bg)", flex: 1, height: "100%" }}>
						<CalendarModalContent
							date={this.state.date}
							contact={this.state.contact}
							board={this.state.board}
							// updateCell={this.updateCell.bind(this, row)}
							// changeRowTitle={this.changeRowTitle.bind(this, row)}
							// onClose={this.closeModal.bind(this)}
							onComplete={(row) => {
								store.dispatch({ type: "ADD_BOARD_ROW", row });
							}}
							external
						/>
					</div>
				);
			case store.getState().user.todo_board_id:
				return (
					<div className="content-wrapper" style={{ background: "var(--modals-bg)", flex: 1, height: "100%" }}>
						<TaskSheet
							open
							inline
							options={{
								contact: this.state.contact ? [this.state.contact.id] : [],
								datetime: this.state.date && {
									datetime: this.state.date,
								},
							}}
							onComplete={(row) => {
								store.dispatch({ type: "ADD_BOARD_ROW", row });
							}}
						/>
					</div>
				);

			default:
				return (
					<RowSheetContent
						open
						initialTabIndex={this.props.initialTabIndex}
						// ref={(f) => {
						// 	this.form = f;
						// }}
						onClose={(action) => {
							if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
								if (action) {
									window.ReactNativeWebView.postMessage(JSON.stringify(action));
								}
								// Send to Apps Webview
								window.ReactNativeWebView.postMessage(JSON.stringify({ action: "close" }));
							}
						}}
						row={this.state.row && store.getState().board_rows[this.state.row.id]}
						groups={this.state.groups}
						defaultValues={this.state.defaultValues}
						board={this.state.form}
						external
					/>
				);
		}
	}

	render() {
		return this.state.loadingBoard || this.state.loadingRow ? (
			<div style={{ textAlign: "center", padding: 20 }}>
				<Spinner size="small" />
			</div>
		) : (
			this.getContent()
		);
	}
}
export default EditRowExternal;
