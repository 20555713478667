import { UseQueryResult, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import { toastr } from "src/js/components/toastr";
import { store } from "src/js/store";
import BoardRowSelectModal from "src/js/components/BoardRowSelectModal";
import SendAsEmailModal from "../SendAsEmailModal";
import useQuery from "src/js/hooks/useQuery";
import getFilledCustomerFields from "../Utilities/getFilledCustomerFields";
import getFilledDeliveryFields from "../Utilities/getFilledDeliveryFields";
import { formatRowToRead, formatRowToWrite } from "../Utilities/formatRows";

type SetFormFunction = (form: Partial<FortnoxOfferType> | null | ((prevForm: Partial<FortnoxOfferType>) => Partial<FortnoxOfferType>)) => void;

type FortnoxOfferContextValue = {
	offer: FortnoxOfferType | null;
	form: Partial<FortnoxOfferType> | null;
	setForm: SetFormFunction;
	isFetching: boolean;
	id: string;
	pdf: any;
	isFetchingPDF: boolean;
	handleSave: (offer: FortnoxOfferType) => any;
	contact: ContactType | null;
	customer: FortnoxCustomerType | null;
	row: BoardRowType | null;
	handleOpenRowSelectModal: () => void;
	handleSendToScrive: () => void;
	upload?: any;
	boardRowValue?: any;
	createOrderFromOffer: () => any;
	createInvoiceFromOffer: () => any;
	handleCancelOffer: () => any;
	handleSetAsSentOffer: () => any;
	handleSendOfferAsEmail: () => any;
	isSaving: boolean;
	handleCreatePdfUpload: (invoice: FortnoxOfferType, pdfData: any) => any;
	pdfUploads: any;
	isFetchingPDFUploads: boolean;
} & WithTranslation;

const FortnoxOfferContext = React.createContext({} as FortnoxOfferContextValue);

export const FortnoxOfferContextProvider = withTranslation(["fortnox", "common"])(
	({ id: propsId, match, history, children, t, defaultData = null }) => {
		const [form, setForm] = useState<Partial<FortnoxOfferType> | null>(defaultData);
		const id = propsId || match.params.id;
		const contactId = match.params.contactId;
		const [row, setRow] = useState<BoardRowType | null>(null);
		const [upload, setUpload] = useState<any | null>(null);
		const [boardRowValue, setBoardRowValue] = useState<any | null>(null);
		const [rowModalIsOpen, setRowModalIsOpen] = useState<boolean | "submit">(false);
		const [isSaving, setIsSaving] = useState(false);
		const [emailModalIsOpen, setEmailModalIsOpen] = useState(false);

		const queryClient = useQueryClient();

		// Order
		const fetch = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/offers/${id}.json`);
				return res.data;
			} catch (error) {
				toastr.error(error);
				return null;
			}
		}, [id]);

		const queryKey = [id && `fortnox_offer-${id}`].filter(Boolean);
		const enabled = !!(id && queryKey?.length);

		const {
			data: offer = null,
			isFetching,
			refetch: refreshOffer,
		}: UseQueryResult<FortnoxOfferType | null> = useQuery({
			queryKey,
			queryFn: fetch,
			refetchOnWindowFocus: false,
			initialData: (enabled && history.location.state?.data) || null,
			enabled,
		});

		// PDF
		const fetchPDF = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/offers/${id}/download.pdf`);

				return res.data;
			} catch (error) {
				toastr.error(error);
				return null;
			}
		}, [id]);
		const {
			data: pdf = null,
			isFetching: isFetchingPDF,
			refetch: refreshPDF,
		}: UseQueryResult<any> = useQuery({
			queryKey: [id && `fortnox_offer-${id}_pdf`].filter(Boolean),
			queryFn: fetchPDF,
			refetchOnWindowFocus: false,
			enabled: !!(offer?.DocumentNumber && id),
		});

		//SELLFINITY PDF
		const fetchSellfinityPDFs = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/offers/${id}/uploads.json`);

				return res.data;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [id]);

		const pdfUploadsQueryKey = [id && `fortnox_offer-${id}_pdf_uploads`].filter(Boolean);

		const {
			data: pdfUploads = null,
			isFetching: isFetchingPDFUploads,
			refetch: refreshPDFUploads,
		}: UseQueryResult<any> = useQuery({
			queryKey: pdfUploadsQueryKey,
			queryFn: fetchSellfinityPDFs,
			refetchOnWindowFocus: false,
			enabled: !!(offer?.DocumentNumber && id),
		});

		const createCRMPdfUpload = useCallback(
			async (offer, pdfData = {}) => {
				const offerId = offer.DocumentNumber || id;
				if (offerId) offer.DocumentNumber = offerId;

				const { pages, template, variables, name = `Offer ${offer.DocumentNumber}` } = pdfData;

				const res = await API.post(
					`/api/fortnox/offers/${offerId}/pdf_template/${template.id}.json`,
					{
						pages: pages.map((page) => page.data),
						name,
					},
					{
						params: variables,
					}
				);

				toastr.success(t("fortnox.responses.pdf_created", "PDF skapad"));

				return res;
			},
			[id, t]
		);

		const createPDF = async ({ offer, pdfData = {} }) => {
			const response = await createCRMPdfUpload(offer, pdfData);
			return response?.data.upload;
		};

		const mutationPdfUploads = useMutation(createPDF, {
			onSuccess: (data) => {
				refreshPDFUploads();
			},
		});

		const handleCreatePdfUpload = useCallback(
			async (offer: FortnoxOfferType, pdfData = {}) => {
				try {
					if (!offer) {
						throw new Error("no offer");
					}

					return await mutationPdfUploads.mutateAsync({ offer, pdfData });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				}
			},
			[mutationPdfUploads]
		);

		//----------

		// Contact
		const fetchContact = useCallback(async () => {
			try {
				if (contactId) {
					const res = await API.get(`/api/contacts/${contactId}.json`);
					if (res.data.contact) queryClient.setQueryData([`contact_${res.data.contact.id}`], res.data.contact);
					return res.data.contact;
				} else if (offer?.CustomerNumber) {
					const res = await API.get(`/api/fortnox/customer/${offer?.CustomerNumber}/contact.json`);
					if (res.data.contact) queryClient.setQueryData([`contact_${res.data.contact.id}`], res.data.contact);
					return res.data.contact;
				}

				return null;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [contactId, offer?.CustomerNumber, queryClient]);
		const { data: contact = null }: UseQueryResult<ContactType | null> = useQuery({
			queryKey: [contactId ? `contact_${contactId}` : offer?.CustomerNumber && `fortnox_customer_${offer?.CustomerNumber}_contact`].filter(Boolean),
			queryFn: fetchContact,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.contact || null,
			enabled: !!(contactId || offer?.CustomerNumber),
		});

		const customerID = offer?.CustomerNumber || contact?.fortnox_customer_id;
		const fetchCustomer = useCallback(async () => {
			try {
				const res = await API.get(`/api/fortnox/customer/${customerID}.json`);

				return res.data?.customer || null;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [customerID]);
		const { data: customer = null }: UseQueryResult<FortnoxCustomerType | null> = useQuery({
			queryKey: [customerID && `fortnox_customer_${customerID}`].filter(Boolean),
			queryFn: fetchCustomer,
			refetchOnWindowFocus: false,
			initialData: history.location.state?.customer || null,
			enabled: !!customerID,
		});

		const handleFormatRowsToRead = useCallback((offer: FortnoxOfferType) => {
			const rows = offer.OfferRows?.map((row) => formatRowToRead(row));

			return {
				...offer,
				OfferRows: rows,
			};
		}, []);

		const handleFormatRowsToWrite = useCallback((offer: FortnoxOfferType) => {
			const rows = offer.OfferRows?.map((row) => formatRowToWrite(row));

			return {
				...offer,
				OfferRows: rows,
			};
		}, []);

		useEffect(() => {
			if (offer)
				setForm(handleFormatRowsToRead({ ...offer, ...(customer && !offer?.DocumentNumber ? getFilledCustomerFields(customer, offer) : {}) }));
		}, [offer, customer, handleFormatRowsToRead]);

		useEffect(() => {
			if (!customer) return;
			setForm((c) => {
				if (c?.DocumentNumber) return c;
				return {
					...c,
					OurReference: customer?.OurReference,
					YourReference: customer?.YourReference,

					...getFilledCustomerFields(customer, c),
					...getFilledDeliveryFields(customer, c),
				};
			});
		}, [customer]);

		const saveFunction = useCallback(
			async (offer, boardRow = row) => {
				const offerId = offer?.DocumentNumber || id;
				if (offerId) {
					offer.DocumentNumber = offerId;
				}

				const endpoint = offerId ? `/api/fortnox/offers/${offerId}.json` : `/api/fortnox/contacts/${contactId}/offers.json`;

				const res = await (offerId
					? API.put(endpoint, { ...offer, board_row_id: boardRow?.id })
					: API.post(endpoint, { ...offer, board_row_id: boardRow?.id }));

				const successMessage = offerId
					? t("fortnox.responses.offer_saved", "Offert uppdaterad")
					: t("fortnox.responses.offer_created", "Offert skapad");

				toastr.success(successMessage);

				if (!offerId) {
					history.replace(`/admin/fortnox/offers/${res.data.DocumentNumber}`, {
						data: res.data,
					});
				}

				return res;
			},
			[id, t, history, contactId, row]
		);

		const update = async ({ offer, boardRow = row }) => {
			const response = await saveFunction(offer, boardRow);
			return response?.data;
		};

		const mutation = useMutation(update, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleSave = useCallback(
			async (offer: FortnoxOfferType, boardRow = row) => {
				try {
					if (!offer) {
						throw new Error("no offer");
					}

					if (!boardRow) {
						setRowModalIsOpen("submit");
						throw new Error("");
					}

					setIsSaving(true);
					return await mutation.mutateAsync({ offer: handleFormatRowsToWrite(offer), boardRow });
				} catch (e: any) {
					if (!e?.response) throw e;
					toastr.error(e);
				} finally {
					setIsSaving(false);
				}
			},
			[mutation, row, handleFormatRowsToWrite]
		);

		const cancel = async ({ offer }) => {
			try {
				const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/cancel.json`);
				toastr.success(t("fortnox.responses.offer_cancelled", "Offert makulerad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return offer;
			}
		};
		const mutationCancelOffer = useMutation(cancel, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});

		const handleCancelOffer = useCallback(async () => {
			return await mutationCancelOffer.mutateAsync({ offer });
		}, [offer, mutationCancelOffer]);

		const getBoardRow = useCallback(async () => {
			if (!id) return;
			try {
				const result = await API.get(`/api/fortnox/offers/${id}/board_row.json`);
				if (result?.data) {
					const { board_row: boardRow, upload, board_row_value } = result.data;

					store.dispatch({
						type: "SET_BOARD_ROWS",
						rows: [boardRow],
					});

					setRow(boardRow);
					setUpload(upload);
					setBoardRowValue(board_row_value);
				}
			} catch (e) {
				console.error("Error", e);
			}
		}, [id]);

		useEffect(() => {
			getBoardRow();
		}, [getBoardRow]);

		const handleOpenRowSelectModal = useCallback(() => {
			setRowModalIsOpen(true);
		}, []);

		const dealBoard = store.getState().boards[store.getState().account.sales_board_id];
		const boardRow = row?.id ? store.getState().board_rows[row?.id] : row;
		const column = dealBoard?.columns?.find((c) => c.title === "Offerter");

		const setAsSent = async ({ offer }) => {
			try {
				const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/externalprint.json`);
				toastr.success(t("fortnox.responses.offer_setAsSented", "Offerten markerad som skickad"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return offer;
			}
		};
		const mutationSetAsSentOffer = useMutation(setAsSent, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});

		const handleSetAsSentOffer = useCallback(async () => {
			return await mutationSetAsSentOffer.mutateAsync({ offer });
		}, [offer, mutationSetAsSentOffer]);

		const handleSendOfferAsEmailPre = useCallback(async () => {
			setEmailModalIsOpen(true);
		}, []);
		const sendAsEmail = async ({ offer }) => {
			try {
				const res = await API.get(`/api/fortnox/offers/${offer.DocumentNumber}/email.json`);
				toastr.success(t("fortnox.responses.offer_setAsSented", "Offerten skickad via email"));
				return res.data;
			} catch (error) {
				toastr.error(error);
				return offer;
			}
		};
		const mutationSendOfferAsEnail = useMutation(sendAsEmail, {
			onSuccess: (data) => {
				if (queryKey?.length) queryClient.setQueryData(queryKey, data);
				if (id) refreshPDF();
			},
		});
		const handleSendOfferAsEmail = useCallback(async () => {
			return await mutationSendOfferAsEnail.mutateAsync({ offer });
		}, [offer, mutationSendOfferAsEnail]);

		const createOrderFromOffer = useCallback(async () => {
			if (!offer?.DocumentNumber) {
				toastr.error("no offer");
				return;
			}

			try {
				const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/order.json`);

				refreshOffer();
				toastr.success(t("fortnox.responses.order_created", "Order skapad"));
				history.push(`/admin/fortnox/orders/${res.data.DocumentNumber}`);

				return res;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [offer?.DocumentNumber, history, t, refreshOffer]);

		const createInvoiceFromOffer = useCallback(async () => {
			if (!offer?.DocumentNumber) {
				toastr.error("no offer");
				return;
			}

			try {
				const res = await API.put(`/api/fortnox/offers/${offer.DocumentNumber}/invoice.json`);

				refreshOffer();
				toastr.success(t("fortnox.responses.invoice_created", "Faktura skapad"));
				history.push(`/admin/fortnox/invoices/${res.data.DocumentNumber}`);

				return res;
			} catch (error) {
				toastr.error(error);
				console.error("error:", error);

				return null;
			}
		}, [offer?.DocumentNumber, history, t, refreshOffer]);

		const handleSendToScrive = useCallback(async () => {
			try {
				if (!row || !column || !upload) return;
				const result = await API.post("/api/boards/" + column.board_id + "/rows/" + row.id + "/columns/" + column.id + "/scrive_upload.json", {
					id: upload.id,
					scrive_data: {
						// days_to_sign: 14,
					},
				});

				if (result.data.error) {
					throw result.data.error;
				}
				if (result.data.file.id) {
					toastr.success(t("scrive.actions.prepare.success", "Dokument förberett för signering"));
					const scriveId = result.data.file.scrive_id;
					const url = `/admin/scrive/documents/${scriveId}`;

					history.push(url, { document: result.data.file.scrive_data, edit: true, tabId: "parties" });
				}
			} catch (e) {
				console.error("handleSendToScrive error", e);
			}
		}, [column, history, row, t, upload]);

		const value: any = useMemo(
			() => ({
				form,
				setForm,
				isFetching,
				isFetchingPDF,
				t,
				pdf,
				id,
				handleSave,
				contact,
				customer,
				offer,
				row: boardRow,
				handleOpenRowSelectModal,
				handleSendToScrive,
				upload,
				boardRowValue,
				createOrderFromOffer,
				createInvoiceFromOffer,
				handleSetAsSentOffer,
				handleCancelOffer,
				handleSendOfferAsEmail: handleSendOfferAsEmailPre,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			}),
			[
				isFetching,
				t,
				id,
				form,
				isFetchingPDF,
				pdf,
				handleSave,
				setForm,
				contact,
				customer,
				offer,
				boardRow,
				handleOpenRowSelectModal,
				handleSendToScrive,
				upload,
				boardRowValue,
				createOrderFromOffer,
				createInvoiceFromOffer,
				handleSetAsSentOffer,
				handleCancelOffer,
				handleSendOfferAsEmailPre,
				isSaving,
				handleCreatePdfUpload,
				pdfUploads,
				isFetchingPDFUploads,
			]
		);

		return useMemo(
			() => (
				<FortnoxOfferContext.Provider value={value}>
					<BoardRowSelectModal
						open={!!rowModalIsOpen}
						onClose={() => {
							setRowModalIsOpen(false);
						}}
						column={column}
						board={dealBoard}
						contact={contact}
						onSelect={async (row) => {
							setRowModalIsOpen(false);
							setRow(row);

							if (rowModalIsOpen === "submit") {
								await handleSave(form as FortnoxOfferType, row);
							}

							if (emailModalIsOpen) {
								setIsSaving(true);
								const response = await handleSendOfferAsEmail();
								setEmailModalIsOpen(false);
								setIsSaving(false);
								return response;
							}
						}}
					/>
					<SendAsEmailModal
						offer={offer as FortnoxOfferType}
						type="offer"
						open={!!emailModalIsOpen}
						loading={isSaving}
						onClose={() => setEmailModalIsOpen(false)}
						EmailInformation={form?.EmailInformation}
						onSend={async (EmailInformation: EmailInformation, disabled) => {
							const newForm = { EmailInformation };

							const res = !disabled ? await handleSave(newForm as FortnoxOfferType) : true;
							if (res) {
								const response = await handleSendOfferAsEmail();
								setEmailModalIsOpen(false);
								return response;
							}
						}}
					/>

					{children}
				</FortnoxOfferContext.Provider>
			),
			[value, children, rowModalIsOpen, column, dealBoard, contact, form, handleSave, emailModalIsOpen, handleSendOfferAsEmail, isSaving, offer]
		);
	}
);
export default FortnoxOfferContext;
