import React from "react";
import styled from "styled-components";
import { SectionWrapper } from "../../views/dashboards/styles";
import { LoadingBox, FlexDiv, Wrapper } from "./styles";

const SkeletonDashboard = () => {
	return (
		<Container>
			<SectionWrapper gridArea={"g1"} title={(<LoadingBox width={100} height={30} />) as unknown as string}>
				<FlexDiv style={{ flexDirection: "column", gap: 10 }}>
					<LoadingBox width={"100%"} height={300} />

					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
				</FlexDiv>
			</SectionWrapper>

			<SectionWrapper gridArea={"g2"} title={(<LoadingBox width={125} height={30} />) as unknown as string}>
				<FlexDiv style={{ gap: 10, flex: 1, flexWrap: "wrap" }}>
					<LoadingBox height={225} width={225} />
					<LoadingBox height={225} width={225} />
					<LoadingBox height={225} width={225} />
					<LoadingBox height={225} width={225} />
				</FlexDiv>
			</SectionWrapper>

			<SectionWrapper gridArea={"g3"} title={(<LoadingBox width={80} height={30} />) as unknown as string}>
				<FlexDiv style={{ flexDirection: "column", gap: 10 }}>
					<LoadingBox width={"100%"} height={75} />
					<LoadingBox width={"100%"} height={75} />
					<LoadingBox width={"100%"} height={75} />
					<LoadingBox width={"100%"} height={75} />
				</FlexDiv>
			</SectionWrapper>

			<SectionWrapper gridArea={"g4"} title={(<LoadingBox width={200} height={30} />) as unknown as string}>
				<FlexDiv style={{ flexDirection: "column", gap: 5 }}>
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
					<LoadingBox width={"100%"} height={36} />
				</FlexDiv>
			</SectionWrapper>
		</Container>
	);
};

export default SkeletonDashboard;

const Container = styled(Wrapper)`
	/* padding: 1rem 2.08rem 0; */
	display: grid;
	grid-template-columns: minmax(40%, 650px) auto;
	gap: 1.25rem;
	padding: 0;

	grid-template-areas: "g1 g2" "g3 g4";
`;
