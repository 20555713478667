import i18next from "i18next";
import typeOptions from "../../views/OrderFlow/CustomFields/Utilities/typeOptions";
import { store } from "src/js/store";

type Filter = {
	key: string;
	label: string;
	type: string;
	options?: { label: any; value: any }[];
	shortcut?: boolean;
	allowMultiple?: boolean;
	operatorText?: string;
	range?: boolean;
	future?: boolean;
	handle?: string;
};

const getDefaultFilters = (type: string): Filter[] => {
	switch (type) {
		case "custom_fields":
			return [
				{
					key: "disabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "0",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "1",
						},
					],
					shortcut: true,
				},
				{
					key: "types",
					label: i18next.t("customfields.filters.type.label", "Typ"),
					type: "options",
					options: typeOptions,
					shortcut: true,
					allowMultiple: true,
				},
				{
					key: "title",
					label: i18next.t("customfields.filters.title.label", "Titel"),
					type: "text",
					shortcut: true,
				},
				{
					key: "default_value",
					label: i18next.t("customfields.filters.default_value.label", "Standardvärde"),
					type: "text",
					shortcut: true,
				},
			];
		case "articles":
			return [
				{
					key: "disabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "0",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "1",
						},
					],
					shortcut: true,
				},
				{
					handle: "NUMBER",
					key: "number",
					label: i18next.t("filters.articleNumber", "Artikelnummer"),
					type: "text",
				},
				{
					handle: "TITLE",
					key: "title",
					label: i18next.t("filters.title", "Title"),
					type: "text",
				},
				{
					handle: "DISPLAY_TITLE",
					label: i18next.t("filters.title", "Visnings title"),
					type: "text",
					key: "display_title",
				},
				{
					handle: "DESCRIPTION",
					label: i18next.t("filters.description", "Beskrivning"),
					type: "text",
					key: "description",
				},
				{
					handle: "UNIT",
					label: i18next.t("filters.unit", "Enhet"),
					type: "text",
					key: "unit",
				},

				// {
				//   handle: "Pris",
				//   label: "Enhet",
				//   type: "text",
				// },
			];
		case "categories":
			return [
				{
					key: "disabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "0",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "1",
						},
					],
					shortcut: true,
				},
			];
		case "pricelists":
			return [
				{
					key: "contact_id",
					label: i18next.t("pricelist.filters.contacts.label", "Kund"),
					type: "contact_id",
					shortcut: true,
				},
				{
					key: "disabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "0",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "1",
						},
					],
					shortcut: true,
				},
				{
					key: "add_new_users_automatically",
					label: i18next.t("filters.addNewUsersAutomatically.label", "Lägg till användare automatiskt"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.addNewUsersAutomatically.options.yes", "Ja"),
							value: "1",
						},
						{
							label: i18next.t("filters.addNewUsersAutomatically.options.no", "Nej"),
							value: "0",
						},
					],
					shortcut: true,
				},
				{
					key: "add_new_contacts_automatically",
					label: i18next.t("filters.addNewContactsAutomatically.label", "Lägg till kontakter automatiskt"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.addNewContactsAutomatically.options.yes", "Ja"),
							value: "1",
						},
						{
							label: i18next.t("filters.addNewContactsAutomatically.options.no", "Nej"),
							value: "0",
						},
					],
					shortcut: true,
				},
			];

		case "people": {
			const filters = [
				{
					key: "user_id",
					label: i18next.t("contact.filters.contact_user.label", "Kundansvarig"),
					type: "user",
					shortcut: true,
					pinned: true,
					additionalOptions: [
						{
							label: i18next.t("contact.filters.contact_user.options.no_user.label", "Saknar kundansvarig"),
							value: "null",
						},
					],
				},
				{
					key: "group_id",
					label: i18next.t("contact.filters.group.label", "Grupp"),
					type: "group",
					shortcut: false,
				},
				{
					key: "tagged",
					label: i18next.t("contact.filters.tagged.label", "Taggad med"),
					type: "contact_tags",
					params: {
						type: "person",
					},
					shortcut: true,
				},
				{
					key: "parent_tagged",
					label: "Företag taggad med",
					type: "contact_tags",
					params: {
						type: "company",
					},
					shortcut: true,
				},
				{
					key: "have_email",
					label: i18next.t("contact.filters.have_email.label", "Har e-post"),
					type: "select",
					options: [
						{ label: i18next.t("common.terms.yes", "Ja"), value: 1 },
						{ label: i18next.t("common.terms.no", "Nej"), value: 0 },
					],
					shortcut: false,
				},
				{
					key: "have_phone",
					label: i18next.t("contact.filters.have_phone.label", "Har telefon"),
					type: "select",
					options: [
						{ label: i18next.t("common.terms.yes", "Ja"), value: 1 },
						{ label: i18next.t("common.terms.no", "Nej"), value: 0 },
					],
					shortcut: false,
				},
				{
					key: "email",
					label: i18next.t("contact.filters.email.label", "E-post"),
					type: "text",
				},
				{
					key: "city",
					label: i18next.t("contact.filters.city.label", "Ort"),
					type: "text",
				},
				{
					key: "street",
					label: i18next.t("contact.filters.street.label", "Gatuadress"),
					type: "text",
				},
				{
					key: "zip",
					label: i18next.t("contact.filters.zip.label", "Postnummer"),
					type: "text",
				},
				{
					key: "last_activity_at_range",
					label: i18next.t("contact.filters.last_activity_at_range.label", "Har aktivitet"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					shortcut: false,
					range: false,
				},
				{
					key: "no_activity_in_range",
					label: i18next.t("contact.filters.no_activity_in_range.label", "Ingen aktivitet"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					shortcut: true,
					range: false,
				},
				{
					key: "last_session_at_range",
					label: i18next.t("contact.filters.last_session_at_range.label", "Har besökt hemsida"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: false,
				},
				{
					key: "no_session_in_range",
					label: i18next.t("contact.filters.no_session_in_range.label", "Inget hemsidebesök"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: true,
				},
				{
					key: "country",
					label: i18next.t("common.terms.country", "Land"),
					operatorText: i18next.t("common.terms.is", "är"),
					type: "select",
					options: store.getState().countries,
				},
			];

			for (let i = 0; i < store.getState().fields.length; i++) {
				filters.push({
					key: "relationship_" + store.getState().fields[i].id,
					label:
						i18next.t("contact.filter.relation.label", "Relation") +
						(store.getState().fields.length > 1 ? " " + store.getState().fields[i].title : ""),
					type: "select",
					options: [
						{ label: i18next.t("contact.filters.relation.none", "Ingen relation"), value: "none" },
						{ label: i18next.t("contact.filters.relation.contacted", "Varit i kontakt"), value: "contacted" },
						{ label: i18next.t("contact.filters.relation.in_progress", "Jobbar på det"), value: "in_progress" },
						{ label: i18next.t("contact.filters.relation.customer", "Är kund"), value: "customer" },
						{ label: i18next.t("contact.filters.relation.ex_customer", "Har varit kund"), value: "ex_customer" },
					] as any,
				});
			}

			for (let i = 0; i < store.getState().contact_metafields.length; i++) {
				if (store.getState().contact_metafields[i].for == "person") {
					if (store.getState().contact_metafields[i].type == "boolean") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "select",
							options: [
								{ label: i18next.t("common.terms.yes", "Ja"), value: "1" },
								{ label: i18next.t("common.terms.no", "Nej"), value: "0" },
							],
							shortcut: false,
						} as any);
					} else if (store.getState().contact_metafields[i].type == "options") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "select",
							options: store.getState().contact_metafields[i].options,
							shortcut: false,
						});
					} else if (store.getState().contact_metafields[i].type == "input") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "textField",
							shortcut: false,
						});
					}
				}
			}

			return filters;
		}

		case "companies": {
			const filters = [
				{
					key: "user_id",
					label: i18next.t("contact.filters.contact_user.label", "Kundansvarig"),
					type: "user",
					shortcut: true,
					additionalOptions: [
						{
							label: i18next.t("contact.filters.contact_user.options.no_user.label", "Saknar kundansvarig"),
							value: "null",
						},
					],
				},
				{
					key: "company_number",
					label: i18next.t("contact.filters.company_number.label", "Orgnr"),
					type: "text",
				},
				{
					key: "group_id",
					label: i18next.t("contact.filters.group.label", "Grupp"),
					type: "group",
					shortcut: false,
				},
				{
					key: "tagged",
					label: i18next.t("contact.filters.tagged.label", "Taggad med"),
					type: "contact_tags",
					params: {
						type: "company",
					},
					shortcut: true,
				},
				{
					key: "have_email",
					label: i18next.t("contact.filters.have_email.label", "Har e-post"),
					type: "select",
					options: [
						{ label: i18next.t("common.terms.yes", "Ja"), value: 1 },
						{ label: i18next.t("common.terms.no", "Nej"), value: 0 },
					],
					shortcut: false,
				},
				{
					key: "have_phone",
					label: i18next.t("contact.filters.have_phone.label", "Har telefon"),
					type: "select",
					options: [
						{ label: i18next.t("common.terms.yes", "Ja"), value: 1 },
						{ label: i18next.t("common.terms.no", "Nej"), value: 0 },
					],
					shortcut: false,
				},
				{
					key: "email",
					label: i18next.t("contact.filters.email.label", "E-post"),
					type: "text",
				},
				{
					key: "city",
					label: i18next.t("contact.filters.city.label", "Ort"),
					type: "text",
				},
				{
					key: "street",
					label: i18next.t("contact.filters.street.label", "Gatuadress"),
					type: "text",
				},
				{
					key: "zip",
					label: i18next.t("contact.filters.zip.label", "Postnummer"),
					type: "text",
				},
				{
					key: "last_activity_at_range",
					label: i18next.t("contact.filters.last_activity_at_range.label", "Har aktivitet"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: false,
				},
				{
					key: "no_activity_in_range",
					label: i18next.t("contact.filters.no_activity_in_range.label", "Ingen aktivitet"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: true,
				},
				{
					key: "last_session_at_range",
					label: i18next.t("contact.filters.last_session_at_range.label", "Har besökt hemsida"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: false,
				},
				{
					key: "no_session_in_range",
					label: i18next.t("contact.filters.no_session_in_range.label", "Inget hemsidebesök"),
					operatorText: i18next.t("common.terms.since", "sedan"),
					type: "dateSelector",
					future: false,
					range: false,
					shortcut: true,
				},
				{
					key: "country",
					label: i18next.t("common.terms.country", "Land"),
					operatorText: i18next.t("common.terms.is", "är"),
					type: "select",
					options: store.getState().countries,
				},
			];

			for (let i = 0; i < store.getState().fields.length; i++) {
				filters.push({
					key: "relationship_" + store.getState().fields[i].id,
					label:
						i18next.t("contact.filter.relation.label", "Relation") +
						(store.getState().fields.length > 1 ? " " + store.getState().fields[i].title : ""),
					type: "select",
					options: [
						{ label: i18next.t("contact.filters.relation.none", "Ingen relation"), value: "none" },
						{ label: i18next.t("contact.filters.relation.contacted", "Varit i kontakt"), value: "contacted" },
						{ label: i18next.t("contact.filters.relation.in_progress", "Jobbar på det"), value: "in_progress" },
						{ label: i18next.t("contact.filters.relation.customer", "Är kund"), value: "customer" },
						{ label: i18next.t("contact.filters.relation.ex_customer", "Har varit kund"), value: "ex_customer" },
					],
				} as any);
			}

			for (let i = 0; i < store.getState().contact_metafields.length; i++) {
				if (store.getState().contact_metafields[i].for == "company") {
					if (store.getState().contact_metafields[i].type == "boolean") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "select",
							options: [
								{ label: i18next.t("common.terms.yes", "Ja"), value: "1" },
								{ label: i18next.t("common.terms.no", "Nej"), value: "0" },
							],
							shortcut: false,
						} as any);
					} else if (store.getState().contact_metafields[i].type == "options") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "select",
							options: store.getState().contact_metafields[i].options,
							shortcut: false,
						});
					} else if (store.getState().contact_metafields[i].type == "input") {
						filters.push({
							key: "metafield_" + store.getState().contact_metafields[i].id,
							label: store.getState().contact_metafields[i].title,
							type: "textField",
							shortcut: false,
						} as any);
					}

					if (store.getState().contact_metafields[i].handle === "kundnummer") {
						filters.push({
							key: "have_metafield_" + store.getState().contact_metafields[i].id,
							label: `Har ${store.getState().contact_metafields[i].title}`,
							type: "select",
							options: [
								{ label: i18next.t("common.terms.yes", "Ja"), value: "1" },
								{ label: i18next.t("common.terms.no", "Nej"), value: "0" },
							],
							shortcut: false,
						} as any);
					}
				}
			}

			return filters;
		}

		case "contacts": {
			const filters = [...getDefaultFilters("people"), ...getDefaultFilters("companies")];

			return filters;
		}
		case "users": {
			const groups = store.getState().groups;
			const filters = [
				{
					key: "enabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "1",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "0",
						},
					],
					shortcut: true,
				},
				{
					key: "group_id",
					label: i18next.t("filters.group.label", "Grupp"),
					type: "select",
					options: groups?.map((group) => ({
						label: group.name,
						value: group.id,
					})),
					shortcut: true,
					enabled: groups?.length,
				},
			];

			return filters?.filter((filter) => !("enabled" in filter) || filter.enabled);
		}
		case "groups": {
			const filters = [
				{
					key: "enabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "1",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "0",
						},
					],
					shortcut: true,
				},
			];

			return filters;
		}
		case "targets": {
			const filters = [
				{
					key: "disabled",
					label: i18next.t("filters.status.label", "Status"),
					type: "select",
					options: [
						{
							label: i18next.t("filters.status.options.active", "Aktiv"),
							value: "0",
						},
						{
							label: i18next.t("filters.status.options.inactive", "Inaktiv"),
							value: "1",
						},
					],
					shortcut: true,
				},
			];

			return filters;
		}
		case "orders": {
			const filters = [
				{
					key: "start_order_date",
					secondKey: "end_order_date",
					label: i18next.t("orders.filters.order_date.label", "Orderdatum"),
					type: "date",
					shortcut: true,
					range: true,
				},
				{
					key: "contact_id",
					label: i18next.t("orders.filters.contact.label", "Kund"),
					type: "contact_id",
					shortcut: true,
				},
				{
					key: "customer_fields_name",
					label: i18next.t("orders.filters.customer_fields_name.label1", "Kundnamn"),
					type: "text",
					shortcut: true,
				},
				{
					key: "status",
					label: i18next.t("orders.filters.status.label", "Status"),
					type: "options",
					shortcut: true,
					allowMultiple: true,
					options: [
						{
							label: i18next.t("orders.filters.status.options.is_draft.label", "Utkast"),
							value: "draft",
						},
						{
							label: i18next.t("orders.filters.status.options.prepared_for_signing_at.label", "Förberedd för signering"),
							value: "prepared_for_signing",
						},
						{
							label: i18next.t("orders.filters.status.options.signed_at.label", "Signerad"),
							value: "signed",
						},
						{
							label: i18next.t("orders.filters.status.options.marked_as_signed_at.label", "Markerad som godkänd"),
							value: "marked_as_signed",
						},
						{
							label: i18next.t("orders.filters.status.options.cancelled_at.label", "Makulerad"),
							value: "cancelled",
						},
					],
					// sort: (a, b) => (a.selected ? -1 : b.selected ? 1 : 0),
					search: true,
				},
				{
					key: "delivery_fields_name",
					label: i18next.t("orders.filters.delivery_fields_name.label", "Leveransnamn"),
					type: "text",
				},
				{
					key: "delivery_fields_start_delivery_date",
					secondKey: "delivery_fields_end_delivery_date",
					label: i18next.t("orders.filters.delivery_fields_start_delivery_date.label", "Leveransdatum"),
					type: "text",
				},
				{
					key: "your_order_number",
					label: i18next.t("orders.filters.your_order_number.label1", "Ert ordernummer"),
					type: "text",
				},
				{
					key: "customer_fields_phone",
					label: i18next.t("orders.filters.customer_fields_phone.label", "Kund telefon"),
					type: "text",
				},
				{
					key: "customer_fields_email",
					label: i18next.t("orders.filters.customer_fields_email.label", "Kund e-post"),
					type: "text",
				},
				{
					key: "start_created_at",
					secondKey: "end_created_at",
					label: i18next.t("orders.filters.created_at.label", "Skapad"),
					type: "date",
					range: true,
				},
				{
					key: "created_by_users",
					label: i18next.t("orders.filters.created_by_user_id.label", "Skapad av"),
					type: "options",
					allowMultiple: true,
					options: store.getState().users.map((user) => ({
						label: user.name,
						value: user.id,
					})),
					sort: (a, b) => (a.selected ? -1 : b.selected ? 1 : 0),
					search: true,
				},
			];

			return filters;
		}

		default:
			return [];
	}
};
export default getDefaultFilters;
