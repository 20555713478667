/* eslint-disable quotes */
import React, { Component } from "react";
import { TextField, FormLayout, Button, EmptyState } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import { store } from "../../store";
import Tabs from "src/js/components/Tabs";
import Modal from "src/js/components/modal";

class VisitorBoardEmptyScreen extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTabIndex: 0,
			snippet:
				'<script>\n\twindow.__st=window.__st || {"offset":3600};\n\t//User id: window.__st.uid=4;\n\t//User e-mail: window.__st.umail=\'user@example.com\';\n</script>\n<script src="https://' +
				store.getState().account.handle +
				".sellfinity.com/tracking/" +
				store.getState().account.id +
				'.js"></script>',
		};
	}

	sendEmail() {
		this.setState({ sending: true });
		const params = Object.assign({}, {});
		API.post(
			"/api/boards/" + this.props.board.id + "/send_visitor_code.json",
			{
				email: this.state.email,
				snippet:
					'<script>\n\twindow.__st=window.__st || {"offset":3600};\n\t//User id: window.__st.uid=4;\n\t//User e-mail: window.__st.umail=\'user@example.com\';\n</script>\n<script src="https://' +
					store.getState().account.handle +
					".sellfinity.com/tracking/" +
					store.getState().account.id +
					'.js"></script>',
			},
			{ params }
		)
			.then((result) => {
				this.setState({ showCodeModal: false, sending: false });
				toastr.success(this.props.t("board.visitor.responses.mail_code_sent", "Mail med kod har skickats!"));
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ sending: false });
			});
	}

	render() {
		const tabs = [
			{
				id: "tech",
				content: this.props.t("board.visitor.tabs.tech", "Maila kod till tekniker"),
				accessibilityLabel: "tech",
				panelID: "tech",
			},
			{
				id: "do-it-myself",
				content: this.props.t("board.visitor.tabs.do_it_myself", "Visa HTML"),
				accessibilityLabel: "do-it-myself",
				panelID: "do-it-myself",
			},
		];

		return (
			<div>
				<EmptyState
					heading={this.props.t("board.visitor.empty.heading", "Inga Besökare")}
					action={{
						content: this.props.t("board.visitor.actions.add", "Lägg till besökare"),
						onAction: () => {
							this.setState({ showCodeModal: true });
						},
					}}
					image="/assets/images/empty_state/Visitors.png"
				>
					<p>{this.props.t("board.visitor.empty.text", "Du har inga besökare att visa")}</p>
				</EmptyState>

				<Modal
					title={this.props.t("board.visitor.modals.tracking.title", "Spårningskod")}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ showCodeModal: false });
							},
						},
					]}
					open={this.state.showCodeModal}
					onClose={() => {
						this.setState({ showCodeModal: false });
					}}
				>
					<Tabs
						tabs={tabs}
						fitted
						selected={this.state.selectedTabIndex}
						onSelect={(v) => {
							this.setState({ selectedTabIndex: v });
						}}
					>
						{this.state.selectedTabIndex == 1 ? (
							<Modal.Section>
								<FormLayout>
									<TextField
										label={this.props.t("board.visitor.modals.tracking.fields.snippet.label", "Kopiera och klistra in följande kod på din hemsida")}
										multiline
										value={this.state.snippet}
									/>
								</FormLayout>
							</Modal.Section>
						) : null}

						{this.state.selectedTabIndex == 0 ? (
							<Modal.Section>
								<FormLayout>
									<TextField
										label={this.props.t("board.visitor.modals.tracking.fields.email.label", "E-postadress till webbansvarig")}
										value={this.state.email}
										onChange={(v) => {
											this.setState({ email: v });
										}}
									/>
									<Button loading={this.state.sending} primary onClick={this.sendEmail.bind(this)} disabled={!this.state.email}>
										{this.props.t("board.visitor.modals.tracking.action.send", "Skicka kod via e-post")}
									</Button>
								</FormLayout>
							</Modal.Section>
						) : null}
					</Tabs>
				</Modal>
			</div>
		);
	}
}
export default withTranslation(["board", "common"], { withRef: true })(VisitorBoardEmptyScreen);
