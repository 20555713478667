/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useContext, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import { Spinner } from "@shopify/polaris";
import { CirclePlusMajor, DuplicateMinor, ExternalMinor, ImportMinor, StatusActiveMajor } from "@shopify/polaris-icons";
import FortnoxContractContext, { FortnoxContractContextProvider } from "./FortnoxContractContext";
import Header from "./Header";
import FortnoxContractInfoView from "./FortnoxContractInfoView";
import FortnoxContactView from "../FortnoxContactView";
import CopyModal from "../Invoices/CopyModal";
import Badge from "src/js/components/Badge";
import FortnoxPDFUploadsList from "../FortnoxPDFUploadsList";
import { PDFContext } from "src/js/PDF/PDFContext";

const FortnoxContract = ({ id: propsId, match, history, t }) => {
	const {
		form,
		handleSave,
		contact,
		customer,
		isFetching,
		handleFinishContract,
		isSaving,
		contract,
		handleCreatePdfUpload,
		pdfUploads,
		isFetchingPDFUploads,
	} = useContext(FortnoxContractContext);
	const { handleOpen: openpDF, handleClose: closePdf } = useContext(PDFContext);

	const disabled = form?.Status === "FINISHED";

	const tabs: { id: string; content: string; render: any }[] = useMemo(
		() =>
			[
				{
					id: "info",
					content: t("fortnox.tabs.info", "Info"),
					render: <FortnoxContractInfoView history={history} />,
				},
				{
					id: "contact",
					content: t("fortnox.tabs.contact", "Kontakt"),
					render: (
						<FortnoxContactView
							contact={contact}
							customer={customer}
							history={history}
							type="contract"
							EmailInformation={contract?.EmailInformation}
						/>
					),
					enabled: !!contact || !!customer,
				},
				{
					id: "uploads",
					content: (
						<div style={{ display: "flex", gap: "0.3125rem", alignItems: "center" }}>
							{isFetchingPDFUploads && <Spinner size="small" />}
							{t("fortnox.tabs.pdf_uploads", "Sellfinity PDF's")}
							{!!pdfUploads?.files?.length && <Badge status="success">{pdfUploads?.files?.length}</Badge>}
						</div>
					),
					render: <FortnoxPDFUploadsList data={pdfUploads} history={history} isLoading={!!isFetchingPDFUploads} />,
					enabled: !!contact || !!customer,
				},
			].filter((t) => !("enabled" in t) || t.enabled),
		[history, contact, customer, t, contract, pdfUploads, isFetchingPDFUploads]
	);

	const getTabIdFromHash = useCallback(() => {
		const hash = new URL(document.URL).hash?.replace("#", "");
		if (hash && tabs.find((t) => t.id == hash)) return hash;
		return "info";
	}, [tabs]);

	const handleSaveForm = useCallback(async () => {
		if (!form) return;

		try {
			await handleSave(form as FortnoxContractType);
		} catch (error) {
			console.error("error:", error);
		}
	}, [form, handleSave]);

	const onChangeTabs = (selectedTabIndex: number) => {
		const tabId = tabs[selectedTabIndex].id;
		setTabID(tabId);

		const url = new URL(document.URL);
		const params = new URLSearchParams(url.search);
		const hash = `#${tabId}`;
		history.replace(url.pathname + "?" + params.toString() + hash);
	};

	const [tabID, setTabID] = useState(history.location.state?.tabId || getTabIdFromHash());
	const tab = tabs.find((tab) => tab.id === tabID);

	const handleCreatePdf = async ({ template, pages, name, variables }) => {
		const res = await handleCreatePdfUpload(form as FortnoxContractType, {
			template,
			pages,
			name,
			variables,
		});

		closePdf();
		return res;
	};

	return (
		<Page
			history={history}
			title={
				isFetching
					? ((<Spinner size="small" />) as any)
					: form?.DocumentNumber
					? t("fortnox.contract.title", "Fortnox avtal: {{id}}", { id: form?.DocumentNumber || "-" })
					: t("fortnox.contract.new_title", "Nytt Fortnox avtal")
			}
			primaryAction={{
				content: form?.DocumentNumber ? t("fortnox.actions.save", "Spara") : t("fortnox.actions.create", "Skapa"),
				onAction: handleSaveForm,
				disabled: !form || disabled,
			}}
			secondaryActions={[
				// This requires a scope, but which scope??
				// {
				// 	content: t("fortnox.actions.create_invoice", "Skapa faktura"),
				// 	onAction: handleCreateInvoiceFromContract,
				// 	icon: ImportMinor,
				// 	enabled: !!form?.DocumentNumber && !form?.OrderReference,
				// },
				// {
				// 	content: t("fortnox.actions.create_invoice_from_contract", "Skapa faktura ifrån contract"),
				// 	onAction: () => downloadPDF(pdf, `fortnox_contract_${form?.DocumentNumber}.pdf`),
				// 	disabled: !pdf,
				// 	icon: ImportMinor,
				// 	enabled: !!form?.DocumentNumber,
				// },
				{
					content: t("fortnox.actions.download2", "Skapa sellfinity PDF"),
					onAction: () => openpDF({ Fortnox: { contract: form, customer }, contact }, { saveHandler: handleCreatePdf }),
					icon: ImportMinor,
				},
				{
					content: t("fortnox.contract.actions.copy.title", "Kopiera avtal"),
					icon: DuplicateMinor,
					modal: <CopyModal contract={form} />,
				},
				{
					content: t("fortnox.actions.contract.new", "Nytt avtal"),
					icon: CirclePlusMajor,
					onAction: () => {
						history.push(`/admin/fortnox/contacts/${contact?.id}/contracts/create`, {
							contact,
							customer,
						});
					},
					enabled: !!(contact?.id && form?.DocumentNumber),
				},
				{
					content: t("fortnox.contract.actions.finish", "Avsluta avtal"),
					icon: StatusActiveMajor,
					onAction: handleFinishContract,
					enabled: !!form?.DocumentNumber && form?.Status !== "FINISHED",
					disabled,
					confirmation: {
						title: t("fortnox.contract.actions.finish", "Avsluta avtal"),
						content: t("fortnox.contract.actions.finish.confirmation", "Är du säker på att du vill avsluta avtalet?"),
						confirm: t("fortnox.contract.actions.finish", "Avsluta avtal"),
						cancel: t("common.actions.cancel", "Avbryt"),
					},
				},
				{
					content: t("fortnox.actions.contact.content", `Kontakt ({{contact}})`, {
						contact: contact?.name || "-",
					}),
					tooltip: t("fortnox.actions.contact.tooltip", `Gå till kontakt ({{contact}})`, { contact: contact?.name || "-" }),
					icon: ExternalMinor,
					onAction: () => {
						history.push(`/admin/contacts/${contact?.id}#fortnox_contracts`, {
							contact,
							customer,
						});
					},
					enabled: !!contact?.id,
				},
			]
				.filter((a) => !("enabled" in a) || a.enabled)
				?.map((a) => ({ ...a, disabled: isSaving || a.disabled }))}
		>
			<Header contract={form} tabs={tabs} selected={tabs.findIndex((t) => t.id === tab?.id)} onSelect={onChangeTabs} />
			{form && typeof tab?.render === "function" ? tab?.render() : tab?.render}
		</Page>
	);
};

export default withTranslation(["fortnox", "common"])((props) => {
	return (
		<FortnoxContractContextProvider {...props}>
			<FortnoxContract {...props} />
		</FortnoxContractContextProvider>
	);
});
