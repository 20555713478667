import { useParams } from "react-router-dom";
import useQuery from "./useQuery";
import API from "../API";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

type useContactProps = {
	id?: number;
	contact?: ContactType | Partial<ContactType>;
};

const useContact = ({ id: propsId, contact: propsContact }: useContactProps = {}) => {
	const { id }: any = useParams();
	const dispatch = useDispatch();

	const fetch = async () => {
		const contactId = propsId || id;
		try {
			const response = await API.get("/api/contacts/" + contactId + ".json");
			if (!response.data.contact) return null;

			dispatch({
				type: "SET_BOARD_CONTACTS",
				contacts: [response.data.contact],
			});

			return response.data.contact;
		} catch (error) {
			console.error("error:", error);
		}
	};

	const contactQueryKey = [id && `contact_${id}`, !id && propsContact?.orgnr && `contact_orgnr_${propsContact?.orgnr}`];

	const {
		data: contact,
		isInitialLoading,
		refetch: refreshContact,
	} = useQuery({
		queryKey: contactQueryKey,
		queryFn: fetch,
		enabled: !!id,
		initialData: propsContact,
		defaultData: propsContact,
	});

	const queryKey = contact?.orgnr ? ["contact", "axicon", contact?.orgnr] : [];
	const {
		data: axiconData,
		isRefetching: axiconIsLoading,
		isInitialLoading: axiconIsInitialLoading,
		error: axiconError,
	} = useQuery({
		queryKey,
		queryFn: async () => {
			const isInvalid = !/^(?:19|20)?\d{6}-?\d{4}$/.test(contact?.orgnr);
			if (isInvalid) {
				throw new Error("Ogiltigt organisationsnummer");
			}

			const res = await API.get(`/api/axicon/companies/${contact?.orgnr}.json`);
			return res?.data?.company;
		},
		enabled: !!contact?.orgnr,

		fetchOnce: true,
		retry: 1,
	});

	const results = useMemo(() => {
		return {
			id,
			contact,
			refreshContact,
			isInitialLoading,
			axiconData,
			axiconIsLoading,
			axiconIsInitialLoading,
			axiconError,
		};
	}, [axiconData, axiconIsLoading, contact, id, isInitialLoading, refreshContact, axiconIsInitialLoading, axiconError]);

	return results;
};

export default useContact;
