import { FormLayout } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import API from "src/js/API";
import Banner from "src/js/components/banner";
import InfoView from "src/js/components/InfoView";
import ProfileAvatar from "src/js/components/ProfileAvatar";
import TextField from "src/js/components/TextField";
import Modal from "src/js/components/modal";
import { toastr } from "src/js/components/toastr";

type ApiUserTokenModalProps = {
	open: boolean;
	history: any;
	onClose: () => void;
	apiUser?: ApiUserTokenType | null;
	onSuccess?: (data?: any) => void;
} & WithTranslation;

const ApiUserTokenModal = ({ open, history, onClose, apiUser = null, t, onSuccess }: ApiUserTokenModalProps) => {
	const [form, setForm] = useState<ApiUserTokenType | null>(apiUser);
	const [loading, setLoading] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [token, setToken] = useState<string | null>(null);
	const [openConfirmSeenToken, setOpenConfirmSeenToken] = useState(false);

	useEffect(() => {
		if (open) {
			setForm(null);
			setToken(null);
		} else {
			setTimeout(() => {
				setForm(null);
				setToken(null);
			}, 250);
		}
	}, [open]);

	useEffect(() => {
		if (apiUser && "id" in apiUser) {
			setForm({ ...apiUser });
		}
	}, [apiUser]);

	const getHandleChange = (field: string) => {
		return (value: any) => {
			setForm({ ...(form || {}), [field]: value });
		};
	};

	const handleDelete = async () => {
		setRemoving(true);
		await API.delete(`/api/api_user_tokens/${form?.id}.json`)
			.then((response) => {
				toastr.success(t("api_user_tokens.responses.deleted", "Användar API nyckel inaktiverad"));
				onClose();
				onSuccess?.();
			})
			.catch((error) => {
				toastr.error(error);
				console.error(toastr.error(error));
			});
		setRemoving(false);
	};

	const handleSave = async () => {
		setLoading(true);
		if (form?.id) {
			await API.put(`/api/api_user_tokens/${form.id}.json`, {
				title: form?.title,
			})
				.then((response) => {
					onSuccess?.(response.data);
					setForm(response.data?.api_user_token);

					toastr.success(t("api_user_tokens.responses.updated", "Användar API nyckel uppdaterad"));
				})
				.catch((error) => {
					toastr.error(error);
					console.error(toastr.error(error));
				});
		} else {
			await API.post("/api/api_user_tokens.json")
				.then((response) => {
					onSuccess?.(response.data);
					setForm(response.data?.api_user_token);
					setToken(response.data?.token?.trim());
					toastr.success(t("api_user_tokens.responses.created", "Användar API nyckel skapad"));
				})
				.catch((error) => {
					toastr.error(error);
					console.error(toastr.error(error));
				});
		}
		setLoading(false);
	};

	const handleCloseSeenModal = () => {
		setOpenConfirmSeenToken(false);
		onClose();
	};
	const handleClose = () => {
		if (token) {
			setOpenConfirmSeenToken(true);
			return;
		}
		onClose();
	};

	const setClipboard = (token: string) => {
		try {
			window.navigator.clipboard.writeText(token);
			toastr.info(t("api_user_tokens.responses.copy_success", "Token kopierad till urklipp"));
		} catch (e) {
			console.error("e:", e);
			toastr.error(t("api_user_tokens.responses.copy_failed", "Kunde inte kopiera token till urklipp"));
		}
	};

	const tokenBanner = token && (
		<Banner
			status="success"
			icon={null}
			actions={[
				{
					primary: true,
					content: t("board.api_user_tokens.fields.token.copy.tooltip", "Kopiera API nyckel") as string,
					onAction: () => setClipboard(token),
					icon: ClipboardMinor,
				},
			]}
		>
			<b>{t("api_user_tokens.fields.token.warning", "Spara ner denna nyckel då den inte kommer kunna visas igen.")}</b>

			<pre
				style={{
					whiteSpace: "pre-line",
					wordBreak: "break-all",
					padding: "0.6250rem",
					background: "var(--main3)",
					borderRadius: "6px",
					boxShadow: "1px 4px 12px 0px rgba(0, 0, 0, 0.1882352941)",
					color: "var(--textColor)",
				}}
			>
				<code> {token}</code>
			</pre>
		</Banner>
	);

	const getContent = () => {
		if (form?.revoked)
			return (
				<Banner status="critical" title={t("api_user_tokens.banner.revoked.title", "Denna API nyckel är inaktiverad.")}>
					<InfoView
						gap="0.6250rem"
						style={{ marginTop: "0.6250rem" }}
						details={[
							{
								label: t("api_user_tokens.fields.revoked_at.label", "Inaktiverad"),
								value: form?.revoked_at,
							},
							{
								label: t("api_user_tokens.fields.revoked_by.label", "Inaktiverad av"),
								value: form?.revoked_by_user ? (
									<div style={{ display: "flex", gap: "0.6250rem" }}>
										<ProfileAvatar user={form?.revoked_by_user} size={20} />
										{form?.revoked_by_user?.name}
									</div>
								) : (
									"-"
								),
							},
						]}
					/>
				</Banner>
			);
		return (
			<FormLayout>
				{!form?.id && (
					<p>
						{t(
							"api_user_tokens.modal.description",
							`Skapa en API användar nyckel för att använda vårat API.
              Rättigheter för nyckeln ärvs ifrån användaren.`
						)}
					</p>
				)}

				{token && tokenBanner}

				{form?.id && (
					<TextField
						label={t("api_user_tokens.fields.title.label", "Titel")}
						placeholder={t("api_user_tokens.fields.title.placeholder", "Projekt 1..") as string}
						value={form?.title}
						onChange={getHandleChange("title")}
						helpText={t("api_user_tokens.fields.title.help_text", "Titel för att enklare kunna identifiera nyckeln.")}
					/>
				)}

				{form && (
					<InfoView
						details={[
							{
								label: t("api_user_tokens.fields.user.label", "User"),
								component: (
									<div style={{ display: "flex", gap: "0.6250rem" }}>
										<ProfileAvatar user={form?.user} size={20} />
										{form?.user?.name}
									</div>
								),
							},
							{
								label: t("api_user_tokens.fields.created_at.label", "Skapad"),
								component: form?.created_at,
							},
						]}
					/>
				)}
			</FormLayout>
		);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				title={
					form?.id
						? form.title || `${t("api_user_tokens.modal.title", "API användar nyckel")} - ${form?.user?.name}`
						: t("api_user_tokens.modal.title", "API användar nyckel")
				}
				primaryAction={{
					content: form?.id ? (t("common.actions.save", "Spara") as string) : (t("common.actions.create", "Skapa") as string),
					onAction: handleSave,
					loading,
					enabled: !form?.revoked,
					// disabled: !form?.user?.id,
				}}
				secondaryActions={[
					{
						content: t("common.actions.close", "Stäng") as string,
						onAction: handleClose,
					},
					{
						content: t("common.actions.deactivate", "Inaktivera") as string,
						onAction: handleDelete,
						destructive: true,
						loading: removing,
						enabled: !!form?.id && !form.revoked,
						confirm: true,
						confirmText: (
							<div style={{ marginBottom: "0.6250rem" }}>
								<p>{t("api_user_tokens.modal.confirm_delete", "Är du säker på att du vill inaktivera denna API nyckel?")}</p>
								<b>{t("api_user_tokens.modal.confirm_delete2", "Detta går inte att ångra.")}</b>
							</div>
						),
					},
				]?.filter((action) => !("enabled" in action) || action.enabled)}
				sectioned
			>
				{getContent()}
			</Modal>

			<Modal
				open={openConfirmSeenToken}
				onClose={() => setOpenConfirmSeenToken(false)}
				title={t("api_user_tokens.modal.confirm_seen_token.title", "Har du sparat ner API nyckeln?")}
				primaryAction={{
					content: t("api_user_tokens.actions.conrm_saved", "Ja, jag har sparat nyckeln") as string,
					onAction: handleCloseSeenModal,
				}}
				sectioned
			>
				<FormLayout>
					<b>{t("api_user_tokens.modal.confirm_seen_token.description", "Spara ner API nyckeln då den inte kommer kunna visas igen.")}</b>
					{tokenBanner}
				</FormLayout>
			</Modal>
		</>
	);
};
export default withTranslation(["api_user_tokens", "common"])(ApiUserTokenModal);
