/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable quotes */
import React, { Component } from "react";
import { FormLayout, Layout } from "@shopify/polaris";

import { LocationMajor, EditMajor, MobileHorizontalDotsMajor, SendMajor, IncomingMajor, ExitMajor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import EmailModal from "src/js/components/Emails/EmailModal";
import EmailListSheetContent from "src/js/components/Emails/EmailListSheetContent";
import useQuery from "src/js/hooks/useQuery";
import { useQueryClient } from "@tanstack/react-query";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import TimeLine from "../../components/TimeLine.js";
import TagModal from "../../components/tag_modal.js";
import ContactModal from "../../components/ContactModal.js";
import ContactCard from "../../components/ContactCard.js";
import OpenNotification from "../../components/OpenNotification.js";
import AddressModal from "../../components/AddressModal.js";
import RemoveModal from "../../components/remove_modal.js";
import ContactTabs from "./ContactTabs/ContactTabs";
import Note from "./Note";
import Banner from "../../components/banner";
import { getIconByString } from "../Workspaces/components/IconSelector";
import TextField from "src/js/components/TextField";
import SkeletonContact from "src/js/components/Skeletons/SkeletonContact";
import SkeletonPage from "src/js/components/Skeletons/Page";
import useContact from "src/js/hooks/useContact";

const Contact = (props) => {
	// const id = props.match.params.id;
	const queryClient = useQueryClient();
	const { contact, isInitialLoading, id, refreshContact } = useContact();

	const updateContact = async (updatedContact) => {
		try {
			queryClient.setQueryData([id && `contact_${id}`], updatedContact);

			if (updatedContact.fortnox_integration && !updatedContact.fortnox_integration?.removed) refreshCustomer();
			if (updatedContact.is_company) refreshChildren();
		} catch (error) {
			console.error("error:", error);
		}
	};

	// Customer
	const fetchCustomer = async () => {
		try {
			if (!contact?.fortnox_customer_id) return;

			const response = await API.get(`/api/fortnox/customer/${contact.fortnox_customer_id}.json`);

			return response?.data?.customer;
		} catch (error) {
			console.error("error:", error);
		}
	};

	const { data: customer, refetch: refreshCustomer } = useQuery({
		queryKey: [id && `fortnox_customer_${id}`],
		queryFn: fetchCustomer,
		enabled: !!(contact?.id && contact?.fortnox_integration && !contact?.fortnox_integration?.removed && contact?.fortnox_customer_id),
	});

	// Contacts (chidren)
	const fetchChildren = async () => {
		try {
			const response = await API.get("/api/contacts/" + id + "/children.json");
			return response.data.contacts;
		} catch (error) {
			console.error("error:", error);
		}
	};

	const { data: contactChildren, refetch: refreshChildren } = useQuery({
		queryKey: [id && `contact_${id}_children`],
		queryFn: fetchChildren,
		enabled: !!id,
	});

	// remove fortnox integration connection
	const removeFortnoxIntegration = async () => {
		try {
			const response = await API.delete(`/api/fortnox/contacts/${contact.id}/disconnect.json`);
			toastr.success(props.t("contact.responses.fortnox.removed", "Fortnox koppling borttagen"));

			if (response.data.contact) updateContact(response.data.contact);
		} catch (error) {
			console.error("error:", error);
			toastr.error(error);
		}
	};

	return (
		<ContactEdit
			contact={contact}
			customer={customer}
			contactChildren={contactChildren}
			isInitialLoading={isInitialLoading}
			updateContact={updateContact}
			refreshContact={refreshContact}
			removeFortnoxIntegration={removeFortnoxIntegration}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

class ContactEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCompany: props?.contact?.is_company,
			selectedContact: null,
			id: props.match.params.id,
			loading: props.isInitialLoading,
			saving: false,
			form: props.contact || {},
			currentAddress: {},
			selectedContacts: [],
			children: props.contactChildren || [],
			secondaryActions: [],
			actionLoading: [],
			customer: props.customer,
		};
		this.timeLine = React.createRef();
	}

	parseQuery(queryString) {
		const query = {};
		const pairs = (queryString[0] === "?" ? queryString.substr(1) : queryString).split("&");
		for (let i = 0; i < pairs.length; i++) {
			const pair = pairs[i].split("=");
			if (pair.length > 1) {
				query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
			}
		}
		return query;
	}

	componentDidMount() {
		// this.fetchItem(this.state.id);
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.setState({ children: [], secondaryActions: [] });
			this.state.id = props.match.params.id;
			this.setState({ id: props.match.params.id });
		}

		// updates from useQuery
		if ("contact" in props) {
			this.setState({ form: props.contact, id: props.contact?.id });
		}

		if ("contactChildren" in props) {
			this.setState({ children: props.contactChildren });
		}

		if ("customer" in props) {
			this.setState({ customer: props.customer });
		}

		if ("isInitialLoading" in props) {
			this.setState({ loading: props.isInitialLoading });
		}
	}

	fetchItem(id) {
		this.setState({ id });
		this.props.refreshContact?.();
	}

	openContactModal(item) {
		this.setState({ showContactModal: true, selectedContact: item });
	}

	openContactModalParent(item) {
		this.setState({ showContactModal: true, selectedContact: Object.assign(item || {}, { is_company: true }) });
	}

	closeContactModal() {
		this.setState({ showContactModal: false });
	}

	closeRemoveModal() {
		this.setState({ showRemoveModal: false });
	}

	closeRemoveTagModal() {
		this.setState({ showRemoveTagModal: false });
	}

	removeContact() {
		this.setState({ removing: true });
		API.delete("/api/contacts/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				toastr.success(
					`
					${
						this.state.isCompany || this.props.isCompany || this.state.form.is_company
							? this.props.t("contact.company.singular", "Företag")
							: this.props.t("contact.person.singular", "Kontaktperson")
					} ${this.props.t("common.terms.removed", "borttagen")}
					`
				);
				if (this.state.isCompany || this.state.form.is_company) {
					this.props.history.replace("/admin/contacts/companies");
				} else {
					this.props.history.replace("/admin/contacts/people");
				}
			})
			.catch((error) => toastr.error(error));
	}

	doRemoveTags(tags) {
		this.setState({ saving: true });
		API.post(
			"/api/contacts/remove_tags.json",
			{
				tags,
			},
			{ params: { ids: this.state.selectedContacts } }
		)
			.then((result) => {
				this.fetchItem(this.state.id);
				toastr.success(this.props.t("contact.responses.tags.removed", "Tog bort taggar"));
				this.setState({ saving: false, showRemoveTagModal: false });
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	async syncToFortnox() {
		try {
			const result = await API.post(`/api/fortnox/contacts/${this.state.id}/customer.json`, { CustomerNumber: this.state.customerNumber });

			if (result.data?.customer) {
				this.setState({ customerNumber: null });
				toastr.success(this.props.t("common.related_resources.sync_fortnox.success", "Kunden synkades till Fortnox"));

				if (this.props.refreshContact) {
					this.props.refreshContact();
				} else {
					window.location.reload();
				}
			}
		} catch (e) {
			console.error("Error", e);
		} finally {
			this.setState({ customerNumber: null });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;
		this.setState({ form: formState });
	}

	gotoContact(item, state) {
		this.props.history.push("/admin/contacts/" + item.id, state);
	}

	editAddress(item) {
		this.setState({ currentAddress: item, showAddressModal: true });
	}

	closeAddressModal() {
		this.setState({ showAddressModal: false });
	}

	setSecondaryActions(secondaryActions) {
		this.setState({ secondaryActions });
	}

	onActionClick(integration, action) {
		this.setState({
			selectedAction: { id: integration.id, type: integration.type, title: integration.name, action, active: true, contact: this.state.form },
		});
	}

	async onSendMail(integration, data, contact) {
		const { type, id, action } = integration;
		this.setState({ actionLoading: [...this.state.actionLoading, action] });
		try {
			await API.post(`/api/integrations/${type}/${id}/${action}`, {
				data: { ...data, contact },
			});
			this.setState({ selectedAction: {} }, () => this.timeLine?.current?.fetchComments?.());
			toastr.success(this.props.t("common.actions.email.sent", "E-posten har skickats"));
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({ actionLoading: this.state.actionLoading.filter((i) => i !== action) });
		}
	}

	render() {
		if (this.state.loading && !this.state.form?.id) {
			return <SkeletonContact contact={this.state.form} id={this.state.id} key={this.state.id} />;
		}

		let query = null;

		if (this.props.history.location.search) {
			query = this.parseQuery(this.props.history.location.search);
		}

		let notification = null;
		if (query && "notification_id" in query && query.notification_id) {
			notification = <OpenNotification notification_id={query.notification_id} />;
		}

		const contactTabsBar = (
			<ContactTabs
				contactTab={this.props.location?.state?.contactTab}
				contact={this.state.form}
				// addTags={this.addTags.bind(this)}
				// removeTags={this.removeTags.bind(this)}
				openContactModal={this.openContactModal.bind(this)}
				openContactModalParent={this.openContactModalParent.bind(this)}
				gotoContact={this.gotoContact.bind(this)}
				updateForm={this.updateForm.bind(this)}
				location={this.props.location}
				loading={this.state.loading}
				history={this.props.history}
				external={this.props.external}
				loadingChildren={this.state.loadingChildren}
				setSecondaryActions={this.setSecondaryActions.bind(this)}
				customer={this.state.customer}
				refresh={this.props.refreshContact}
			>
				{this.state.children}
			</ContactTabs>
		);
		const { integrations } = store.getState().user;

		const actions =
			integrations?.flatMap((integration) => {
				return (
					(integration.links &&
						[integration.links]
							?.filter(({ placement }) => placement === "contact")
							?.flatMap((link) => {
								const { title, handle, icon, placement } = link;
								if (placement === "contact") {
									return {
										icon: getIconByString(icon),
										title: "E-post",
										actions: [
											{
												icon: SendMajor,
												content: this.props.t("contact.integration.microsoft.links.send", "Skicka nytt"),
												onAction: this.onActionClick.bind(this, integration, handle),
											},
											{
												icon: IncomingMajor,
												content: this.props.t("contact.integration.microsoft.links.import", "Importera"),
												onAction: () => {
													this.setState({ emailsImportModalIsOpen: true });
												},
												enabled: !!this.state.form?.email,
											},
										]?.filter((a) => !("enabled" in a) || a?.enabled),
									};
								}

								return {
									icon: getIconByString(icon),
									content: title,
									onAction: this.onActionClick.bind(this, integration, handle),
								};
							})) ||
					[]
				);
			}) || [];

		const { selectedAction } = this.state;
		const secondaryActions = [
			...(this.state.secondaryActions || []),
			{
				icon: <img src="/assets/fortnox-logo.png" alt="Fortnox" />,
				content: this.props.t("common.related_resources.actions.sync_fortnox", "Synka till Fortnox"),
				onAction: this.syncToFortnox.bind(this),
				confirmation: {
					title: this.props.t("common.related_resources.sync_fortnox.confirmation.title", "Synka till Fortnox"),
					content: (
						<FormLayout>
							<p>
								{this.props.t(
									"common.related_resources.sync_fortnox.confirmation.content",
									"Är du säker på att du vill synka denna kund till Fortnox?"
								)}
							</p>

							<TextField
								label={this.props.t("common.related_resources.sync_fortnox.confirmation.optional_customer_number", "Kundnummer (Valfritt)")}
								value={this.state.customerNumber}
								onChange={(v) => {
									this.setState({ customerNumber: v });
								}}
							/>
						</FormLayout>
					),
					confirm: this.props.t("common.related_resources.sync_fortnox.confirmation.confirm", "Synka"),
					cancel: this.props.t("common.related_resources.sync_fortnox.confirmation.cancel", "Avbryt"),
				},

				enabled:
					store.getState().account.fortnox_integration &&
					!store.getState().account.fortnox_integration?.removed &&
					!this.state.form?.fortnox_customer_id,
			},

			...(actions?.filter((action) => !action?.actions) || []),
		].filter((a) => !("enabled" in a) || a?.enabled);
		const actionGroups = actions?.filter((action) => action?.actions) || [];

		return (
			<Page
				key={this.state.form?.id}
				title={this.state.form?.name}
				fullWidth
				backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
				// title={this.state.form.name}
				// titleMetadata={this.state.form.is_company ? <Badge>Företag</Badge> : <Badge status="attention">Kontaktperson</Badge>}
				secondaryActions={secondaryActions}
				actionGroups={
					this.state.form?.removed
						? []
						: [
								...actionGroups,
								{
									icon: MobileHorizontalDotsMajor,
									title: this.props.t("common.terms.rest", "Övrigt"),
									actions: [
										{
											icon: EditMajor,
											content: this.props.t("contact.actions.change", "Ändra uppgifter"),
											onAction: this.openContactModal.bind(this, this.state.form),
										},
										{
											icon: LocationMajor,
											content: this.props.t("contact.actions.workplace.add", "Lägg till nytt arbetsställe"),
											onAction: this.editAddress.bind(this, { title: "", country: "SE" }),
										},
										{
											icon: ExitMajor,
											content: this.props.t("contact.actions.fornox.remove", "Ta bort Fortnox koppling"),
											onAction: this.props.removeFortnoxIntegration,
											destructive: true,
											enabled:
												!this.state.form.fortnox_integration?.removed &&
												this.state.form.fortnox_customer_id &&
												store.getState().user.roles.includes("ROLE_ADMIN"),
											confirmation: {
												title: this.props.t("contact.fortnox.remove.confirmation.title", "Ta bort koppling till Fortnox"),
												content: this.props.t(
													"contact.fortnox.remove.confirmation.content",
													"Är du säker på att du vill ta bort kopplingen till Fortnox?"
												),
											},
										},
									].filter((a) => !("enabled" in a) || a?.enabled),
								},
						  ]
				}
			>
				<div className="contact-wrapper">
					{this.state.form?.removed && (
						<Banner
							style={{ marginBottom: "0.6250rem" }}
							status="warning"
							title={this.props.t("contact.statuses.removed", "Denna kontakt är borttagen")}
						>
							{this.props.t("contact.text.removed", "{{name}} är borttagen och kan ej redigeras längre.", { name: this.state.form.name })}
						</Banner>
					)}
					{notification}
					{this.state.form.is_company ? (
						contactTabsBar
					) : (
						<Layout>
							<Layout.Section>
								<ContactCard
									contact={this.state.form}
									openContactModal={this.openContactModal.bind(this, this.state.form)}
									openContactModalParent={this.openContactModalParent.bind(this, this.state.form.parent)}
									compact
									onUpdate={(contact) => {
										if (!contact.is_company && contact.id != this.state.form.id) {
											this.props.history.push("/admin/contacts/" + contact.id);
										}
									}}
									gotoContact={this.gotoContact.bind(this)}
									loading={this.state.loading}
									external={this.props.external}
								/>
								<div style={{ marginTop: "1.0000rem" }}>{contactTabsBar}</div>
							</Layout.Section>
							<Layout.Section secondary>
								<TimeLine
									expanded
									ref={this.timeLine}
									disabled={this.state.form?.removed}
									history={this.props.history}
									gotoContact={(contact) => {
										this.gotoContact(contact);
									}}
									contact={this.state.form}
									isCompany={this.state.form.is_company}
								/>
								<Note
									contact={this.state.form}
									// className={`note_${this.state.form.is_company ? "company" : "person"}`}
									note={this.state.form && this.state.form.note}
									onUpdate={this.updateForm.bind(this, "note")}
									disabled={this.state.form?.removed}
									external={this.props.external}
								/>
							</Layout.Section>
						</Layout>
					)}

					<ContactModal
						open={this.state.showContactModal}
						onUpdate={(contact = {}) => {
							if (!contact.is_company && this.state.form.is_company) {
								let found = false;
								for (let i = 0; i < this.state.children.length; i++) {
									if (this.state.children[i].id == contact.id) {
										this.state.children[i] = contact;
										found = true;
										break;
									}
								}
								if (!found) {
									this.state.children.push(contact);
								}
								this.setState({ children: this.state.children });
							} else {
								if (this.state.form.id == contact.id) {
									this.state.form = contact;
									this.props.updateContact?.(contact);
									this.setState({ form: this.state.form });
								} else {
									this.props.history.push("/admin/contacts/" + contact.id);
								}
							}
						}}
						onCreate={(contact) => {
							if ("children" in contact) {
								for (let i = 0; i < contact.children.length; i++) {
									if (contact.children[i].id == this.state.form.id) {
										this.gotoContact(contact);
									}
								}
							}
						}}
						onRemove={(contact) => {
							const parent = this.props.location && this.props.location.state && this.props.location.state.parent;
							if (contact.id == this.state.form.id && !parent) {
								this.props.history.goBack();
							} else if (parent) {
								this.gotoContact(parent, { contactTab: "contacts" });
							} else {
								const children = [];
								for (let i = 0; i < this.state.children.length; i++) {
									if (this.state.children[i].id != contact.id) {
										children.push(this.state.children[i]);
									}
								}
								this.setState({ children });
							}
						}}
						company={this.state.form.is_company ? this.state.form : this.state.form.parent}
						contact={this.state.selectedContact}
						// contact={this.state.selectedContact || (this.state.form.is_company ? this.state.form : this.state.form.parent)}
						onClose={this.closeContactModal.bind(this)}
					/>
					<RemoveModal
						open={this.state.showRemoveModal}
						onClose={this.closeRemoveModal.bind(this)}
						loading={this.state.removing}
						onConfirm={this.removeContact.bind(this)}
						numItems={1}
						objectName={this.state.form.name}
						resourceName={
							this.state.selectedContact && this.state.selectedContact.is_company
								? {
										singular: this.props.t("contact.company.singular", "företag"),
										plural: this.props.t("contact.company.plural", "företag"),
								  }
								: {
										singular: this.props.t("contact.person.singular", "kontaktperson"),
										plural: this.props.t("contact.person.plural", "kontaktpersoner"),
								  }
						}
					/>
					{this.state.form.is_company ? (
						<AddressModal
							open={this.state.showAddressModal}
							onClose={this.closeAddressModal.bind(this)}
							contact={this.state.form}
							onRemove={this.fetchItem.bind(this, this.state.form.id)}
							onUpdate={this.fetchItem.bind(this, this.state.form.id)}
							address={this.state.currentAddress}
							onCreate={(address) => {
								this.state.form.addresses.push(address);
								this.setState({ form: this.state.form });
							}}
							loading={this.state.saving}
						/>
					) : null}

					<TagModal
						open={this.state.showRemoveTagModal}
						onClose={this.closeRemoveTagModal.bind(this)}
						resourceName={{
							singular: this.props.t("contact.singular", "kontakt"),
							plural: this.props.t("contact.plural", "kontakter"),
						}}
						resource="contacts"
						numItems={this.state.selectedContacts.length}
						onConfirm={this.doRemoveTags.bind(this)}
						loading={this.state.saving}
						remove
					/>
				</div>
				<EmailModal
					open={selectedAction?.action == "email"}
					contact={selectedAction?.contact}
					loading={this.state.actionLoading.includes("email")}
					onClose={() => this.setState({ selectedAction: null })}
					onSend={(email, contact) => {
						this.onSendMail(selectedAction, email, contact);
					}}
				/>

				{integrations.find((integration) => integration.type === "microsoft365") && this.state.form?.email && (
					<EmailListSheetContent
						open={this.state.emailsImportModalIsOpen}
						onClose={() => {
							this.setState({ emailsImportModalIsOpen: false });
						}}
						contact={this.state.form}
						integration={integrations.find((integration) => integration.type === "microsoft365")}
					/>
				)}
			</Page>
		);
	}
}
// <AttributeForm metafields={this.state.form.metafields} onUpdate={this.updateMetafields.bind(this)} />

// export default connect(
// 	(state) => ({
// 		workspaces: state.workspaces,
// 	}),
// 	{}
// )(withTranslation(["contact", "common"], { withRef: true })(ContactEdit));
export default withTranslation(["contact", "common"], { withRef: true })(Contact);
