import { withTranslation } from "react-i18next";
/* eslint-disable quotes */
import React, { useCallback, useState } from "react";
import { Button, LegacyStack } from "@shopify/polaris";
import Popover from "../Popover";

const ConfirmPopup = ({ activator, title, primaryButton = null, t }: any) => {
	const [open, setOpen] = useState(false);

	const handleOnClose = useCallback(() => {
		setOpen(false);
	}, []);
	const handleOnToggle = useCallback(() => {
		setOpen((c) => !c);
	}, []);

	const activatorButton = React.cloneElement(activator, { onClick: handleOnToggle });

	return (
		<Popover showCloseButton={false} active={open} activator={activatorButton} onClose={handleOnClose} sectioned>
			{title}
			<LegacyStack>
				<Button onClick={handleOnClose}>{t("common.actions.cancel", "Avbryt")}</Button>
				<div onClick={handleOnClose}>{primaryButton || activator}</div>
			</LegacyStack>
		</Popover>
	);
};

export default withTranslation(["modal", "common"], { withRef: true })(ConfirmPopup);
