import React, { useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import getRowSum from "../Utilities/getRowSum";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import InfoView from "src/js/components/InfoView";
import OrderRow, { OrderRowInnerWrapper } from "./OrderRow";
import styled from "styled-components";
import { CirclePlusMajor } from "@shopify/polaris-icons";
import Button from "src/js/components/Button";
import { numberFormatter } from "src/js/Utilities";
import Colors from "src/js/Colors";

const OrderRows = ({ t, order, onChange, isSaving, disabled }) => {
	const handleChangeForm = (index: number) => (row: OrderRowType | null) => {
		const rows = [...(order.order_rows || [])];
		if (!row) {
			rows.splice(index, 1);
		} else {
			rows[index] = row;
		}

		onChange("order_rows")(rows);
	};

	const totalStats = useMemo(() => {
		const stats =
			order?.order_rows?.reduce(
				(acc, row) => {
					const rowStats = getRowSum(row);

					acc.sum += rowStats.sum;
					acc.sumExclVat += rowStats.sumExclVat;
					acc.vat += rowStats.vat;
					acc.discount += rowStats.discount;

					return acc;
				},
				{ sum: 0, sumExclVat: 0, vat: 0, discount: 0 }
			) || {};

		const valueStyle = {
			textAlign: "end",
		};

		const formatter = (value) => {
			return numberFormatter({
				value: value || 0,
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) as unknown as number;
		};

		return [
			{
				label: t("order.fields.total_to_pay.label", "Totalt att betala"),
				value: formatter(stats.sum / 100),
				valueStyle,
				style: { fontWeight: "bold", color: Colors.green },
			},
			{
				label: t("order.fields.total_exl_vat.label", "Totalt exkl. moms"),
				value: formatter(stats.sumExclVat / 100),
				valueStyle,
			},
			{
				label: t("order.fields.vat.label", "moms"),
				value: formatter(stats.vat / 100),
				valueStyle,
			},
		];
	}, [order?.order_rows, t]);

	const onDragEnd = useCallback(
		(result) => {
			const { destination, source, draggableId } = result;
			if (!destination || !draggableId) return;

			const reorderedRows = Array.from(order?.order_rows);
			const [removed] = reorderedRows.splice(source.index, 1);
			reorderedRows.splice(destination.index, 0, removed);
			onChange("order_rows")(reorderedRows);
		},
		[onChange, order?.order_rows]
	);

	const columns = [
		{
			header: t("orders.row.fields.ArticleNumber.label", "Artikelnr"),
			handle: "article",
		},
		{
			header: t("orders.row.fields.Description.label", "Benämning"),
			handle: "description",
		},
		{
			header: t("orders.row.fields.OrderedQuantity.label", "Antal"),
			handle: "quantity",
		},
		{
			header: t("orders.row.fields.Unit.label", "Enhet"),
			handle: "unit",
		},
		{
			header: t("orders.row.fields.article_pricelist.label", "Prislista"),
			handle: "article_pricelist",
		},
		{
			header: t("orders.row.fields.price.label2", "Á-Pris"),
			handle: "price",
		},
		{
			header: t("orders.row.fields.tb1.label", "TB1"),
			handle: "tb1",
		},
		{
			header: t("orders.row.fields.tb2.label", "TB2"),
			handle: "tb2",
		},
		{
			header: t("orders.row.fields.Discount.label", "Rabatt"),
			handle: "discount",
		},

		{
			header: t("orders.row.fields.sum.label", "Summa"),
			handle: "sum",
		},
	] as { header: string; handle: string; style: any; suffix?: string }[];

	const rows = order?.order_rows || [];

	return (
		<Wrapper>
			<GridTableHeaderWrapper>
				<GridTableHeaderRow>
					{columns?.map((column) => (
						<GridTableHeaderCell key={column.handle} style={column.style}>
							{column.header}
							{column.suffix}
						</GridTableHeaderCell>
					))}
				</GridTableHeaderRow>
			</GridTableHeaderWrapper>

			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId={`Order_${order.id}_rows`}>
					{(droppableProvided, snapshot) => {
						return (
							<GridTableBodyWrapper ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
								{rows?.map((row: OrderRowType, index: number) => {
									// const key = JSON.stringify({ ...row, index });
									const key = index;
									return (
										<Draggable draggableId={String(index)} index={index} key={key} isDragDisabled={disabled}>
											{(provided, snapshot) => {
												return (
													<OrderRow
														disabled={disabled}
														order={order}
														row={row}
														columns={columns}
														onChange={handleChangeForm(index)}
														index={index}
														key={key}
														innerRef={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className="row"
													/>
												);
											}}
										</Draggable>
									);
								})}
								{!disabled && (
									<OrderRow
										columns={columns}
										key={rows?.length || 0}
										order={order}
										row={null}
										index={rows?.length || 0}
										onChange={handleChangeForm(order?.order_rows?.length || 0)}
									/>
								)}
								{droppableProvided.placeholder}
							</GridTableBodyWrapper>
						);
					}}
				</Droppable>
				<br />
				{!disabled && (
					<Button
						icon={CirclePlusMajor}
						primary
						onClick={() =>
							handleChangeForm(order?.order_rows?.length || 0)({
								article: null,
								custom_fields: [],
								description: "",
								article_pricelist: null,
								price_in_cents: 0,
								tb1_in_cents: 0,
								tb2_in_cents: 0,
								quantity: 1,
								discount: 0,
								vat_in_percentage: 0,
							})
						}
					/>
				)}
			</DragDropContext>

			<InfoView style={{ display: "flex", justifyContent: "end" }} details={totalStats} />
		</Wrapper>
	);
};
export default withTranslation(["orders", "common"])(OrderRows);

const GridTableHeaderWrapper = styled.div``;
const GridTableHeaderRow = styled.div`
	margin-bottom: 0.5rem;
`;
const GridTableHeaderCell = styled.div`
	font-weight: 700;
`;
const GridTableBodyWrapper = styled.div``;
const GridTableBodyCell = styled.div``;

const Wrapper = styled.div`
	margin-top: 5rem;

	${GridTableHeaderWrapper},
	${GridTableBodyWrapper} {
		display: grid;
		gap: 1rem;
	}

	${GridTableHeaderRow},
	${OrderRowInnerWrapper} {
		display: grid;
		// article description quantity unit  pricelist price_in_cents tb_in_cents tb2_in_cents discount sum [actions] [drag]
		grid-template-columns: 0.8fr 1fr 0.35fr 0.35fr 0.5fr 0.5fr 0.5fr 0.5fr 0.35fr 100px 25px 25px;
		gap: 1rem;
	}

	${GridTableBodyCell},
	${GridTableHeaderCell} {
		display: flex;
		align-items: center;
		padding: 0 1rem;
	}
`;
