import React, { Component } from "react";
import { LegacyCard, ChoiceList, Layout } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";
import { toastr } from "../../components/toastr";
import ContactCard from "../../components/ContactCard";
import Skeleton1Col from "../../components/skeleton_1col";
import ContactModal from "../../components/ContactModal";
import ContactContactsList from "./ContactContactsList";

class ContactImportRoaring extends Component {
	constructor(props) {
		super(props);
		this.state = { form: { tags: [] }, open: false, id: this.props.match && this.props.match.params.id };
	}

	componentDidMount() {
		this.fetch();
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match && props.match.params.id !== this.state.id) {
			this.setState({ id: props.match.params.id });
			this.fetch(props);
		}
	}

	fetch(props = this.props) {
		this.setState({ loading: true });
		API.get(`/api/contacts/roaring/${props.match.params.id}.json`)
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				const dir = result.data.company.topDirectorName || result.data.company.companyHolder;
				const ar = dir?.split?.(", ");
				const childs = ar ? [{ first_name: ar[1], last_name: ar[0], name: dir, id: 1 }] : [];

				const form = this.transformToContact(result.data.company, childs);
				this.setState({ form, loading: false, childs });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	transformToContact(rc, childs = []) {
		return Object.assign(rc, {
			address1: rc.address,
			street: rc.address,
			city: rc.town,
			country: "SE",
			name: rc.companyName,
			orgnr: rc.companyId,
			phone: rc.phoneNumber,
			zip: rc.zipCode,
			is_company: true,
			fromRoaring: true,
			childs,
		});
	}

	handleContactChange(childs) {
		this.state.form.childs = childs;
		this.setState({ form: this.state.form });
	}

	handleContactChangeIds(childsIds) {
		this.state.form.childs = this.state.childs.filter((child) => childsIds.includes(child.id));
		this.setState({ form: this.state.form });
	}

	openModal() {
		this.setState({ open: true });
	}

	closeModal() {
		this.setState({ open: false });
	}

	gotoContact(item) {
		item.path = "/admin/contacts/" + item.id;
		this.props.history.push("/admin/contacts/" + item.id, {
			stacks: [
				{
					parent_path: this.props.location.pathname + this.props.location.search,
					parent_title: this.props.t("contact.roaring.breadcrumb.company", "Företag"),
					item: { id: item.id, item, path: "/admin/contacts/" + item.id },
					items: this.state.visibleItems,
				},
			],
		});
	}

	render() {
		if (this.state.loading || !this.state.form) return <Skeleton1Col />;
		const primaryAction = {
			content: this.props.t("common.actions.import", "Importera"),
			onAction: this.openModal.bind(this),
		};

		return (
			<React.Fragment>
				<Page
					fullWidth
					backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
					primaryAction={primaryAction}
					secondaryActions={[primaryAction]}
				>
					<div className="contact-wrapper">
						<Layout>
							<Layout.Section>
								{this.state.childs && !!this.state.childs.length && (
									<LegacyCard title={this.props.t("contact.person.plural", "Kontaktpersoner")}>
										<div className="people" style={{ padding: "0.6250rem 1.2500rem" }}>
											<ChoiceList
												allowMultiple
												onChange={this.handleContactChange.bind(this)}
												selected={this.state.form.childs || []}
												choices={this.state.childs.map((child) => {
													const { first_name = "", last_name = "" } = child;
													// eslint-disable-next-line camelcase
													return { label: `${first_name} ${last_name}`, value: child };
												})}
											/>
										</div>
									</LegacyCard>
								)}
								<ContactContactsList
									roaring
									contact={this.state.form}
									items={this.state.childs}
									selectedContacts={this.state.form.childs && this.state.form.childs.map((i) => i.id)}
									handleContactChange={this.handleContactChangeIds.bind(this)}
								/>
							</Layout.Section>
							<Layout.Section secondary>{this.state.form && <ContactCard roaring contact={this.state.form} />}</Layout.Section>
						</Layout>
					</div>
				</Page>
				<ContactModal
					open={this.state.open}
					onUpdate={(form) => {
						this.setState({ form });
					}}
					contact={this.state.form}
					onCreate={(contact) => {
						this.gotoContact(contact);
					}}
					isCompany
					onClose={this.closeModal.bind(this)}
				/>
			</React.Fragment>
		);
	}
}
export default withTranslation(["contact", "common"], { withRef: true })(ContactImportRoaring);
