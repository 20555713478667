/* eslint-disable quotes */
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, ButtonGroup, ModalProps as PolarisModalProps, Modal as PolarisModal } from "@shopify/polaris";
import type { ComplexAction } from "@shopify/polaris";
import { store } from "../../store";
import ConfirmPopup from "./ConfirmPopup";
import ActionButton from "../page/ActionButton";
import { getPortalsMaxZIndex } from "src/js/Utilities";

const DEFAULT_ZINDEX = 550;
interface SecondaryAction extends ComplexAction {
	confirm?: boolean;
	confirmText?: string | React.ReactNode;

	confirmation?: {
		title?: string | null;
		content?: string | null | React.ReactNode;
		confirm?: string | null;
		cancel?: string | null;
	};
}

interface PrimaryAction extends ComplexAction {
	enabled?: boolean;
	confirm?: boolean;
	confirmText?: string | React.ReactNode;
}

interface ModalProps extends PolarisModalProps {
	noTransition?: boolean;
	secondaryActions?: SecondaryAction[];
	primaryAction?: PrimaryAction;
	onOverlayClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

interface ModalComponent extends React.FC<ModalProps> {
	Section: typeof PolarisModal.Section;
}

// type ModalReturn = (ReactElement | null) & { Section: ReactElement | null };

const Modal: ModalComponent = ({
	children,
	onClose,
	open,
	title,
	primaryAction,
	noTransition,
	secondaryActions: propsSecondaryActions,
	large,
	footer,
	sectioned,
	onOverlayClick,
	...rest
}: ModalProps) => {
	const ref = useRef<any>();

	const handleConditionalOnClose = useCallback(
		(e?: any) => {
			// if (e) e.stopPropagation();
			e?.stopPropagation();
			e?.preventDefault();

			if ([2, 419, 418].includes(store.getState().user.id)) {
				onClose?.();
			}
		},
		[onClose]
	);

	const onKeyDown = useCallback(
		(e: any) => {
			if (e.key === "Escape") handleConditionalOnClose(e);
		},
		[handleConditionalOnClose]
	);

	const getContent = () => {
		// if (sectioned) return <PolarisModal.Section>{children}</PolarisModal.Section>;
		return children;
	};

	useEffect(() => {
		const refElement = ref.current;
		const container = refElement?.closest(".Polaris-Modal-Dialog__Container");
		container?.addEventListener("keydown", onKeyDown);

		return () => {
			container?.removeEventListener("keydown", onKeyDown);
		};
	}, [onKeyDown]);

	useEffect(() => {
		const refElement = ref.current;
		const mainContainer = refElement?.closest("[data-portal-id]");
		const backdrop = mainContainer?.querySelector(".Polaris-Backdrop");

		if (backdrop) backdrop.addEventListener("click", onOverlayClick);

		return () => {
			backdrop?.removeEventListener("keydown", onOverlayClick);
		};
	}, [onOverlayClick]);

	useEffect(() => {
		const refElement = ref.current;
		if (open) {
			const container = refElement?.closest(".Polaris-Modal-Dialog__Container");
			const mainContainer = refElement?.closest("[data-portal-id]");
			const backdrop = mainContainer?.querySelector(".Polaris-Backdrop");
			if (container) {
				const zIndex = Math.max(DEFAULT_ZINDEX, getPortalsMaxZIndex()) + 3;
				container.style.zIndex = zIndex;
				if (backdrop) {
					backdrop.style.zIndex = zIndex - 1;
				}
			}
		}
	}, [open]);

	const secondaryActions = propsSecondaryActions ? (Array.isArray(propsSecondaryActions) ? propsSecondaryActions : [propsSecondaryActions]) : [];

	return (
		<PolarisModal
			open={open}
			onClose={onClose}
			title={title}
			// primaryAction={primaryAction}
			// secondaryActions={secondaryActions}
			large={large}
			footer={
				<>
					{footer}

					<Footer>
						<FooterWrapperContent ref={ref}>
							{footer && <CustomStack>{footer}</CustomStack>}

							<CustomStack style={{ justifyContent: "space-between" }}>
								<ButtonGroup>
									{secondaryActions?.map((secondaryAction, index) => {
										const { confirmation, content, onAction, loading, disabled, destructive, icon, confirm, confirmText } = secondaryAction;

										if (confirmation) {
											return <ActionButton key={index} item={secondaryAction} plain={false} />;
										}

										if (confirm) {
											return (
												<ConfirmPopup
													title={confirmText}
													key={index}
													activator={
														<ModalActionButton
															key={index}
															onAction={onAction}
															onClick={(secondaryAction as any)?.onClick}
															icon={icon}
															loading={loading}
															disabled={disabled}
															destructive={destructive}
														>
															{content}
														</ModalActionButton>
													}
												/>
											);
										}

										return (
											<ModalActionButton
												key={index}
												onAction={onAction}
												onClick={(secondaryAction as any)?.onClick}
												icon={icon}
												loading={loading}
												disabled={disabled}
												destructive={destructive}
											>
												{content}
											</ModalActionButton>
										);
									})}
								</ButtonGroup>
								<ButtonGroup>
									{primaryAction &&
										(!("enabled" in primaryAction) || primaryAction.enabled) &&
										(primaryAction?.confirm ? (
											<ConfirmPopup
												title={primaryAction.confirmText}
												activator={
													<ModalActionButton
														primary={!primaryAction.destructive}
														onAction={primaryAction.onAction}
														onClick={(primaryAction as any)?.onClick}
														icon={primaryAction.icon}
														loading={primaryAction.loading}
														disabled={primaryAction.disabled}
														destructive={primaryAction.destructive}
													>
														{primaryAction.content}
													</ModalActionButton>
												}
											/>
										) : (
											<ModalActionButton
												primary={!primaryAction.destructive}
												onAction={primaryAction.onAction}
												onClick={(primaryAction as any)?.onClick}
												icon={primaryAction.icon}
												loading={primaryAction.loading}
												disabled={primaryAction.disabled}
												destructive={primaryAction.destructive}
											>
												{primaryAction.content}
											</ModalActionButton>
										))}
								</ButtonGroup>
							</CustomStack>
						</FooterWrapperContent>
					</Footer>
				</>
			}
			sectioned={sectioned}
			{...rest}
		>
			{getContent()}
		</PolarisModal>
	);
};

Modal.Section = PolarisModal.Section;

export default Modal;

const ModalActionButton = ({ children, onClick, onAction, loading, ...props }) => {
	const [isLoaded, setIsLoaded] = useState(false);

	const handleAction = async () => {
		try {
			setIsLoaded(true);
			await onClick?.();
			return await onAction?.();
		} catch (error) {
			console.error("error:", error);
		} finally {
			setIsLoaded(false);
		}
	};

	return (
		<Button {...props} onClick={handleAction} loading={loading || isLoaded}>
			{children}
		</Button>
	);
};

const FooterWrapper = styled.div.attrs({ className: "Polaris-Modal-Footer" })`
	margin-top: 1rem;
`;
const FooterWrapperContent = styled.div.attrs({ className: "Polaris-Modal-Footer__FooterContent" })``;

const CustomStack = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
`;

const Footer = ({ children }) => {
	const ref = useRef<any>();

	const fixFooter = useCallback(() => {
		const refElement = ref.current;
		if (refElement && refElement.closest(".Polaris-Box")) refElement.closest(".Polaris-Box").style.width = "100%";
	}, []);

	useEffect(() => {
		fixFooter();
	}, [fixFooter]);

	return <FooterWrapper ref={ref}>{children}</FooterWrapper>;
};
