import { Spinner } from "@shopify/polaris";
import React, { Component } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player/youtube";
import { withTranslation } from "react-i18next";
import nl2br from "react-nl2br";
import Page from "src/js/components/page";
import API from "../../API";
import { HelpPageWrapper } from ".";
import { toastr } from "../../components/toastr";
import HelpHeader from "./HelpHeader";

class HelpPage extends Component {
	constructor(props) {
		super(props);
		this.state = { id: props.match.params.id, item: props.location.state && props.location.state.item, loading: false };
	}

	componentDidMount() {
		this.fetch();
	}

	fetch() {
		this.setState({ loading: true });
		API.get(`/api/helpcenter/${this.state.id}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
					this.setState({ loading: false });
					return;
				}

				if (result.data.item) this.setState({ item: result.data.item });
			})
			.catch((error) => {
				console.error("error:", error);
				this.setState({ loading: false });
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	render() {
		const { item, loading } = this.state;
		const { videos, title, text, image_url: imageUrl, icon } = item || {};

		return (
			<Page
				backAction={
					this.props.history.length > 1 && { content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }
				}
			>
				<HelpPageWrapper>
					<HelpHeader
						title={
							<React.Fragment>
								{nl2br(title)}
								{loading && (
									<span style={{ marginLeft: "0.6250rem" }}>
										<Spinner size="small" />
									</span>
								)}
							</React.Fragment>
						}
						text={nl2br(text)}
						icon={icon}
						image={imageUrl}
					/>
					<VideosWrapper>
						{videos &&
							videos.map(({ url, title, text }, key) => (
								<Video key={key}>
									<h1 className="loading">{nl2br(title)}</h1>

									<ReactPlayer
										// onReady={(e) => {}}
										width="100%"
										height="unset"
										url={url}
										light
										playsinline
										controls
										style={{ margin: "1.2500rem 0", aspectRatio: "16/9" }}
										// config={{
										// 	youtube: {
										// 		playerVars: { showinfo: 1 },
										// 	},
										// 	facebook: {
										// 		appId: "12345",
										// 	},
										// }}
									/>
									<p>{nl2br(text)}</p>
								</Video>
							))}
					</VideosWrapper>
				</HelpPageWrapper>
			</Page>
		);
	}
}
export default withTranslation(["help", "common"], { withRef: true })(HelpPage);

const Video = styled.div`
	color: var(--textColor);
	/* width: calc(33% - 0.8333rem); */
	/* min-width: 350px;
	width: calc(33% - calc(12.5000rem / 3)); */
	aspect-ratio: 16/9;
	height: max-content;
	position: relative;
	transition: width 500ms;

	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 100%;

	width: 300px;
	margin: auto;

	h1 {
		font-weight: 700;
		font-size: 22px;
		line-height: 27px;
		height: 54px;
		overflow: hidden;
		white-space: pre-line;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}

	p {
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
	}

	/* @media screen and (max-width: 1920px) {
		width: calc(33% - calc(12.5000rem / 3));
	} */
`;

const VideosWrapper = styled.div`
	/* display: flex;
	gap: 6.2500rem;
	flex-wrap: wrap; */

	transition: gap 500ms;

	margin-top: 3.125rem;
	display: grid;
	gap: 1.25rem;
	row-gap: 6.25rem;
	grid-template-columns: repeat(auto-fill, minmax(300px, auto));

	/* @media screen and (max-width: 1725px) {
		gap: 3.1250rem;
	}
	@media screen and (max-width: 1625px) {
		gap: 1.2500rem;
	}
	@media screen and (max-width: 1575px) {
		gap: 6.2500rem;
	}
	@media screen and (max-width: 1275px) {
		gap: 3.1250rem;
	}
	@media screen and (max-width: 1225px) {
		gap: 6.2500rem;
	} */
	/* @media screen and (max-width: 1650px) {
	gap: 3.1250rem;
	} */
`;
