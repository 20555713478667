import React, { Component } from "react";
import { FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { toastr } from "./toastr.js";
import AdAccountSelector from "./AdAccountSelector.js";
import Modal from "src/js/components/modal";

class AdCampaignModal extends Component {
	constructor(props) {
		super(props);
		if (props.ad_account) {
			props.campaign.ad_account = props.ad_account;
		}
		this.state = { open: props.open, form: props.campaign, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (props.ad_account) {
				props.campaign.ad_account = props.ad_account;
			}
			this.setState({ open: props.open, form: props.campaign });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id && this.state.form.ad_account) {
			API.put("/api/ans_accounts/" + this.state.form.ad_account.id + "/ans_campaigns/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("ad.responses.updated", "Uppdaterade kampanj"));
					this.setState({ saving: false });
					this.props.onCreate(result.data.ad_campaign);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else if (this.state.form.ad_account) {
			API.post("/api/ans_accounts/" + this.state.form.ad_account.id + "/ans_campaigns.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("ad.responses.created", "Skapade kampanj"));

					this.setState({ saving: false });
					this.props.onCreate(result.data.ad_campaign);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	render() {
		const typeOptions = [];
		typeOptions.push({ label: this.props.t("ad.fields.type.options.choose", "-- välj --"), value: "" });
		if (this.state.form.ad_account && this.state.form.ad_account.type == "adwords") {
			typeOptions.push({ label: this.props.t("ad.fields.type.options.choose", "Sökannons"), value: "text" });
			typeOptions.push({ label: this.props.t("ad.fields.type.options.display", "Sökannons", "Bilder"), value: "display" });
			typeOptions.push({ label: this.props.t("ad.fields.type.options.video", "Sökannons", "Videos"), value: "video" });
		} else if (this.state.form.ad_account && this.state.form.ad_account.type == "facebook") {
			typeOptions.push({ label: this.props.t("ad.fields.type.options.facebook_display", "Sökannons", "Bilder/Video"), value: "display" });
		}
		return (
			<Modal
				open={this.state.open}
				onClose={this.props.onClose}
				title={this.state.form.id ? this.props.t("ad.titles.update", "Uppdatera kampanj") : this.props.t("ad.titles.new", "Ny kampanj")}
				secondaryActions={[
					{
						content: this.props.t("common.actions.close", "Stäng"),
						onAction: this.props.onClose,
					},
				]}
				primaryAction={{
					content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
					loading: this.state.saving,
					disabled:
						!this.state.form.ad_account ||
						!this.state.form.type ||
						!this.state.form.title ||
						(!this.state.form.budget && this.state.form.ad_account.type != "facebook"),
					onAction: this.saveForm.bind(this),
				}}
			>
				<Modal.Section>
					<FormLayout>
						<AdAccountSelector
							disabled={!!this.props.ad_account}
							required
							label={this.props.t("ad.fields.ad_account.label", "Annonskonto")}
							ad_account={this.state.form.ad_account}
							onChange={(value) => {
								this.state.form.ad_account = value;
								this.setState({ form: this.state.form });
							}}
						/>
						{this.state.form.ad_account ? (
							<TextField
								label={this.props.t("ad.fields.title.label", "Namn på kampanj")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
						) : null}
						{this.state.form.ad_account ? (
							<Select
								label={this.props.t("ad.fields.type.label", "Typ av kampanj")}
								options={typeOptions}
								onChange={this.updateForm.bind(this, "type")}
								value={this.state.form.type}
							/>
						) : null}
						{this.state.form.ad_account && this.state.form.ad_account.type != "facebook" ? (
							<TextField
								label={this.props.t("ad.fields.budget.label", "Daglig budget")}
								value={this.state.form.budget}
								type="currency"
								suffix="kr"
								onChange={this.updateForm.bind(this, "budget")}
							/>
						) : null}
					</FormLayout>
				</Modal.Section>
			</Modal>
		);
	}
}
export default withTranslation(["ad", "common"], { withRef: true })(AdCampaignModal);
