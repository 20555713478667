import React from "react";
import { LoadingBox } from "./styles";

const SkeletonTextfield = ({ label = true, loading = true }) => {
	return (
		<div style={{ marginTop: "1.6rem" }}>
			{label && (
				<LoadingBox height={20} width={"15%"} loading={loading} style={{ background: "var(--tertiary)", marginBottom: 10 }}>
					{label}
				</LoadingBox>
			)}
			<LoadingBox height={36} width={"100%"} loading={loading} style={{ background: "var(--tertiary)" }} />
		</div>
	);
};
export default SkeletonTextfield;
