import MyPage from "./views/Users/mypage.js";
import Goals from "./views/Goals/Goals.js";
import NotificationsIndex from "./views/Notifications/NotificationsIndex.js";
import AdwordAccountIndex from "./views/AdAccounts/GoogleIndex.js";
import FacebookAccountIndex from "./views/AdAccounts/FacebookIndex.js";
import AutomationIndex from "./views/Automations/AutomationIndex.js";
import AutomationEdit from "./views/Automations/AutomationEdit.js";
import AdsIndex from "./views/Ads/Ads.js";
import AdCampaignIndex from "./views/AdCampaigns/AdCampaigns.js";
import AdCampaignEdit from "./views/AdCampaigns/AdCampaignEdit.js";
import AdCreate from "./views/Ads/AdCreate.js";
import AdEdit from "./views/Ads/AdEdit.js";
import AdCompleteFacebook from "./views/Ads/AdCompleteFacebook.js";
import EmailCampaigns from "./views/EmailCampaigns/EmailCampaigns.js";
import EmailCampaignsCreate from "./views/EmailCampaigns/EmailCampaignCreate.js";
import EmailCampaignsTemplate from "./views/EmailCampaigns/EmailCampaignTemplate.js";
import EmailCampaignsEdit from "./views/EmailCampaigns/EmailCampaignEdit.js";
import SettingsIndex from "./views/Settings/index.js";
// import SupportSettings from "./views/Tickets/SupportSettings.js";
import ContactSettings from "./views/Contacts/ContactSettings.js";
import AccountIndex from "./views/Account/index.js";
import UserIndex from "./views/Users/index";
// import UserCreate from "./views/Users/create.js";
import UserEdit from "./views/Users/edit.tsx";
import GroupIndex from "./views/Groups/index";
import GroupCreate from "./views/Groups/create";
import GroupEdit from "./views/Groups/edit";
import BillingIndex from "./views/Billing/index.js";
import ContactsCompanies from "./views/Contacts/Companies.js";
import ContactsPeople from "./views/Contacts/People.js";
import ContactEdit from "./views/Contacts/edit.js";
import ContactImportRoaring from "./views/Contacts/ContactImportRoaring.js";
import Integrations from "./views/Integrations/Integrations.js";
import IntegrationFullscreen from "./views/Integrations/IntegrationFullscreen.js";
import Tracking from "./views/Integrations/Tracking.js";
import Triggers from "./views/Triggers/Triggers.js";
import TriggersCreate from "./views/Triggers/TriggersCreate.js";
import TriggersEdit from "./views/Triggers/TriggersEdit.js";
import WorkspaceView from "./views/Workspaces/WorkspaceView.js";
import BoardView from "./views/Workspaces/BoardView.js";
import DashboardView from "./views/Workspaces/DashboardView.js";

import Audiences from "./views/Audiences/Audiences.js";
import AudienceCreate from "./views/Audiences/AudienceCreate.js";
import AudienceEdit from "./views/Audiences/AudienceEdit.js";
import Pdf from "./views/Pdf/Pdf.js";
import NewPDF from "./views/Pdf/NewPDF.js";
import EditPDF from "./views/Pdf/EditPDF.js";
import EditorView from "./views/Pdf/Editor/EditorView.js";
import EmailContacts from "./views/EmailContacts/EmailContacts.js";
import IconsOverview from "./components/IconsOverview.js";
import Help from "./views/Help/index.js";
import HelpPage from "./views/Help/HelpPage.js";
import TagsIndex from "./views/Tags/index.js";
import GoalsUserIndex from "./views/Goals/GoalsUserIndex.js";
import VisitorsConversions from "./views/Visitors/VisitorsConversions.js";
import LanguagesIndex from "./views/Languages/index.js";
import EditLanguage from "./views/Languages/edit.js";
import { store } from "./store/index.js";
import CallLists from "./views/CallLists/index.js";
import IntegrationConnected from "./components/IntegrationConnected.js";
import UsersActivity from "./views/UsersActivity/index.tsx";
import UsersActivityUserPage from "./views/UsersActivity/UsersActivityUserPage.tsx";
import ComponentRoutes from "./views/ComponentsOverview/ComponentRoutes.js";
import ApiUserTokens from "./views/ApiUserTokens";
import ScriveIndex from "./views/Scrive";
import ScriveDocumentView from "./views/Scrive/Document";
import FortnoxOrder from "./views/Fortnox/Orders";
import FortnoxInvoice from "./views/Fortnox/Invoices";
import FortnoxOrderCreate from "./views/Fortnox/Orders/Create";
import FortnoxInvoiceCreate from "./views/Fortnox/Invoices/Create";

import FortnoxOffer from "./views/Fortnox/Offers";
import FortnoxOfferCreate from "./views/Fortnox/Offers/Create";
import FortnoxContractCreate from "./views/Fortnox/BillingContracts/Create";
import FortnoxContract from "./views/Fortnox/BillingContracts";
import FortnoxSettings from "./views/Fortnox/Settings";
import FortnoxInvoicesList from "./views/Fortnox/Invoices/List/FortnoxInvoicesList";
import FortnoxOrdersList from "./views/Fortnox/Orders/List/FortnoxOrdersList";
import FortnoxOffersList from "./views/Fortnox/Offers/List/FortnoxOffersList";
import FortnoxBillingContractsList from "./views/Fortnox/BillingContracts/List/FortnoxBillingContractsList";
import ContractLicenses from "./views/Licenses/ContractLicenses";
import FortnoxArticlesList from "./views/Fortnox/Articles/List/FortnoxArticlesList";
import FortnoxArticle from "./views/Fortnox/Articles";
import Dashboards from "./views/dashboards";
import ArticleSettings from "src/js/views/OrderFlow/Settings";
import ArticleList from "src/js/views/OrderFlow/Articles/List";
import ArticleCreate from "src/js/views/OrderFlow/Articles/Create";
import Article from "src/js/views/OrderFlow/Articles";
import PricelistList from "src/js/views/OrderFlow/Pricelists/List";
import PricelistCreate from "src/js/views/OrderFlow/Pricelists/Create";
import Pricelist from "src/js/views/OrderFlow/Pricelists/";
import CategoriesList from "src/js/views/OrderFlow/Categories/List";
import CategoryCreate from "src/js/views/OrderFlow/Categories/Create";
import Category from "src/js/views/OrderFlow/Categories/";
//import CustomFieldCreate from "./views/OrderFlow/CustomFields/Create";
import CustomFieldsList from "./views/OrderFlow/CustomFields/List";
import CustomField from "./views/OrderFlow/CustomFields";
import CustomFieldCreate from "./views/OrderFlow/CustomFields/Create";
import TargetsList from "./views/Targets/List";
import TargetCreate from "./views/Targets/Create";
import TargetEdit from "./views/Targets/Edit";
import i18n from "./i18n.js";
import OrdersList from "./views/OrderFlow/Orders/List";
import OrderCreate from "./views/OrderFlow/Orders/Create";
import OrderEdit from "./views/OrderFlow/Orders/Edit";
import OrderSettings from "./views/OrderFlow/Orders/OrderSettings";
import MissingPermission from "./views/MissingPermission";
import ContactImport from "./views/Contacts/Import/Import";

// import Dashboard from "./views/Dashboard/dashboard.js";
// import FortnoxArticleCreate from "./views/Fortnox/Articles/Create";

const getBaseRoutes = () => [
	{
		path: "/admin/dashboard/:type?",
		mainMenu: true,
		handle: "dashboard",
		exact: true,
		component: Dashboards,
	},
	{
		path: "/admin/audiences/:id(\\d+)",
		mainMenu: false,
		exact: true,
		component: AudienceEdit,
	},
	{
		path: "/admin/audiences/new",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: AudienceCreate,
		name: i18n.t("routes.names.audience_create", "Skapa målgrupp"),
	},
	{
		path: "/admin/audiences",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: Audiences,
		name: i18n.t("routes.names.audiences", "Målgrupper"),
	},
	{
		path: "/admin/email_contacts",
		mainMenu: true,
		handle: "myweek",
		exact: true,
		component: EmailContacts,
		name: i18n.t("routes.names.email_contacts", "E-post kontakter"),
	},
	{
		path: "/admin/ads/complete_facebook/:id(\\d+)",
		mainMenu: true,
		handle: "ad facebook",
		exact: true,
		component: AdCompleteFacebook,
	},
	{
		path: "/admin/ads/:id(\\d+)",
		mainMenu: false,
		exact: true,
		component: AdEdit,
	},
	{
		path: "/admin/ads/new",
		mainMenu: true,
		handle: "ads",
		exact: true,
		component: AdCreate,
		name: i18n.t("routes.names.ad_create", "Skapa annons"),
	},
	{
		path: "/admin/ads",
		mainMenu: true,
		handle: "ads",
		exact: true,
		component: AdsIndex,
		name: i18n.t("routes.names.ads", "Annonser"),
	},
	{
		path: "/admin/ad_campaigns",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: AdCampaignIndex,
		name: i18n.t("routes.names.ad_campaigns", "Annonskampanjer"),
	},
	{
		path: "/admin/ad_campaigns/:id(\\d+)",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: AdCampaignEdit,
	},
	{
		path: "/admin/me",
		mainMenu: true,
		handle: "me",
		exact: true,
		component: MyPage,
		name: i18n.t("routes.names.my_page", "Min sida"),
	},
	{
		path: "/admin/scrive/documents/:id(\\d+)",
		mainMenu: true,
		handle: "scrive Document",
		exact: true,
		component: ScriveDocumentView,
	},
	{
		path: "/admin/scrive/documents",
		mainMenu: true,
		handle: "scrive Documents",
		exact: true,
		component: ScriveIndex,
		name: i18n.t("routes.names.scrive", "Scrive"),
	},
	{
		path: "/admin/integrations/:id(\\d+)/:rand",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: IntegrationFullscreen,
	},
	{
		path: "/admin/integrations/:id(\\d+)/:rand([\\d|\\.]+):path(.+)",
		mainMenu: true,
		handle: "ad_campaigns",
		exact: true,
		component: IntegrationFullscreen,
	},
	{
		path: "/admin/goals/:id(\\d+)/users",
		mainMenu: false,
		exact: true,
		component: GoalsUserIndex,
	},
	{
		path: "/admin/goals/:id(\\d+)",
		mainMenu: false,
		exact: true,
		component: Goals,
	},
	{
		path: "/admin/goals",
		mainMenu: true,
		exact: true,
		component: Goals,
		name: i18n.t("routes.names.goals", "Mål"),
	},
	{
		path: "/admin/companies/:id/:people_id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
	},
	{
		path: "/admin/companies/:id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
	},
	{
		path: "/admin/notifications",
		mainMenu: true,
		handle: "tasks",
		exact: true,
		component: NotificationsIndex,
		name: i18n.t("routes.names.notifications", "Notifikationer"),
	},
	{
		path: "/admin/call_lists",
		mainMenu: true,
		exact: true,
		component: CallLists,
		name: i18n.t("routes.names.call_lists", "Ring-listor"),
	},
	{
		path: "/admin/google/accounts",
		handle: "accounts",
		exact: true,
		component: AdwordAccountIndex,
		name: i18n.t("routes.names.google_accounts", "Google konton"),
	},
	{
		path: "/admin/facebook/accounts",
		mainMenu: true,
		handle: "accounts",
		exact: true,
		component: FacebookAccountIndex,
		name: i18n.t("routes.names.facebook_accounts", "Facebook konton"),
	},
	{
		path: "/admin/email_campaigns",
		mainMenu: true,
		handle: "email_campaigns",
		exact: true,
		component: EmailCampaigns,
		name: i18n.t("routes.names.email_campaigns", "E-postkampanjer"),
		// roles: ["ROLE_MARKETING_EMAIL_CAMPAIGNS"],
	},
	{
		path: "/admin/automations/:id",
		mainMenu: true,
		handle: "automations",
		exact: true,
		component: AutomationEdit,
		name: i18n.t("routes.names.automation_edit", "Redigera automatisering"),
	},
	{
		path: "/admin/automations",
		mainMenu: true,
		handle: "automations",
		exact: true,
		component: AutomationIndex,
		name: i18n.t("routes.names.automations", "Automatiseringar"),
	},
	{
		path: "/admin/conversions",
		mainMenu: false,
		exact: true,
		component: VisitorsConversions,
		name: i18n.t("routes.names.visitors_conversions", "Besökare och konverteringar"),
	},
	{
		path: "/admin/email_campaigns/new",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsCreate,
		name: i18n.t("routes.names.email_campaign_create", "Skapa e-postkampanj"),
	},
	{
		path: "/admin/email_campaigns/new/template",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_campaigns/new/template/:id",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_templates/:id",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsTemplate,
		name: i18n.t("routes.names.email_campaign_template", "E-postmall"),
	},
	{
		path: "/admin/email_campaigns/:id",
		mainMenu: false,
		exact: true,
		component: EmailCampaignsEdit,
		name: i18n.t("routes.names.email_campaign_edit", "Redigera e-postkampanj"),
	},
	{
		path: "/admin/contacts/import/:orgnr",
		mainMenu: false,
		exact: true,
		component: ContactImport,
		name: i18n.t("routes.names.contacts.import.name", "Importera kontakter"),
	},
	{
		path: "/admin/contacts/roaring/:id",
		mainMenu: false,
		exact: true,
		component: ContactImportRoaring,
		name: i18n.t("routes.names.contact_import_roaring", "Importera kontakter från Roaring"),
	},
	{
		path: "/admin/contacts/companies",
		mainMenu: true,
		handle: "companies",
		exact: true,
		component: ContactsCompanies,
		name: i18n.t("routes.names.companies", "Företag"),
	},
	{
		path: "/admin/contacts/people",
		mainMenu: true,
		handle: "contacts",
		exact: true,
		component: ContactsPeople,
		name: i18n.t("routes.names.people", "Personer"),
	},
	{
		path: "/admin/contacts/:id/:people_id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
		name: i18n.t("routes.names.contact_edit", "Redigera kontakt"),
	},
	{
		path: "/admin/contacts/:id",
		mainMenu: false,
		exact: true,
		component: ContactEdit,
		name: i18n.t("routes.names.contact_edit", "Redigera kontakt"),
		// roles: ["ROLE_CONTACTS"],
	},
	{
		path: "/admin/workspaces/:workspace_id/dashboards/:id",
		mainMenu: false,
		exact: true,
		component: DashboardView,
		name: i18n.t("routes.names.dashboard", "Dashboard"),
	},
	{
		path: "/admin/workspaces/:workspace_id/boards/:id",
		mainMenu: false,
		exact: true,
		component: BoardView,
		name: i18n.t("routes.names.board", "Board"),
	},
	{
		path: "/admin/workspaces/:id",
		mainMenu: false,
		exact: true,
		component: WorkspaceView,
		name: i18n.t("routes.names.workspace", "Workspace"),
	},

	{
		path: "/admin/account",
		settings: true,
		description: "Hantera kontoinställningar och profilering",
		exact: true,
		component: AccountIndex,
		name: i18n.t("routes.names.account", "Kontoinställningar"),
	},
	{
		path: "/admin/tags",
		description: "Hantera företag och person taggar",
		exact: true,
		component: TagsIndex,
		name: i18n.t("routes.names.tags", "Taggar"),
	},
	{
		path: "/admin/billing",
		settingsa: true,
		description: "Visar faktureringhistorik till oss",
		exact: true,
		component: BillingIndex,
		name: i18n.t("routes.names.billing", "Fakturering"),
	},
	{
		path: "/admin/triggers",
		settingsa: true,
		description: "Hantera automatiska jobb som utför handlingar när något händer i systemet",
		exact: true,
		component: Triggers,
		name: i18n.t("routes.names.triggers", "Automationer"),
	},
	{
		path: "/admin/languages",
		settings: true,
		description: "Översättningar för språk",
		exact: true,
		component: LanguagesIndex,
		admin: true,
		enabled: [24, 1514].includes(store.getState().account.id) || [2, 3, 418, 419].includes(store.getState().user.id),
		name: i18n.t("routes.names.languages", "Språk"),
		// enabled: store.getState().user.roles.includes(["ROLE_TRANSLATION"]),
	},
	{
		path: "/admin/users_activity",
		exact: true,
		admin: true,
		component: UsersActivity,
		name: i18n.t("routes.names.users_activity", "Användaraktivitet"),
	},
	{
		path: "/admin/users_activity/user/:id",
		exact: true,
		component: UsersActivityUserPage,
		name: i18n.t("routes.names.users_activity", "Användaraktivitet"),
	},
	{
		path: "/admin/languages/:id",
		description: "Översättningar för språk",
		exact: true,
		component: EditLanguage,
		admin: true,
		enabled: [24, 1514].includes(store.getState().account.id) || [2, 3, 418, 419].includes(store.getState().user.id),
		name: i18n.t("routes.names.languages", "Språk"),
		// enabled: store.getState().user.roles.includes(["ROLE_TRANSLATION"]),
	},

	{
		path: "/admin/integrations",
		settings: true,
		description: "Tillåter dina system att kommunicera med Sellfinity för en bättre upplevelse",
		exact: true,
		component: Integrations,
		name: i18n.t("routes.names.integrations", "API & Integrationer"),
	},
	{
		path: "/admin/tracking-chat",
		settingsa: true,
		description: "Spåra dina besökare från hemsidan",
		exact: true,
		component: Tracking,
		name: i18n.t("routes.names.tracking", "Spårning"),
	},
	// {
	// 	path: "/admin/servicedesk",
	// 	settings: true,
	// 	name: "Servicedesk",
	// 	description: "Hantera din servicedesk och köer här",
	// 	exact: true,
	// 	component: SupportSettings,
	// },
	{
		path: "/admin/contact-settings",
		settings: true,
		description: "Konfigurera extra data-fält, aktiviteter, etc. för dina kontakter",
		exact: true,
		component: ContactSettings,
		name: i18n.t("routes.names.contact_settings", "Kontaktinställningar"),
	},
	{
		path: "/admin/triggers/new",
		exact: true,
		component: TriggersCreate,
		name: i18n.t("routes.names.triggers_create", "Skapa automation"),
	},
	{
		path: "/admin/triggers/:id",
		exact: true,
		component: TriggersEdit,
		name: i18n.t("routes.names.triggers_edit", "Redigera automation"),
	},
	{
		path: "/admin/users",
		mainMenu: true,
		exact: true,
		component: UserIndex,
		name: i18n.t("routes.names.users", "Användare"),
	},
	// {
	// 	path: "/admin/users/new",
	// 	mainMenu: false,
	// 	exact: true,
	// 	component: UserCreate,
	// 	name: i18n.t("routes.names.user_create", "Skapa användare"),
	// },
	{
		path: "/admin/users/:id",
		mainMenu: false,
		exact: true,
		component: UserEdit,
		name: i18n.t("routes.names.user_edit", "Redigera användare"),
	},
	{
		path: "/admin/groups",
		mainMenu: true,
		exact: true,
		component: GroupIndex,
		name: i18n.t("routes.names.groups", "Grupper"),
	},
	{
		path: "/admin/groups/create",
		mainMenu: false,
		exact: true,
		component: GroupCreate,
		name: i18n.t("routes.names.group_create", "Skapa grupp"),
	},
	{
		path: "/admin/groups/:id",
		mainMenu: false,
		exact: true,
		component: GroupEdit,
		name: i18n.t("routes.names.group_edit", "Redigera grupp"),
	},
	{
		path: "/admin/settings",
		mainMenu: true,
		settings: true,
		exact: true,
		component: SettingsIndex,
		name: i18n.t("routes.names.settings", "Inställningar"),
	},
	{
		path: "/admin/help",
		exact: true,
		component: Help,
		name: i18n.t("routes.names.help", "Hjälp"),
	},
	{
		path: "/admin/help/:id",
		exact: true,
		component: HelpPage,
		name: i18n.t("routes.names.help", "Hjälp"),
	},
	{
		path: "/admin/icons",
		mainMenu: true,
		exact: true,
		component: IconsOverview,
		name: i18n.t("routes.names.icons", "Ikonöversikt"),
	},
	{
		path: "/admin/pdf_templates/new",
		mainMenu: true,
		exact: true,
		component: NewPDF,
		name: i18n.t("routes.names.pdf_create", "Skapa PDF"),
	},
	{
		path: "/admin/pdf_templates",
		mainMenu: true,
		exact: true,
		component: Pdf,
		name: i18n.t("routes.names.pdfs", "PDFs"),
	},
	{
		path: "/admin/pdf_templates/editor/:id",
		mainMenu: true,
		exact: true,
		component: EditorView,
		name: i18n.t("routes.names.pdf_editor", "Redigera PDF"),
	},
	{
		path: "/admin/pdf_templates/:id",
		mainMenu: true,
		exact: true,
		component: EditPDF,
		name: i18n.t("routes.names.pdf_edit", "Redigera PDF"),
	},
	{
		path: "/admin/integration/connected",
		mainMenu: false,
		exact: true,
		component: IntegrationConnected,
		name: i18n.t("routes.names.integration_connected", "Integrationer"),
	},
	{
		path: "/admin/licenses",
		mainMenu: false,
		exact: true,
		component: ContractLicenses,
		name: i18n.t("routes.names.licenses", "Licenser"),
	},
	{
		path: "/admin/fortnox/contacts/:contactId/orders/create",
		mainMenu: false,
		exact: true,
		component: FortnoxOrderCreate,
		name: i18n.t("routes.names.fortnox_order_create", "Skapa order"),
	},
	{
		path: "/admin/fortnox/orders/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxOrder,
		name: i18n.t("routes.names.fortnox_order", "Order"),
	},
	{
		path: "/admin/fortnox/contacts/:contactId/invoices/create",
		mainMenu: false,
		exact: true,
		component: FortnoxInvoiceCreate,
		name: i18n.t("routes.names.fortnox_invoice_create", "Skapa faktura"),
	},
	{
		path: "/admin/fortnox/invoices/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxInvoice,
		name: i18n.t("routes.names.fortnox_invoice", "Faktura"),
	},
	{
		path: "/admin/fortnox/contacts/:contactId/offers/create",
		mainMenu: false,
		exact: true,
		component: FortnoxOfferCreate,
		name: i18n.t("routes.names.fortnox_offer_create", "Skapa offert"),
	},
	{
		path: "/admin/fortnox/offers/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxOffer,
		name: i18n.t("routes.names.fortnox_offer", "Offert"),
	},
	{
		path: "/admin/fortnox/contacts/:contactId/contracts/create",
		mainMenu: false,
		exact: true,
		component: FortnoxContractCreate,
		name: i18n.t("routes.names.fortnox_contract_create", "Skapa avtal"),
	},
	{
		path: "/admin/fortnox/contracts/:id",
		mainMenu: false,
		exact: true,
		component: FortnoxContract,
		name: i18n.t("routes.names.fortnox_contract", "Avtal"),
	},
	{
		path: "/admin/fortnox/settings",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxSettings,
		name: i18n.t("routes.names.fortnox_settings", "Fortnox inställningar"),
	},
	{
		path: "/admin/fortnox/invoices",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxInvoicesList,
		name: i18n.t("routes.names.fortnox_invoices", "Fakturor"),
	},
	{
		path: "/admin/fortnox/orders",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxOrdersList,
		name: i18n.t("routes.names.fortnox_orders", "Ordrar"),
	},
	{
		path: "/admin/fortnox/offers",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxOffersList,
		name: i18n.t("routes.names.fortnox_offers", "Offerter"),
	},
	{
		path: "/admin/fortnox/contracts",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxBillingContractsList,
		name: i18n.t("routes.names.fortnox_contracts", "Avtal"),
	},
	{
		path: "/admin/fortnox/articles",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxArticlesList,
		name: i18n.t("routes.names.fortnox_articles", "Artiklar"),
	},
	// {
	// 	path: "/admin/fortnox/articles/create",
	// 	exact: true,
	// 	enabled: store.getState().account.fortnox_integration,
	// 	component: FortnoxArticleCreate,
	// },
	{
		path: "/admin/fortnox/articles/:id",
		exact: true,
		enabled: store.getState().account.fortnox_integration,
		component: FortnoxArticle,
		name: i18n.t("routes.names.fortnox_article", "Artikel"),
	},
	{
		path: "/admin/orders",
		exact: true,
		component: OrdersList,
		name: i18n.t("routes.names.orders.list", "Ordrar"),
	},

	{
		path: "/admin/orders/settings",
		exact: true,
		component: OrderSettings,
		name: i18n.t("routes.names.order_settings", "Orderinställningar"),
		settings: true,
		description: i18n.t("routes.names.order_settings_desc", "Hantera inställningar för ordrar"),
	},
	{
		path: "/admin/orders/create",
		exact: true,
		component: OrderCreate,
		name: i18n.t("routes.names.orders.create", "Skapa order"),
	},
	{
		path: "/admin/orders/:id",
		exact: true,
		component: OrderEdit,
		name: i18n.t("routes.names.orders.edit", "Redigera order"),
	},

	{
		path: "/admin/articles/settings",
		exact: true,
		component: ArticleSettings,
		name: i18n.t("routes.names.article_settings", "Artikelinställningar"),
	},
	{
		path: "/admin/articles",
		exact: true,
		component: ArticleList,
		name: i18n.t("routes.names.articles", "Artiklar"),
	},
	{
		path: "/admin/articles/create",
		exact: true,
		component: ArticleCreate,
		name: i18n.t("routes.names.article_create", "Skapa artikel"),
	},
	{
		path: "/admin/articles/:id(\\d+)",
		exact: true,
		component: Article,
		name: i18n.t("routes.names.article", "Artikel"),
	},
	{
		path: "/admin/pricelists",
		exact: true,
		component: PricelistList,
		name: i18n.t("routes.names.pricelists", "Prislistor"),
	},
	{
		path: "/admin/pricelists/create",
		exact: true,
		component: PricelistCreate,
		name: i18n.t("routes.names.pricelist_create", "Skapa prislista"),
	},
	{
		path: "/admin/customfields",
		exact: true,
		component: CustomFieldsList,
		name: i18n.t("routes.names.customfields", "Anpassade fält"),
	},
	{
		path: "/admin/customfields",
		exact: true,
		component: CustomFieldsList,
		name: i18n.t("routes.names.customfields", "Anpassade fält"),
	},
	{
		path: "/admin/pricelists/:id(\\d+)",
		exact: true,
		component: Pricelist,
		name: i18n.t("routes.names.pricelist", "Prislista"),
	},
	{
		path: "/admin/categories",
		exact: true,
		component: CategoriesList,
		name: i18n.t("routes.names.categories", "Kategorier"),
	},
	{
		path: "/admin/categories/create",
		exact: true,
		component: CategoryCreate,
		name: i18n.t("routes.names.category_create", "Skapa kategori"),
	},
	{
		path: "/admin/categories/:id(\\d+)",
		exact: true,
		component: Category,
		name: i18n.t("routes.names.category", "Kategori"),
	},
	{
		path: "/admin/customfields/:id(\\d+)",
		exact: true,
		component: CustomField,
		name: i18n.t("routes.names.customfield", "Anpassat fält"),
	},
	{
		path: "/admin/customfields/create",
		exact: true,
		component: CustomFieldCreate,
		name: i18n.t("routes.names.customfield_create", "Skapa anpassat fält"),
	},
	{
		path: "/admin/api_user_tokens",
		exact: true,
		component: ApiUserTokens,
		name: i18n.t("routes.names.api_user_tokens", "API användartokens"),
	},
	{
		path: "/admin/targets",
		exact: true,
		component: TargetsList,
		name: i18n.t("routes.names.targets", "Målsättningar"),
	},
	{
		path: "/admin/targets/create",
		exact: true,
		component: TargetCreate,
		name: i18n.t("routes.names.target_create", "Skapa målsättning"),
	},
	{
		path: "/admin/targets/:id",
		exact: true,
		component: TargetEdit,
		name: i18n.t("routes.names.target_edit", "Redigera målsättning"),
	},
	{
		redirect: true,
		path: "/admin/contacts",
		to: "/admin/contacts/companies",
		name: i18n.t("routes.names.contacts", "Kontakter"),
	},
	{
		path: "/admin/components",
		component: ComponentRoutes,
		enabled:
			process.env.NODE_ENV === "development" ||
			String(store.getState().account.id) === String(24) ||
			String(store.getState().user.id) === String(419),
		name: i18n.t("routes.names.components", "Komponenter"),
	},
	{
		path: "/admin/missing_permission",
		name: i18n.t("routes.names.missing_permission", "Sknar behörighet"),
		exact: true,
		component: MissingPermission,
	},
	{
		path: "/admin/403",
		name: i18n.t("routes.names.missing_permission", "Sknar behörighet"),
		exact: true,
		component: MissingPermission,
	},
	{
		redirect: true,
		path: "/admin/",
		to: "/admin/dashboard",
		name: i18n.t("routes.names.dashboard", "Dashboard"),
	},
];

const getRoutes = () => {
	return getBaseRoutes().filter((route) => {
		return (
			(!("enabled" in route) || route.enabled) &&
			(!("admin" in route) || !route.admin || store.getState().user?.roles?.some((role) => ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"].includes(role)))
		);
	});
};

export default getRoutes;

export const routesAsObject = getBaseRoutes().reduce((acc, route) => {
	acc[route.path] = route;
	return acc;
}, {});
