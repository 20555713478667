import React, { Component } from "react";
import { Button, LegacyCard, TextField } from "@shopify/polaris";
import nl2br from "react-nl2br";
import { EditMajor, CirclePlusMajor } from "@shopify/polaris-icons";

import { withTranslation } from "react-i18next";
import API from "../../API";
import { toastr } from "../../components/toastr.js";
import Modal from "src/js/components/modal";

class Note extends Component {
	constructor(props) {
		super(props);
		this.state = { note: props.note };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (!this.state.open) this.setState({ note: props.note });
	}

	onClose() {
		this.setState({ open: false });
	}

	onOpen() {
		this.setState({ open: true });
	}

	onSave() {
		this.setState({ saving: true });
		const form = { note: this.state.note };
		API.put("/api/contacts/" + this.props.contact.id + ".json", form)
			.then((result) => {
				this.setState({ saving: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}

				toastr.success(this.props.t("contact.note.reponses.updated", "Notering uppdaterad"));
				this.setState({ open: false });
				this.props.onUpdate(result.data.contact?.note);
			})
			.catch((error) => {
				this.setState({ saving: false });
				toastr.error(error);
			});
	}

	render() {
		const { note, className } = this.props;

		const content = (
			<div className={`contact_note note ${className}`}>
				{/* <div className="contact_note__icon">
					<IconWithBackround color="#ffffff" backgroundColor="rgba(0,0,0,0.25)" icon={NoteMajor} />
				</div> */}
				<div className="contact_note__body">
					<h2 className="small_card_title">
						{this.props.t("contact.note.title", "Allmänna noteringar")}{" "}
						{!this.props.disabled && (
							<span className="edit-button">
								<Button onClick={this.onOpen.bind(this)} plain icon={note ? EditMajor : CirclePlusMajor} />
							</span>
						)}
					</h2>

					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<p>{note ? nl2br(note) : <span style={{ opacity: 0.5, color: "var(--textColor)" }}>Ingen anteckning</span>}</p>
					</div>

					{note && (
						<div className="showmore">
							<button
								style={{ border: "none", background: "transparent", outline: "none" }}
								onClick={() => this.setState((c) => ({ expanded: !c.expanded }))}
							>
								{this.state.expanded
									? this.props.t("timeline.actions.show_less", "Visa mindre")
									: this.props.t("timeline.actions.show_more", "Visa mer")}
							</button>
						</div>
					)}
				</div>

				<Modal
					title={note ? this.props.t("contact.note.singular", "Notering") : this.props.t("contact.note.actions.add", "Lägg till notering")}
					open={this.state.open}
					onClose={this.onClose.bind(this)}
					primaryAction={{
						content: this.props.t("common.actions.save", "Spara"),
						loading: this.state.saving,
						onAction: this.onSave.bind(this),
						disabled: this.state.note === note,
					}}
					secondaryActions={[{ content: this.props.t("common.actions.close", "Stäng"), onAction: this.onClose.bind(this) }]}
				>
					<Modal.Section>
						<TextField
							placeholder={this.props.t("contact.note.placeholder", "Notering..")}
							multiline={2}
							onChange={(note) => this.setState({ note })}
							value={this.state.note}
						/>
					</Modal.Section>
				</Modal>
				<Modal
					title={note ? this.props.t("contact.note.singular", "Notering") : this.props.t("contact.note.actions.add", "Lägg till notering")}
					open={this.state.expanded}
					onClose={() => {
						this.setState({ expanded: false });
					}}
					secondaryActions={[
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: () => {
								this.setState({ expanded: false });
							},
						},
					]}
				>
					<Modal.Section>
						<p style={{ wordBreak: "break-word" }}>{nl2br(note)}</p>
					</Modal.Section>
				</Modal>
			</div>
		);

		if (this.props.card) {
			return <LegacyCard sectioned>{content}</LegacyCard>;
		}

		return content;
	}
}

Note.defaultProps = {
	card: true,
};
export default withTranslation(["contact", "common"], { withRef: true })(Note);
