import React, { Component } from "react";
import {
	Button,
	LegacyStack,
	FormLayout,
	Text,
	Tag,
	ChoiceList,
	TextField,
	Checkbox,
	Select,
	DatePicker,
	Spinner,
	Collapsible,
} from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import SearchField from "./search_field.js";
import GoalsUserIndex from "../views/Goals/GoalsUserIndex";
import BringModalToFront from "./BringModalToFront";
import Tabs from "./Tabs";
import Modal from "./modal";

class GoalModal extends Component {
	constructor(props) {
		super(props);

		const nextweek = new Date();
		nextweek.setDate(nextweek.getDate() + 30);
		let fromTime;

		if (props.goal.start_at) {
			fromTime = new Date(props.goal.start_at);
		} else {
			fromTime = new Date();
			props.goal.start_at = this.getYmd(fromTime);
		}
		if (!props.goal.connections) {
			props.goal.connections = [];
		}
		if (!props.goal.users) {
			props.goal.users = [];
		}
		if (!props.goal.roles) {
			props.goal.roles = [];
		}
		if (!props.goal.groups) {
			props.goal.groups = [];
		}
		if (!props.goal.color) {
			props.goal.color = "#6cf";
		}
		if (!props.goal.fields) {
			props.goal.fields = [];
		}
		if (!props.goal.default_value) {
			props.goal.default_value = 0;
		}
		if (!props.goal.measure_type) {
			props.goal.measure_type = "activity";
		}
		let toTime;
		if (props.goal.end_at) {
			toTime = new Date(props.goal.end_at);
		} else {
			toTime = nextweek;
			props.goal.end_at = this.getYmd(toTime);
		}
		if (!props.goal.interval) {
			props.goal.interval = "monthly";
		}
		this.state = {
			sections: [],
			open: props.open,
			from: fromTime,
			to: toTime,
			selected: {
				start: fromTime,
				end: toTime,
			},
			month: fromTime.getMonth(),
			year: fromTime.getFullYear(),
			form: props.goal,
			saving: false,
			showSuggestions: props.goal?.roles?.length || props.goal?.groups?.length,
			tabId: "goal",
			id: props.goal && props.goal.id,
		};
	}

	getTabs() {
		return [
			{
				content: <div style={{ opacity: !this.state.form.id && !this.state.form.title && this.state.tabId !== "goal" ? 0.5 : 1 }}>Målsättning</div>,
				id: "goal",
				disabled: !this.state.form.id && !this.state.form.title,
				submitDisabled: !this.state.form.id && !this.state.form.title,
			},
			{
				content: (
					<div style={{ opacity: !this.state.form.id && !this.state.form.title && this.state.tabId !== "users" ? 0.5 : 1 }}>
						{" "}
						{`Användare ${this.state.form?.users?.length ? `(${this.state.form?.users?.length})` : ""}`}
					</div>
				),
				id: "users",
				disabled: !this.state.form.id && !this.state.form.title,
				submitDisabled: !this.state.form.id && !this.state.form.title,
			},
			{
				content: (
					<div
						style={{
							opacity: !this.state.form.id && !this.state.form.title && this.state.tabId !== "values" ? 0.5 : 1,
						}}
					>
						Värden
					</div>
				),
				id: "values",
				submitDisabled:
					!this.state.form.id && !!((this.state.form.connections.length < 1 && !this.state.form.special_type) || !this.state.form.title),
				disabled: !this.state.form.id && !this.state.form.title,
			},
		].filter((t) => !("enabled" in t) || t.enabled);
	}

	leadingZero(val) {
		if (val < 10) {
			return "0" + val;
		}
		return val;
	}

	getYmd(start) {
		return start.getFullYear() + "-" + this.leadingZero(start.getMonth() + 1) + "-" + this.leadingZero(start.getDate());
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			if (props.open) {
				this.setState({ tabId: "goal", id: props.goal && props.goal.id });
				if (props.goal && props.goal.id) this.fetch(props.goal && props.goal.id);
				this.fetchSections();
			} else {
				this.setState({ tabId: "goal" });
			}
			const nextweek = new Date();
			nextweek.setDate(nextweek.getDate() + 30);
			let fromTime;

			if (props.goal.start_at) {
				fromTime = new Date(props.goal.start_at);
			} else {
				fromTime = new Date();
				props.goal.start_at = this.getYmd(fromTime);
			}
			if (!props.goal.connections) {
				props.goal.connections = [];
			}
			if (!props.goal.users) {
				props.goal.users = [];
			}
			if (!props.goal.roles) {
				props.goal.roles = [];
			}
			if (!props.goal.color) {
				props.goal.color = "#6cf";
			}
			if (!props.goal.groups) {
				props.goal.groups = [];
			}
			if (!props.goal.fields) {
				props.goal.fields = [];
			}
			if (!props.goal.default_value) {
				props.goal.default_value = 0;
			}
			if (!props.goal.measure_type) {
				props.goal.measure_type = "activity";
			}
			let toTime;
			if (props.goal.end_at) {
				toTime = new Date(props.goal.end_at);
			} else {
				toTime = nextweek;
				props.goal.end_at = this.getYmd(toTime);
			}
			if (!props.goal.interval) {
				props.goal.interval = "monthly";
			}

			this.setState({
				open: props.open,
				from: fromTime,
				to: toTime,
				selected: {
					start: fromTime,
					end: toTime,
				},
				month: fromTime.getMonth(),
				year: fromTime.getFullYear(),
				form: props.goal,
				showSuggestions: props.goal?.roles?.length || props.goal?.groups?.length,
			});
		}
	}

	fetch(id = this.state.id, silent = false) {
		// console.debug(`Hämtar goal med id ${id} mellan ${from} -> ${to}`);
		if (!silent) this.setState({ loading: true });
		API.get("/api/goals/" + id + ".json")
			.then((result) => {
				this.setState({
					loading: false,
					form: result.data.goal,
					selected: {
						start: new Date(result.data.goal.start_at),
						end: new Date(result.data.goal.end_at),
					},
					showSuggestions: result.data.goal?.roles?.length || result.data.goal?.groups?.length,
				});
			})
			.catch((error) => {
				toastr.error(error);
				this.setState({ loading: false });
			});
	}

	updateForm(field, value) {
		if (field === "default_value") {
			this.state.form.users = this.state.form.users?.map((user) => {
				if (user.value === this.state.form.default_value) user.value = value;
				if (user.next_value === this.state.form.default_value) user.next_value = value;

				return user;
			});
		}

		if (field === "users") {
			this.state.form[field] = value.map((id) => store.getState().users.find((usr) => String(usr.id) === String(id)));
		} else {
			this.state.form[field] = value;
		}

		this.setState({ form: this.state.form });
	}

	saveForm() {
		const currentIndex = this.getTabs().findIndex((t) => t.id === this.state.tabId);

		if (!this.state.form.id && currentIndex < this.getTabs().length - 1) {
			const tab = this.getTabs()[currentIndex + 1];
			if (!this.state.form?.users?.length) {
				this.state.addUserIsOpen = true;
			}
			this.setState({ tabId: tab.id });
			return;
		}

		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/goals/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("goals.responses.updated", "Uppdaterade målsättning"));
					this.setState({ saving: false });
					if (this.props.onCreate) {
						this.props.onCreate(result.data.goal);
					}
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/goals.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("goals.responses.created", "Skapade målsättning"));
					this.setState({ saving: false });
					this.props.onCreate(result.data.goal);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	getWeekdays(from, to) {
		const currentDate = new Date(from);
		let weekdays = 0;
		do {
			if (currentDate.getDay() + "" != "6" && currentDate.getDay() + "" != "0") {
				weekdays++;
			}

			currentDate.setDate(currentDate.getDate() + 1);
		} while (currentDate <= to);
		return weekdays;
	}

	getIntervalTitle(interval) {
		if (interval == "daily") {
			return ` ${this.props.t("goals.intervals.daily", "per dag")}`;
		} else if (interval == "annually") {
			return ` ${this.props.t("goals.intervals.annually", "per år")}`;
		} else if (interval == "weekly") {
			return ` ${this.props.t("goals.intervals.weekly", "per vecka")}`;
		} else if (interval == "monthly") {
			return ` ${this.props.t("goals.intervals.monthly", "per månad")}`;
		} else {
			return ` ${this.props.t("goals.intervals.whole", "för hela perioden")}`;
		}
	}

	getColumnOptions(board, types) {
		const arr = [];
		arr.push({ value: "", label: this.props.t("goals.columns.choose.label", "-- välj --") });
		if (types.indexOf("created_at") >= 0) {
			arr.push({ value: "created_at", label: this.props.t("goals.columns.created_at.label", "Skapades") });
		}
		if (types.indexOf("count") >= 0) {
			arr.push({ value: "count", label: this.props.t("goals.columns.created_countat.label", "Antal rader") });
		}
		for (let i = 0; i < board.columns.length; i++) {
			if (
				types.indexOf(board.columns[i].type) >= 0 ||
				(types.indexOf("number") >= 0 && board.columns[i].type == "formula" && !board.columns[i].options.is_string)
			) {
				arr.push({
					value: board.columns[i].id + "",
					label: board.columns[i].title,
				});
			}
		}
		return arr;
	}

	getTypeOptions(board, columnId) {
		let arr = [];
		for (let i = 0; i < board.columns.length; i++) {
			if (board.columns[i].id + "" == columnId + "") {
				if (board.columns[i].type == "dropdown") {
					arr = board.columns[i].options.choices
						.map((choice, index) => ({ label: choice, value: index + "", removed: board.columns[i].options?.removed_choices?.includes(index) }))
						?.filter((choice) => !choice.removed);
				}
				if (board.columns[i].type == "category") {
					arr = store.getState().user.todo_board.categories.map((category, index) => ({ label: category.title, value: category.id + "" }));
				}
			}
		}
		if (arr.length > 0) {
			arr.unshift({ value: "", label: this.props.t("goals.types.choose.label", "-- välj --") });
			return arr;
		}
		return [];
	}

	getStatusOptions(board, columnId) {
		const columm = board.columns.find((column) => column.id == columnId);

		return [
			{ value: "", label: this.props.t("goals.statuses.choose.label", "-- välj --") },
			...(columm?.options?.statuses?.map((status, index) => ({ label: status.label, value: status.id + "" })) || []),
		];
	}

	fetchSections() {
		API.get("/api/sections.json")
			.then((result) => {
				this.setState({ sections: result.data.workspaces });
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateConnection(index, field, value) {
		this.state.form.connections[index][field] = value;
		this.setState({ form: this.state.form });
	}

	removeConnection(index) {
		this.state.form.connections.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	removeGroup(index) {
		this.state.form.groups.splice(index, 1);
		this.setState({ form: this.state.form });
	}

	getTotalValue() {
		let total = 0;
		for (let i = 0; i < this.state.form.users.length; i++) {
			total += parseInt(this.state.form.users[i].value || 0) || 0;
		}
		return total;
	}

	removeFormUser(user) {
		const users = this.state.form.users.filter((usr) => String(usr.user_id || usr?.user?.id) !== String(user.user_id || user?.user?.id));
		this.setState((c) => ({ form: { ...c.form, users } }));
	}

	updateFormUsers(user, checked) {
		if (checked) {
			this.state.form.users.push({
				user,
				user_id: user.id,
				value: "",
				mail_daily_report: 0,
			});
			this.setState({ form: this.state.form });
		} else {
			const users = this.state.form.users.filter((usr) => String(usr.user_id || usr?.user?.id) !== String(user.id));
			this.setState((c) => ({ form: { ...c.form, users } }));
		}
	}

	onUpdateUser(index, field, value) {
		this.state.form.users[index][field] = value;
		this.setState({ form: this.state.form });
	}

	handleCloseRemoveModal() {
		this.setState({ removeModalIsOpen: false });
	}

	handleOpenRemoveModal() {
		this.setState({ removeModalIsOpen: true });
	}

	handleRemoveGoal() {
		this.setState({ loadingDelete: true });

		API.delete(`/api/goals/${this.state.form.id}.json`)
			.then((result) => {
				if (result.data.error) {
					console.error("error:", result.data.error);
					toastr.error(result.data.error);
				}
				toastr.success("Målsättning borttagen");
				store.dispatch({ type: "REMOVE_GOAL", goal: result.data.goal });
				this.handleCloseRemoveModal();
				this.props.onRemove?.();
				this.props.onClose?.();
			})
			.catch((error) => {
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loadingDelete: false });
			});
	}

	render() {
		const roleOptions = [];
		roleOptions.push({
			value: "ROLE_SUPPORT",
			label: this.props.t("goals.roles.support.label", "Kundtjänst"),
		});
		for (let i = 0; i < this.state.sections.length; i++) {
			const section = this.state.sections[i];
			if (section.type == "shareable" && section.title != "Kundtjänst") {
				roleOptions.push({ value: "ROLE_WORKSPACE_" + section.id, label: section.title });
			}
		}

		const activityOptions = store.getState().contact_activities.map((activity) => ({ value: activity.id + "", label: activity.title }));

		activityOptions.unshift({ value: "all", label: this.props.t("goals.activities.all.label", "All aktivitet") });

		activityOptions.unshift({ value: "", label: this.props.t("goals.activities.choose.label", "Välj aktivitet...") });

		const disabled = (() => {
			if (this.state.form.id) return false;
			const tabs = this.getTabs();
			switch (this.state.tabId) {
				case "goal":
					return tabs.find((t) => t.id === this.state.tabId)?.submitDisabled;
				case "users":
					return tabs.find((t) => t.id === this.state.tabId)?.submitDisabled;
				case "values":
					return tabs.find((t) => t.id === this.state.tabId)?.submitDisabled;

				default:
					return tabs.find((t) => t.id === this.state.tabId)?.submitDisabled;
			}
		})();

		const content = (() => {
			if (this.state.loading)
				return (
					<Modal.Section>
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "calc(100vh - 300px)" }}>
							<Spinner />
							<p className="loading loading-text">{this.props.t("goals.loading", "Hämtar målsättning")}</p>
						</div>
					</Modal.Section>
				);

			switch (this.state.tabId) {
				case "users":
					return (
						<Modal.Section>
							<div style={{ display: "flex", justifyContent: "space-between" }}>
								<Text variant="headingMd" as="h2">
									{this.props.t("goals.fields.coworkers.title", "Vilka medarbetare mäter vi?")}
								</Text>
								<Button
									primary
									onClick={() => {
										this.setState((c) => ({ addUserIsOpen: !c.addUserIsOpen }));
									}}
								>
									{this.state.addUserIsOpen
										? this.props.t("goals.actions.open_remove_user", "Avbryt")
										: this.props.t("goals.actions.open_add_user", "Lägg till användare/grupp")}
								</Button>
							</div>
							<Collapsible open={this.state.addUserIsOpen}>
								<FormLayout>
									<Checkbox
										checked={this.state.showSuggestions}
										label={this.props.t("goals.fields.show_suggestions.label", "Visa förslag")}
										onChange={(v) => {
											this.setState((c) => ({ showSuggestions: !c.showSuggestions }));
										}}
									/>
									{this.state.showSuggestions ? (
										<ChoiceList
											title={this.props.t("goals.fields.roles.label", "Lägg till roller")}
											onChange={this.updateForm.bind(this, "roles")}
											choices={roleOptions}
											selected={this.state.form.roles}
											allowMultiple
										/>
									) : null}

									{this.state.showSuggestions ? (
										<SearchField
											label={this.props.t("goals.fields.groups_add.label", "Lägg till grupp")}
											resource="groups.json"
											onSelect={(group) => {
												this.state.form.groups.push(group);
												this.setState({ form: this.state.form });
											}}
											id_handle="id"
											label_handle="name"
											resource_handle="groups"
										/>
									) : null}
									{this.state.showSuggestions ? (
										<LegacyStack>
											{this.state.form.groups.map((group, index) => (
												<Tag key={group.id || index} onRemove={this.removeGroup.bind(this, index)}>
													{group.name}
												</Tag>
											))}
										</LegacyStack>
									) : null}
									{/*! this.state.form.manual ? (
								<SearchField
									label="Lägg till affärsområde"
									resource="fields.json"
									onSelect={(field) => {
										this.state.form.fields.push(field);
										this.setState({ form: this.state.form });
									}}
									id_handle="id"
									label_handle="title"
									resource_handle="fields"
								/>
							) : null */}
									{/* {this.state.showSuggestions ? (
								<LegacyStack>
									{this.state.form.fields.map((field, index) => (
										<Tag key={field.id || index} onRemove={this.removeField.bind(this, index)}>
											{field.title}
										</Tag>
									))}
								</LegacyStack>
							) : null} */}

									<SearchField
										label={this.props.t("goals.fields.coworkers_add.label", "Lägg till medarbetare")}
										resource="users.json"
										onSelect={(user) => {
											if (this.state.form.users.filter((e) => e.user.id === user.id).length <= 0) {
												this.state.form.users.push({
													user,
													value: this.state.form.default_value || "",
													next_value: this.state.form.default_value || "",
													mail_daily_report: 0,
												});
												this.setState({ form: this.state.form });
											}
										}}
										id_handle="id"
										label_handle="fullname"
										resource_handle="users"
									/>

									<ul>
										{store
											.getState()
											.users.filter((user) => {
												const roleMatch = user.roles.some((role) => this.state.form.roles.includes(role));
												const groupMatch = this.state.form.groups?.find((group) => String(group.id) === String(user.group?.id));
												const found = this.state.form.users?.find((usr) => String(usr.user_id || usr.user?.id) === String(user.id));

												return (roleMatch || groupMatch) && user.enabled && !found;
											})
											.map((user) => (
												<li key={user?.id}>
													<Checkbox
														onChange={this.updateFormUsers.bind(this, user)}
														checked={this.state.form.users.find((usr) => String(usr.user_id || usr.user?.id) === String(user?.user?.id || user?.id))}
													/>
													<span>{user?.name}</span>
												</li>
											))}
									</ul>
								</FormLayout>
							</Collapsible>

							<GoalsUserIndex
								goal={this.state.form}
								id={this.state.id}
								onChange={this.onUpdateUser.bind(this)}
								loading={this.state.saving}
								removeFormUser={this.removeFormUser.bind(this)}
							/>

							<Text variant="headingMd" as="h2">
								{this.props.t("goals.fields.total.label", "Totalt")} {this.getTotalValue()} {this.state.form.suffix}{" "}
								{this.state.form.interval != "period"
									? this.getIntervalTitle(this.state.form.interval)
									: this.props.t("goals.fields.whole_period.label", "för hela perioden ({{days}} arbetsdagar)", {
											days: this.getWeekdays(this.state.from, this.state.to),
									  })}
							</Text>
						</Modal.Section>
					);
				case "values":
					return (
						<Modal.Section>
							<Modal.Section>
								<Text variant="headingMd" as="h2">
									{this.props.t("goals.fields.calculting.title", "Vad räknar vi på?")}
								</Text>
								<FormLayout>
									<ChoiceList
										choices={[
											{
												label: this.props.t("goals.fields.contact_activity.label", "Kontaktaktivitet"),
												value: "activity",
												renderChildren: (selected) =>
													selected && (
														<Select
															label={this.props.t("goals.fields.add_contact_activity.label", "Lägg till kontaktaktivitet")}
															options={activityOptions}
															onChange={(activityId) => {
																if (activityId == "all") {
																	this.state.form.connections.push({
																		activity: { id: "all", title: this.props.t("goals.fields.contact_activity.options.all", "All aktivitet") },
																	});
																} else {
																	const activity = activityOptions.find((act) => act.value + "" == activityId + "");
																	this.state.form.connections.push({
																		activity: { id: parseInt(activity.value), title: activity.label },
																	});
																}
																this.setState({ form: this.state.form });
															}}
														/>
													),
											},
											{
												label: this.props.t("goals.fields.rows_with_category.title", "Alla rader med en kategori"),
												value: "category",
												renderChildren: (selected) =>
													selected && (
														<SearchField
															label={this.props.t("goals.fields.add_category.title", "Lägg till kategori")}
															resource="board_categories.json"
															onSelect={(category) => {
																this.state.form.connections.push({
																	category,
																	status_complete: null,
																});
																this.setState({ form: this.state.form });
															}}
															id_handle="id"
															resource_handle="categories"
														/>
													),
											},
											{
												label: this.props.t("goals.fields.values_from_board.title", "Värden från board"),
												value: "board",
												renderChildren: (selected) =>
													selected && (
														<SearchField
															label={this.props.t("goals.fields.values_from_board.label", "Lägg till board")}
															resource="boards.json"
															onSelect={(board) => {
																this.state.form.connections.push({
																	board,
																	date_column_id: null,
																	value_column_id: null,
																	person_column_id: null,
																	status_column_id: null,
																	status_value: null,
																	type_column_id: null,
																	type_value: null,
																});
																this.setState({ form: this.state.form });
															}}
															id_handle="id"
															resource_handle="boards"
														/>
													),
											},
										]}
										selected={[this.state.form.measure_type]}
										onChange={(val) => {
											this.updateForm("measure_type", val[0]);
										}}
									/>
								</FormLayout>
							</Modal.Section>

							{this.state.form.connections.map((connection, index) => {
								if (connection.activity) {
									return (
										<Modal.Section key={connection.id || index}>
											<LegacyStack>
												<LegacyStack.Item fill>
													<Text variant="headingMd" as="h2">
														{this.props.t("goals.terms.contact_activity", "Kontaktaktivitet")}:{connection.activity.title}
													</Text>
												</LegacyStack.Item>
												<Button plain onClick={this.removeConnection.bind(this, index)}>
													{this.props.t("common.actions.remove", "ta bort")}
												</Button>
											</LegacyStack>

											<LegacyStack>
												<div>{this.props.t("goals.terms.sum_row_count", "Summera antal rader")}</div>
											</LegacyStack>
										</Modal.Section>
									);
								} else if (connection.category) {
									return (
										<Modal.Section key={connection.id || index}>
											<LegacyStack>
												<LegacyStack.Item fill>
													<Text variant="headingMd" as="h2">
														{this.props.t("goals.terms.category", "Kategori")}:{connection.category.title}
													</Text>
												</LegacyStack.Item>
												<Button plain onClick={this.removeConnection.bind(this, index)}>
													{this.props.t("common.actions.remove", "ta bort")}
												</Button>
											</LegacyStack>

											<LegacyStack>
												<div>{this.props.t("goals.terms.sum_row_count", "Summera antal rader")}</div>
												<Select
													label={this.props.t("goals.fields.with_status.label", "med status")}
													options={[
														{ value: "", label: this.props.t("goals.fields.with_status.options.does_not_matter", "kvittar") },
														{ value: "1", label: this.props.t("goals.fields.with_status.options.finished", "avslutad") },
														{ value: "0", label: this.props.t("goals.fields.with_status.options.not_finished", "ej avslutad") },
													]}
													value={connection.status_complete + ""}
													onChange={this.updateConnection.bind(this, index, "status_complete")}
												/>
											</LegacyStack>
										</Modal.Section>
									);
								} else if (connection.board) {
									return (
										<Modal.Section key={connection.id || index}>
											<LegacyStack>
												<LegacyStack.Item fill>
													<Text variant="headingMd" as="h2">
														{connection.board.title}
													</Text>
												</LegacyStack.Item>
												<Button plain onClick={this.removeConnection.bind(this, index)}>
													{this.props.t("common.actions.remove", "ta bort")}
												</Button>
											</LegacyStack>
											<LegacyStack>
												<Select
													label={this.props.t("goals.fields.value_column_id.label", "Summera värde från")}
													options={this.getColumnOptions(connection.board, ["count", "number", "timetrack"])}
													value={connection.value_column_id + ""}
													onChange={this.updateConnection.bind(this, index, "value_column_id")}
												/>
												<Select
													label={this.props.t("goals.fields.date_column_id.label", "med datum")}
													options={this.getColumnOptions(connection.board, ["created_at", "datetime", "timeline"])}
													value={connection.date_column_id + ""}
													onChange={this.updateConnection.bind(this, index, "date_column_id")}
												/>
												<Select
													label={this.props.t("goals.fields.person_column_id.label", "med person")}
													options={this.getColumnOptions(connection.board, ["person"])}
													value={connection.person_column_id + ""}
													onChange={this.updateConnection.bind(this, index, "person_column_id")}
												/>
												<Select
													label={this.props.t("goals.fields.status_column_id.label", "och status")}
													options={this.getColumnOptions(connection.board, ["status"])}
													value={connection.status_column_id + ""}
													onChange={this.updateConnection.bind(this, index, "status_column_id")}
												/>
												{connection.status_column_id ? (
													<Select
														label={this.props.t("goals.fields.status_value.label", "är")}
														options={this.getStatusOptions(connection.board, connection.status_column_id)}
														value={connection.status_value + ""}
														onChange={this.updateConnection.bind(this, index, "status_value")}
													/>
												) : null}
												<Select
													label={this.props.t("goals.fields.type_column_id.label", "och typ")}
													options={this.getColumnOptions(connection.board, ["dropdown", "category"])}
													value={connection.type_column_id + ""}
													onChange={this.updateConnection.bind(this, index, "type_column_id")}
												/>
												{connection.type_column_id ? (
													<Select
														label={this.props.t("goals.fields.type_value.label", "är")}
														options={this.getTypeOptions(connection.board, connection.type_column_id)}
														value={connection.type_value + ""}
														onChange={this.updateConnection.bind(this, index, "type_value")}
													/>
												) : null}
											</LegacyStack>
										</Modal.Section>
									);
								}

								return null;
							})}
						</Modal.Section>
					);
				case "goal":
				default:
					return (
						<Modal.Section>
							<FormLayout>
								<TextField
									label={this.props.t("goals.fields.title.label", "Namn")}
									value={this.state.form.title}
									onChange={this.updateForm.bind(this, "title")}
								/>

								<React.Fragment>
									<Text variant="headingMd" as="h2">
										{this.props.t("goals.fields.period.title", "Över vilken period mäter vi?")}
									</Text>
									{this.state.form.old_id && (
										<span style={{ color: "var(--textColor2)" }}>Kan inte redigera beräkningsintervallet när en föregående målsättning finns</span>
									)}
								</React.Fragment>
								<ChoiceList
									disabled={this.state.form.old_id}
									title={this.props.t("goals.fields.interval.label", "Beräkningsintervall")}
									choices={[
										{ label: this.props.t("goals.fields.interval.options.annually", "Årsvis"), value: "annually" },
										{ label: this.props.t("goals.fields.interval.options.monthly", "Månadsvis"), value: "monthly" },
										{ label: this.props.t("goals.fields.interval.options.weekly", "Veckovis"), value: "weekly" },
										// { label: "Daglig", value: "daily" },
										{ label: this.props.t("goals.fields.interval.options.period", "Annan period"), value: "period" },
									]}
									selected={[this.state.form.interval]}
									onChange={(val) => {
										this.updateForm("interval", val[0]);
									}}
								/>
								{this.state.form.interval != "period" && this.state.form.interval != "annually" ? (
									<Checkbox
										checked={this.state.form.compare_with_prev_year}
										label={this.props.t("goals.fields.compare_with_prev_year.label", "Jämför med föregående år istället för föregående period")}
										onChange={(v) => {
											this.state.form.compare_with_prev_year = !this.state.form.compare_with_prev_year;
											this.setState({ form: this.state.form });
										}}
									/>
								) : null}
								{this.state.form.interval == "period" ? (
									<DatePicker
										month={this.state.month}
										year={this.state.year}
										weekStartsOn={1}
										onChange={(value) => {
											this.state.selected = value;
											const fromTime = new Date(this.getYmd(value.start));
											const toTime = new Date(this.getYmd(value.end));
											this.state.from = fromTime;
											this.state.to = toTime;
											this.state.form.start_at = this.getYmd(fromTime);
											this.state.form.end_at = this.getYmd(toTime);

											this.setState({
												from: fromTime,
												to: toTime,
												selected: value,
												form: this.state.form,
											});
										}}
										onMonthChange={(month, year) => {
											this.setState({ month, year });
										}}
										selected={this.state.selected}
										multiMonth
										allowRange
									/>
								) : null}
								<LegacyStack distribution="fillEvenly">
									<TextField
										label={this.props.t("goals.fields.default_value.label", "Standard målvärde per person")}
										type="numeric"
										value={this.state.form.default_value + ""}
										onChange={this.updateForm.bind(this, "default_value")}
										suffix={this.state.form.suffix}
									/>
									<TextField
										label={this.props.t("foals.fields.suffix.label", "Suffix")}
										helpText={this.props.t("foals.fields.suffix.helptext", "Visas efter siffrorna (t.ex. kr, timmar)")}
										value={this.state.form.suffix}
										onChange={this.updateForm.bind(this, "suffix")}
									/>
								</LegacyStack>
							</FormLayout>
						</Modal.Section>
					);
			}
		})();
		const tabIndex = this.getTabs().findIndex((t) => t.id === this.state.tabId);
		return (
			<>
				<Modal
					large
					fullScreen
					open={this.state.open}
					onClose={this.props.onClose}
					title={this.state.form.id ? this.props.t("goals.titles.change", "Ändra målsättning") : this.props.t("goals.titles.new", "Ny målsättning")}
					secondaryActions={[
						{
							content: this.props.t("goal.actions.remove", "Ta bort"),
							onAction: this.handleOpenRemoveModal.bind(this),
							destructive: true,
							enabled: this.state.form?.id,
						},
						{
							content: this.props.t("common.actions.close", "Stäng"),
							onAction: this.props.onClose,
						},
					].filter((i) => !("enabled" in i) || i.enabled)}
					primaryAction={{
						content: this.state.form.id
							? this.props.t("common.actions.update", "Uppdatera")
							: tabIndex < this.getTabs().length - 1
							? this.props.t("goals.actions.next_step", "Nästa steg")
							: this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						disabled,
						onAction: this.saveForm.bind(this),
					}}
				>
					<div className="goal_modal">
						<div className="tabs" style={{ position: "sticky", top: 0, zIndex: 100 }}>
							<Tabs
								tabs={this.getTabs()}
								selected={this.getTabs().findIndex((t) => String(t.id) === String(this.state.tabId))}
								onSelect={(tabIndex) => {
									const tab = this.getTabs()[tabIndex];
									if (!this.state.form?.users?.length && tab.id === "users") {
										this.state.addUserIsOpen = true;
									}
									if (tab.disabled) return;
									this.setState({ tabId: tab.id }, () => {
										if (tab?.onAction) tab.onAction();
									});
								}}
							/>
						</div>

						<div className="content">{content}</div>
					</div>
				</Modal>

				<Modal
					title={this.props.t("goals.remove.title", "Ta bort målsättning")}
					open={this.state.removeModalIsOpen}
					onClose={this.handleCloseRemoveModal.bind(this)}
					secondaryActions={[
						{
							content: this.props.t("common.action.cancel", "Avbryt"),

							onAction: this.handleCloseRemoveModal.bind(this),
						},
						{
							content: this.props.t("common.action.remove", "Ta bort"),
							destructive: true,
							onAction: this.handleRemoveGoal.bind(this),
							loading: this.state.loadingDelete,
						},
					]}
				>
					<Modal.Section>
						<BringModalToFront />
						<p>
							{/* // eslint-disable-next-line quotes */}
							{this.props.t("goals.confirm_remove_text", "Är du säker du vill ta bort målsättning '{{title}}'", {
								title: this.state.form.title,
							})}
						</p>
					</Modal.Section>
				</Modal>
			</>
		);
	}
}
export default withTranslation(["goals", "common"], { withRef: true })(GoalModal);
