import React, { Component } from "react";
import { Button } from "@shopify/polaris";

import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import API from "../../API";
import { store } from "../../store";
import { toastr } from "../../components/toastr.js";
import BoardHelper from "./BoardHelper.js";
import RecycledList from "./components/RecycledList.js";
import ColumnHeaderRow from "./ColumnHeaderRow.js";
import BulkActionMenu from "./components/BulkActionMenu.js";
import BoardListRow from "./BoardViewListRow.js";

class BoardViewList extends Component {
	constructor(props) {
		super(props);
		this.renderedItems = {};
		this.timeout = null;
		this.endDrag = this.moveRow.bind(this);
		this.stopDragGroup = () => {
			this.moveGroup(this.state.currentGroupIndex);
			this.setState({ draggingGroup: null, currentGroupIndex: null });
		};
		this.startDragGroup = (group) => {
			this.setState({ draggingGroup: group, currentGroupIndex: null });
			window.addEventListener("mouseup", this.stopDragGroup);
		};
		this.state = {
			rowContainerClass: "row-list",
			rows: [],
			selection: [],
		};
		this.updateSelection = this.toggleSelection.bind(this);
	}

	sortRows(a, b) {
		if (this.props.sorting.length > 0) {
			let sortValue = 0;
			for (let i = 0; i < this.props.sorting.length; i++) {
				sortValue = BoardHelper.getSortValue(a, b, this.props.sorting[i]);
				if (sortValue !== 0) {
					return sortValue;
				}
			}
			return sortValue;
		}
		return a.position - b.position;
	}

	moveGroup(newPosition) {
		const groups = Object.values(store.getState().board_groups)
			.filter((grp) => grp.board_id == this.props.board.id)
			.sort((a, b) => a.position - b.position);
		const newGroups = [];
		let n = 0;
		for (let i = 0; i < groups.length; i++) {
			if ((this.state.currentGroupIndex === null && this.state.draggingGroup.id == groups[i].id) || newPosition == i) {
				const group = BoardHelper.getGroup(this.state.draggingGroup.id);
				group.position = n;

				API.put("/api/boards/" + this.props.board.id + "/groups/" + this.state.draggingGroup.id + ".json", { position: n }, { params: {} })
					.then((result) => {
						if (result.data.error) {
							toastr.error(result.data.error);
						}
					})
					.catch((error) => {
						toastr.error(error);
					});

				newGroups.push(group);
				n++;
			}
			if (groups[i].id != this.state.draggingGroup.id) {
				groups[i].position = n;
				newGroups.push(groups[i]);
				n++;
			}
		}

		store.dispatch({ type: "SET_BOARD_GROUPS", groups: newGroups });
	}

	moveRow(index, toIndex) {
		const item = this.state.rows[index];
		const destItem = this.state.rows[toIndex];

		let toGroupId = destItem.id;
		let toPosition = 0;

		if (destItem.color) {
			toGroupId = destItem.id;
			toPosition = 0;
		} else {
			toGroupId = destItem.group_id;
			toPosition = destItem.position;
		}

		const row = Object.assign({}, BoardHelper.getRow(item.id));
		row.group_id = toGroupId;
		row.position = toPosition;

		store.dispatch({ type: "UPDATE_BOARD_ROW", row });

		API.put(
			"/api/boards/" + this.props.board.id + "/rows/" + row.id + ".json",
			{ group_id: row.group_id, position: row.position },
			{ params: {} }
		).catch((error) => {
			toastr.error(error);
		});
	}

	toggleSelection(index, value) {
		const item = this.state.rows[index];

		if (item.color) {
			// Toggle multiple rows
			for (let i = 0; i < item.rows.length; i++) {
				const index = this.state.selection.indexOf(item.rows[i]);
				if (value && index < 0) {
					this.state.selection.push(item.rows[i]);
				} else if (!value && index >= 0) {
					this.state.selection.splice(index, 1);
				}
			}
		} else {
			if (this.shiftDown && this.state.selection.length > 0) {
				let startIndex = null;
				let endIndex = null;

				if (this.state.selection[this.state.selection.length - 1] == item.id) {
					return;
				}
				for (let i = 0; i < this.state.rows.length; i++) {
					if (this.state.rows[i].id == this.state.selection[this.state.selection.length - 1] || this.state.rows[i].id == item.id) {
						if (startIndex === null) {
							startIndex = i;
						} else {
							endIndex = i;
						}
					}
				}
				for (let i = startIndex; i <= endIndex; i++) {
					if (this.state.rows[i].group_id && this.state.rows[i].id) {
						if (this.state.selection.indexOf(this.state.rows[i].id) < 0) {
							this.state.selection.push(this.state.rows[i].id);
						}
					}
				}
			} else {
				if (value && this.state.selection.indexOf(item.id) < 0) {
					this.state.selection.push(item.id);
				} else if (!value && this.state.selection.indexOf(item.id) >= 0) {
					const index = this.state.selection.indexOf(item.id);
					this.state.selection.splice(index, 1);
				}
			}
		}
		this.setState({ selection: this.state.selection });
	}

	onUpdateValue(row, column, data) {
		if (this.state.selection?.length) {
			const ids = new Set([row.id, ...this.state.selection]);
			const rows = this.state.rows.filter((row) => ids.has(row.id));

			BoardHelper.onUpdateMultipleRowsValue(rows, column, data).then((result) => {
				if (result?.rows?.length) {
					this.setState({ selection: [] });
					toastr.success(`${result?.rows?.length}st rader uppdaterade`);
				}
			});
			return;
		}

		BoardHelper.onUpdateValue(row, column, data);
	}

	renderRow(key, style, index, id, className, blank) {
		const item = this.state.rows[index];
		let mode = null;
		if (key in this.renderedItems && this.renderedItems[key].id == id) {
			mode = this.renderedItems[key].mode;
		} else {
			this.renderedItems[key] = { id, mode: "placeholder" };
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
			this.timeout = setTimeout(
				() => {
					// eslint-disable-next-line no-restricted-syntax
					for (const i in this.renderedItems) {
						this.renderedItems[i].mode = "full";
					}
					if (this.list) {
						this.list.renderItems();
					}
				},
				isMac ? 120 : 500
			);
			mode = "placeholder";
		}

		let selected = false;
		if (item.color) {
			selected = false;
			if (!item.collapsed && item.rows) {
				if (item.rows.length > 0) {
					selected = true;
				}
				for (let i = 0; i < item.rows.length; i++) {
					if (this.state.selection.indexOf(item.rows[i]) < 0) {
						selected = false;
					}
				}
			}
		} else {
			selected = this.state.selection.indexOf(item.id) >= 0;
		}

		return (
			<BoardListRow
				blank={blank}
				mode={mode}
				key={key}
				boardClass={this.props.boardClass}
				boardString={this.props.boardString}
				sorting={this.props.sorting}
				onChangeSorting={this.props.onChangeSorting}
				dakey={key}
				style={style}
				index={index}
				item={item}
				id={id}
				selected={selected}
				toggleSelection={this.updateSelection}
				onDragEnd={this.endDrag}
				onDragGroupStart={this.startDragGroup}
				className={className}
				openCalendarModal={this.props.openCalendarModal}
				openRow={this.props.openRow}
				openTicketModal={this.props.openTicketModal}
				onUpdateValue={this.onUpdateValue.bind(this)}
				history={this.props.history}
			/>
		);
	}

	componentDidMount() {
		$("." + this.props.boardClass).on("keydown", (e) => {
			if (e.keyCode == 16) {
				this.shiftDown = true;
			}
			if (e.code == "Tab") {
				e.preventDefault();
				let focusNext = false;
				for (let i = 0; i < $(".board-column").length; i++) {
					if (focusNext) {
						if ($(".board-column").eq(i).find(".board-tabbable").length > 0) {
							$(".board-column").eq(i).find(".board-tabbable").trigger("click");
							return;
						}
					}
					if ($(".board-column").eq(i).hasClass("focused")) {
						focusNext = true;
					}
				}
			}
		});
		$("." + this.props.boardClass).on("keyup", (e) => {
			if (e.keyCode == 16) {
				this.shiftDown = false;
			}
		});
		this.onVerticalScroll = this.updateVerticalScroll.bind(this);
		this.onHorizontalScroll = this.updateHorizontalScroll.bind(this);
		$("." + this.props.containerClass).on("scroll", this.onHorizontalScroll);
		$("." + this.props.containerClass).on("scroll", this.onVerticalScroll);
		this.updateGroupRows(this.props);
	}

	UNSAFE_componentWillReceiveProps(props) {
		this.updateGroupRows(props);
	}

	shouldShowArchives() {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get("archived") == 1;
	}

	updateGroupRows(props) {
		const allRows = [];
		const selection = [];
		const groups = Object.values(store.getState().board_groups)
			.filter((grp) => grp.board_id == this.props.board.id)
			.sort((a, b) => a.position - b.position);
		for (let i = 0; i < groups.length; i++) {
			const group = groups[i];
			// let groupRows = {};
			// props.rows.map(()=>)
			const rows = props.rows.filter((row) => row.group_id == group.id).sort(this.sortRows.bind(this));

			if (!this.props.haveSearch || rows.length > 0) {
				group.count = rows.length;
				allRows.push(group);

				if (!group.collapsed) {
					group.rows = [];
					group.selection = [];
					let lastPosition = 0;
					for (let s = 0; s < rows.length; s++) {
						group.rows.push(rows[s].id); // For the select
						if (rows[s].selected) {
							group.selection.push(rows[s].id);
							selection.push(rows[s].id);
						}
						lastPosition = rows[s].position + 1;
						allRows.push(rows[s]);
					}
					if (!this.shouldShowArchives()) {
						allRows.push({
							type: "quickadd",
							position: lastPosition,
							board_id: this.props.board.id,
							group_id: group.id,
						});
					}
					if (this.haveSummaryColumn() && !!rows.length) {
						allRows.push({
							type: "summary",
							board_id: this.props.board.id,
							rows,
							group_id: group.id,
						});
					}
				}
				if (!this.shouldShowArchives()) {
					allRows.push({
						type: "blank",
						board_id: this.props.board.id,
						group_id: group.id,
					});
				}
			}
		}
		this.setState({ rows: allRows, selection });
	}

	componentWillUnmount() {
		$("." + this.props.containerClass).off("scroll", this.onHorizontalScroll);
		$("." + this.props.containerClass).off("scroll", this.onVerticalScroll);
	}

	getTotalColumnWidth() {
		const totalWidth = this.props.board.columns.reduce((acc, curr) => acc + curr.width, this.props.board.title_width + 40);
		return totalWidth;
	}

	updateHorizontalScroll() {
		if ($("." + this.props.containerClass).scrollLeft() > 0) {
			$("." + this.props.containerClass).addClass("have-left-scroll");
		} else if ($("." + this.props.containerClass).scrollLeft() <= 0) {
			$("." + this.props.containerClass).removeClass("have-left-scroll");
		}
	}

	updateVerticalScroll() {
		let offsetTop = $("." + this.props.boardClass).offset().top;
		if (offsetTop < 0) {
			offsetTop = 0;
		}
		let scrollTop = offsetTop + $("." + this.state.rowContainerClass).offset().top * -1;
		if (scrollTop < 0) {
			scrollTop = 0;
		}
		for (let i = 0; i < $(".fixed-row").length; i++) {
			const top = $(".fixed-row").eq(i).attr("data-top");
			if (scrollTop > top) {
				$(".fixed-row").eq(i).addClass("stuck");
			} else {
				$(".fixed-row").eq(i).removeClass("stuck");
			}
		}
		if (this.list) {
			this.list.scrollTo(scrollTop);
		}
	}

	haveSummaryColumn() {
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (this.props.board.columns[i].have_summary) {
				return true;
			}
		}
		return false;
	}

	getGroupDragger() {
		const groups = Object.values(store.getState().board_groups)
			.filter((grp) => grp.board_id == this.props.board.id)
			.sort((a, b) => a.position - b.position);
		groups.push({ id: "blank" });
		return (
			<div>
				{groups.map((group, index) => {
					let placeholder = null;
					if ((this.state.currentGroupIndex === null && this.state.draggingGroup.id == group.id) || this.state.currentGroupIndex == index) {
						placeholder = (
							<div
								style={{
									marginLeft: 30,
									marginRight: -30,
									border: "4px dashed #eee",
									height: 40,
								}}
							/>
						);
					}
					return (
						<div
							onMouseOver={() => {
								this.setState({ currentGroupIndex: index });
							}}
							className="board-item"
							data-index={index}
							key={group.id}
						>
							{placeholder}
							{this.state.draggingGroup.id == group.id ? null : group.id == "blank" ? (
								<div style={{ marginLeft: 30, marginRight: -30, height: 40 }} />
							) : (
								<ColumnHeaderRow
									group={group}
									draggingGroup
									count={group.count}
									board_id={group.board_id}
									openCalendarModal={this.props.openCalendarModal}
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	}

	render() {
		const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

		return (
			<DndProvider backend={HTML5Backend}>
				<div
					className={this.state.selection.length ? "have-selection" : ""}
					style={{
						minWidth: this.getTotalColumnWidth(),
						width: $("." + this.props.boardClass).width(),
						marginRight: "1.8750rem",
					}}
				>
					<div className="fixed-column" style={{ width: $("." + this.props.boardClass).width() - 10 }}>
						{this.props.header}
					</div>
					{(!this.state.rows || !this.state.rows.length) && (
						<div className="board-empty_screen">
							<img alt="" className="Polaris-EmptySearchResult__Image" src="/assets/images/empty_state/NoResults.png" />
							<h2>
								{this.props.t("board.rows.empty.title", "Inga {{plural}} hittades", {
									plural: this.props.board?.plural || "rader",
								})}
							</h2>
							{!this.props.noheader && (
								<React.Fragment>
									<p>{this.props.t("board.rows.empty.text", "Prova att ändra filter eller sökning")}</p>
									<Button
										primary
										onClick={() => {
											this.props.onChangeFilters([]);
										}}
									>
										{this.props.t("board.rows.empty.action", "Rensa filters")}
									</Button>
								</React.Fragment>
							)}
						</div>
					)}
					{this.state.draggingGroup ? (
						this.getGroupDragger()
					) : (
						<div className={this.state.rowContainerClass}>
							<RecycledList
								viewportHeight={$("." + this.props.containerClass).height()}
								itemHeight={36}
								ref={(r) => {
									this.list = r;
								}}
								style={{ height: this.state.rows.length * 36 }}
								itemCount={this.state.rows.length}
								overscan={isMac ? 15 : 1}
								renderItem={this.renderRow.bind(this)}
								itemGetter={(index) => this.state.rows[index]}
								groupHeaders={this.state.rows.map((i, index) => (i.color ? Object.assign({ index }, i) : null)).filter((i) => i)}
								keyGetter={(index) => {
									const item = this.state.rows[index];
									return item.type == "quickadd" || item.type == "summary" || item.type == "blank"
										? item.type + item.group_id
										: (item.color ? "g" : "") + item.id;
								}}
							/>
						</div>
					)}
				</div>
				<BulkActionMenu
					onDuplicateRow={this.props.onDuplicateRow}
					onUpdateRow={this.props.onUpdateRow}
					selection={this.state.selection}
					deselectAll={() => this.setState({ selection: [] })}
					groups={this.props.groups}
					board={this.props.board}
					rows={this.state.rows}
				/>
			</DndProvider>
		);
	}
}

export default withTranslation(["board", "common"], { withRef: true })(BoardViewList);
