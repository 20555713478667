import React, { Component } from "react";
import { FormLayout, TextField, Select } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import RemoveModal from "./remove_modal.js";
import Modal from "src/js/components/modal";

class TaskTypeModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: props.type, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.type });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/task_types/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("task.responses.updated_type", "Uppdaterade påminnelsestyp"));
					this.setState({ saving: false });
					store.dispatch({ type: "UPDATE_TASK_TYPE", task_type: result.data.task_type });
					this.props.onCreate(result.data.task_type);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/task_types.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("task.responses.sent_type", "Skapade påminnelsestyp"));
					this.setState({ saving: false });
					store.dispatch({ type: "ADD_TASK_TYPE", task_type: result.data.task_type });
					this.props.onCreate(result.data.task_type);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	doRemove() {
		this.setState({ removing: true });
		API.delete("/api/task_types/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				store.dispatch({ type: "REMOVE_TASK_TYPE", id: this.state.form.id });
				toastr.success(this.props.t("task.responses.remove_type", "Påminnelsetyp borttaget"));

				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	render() {
		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN")) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={this.state.form.id ? this.props.t("task.type.titles.change", "Ändra typ") : this.props.t("task.type.titles.create", "Skapa typ")}
					secondaryActions={secondaryActions}
					primaryAction={{
						content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
						loading: this.state.saving,
						disabled: !this.state.form.title,
						onAction: this.saveForm.bind(this),
					}}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("task.type.fields.title.label", "Namn")}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							<Select
								label="Typ"
								options={[
									{ value: "return", label: this.props.t("task.type.fields.options.return", "Kontakta") },
									{ value: "task", label: this.props.t("task.type.fields.options.task", "Uppdrag/Ärende") },
									{ value: "meeting", label: this.props.t("task.type.fields.options.meeting", "Möte") },
									{ value: "other", label: this.props.t("task.type.fields.options.other", "Annat") },
								]}
								onChange={(type) => {
									this.state.form.type = type;
									this.setState({ form: this.state.form });
								}}
								value={this.state.form.type}
							/>
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.doRemove.bind(this)}
					numItems={1}
					objectName={this.state.form.title}
					resourceName={{
						singular: this.props.t("task.type.singular", "påminnelsetyp"),
						plural: this.props.t("task.type.pliural", "påminnelsetyper"),
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["task", "common"], { withRef: true })(TaskTypeModal);
