import React, { Component } from "react";
import { Icon, LegacyStack, Select, FormLayout, TextField } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import { withTranslation } from "react-i18next";
import API from "../../../API";
import { toastr } from "../../../components/toastr.js";
import SelectSearchField from "src/js/components/SelectSearchField";

class TriggerAction extends Component {
	constructor(props) {
		super(props);

		this.state = {
			boards: [],
			focused: props.focused,
			event: props.event,
			action: Object.assign({}, props.action),
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (
			props.focused != this.state.focused ||
			props.event != this.state.event ||
			JSON.stringify(props.action) !== JSON.stringify(this.state.action)
		) {
			if (props.focused != this.state.focused) {
				this.state.editingTarget = props.focused;
				this.setState({ editingTarget: props.focused, editingValue: false });
			}
			this.setState({
				focused: props.focused,
				event: props.event,
				action: Object.assign({}, props.action),
			});
			this.updateActions(props);
		}
	}

	componentDidMount() {
		this.updateActions(this.props);
	}

	getActionTitle(action, targetTitle = null, valueTitle = null) {
		if (action.action == "notify") {
			if (!targetTitle && action.target) {
				const member = this.props.board.members.find((member) => member.user.id == action.target);
				if (member) {
					targetTitle = member.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.actions.send", "skicka")}{" "}
					<span
						className="value-title"
						onClick={() => {
							this.changeFocus("value");
						}}
					>
						{this.props.t("trigger.terms.notis", "notis")}
					</span>{" "}
					{this.props.t("trigger.terms.notis", "till")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.some", "någon")}
					</span>
				</span>
			);
		}
		if (action.action == "send.email_campaign") {
			return (
				<span>
					{this.props.t("trigger.actions.send", " skicka")}{" "}
					<span className="target-title" onClick={() => this.changeFocus("target")}>
						{targetTitle ||
							this.props.t("trigger.terms.email_campaign", "E-postkampanj {{title}}", {
								title: this.props.action.target && `(${this.props.action.target?.title})`,
							})}
					</span>{" "}
					{this.props.t("trigger.terms.send1", "till kontakt/person i kolumnen")}{" "}
					<span className="value-title" onClick={this.changeFocus.bind(this, "value")}>
						{valueTitle || this.props.t("trigger.terms.send2", "kolumn")}
					</span>
				</span>
			);
		} else if (action.action == "webhook.send") {
			return (
				<span>
					{this.props.t("trigger.actions.send", " skicka")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{this.props.t("trigger.actions.webhook", "webhook")}
					</span>
				</span>
			);
		} else if (action.action == "board_row.move_to_group") {
			if (!targetTitle && action.target) {
				const group = this.props.groups.find((group) => group.id == action.target);
				if (group) {
					targetTitle = group.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.actions.move_row_to", " flytta rad till")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "grupp"}
					</span>
				</span>
			);
		} else if (action.action == "board_row.move_to_board") {
			if (!targetTitle && action.target) {
				const board = this.state.boards.find((board) => board.id == action.target);
				if (board) {
					targetTitle = board.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.actions.move_row_to", " flytta rad till")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "board"}
					</span>
				</span>
			);
		} else if (action.action == "board_row.set_status") {
			let column = null;
			if (!targetTitle && action.target) {
				column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			if (!valueTitle && action.value && column) {
				valueTitle = column.options.statuses.find((s) => s.id == action.value).label;
			}
			return (
				<span>
					{" "}
					{this.props.t("common.actions.change", "ändra")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.status", "status")}
					</span>{" "}
					{this.props.t("trigger.terms.to", "till")}{" "}
					<span
						className="value-title"
						onClick={() => {
							if (action.target) {
								this.changeFocus("value");
							}
						}}
					>
						{valueTitle || this.props.t("trigger.terms.some", "något")}
					</span>
				</span>
			);
		} else if (action.action == "board_row.assign_me") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.actions.add_members_in", "lägg till användare i")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "person"}
					</span>{" "}
				</span>
			);
		} else if (action.action == "board_row.set_date") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.terms.set", "sätt")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "datum"}
					</span>{" "}
					{this.props.t("trigger.terms.to_today", "till idag")}
				</span>
			);
		} else if (action.action == "board_row.set_value") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.terms.set", "sätt")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "värde"}
					</span>{" "}
				</span>
			);
		} else if (action.action == "board_row.checkbox_check") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.terms.check_for", "bocka för")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "checkbox"}
					</span>
				</span>
			);
		} else if (action.action == "board_row.timetrack_start") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					starta{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "tidtagare"}
					</span>
				</span>
			);
		} else if (action.action == "board_row.timetrack_stop") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					stoppa{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || "tidtagare"}
					</span>
				</span>
			);
		} else if (action.action == "board_row.push_date") {
			if (!targetTitle && action.target) {
				const column = this.props.board.columns.find((column) => column.id == action.target);
				if (column) {
					targetTitle = column.title;
				}
			}
			return (
				<span>
					{this.props.t("trigger.actions.move_forward", " flytta fram")}{" "}
					<span className="target-title" onClick={this.changeFocus.bind(this, "target")}>
						{targetTitle || this.props.t("trigger.terms.date", "datum")}
					</span>{" "}
					<span
						className="value-title"
						onClick={() => {
							if (action.target) {
								this.changeFocus("value");
							}
						}}
					>
						{valueTitle || ""}
					</span>
				</span>
			);
		} else if (action.action == "board_row.archive") {
			return <span>{this.props.t("trigger.actions.archive_row", "arkivera rad")}</span>;
		}
	}

	changeFocus(target) {
		if (target == "value") {
			this.setState({ editingValue: true, editingTarget: false }, this.updateActions.bind(this, this.props));
		} else if (target == "target") {
			this.setState({ editingValue: false, editingTarget: true }, this.updateActions.bind(this, this.props));
		}
		//  else if (target == "source") {
		// 	this.setState({ editingValue: false, editingTarget: false, editingSource: true }, this.updateActions.bind(this, this.props));
		// }
		else {
			this.setState({ editingValue: false, editingTarget: false }, this.updateActions.bind(this, this.props));
		}
	}

	changeValue(value) {
		this.props.action.value = value;
		this.changeFocus("none");
		this.props.onUpdateAction(this.props.action);
	}

	changeTarget(target, extractId = true) {
		if (this.props.action.action == "notify") {
			this.props.action.value = this.props.t("trigger.terms.this_is_notis", "Detta är en notis");
			this.props.action.priority = "normal";
		}
		this.props.action.target = extractId ? target.id : target;
		this.changeFocus("value");
		this.props.onUpdateAction(this.props.action);
	}

	changeAction(action) {
		this.props.action.action = action;
		if (this.props.action.action == "webhook.send") {
			this.props.action.url = "https://";
			this.changeFocus("target");
		}
		if (this.props.action.action == "board_row.push_date") {
			this.props.action.duration = "0";
			this.props.action.unit = "days";
			this.setState({
				valueTitle: this.props.action.duration + " " + this.props.t("trigger.terms.days", "dagar"),
			});
		}
		this.props.onUpdateAction(this.props.action);
		this.setState({ actionTitle: null });
		this.changeFocus("target");
	}

	getColumns(types) {
		const arr = [];
		for (let i = 0; i < this.props.board.columns.length; i++) {
			if (types.indexOf("*") >= 0 || types.indexOf(this.props.board.columns[i].type) >= 0) {
				arr.push(this.props.board.columns[i]);
			}
		}
		return arr;
	}

	fetchBoards() {
		if (this.state.loadedBoards) {
			return;
		}
		this.state.loadedBoards = true;
		API.get("/api/boards/" + this.props.board.id + "/boards.json", {
			params: {},
		})
			.then((result) => {
				this.setState(
					{
						boards: result.data.boards,
					},
					this.updateActions.bind(this, this.props)
				);
			})
			.catch((error) => {
				toastr.error(error);
			});
	}

	updateActions(props) {
		if (!props.focused) {
			return;
		}

		if (!this.props.action.action) {
			const actions = [
				"notify",
				"send.email_campaign",
				"board_row.move_to_group",
				"board_row.move_to_board",
				"board_row.set_status",
				"board_row.set_value",
				"board_row.set_date",
				"board_row.assign_me",
				"board_row.push_date",
				"board_row.timetrack_start",
				"board_row.timetrack_stop",
				"board_row.checkbox_check",
				"board_row.archive",
				"webhook.send",
			];
			const options = actions.map((action) => ({
				label: this.getActionTitle({ action }),
				onMouseOver: () => {
					this.setState({ actionTitle: this.getActionTitle({ action }) });
				},
				onMouseOut: () => {
					this.setState({ actionTitle: null });
				},
				onAction: this.changeAction.bind(this, action),
			}));
			props.updateOptions(this.props.t("trigger.terms.choose", "Välj ett av dessa alternativ för att bygga din automatisering"), options);
		} else if (!this.state.editingTarget && !this.state.editingValue) {
			props.updateOptions("", []);
		} else if (this.props.action.action == "notify") {
			if (this.state.editingTarget) {
				const options = props.board.members.map((member) => ({
					label: member.title,
					onMouseOver: () => {
						this.setState({ targetTitle: member.title });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, member.user),
				}));

				options.unshift({
					label: this.props.t("trigger.terms.subscribers_row", "Prenumeranter på rad"),
					onMouseOver: () => {
						this.setState({
							targetTitle: this.props.t("trigger.terms.subscribers_row", "Prenumeranter på rad"),
						});
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, { id: "row" }),
				});
				options.unshift({
					label: this.props.t("trigger.terms.all_subscribers", "Alla prenumeranter"),
					onMouseOver: () => {
						this.setState({
							targetTitle: this.props.t("trigger.terms.all_subscribers", "Alla prenumeranter"),
						});
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, { id: "board" }),
				});
				props.updateOptions(this.props.t("trigger.fields.choose_member.label", "Välj medlem"), options);
			} else if (this.state.editingValue) {
				props.updateOptions(
					this.props.t("trigger.fields.priority.title", "Ändra meddelande"),
					[],
					<FormLayout>
						<TextField
							multiline
							focused
							autoFocus
							value={this.props.action.value}
							onChange={(v) => {
								this.props.action.value = v;
								this.props.onUpdateAction(this.props.action);
							}}
						/>
						<Select
							label={this.props.t("trigger.fields.priority.label", "Prioritet")}
							options={[
								{
									value: "normal",
									label: this.props.t("trigger.fields.priority.normal", "normal"),
								},
								{
									value: "low",
									label: this.props.t("trigger.fields.priority.low", "låg"),
								},
								{
									value: "high",
									label: this.props.t("trigger.fields.priority.high", "hög"),
								},
							]}
							value={this.props.action.priority}
							onChange={(v) => {
								this.props.action.priority = v;
								this.props.onUpdateAction(this.props.action);
							}}
						/>
					</FormLayout>
				);
			}
		} else if (this.props.action.action == "send.email_campaign") {
			if (this.state.editingTarget || !this.props.action.target) {
				this.props.updateOptions(
					this.props.t("trigger.fields.send.email_campaign", "Välj E-postkampanj"),
					[],
					<SelectSearchField
						value={this.props.action.target?.id}
						resourceName={{ singular: "E-postkampanj", plural: "E-postkampanjer" }}
						resource="email_campaigns"
						resourceHandle="email_campaigns"
						resourceURL="/api/email_campaigns.json"
						labelHandle="title"
						idHandle="id"
						onSelect={(v) => {
							this.changeTarget(v, false);
						}}
						params={{
							type: "transactional",
						}}
						fetchOnMount
						renderValue={(id, data) => {
							if (this.props.action.target?.id === id) return this.props.action.target?.title;

							return data?.find((emnailCampaign) => emnailCampaign.id === id)?.title || id;
						}}
						renderLabel={(item) => {
							return `${item.title}`;
						}}
					/>
				);
			} else if (this.state.editingValue || !this.props.action.value) {
				const options = this.getColumns(["contact", "person"]).map((column) => ({
					label: column.title,
					onMouseOver: () => {
						this.setState({ valueTitle: column.title });
					},
					onMouseOut: () => {
						this.setState({ valueTitle: null });
					},
					onAction: this.changeValue.bind(this, column),
				}));
				props.updateOptions(this.props.t("trigger.fields.choose_status.title", "Välj kolumn"), options);
			}
		} else if (this.props.action.action == "webhook.send") {
			props.updateOptions(
				this.props.t("trigger.fields.webhook.title", "Ange url för webhook"),
				[],
				<TextField
					focused
					autoFocus
					value={this.props.action.url}
					onChange={(v) => {
						this.props.action.url = v;
						this.props.onUpdateAction(this.props.action);
					}}
				/>
			);
		} else if (this.props.action.action == "board_row.move_to_group") {
			const options = props.groups.map((group) => ({
				label: group.title,
				onMouseOver: () => {
					this.setState({ targetTitle: group.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, group),
			}));
			props.updateOptions(this.props.t("trigger.fields.choose_group.title", "Välj grupp"), options);
		} else if (this.props.action.action == "board_row.move_to_board") {
			this.fetchBoards();
			const options = this.state.boards.map((board) => ({
				label: board.title,
				onMouseOver: () => {
					this.setState({ targetTitle: board.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, board),
			}));
			props.updateOptions(this.props.t("trigger.fields.choose_board.title", "Välj board"), options);
		} else if (this.props.action.action == "board_row.set_status") {
			if (this.state.editingValue) {
				const column = this.props.board.columns.find((column) => column.id == props.action.target);
				if (column) {
					const options = column.options.statuses.map((option) => {
						if (option.label) {
							return {
								label: option.label,
								onMouseOver: () => {
									this.setState({ valueTitle: option.label });
								},
								onMouseOut: () => {
									this.setState({ valueTitle: null });
								},
								onAction: this.changeValue.bind(this, option.id),
							};
						}
						return null;
					});
					props.updateOptions(this.props.t("trigger.fields.choose_status.title", "Välj status"), options);
				} else {
					props.updateOptions("", []);
				}
			} else {
				const options = this.getColumns(["status"]).map((column) => ({
					label: column.title,
					onMouseOver: () => {
						this.setState({ targetTitle: column.title });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, column),
				}));
				props.updateOptions(this.props.t("trigger.fields.choose_status.title", "Välj kolumn"), options);
			}
		} else if (this.props.action.action == "board_row.assign_me") {
			const options = this.getColumns(["person"]).map((column) => ({
				label: column.title,
				onMouseOver: () => {
					this.setState({ targetTitle: column.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, column),
			}));
			props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
		} else if (this.props.action.action == "board_row.set_date") {
			const options = this.getColumns(["datetime"]).map((column) => ({
				label: column.title,
				onMouseOver: () => {
					this.setState({ targetTitle: column.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, column),
			}));
			props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
		} else if (this.props.action.action == "board_row.set_value") {
			if (this.state.editingValue) {
				props.updateOptions(
					this.props.t("trigger.fields.set_value.title", "Värde:"),
					[],
					<div>
						<TextField
							focused
							autoFocus
							value={this.props.action.value}
							onChange={(v) => {
								this.props.action.value = v;

								this.props.onUpdateAction(this.props.action);

								this.setState({
									valueTitle: this.props.action.value,
								});
							}}
						/>
					</div>
				);
			} else {
				const options = this.getColumns(["number", "text"]).map((column) => ({
					label: column.title,
					onMouseOver: () => {
						this.setState({ targetTitle: column.title });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, column),
				}));
				props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
			}
		} else if (this.props.action.action == "board_row.timetrack_start" || this.props.action.action == "board_row.timetrack_stop") {
			const options = this.getColumns(["timetrack"]).map((column) => ({
				label: column.title,
				onMouseOver: () => {
					this.setState({ targetTitle: column.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, column),
			}));
			props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
		} else if (this.props.action.action == "board_row.checkbox_check") {
			const options = this.getColumns(["checkbox"]).map((column) => ({
				label: column.title,
				onMouseOver: () => {
					this.setState({ targetTitle: column.title });
				},
				onMouseOut: () => {
					this.setState({ targetTitle: null });
				},
				onAction: this.changeTarget.bind(this, column),
			}));
			props.updateOptions(this.props.t("trigger.fields.column.title", "Välj kolumn"), options);
		} else if (this.props.action.action == "board_row.push_date") {
			if (this.state.editingValue) {
				props.updateOptions(
					this.props.t("trigger.fields.push_date.title", "Hur långt fram?"),
					[],
					<div>
						<LegacyStack>
							<TextField
								focused
								autoFocus
								value={this.props.action.duration}
								onChange={(v) => {
									this.props.action.duration = v;
									this.props.onUpdateAction(this.props.action);
									let unitTitle = "";
									if (this.props.action.unit == "hours") {
										unitTitle = this.props.t("trigger.fields.push_date.options.hours", "timmar");
									}
									if (this.props.action.unit == "days") {
										unitTitle = this.props.t("trigger.fields.push_date.options.days", "dagar");
									}
									if (this.props.action.unit == "weeks") {
										unitTitle = this.props.t("trigger.fields.push_date.options.weeks", "veckor");
									}
									if (this.props.action.unit == "months") {
										unitTitle = this.props.t("trigger.fields.push_date.options.months", "månader");
									}
									this.setState({
										valueTitle: this.props.action.duration + " " + unitTitle,
									});
								}}
							/>
							<Select
								options={[
									{
										value: "hours",
										label: this.props.t("trigger.fields.push_date.options.hours", "timmar"),
									},
									{
										value: "days",
										label: this.props.t("trigger.fields.push_date.options.days", "dagar"),
									},
									{
										value: "weeks",
										label: this.props.t("trigger.fields.push_date.options.weeks", "veckor"),
									},
									{
										value: "months",
										label: this.props.t("trigger.fields.push_date.options.months", "månader"),
									},
								]}
								value={this.props.action.unit}
								onChange={(v) => {
									this.props.action.unit = v;
									this.props.onUpdateAction(this.props.action);
									let unitTitle = "";
									if (this.props.action.unit == "hours") {
										unitTitle = this.props.t("trigger.fields.push_date.options.hours", "timmar");
									}
									if (this.props.action.unit == "days") {
										unitTitle = this.props.t("trigger.fields.push_date.options.days", "dagar");
									}
									if (this.props.action.unit == "weeks") {
										unitTitle = this.props.t("trigger.fields.push_date.options.weeks", "veckor");
									}
									if (this.props.action.unit == "months") {
										unitTitle = this.props.t("trigger.fields.push_date.options.months", "månader");
									}
									this.setState({
										valueTitle: this.props.action.duration + " " + unitTitle,
									});
								}}
							/>
						</LegacyStack>
					</div>
				);
			} else {
				const options = this.getColumns(["datetime"]).map((column) => ({
					label: column.title,
					onMouseOver: () => {
						this.setState({ targetTitle: column.title });
					},
					onMouseOut: () => {
						this.setState({ targetTitle: null });
					},
					onAction: this.changeTarget.bind(this, column),
				}));
				props.updateOptions(this.props.t("trigger.fields.choose_status.title", "Välj kolumn"), options);
			}
		} else {
			props.updateOptions("", []);
		}
	}

	render() {
		const actionLabel = this.state.actionTitle
			? this.state.actionTitle
			: this.props.action.action
			? this.getActionTitle(this.props.action, this.state.targetTitle, this.state.valueTitle)
			: this.props.t("trigger.placeholder", "gör något");
		return (
			<div
				onClick={(event) => {
					event.stopPropagation();
					if (!this.state.focused) {
						this.props.onFocus();
					}
				}}
				className={"entry" + (this.state.focused ? " focused" : "")}
			>
				<LegacyStack spacing="tight">
					<span className={(this.state.editingTarget ? "editing-target" : "") + (this.state.editingValue ? " editing-value" : "")}>
						{actionLabel}
					</span>
				</LegacyStack>
				{this.props.onRemove ? (
					<span className="clear-button" onClick={this.props.onRemove}>
						<Icon source={CircleCancelMinor} />
					</span>
				) : null}
			</div>
		);
	}
}
export default withTranslation(["trigger", "common"], { withRef: true })(TriggerAction);
