import React, { Component } from "react";
import { EmptyState, Link, Button, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import Page from "src/js/components/page";
import API from "../../API";

import { toastr } from "../../components/toastr.js";
import SkeletonPage from "src/js/components/Skeletons/Page";

class WorkspaceView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: props.match.params.id,
			loading: true,

			form: [],
			children: [],
		};
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.match.params.id != this.state.id) {
			this.fetchChildren(this.state.id);
		}
	}

	componentDidMount() {
		this.fetchChildren(this.state.id);
	}

	fetchChildren(id) {
		this.setState({ loading: true });
		API.get("/api/workspaces/" + id + "/children.json", { params: {} })
			.then((result) => {
				this.setState({ loading: false });
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.state.children = this.getChildren(result.data.workspace);
				this.state.form = result.data.workspace;

				this.setState({ children: this.state.children, form: this.state.form });
			})
			.catch((error) => {
				this.setState({ loading: false });
				toastr.error(error);
			});
	}

	getChildren(workspace) {
		const arr = [];
		if (workspace.children) {
			for (let i = 0; i < workspace.children.length; i++) {
				const ws = workspace.children[i];
				ws.children = this.getChildren(ws);
				arr.push(ws);
			}
		}
		if (workspace.dashboards) {
			for (let i = 0; i < workspace.dashboards.length; i++) {
				arr.push(workspace.dashboards[i]);
			}
		}
		if (workspace.boards) {
			for (let i = 0; i < workspace.boards.length; i++) {
				arr.push(workspace.boards[i]);
			}
		}

		return arr;
	}

	render() {
		if (this.state.loading) {
			return <SkeletonPage />;
		}

		return (
			<Page>
				{this.state.children.length > 0 ? (
					<div>
						<TextField />
					</div>
				) : (
					<EmptyState
						heading={this.props.t("workspace.empty.heading", "Här let det tomt")}
						footerContent={
							<p>
								{this.props.t("workspace.empty.text", "En mapp kan innehålla många boards - vi älskar struktur!")}
								<Link monochrome url="/settings">
									{this.props.t("workspace.empty.link_text", "Klicka här för att se en kort film hur man gör")}
								</Link>
								.
							</p>
						}
						image="/assets/images/emptystate1.svg"
					>
						<p> {this.props.t("workspace.empty.text2", "skapa en board och kom igång med arbetet nu direkt!")}</p>

						<div style={{ height: "100px", marginTop: 15 }}>
							<Button
								primary
								connectedDisclosure={{
									accessibilityLabel: this.props.t("workspace.text3", "Du kan även skapa"),
									actions: [
										{ content: this.props.t("workspace.actions.new_folder", "Skapa ny mapp") },
										{ content: this.props.t("workspace.actions.new_dashboard", "Skapa ny dashboard") },
									],
								}}
							>
								{this.props.t("workspace.actions.new_board", "Skapa ny board")}
							</Button>
							<Button plain destructive>
								{this.props.t("workspace.actions.delete", "Radera arbetsyta")}
							</Button>
						</div>
					</EmptyState>
				)}
			</Page>
		);
	}
}
export default withTranslation(["workspace", "common"], { withRef: true })(WorkspaceView);
