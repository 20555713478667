import React, { Component } from "react";
import axios from "axios";
import { Spinner } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import API from "../../API";
import { toastr } from "../../components/toastr";
import GoalUserIndexItem from "./GoalUserIndexItem";

class GoalsUserIndex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// id: props.match?.params?.id || props.id,
			form: props.location?.state?.goal || props.goal,
			stats: props.location?.state?.stats || props.stats || [],
		};
	}

	componentDidMount() {
		// this.fetchItem(this.props.match?.params?.id || this.props.id);
		this.fetchStats(this.props.match?.params?.id || this.props.id);
	}

	fetchStats(id = this.state.form?.id) {
		if (!id) return null;
		this.setState({ stats: [], loading: true });

		API.get("/api/goals/" + id + "/stats.json?total=1&from=" + this.state.form.start_at + "&to=" + this.state.form.end_at)
			.then((result) => {
				this.setState((c) => ({
					stats: result.data.stats || [],
				}));
			})
			.catch((error) => {
				if (axios.isCancel(error)) {
					return;
				}
				console.error("error:", error);
				toastr.error(error);
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.id !== this.props.id) {
			// if (props.match?.params?.id !== this.state.id) {
			// this.fetchItem(props.match?.params?.id || props.id);
			this.fetchStats(this.props.match?.params?.id || this.props.id);
		}

		if (props.location?.state?.stats || props.stats) {
			this.setState({ stats: props.location?.state?.stats || props.stats });
		}

		this.setState({ form: props.location?.state?.goal || props.goal });
	}

	onChange(index, field, value) {
		// this.state.form.users[index][field] = value;
		if (this.props.onChange) this.props.onChange(index, field, value);
	}

	// saveForm() {
	// 	this.setState({ saving: true });

	// 	axios
	// 		.put("/api/goals/" + this.state.form.id + ".json", this.state.form)
	// 		.then((result) => {
	// 			if (result.data.error) {
	// 				toastr.error(result.data.error);
	// 				return;
	// 			}
	// 			toastr.success(this.props.t("goals.responses.updated", "Uppdaterade målsättning"));
	// 			this.setState({ saving: false, form: result.data.goal });
	// 		})
	// 		.catch((error) => {
	// 			this.setState({ saving: false });
	// 			toastr.error(error);
	// 		});
	// }

	// fetchItem(id = this.state.id, silent) {
	// 	if (!silent) this.setState({ loading: true });

	// 	axios
	// 		.get("/api/goals/" + id + ".json")
	// 		.then((result) => {
	// 			this.setState(
	// 				{
	// 					loading: false,
	// 					form: result.data.goal,
	// 				},
	// 				this.fetchStats.bind(this)
	// 			);
	// 		})
	// 		.catch((error) => {
	// 			toastr.error(error);
	// 		})
	// 		.finally(() => {
	// 			this.setState({ loading: false });
	// 		});
	// }

	getUserSum(user) {
		const stats = this.state.stats?.filter((s) => s.user_id == user.id);
		return stats?.reduce((acc, stat) => acc + stat.sum, 0);
	}

	render() {
		const { users } = this.props.goal || {};

		if (this.state.loading) {
			return (
				<div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "calc(100vh - 300px)" }}>
					<Spinner />
					<p className="loading loading-text">{this.props.t("goals.stats.loading", "Hämtar värden..")}</p>
				</div>
			);
		}

		return (
			// <Page
			// 	title={
			// 		<div style={{ display: "flex", gap: "0.6250rem" }}>
			// 			{title}
			// 			{this.state.loading && <Spinner size="small" />}
			// 		</div>
			// 	}
			// 	fullWidth
			// 	primaryAction={{ content: this.props.t("common.actions.save", "Spara"), onAction: this.saveForm.bind(this), loading: this.state.saving }}
			// 	backAction={{ content: this.props.t("common.actions.back", "Tillbaka"), onAction: () => this.props.history.goBack() }}
			// >
			<StyledTable className="goal-users-table">
				<thead>
					<tr>
						<th>{this.props.t("goals.columns.name", "Namn")}</th>
						<th>{this.props.t("goals.columns.goal", "Målsättning")}</th>
						<th>{this.props.t("goals.columns.next_period", "Nästa periods målsättning")}</th>
						<th>{this.props.t("goals.columns.current_value", "Nuvarande värde")}</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{users
						?.sort((a, b) => this.getUserSum(b?.user) - this.getUserSum(a?.user))
						?.map((user, index) => (
							<GoalUserIndexItem
								goal={this.props.goal}
								key={user.id}
								user={user}
								onChange={this.onChange.bind(this, index)}
								sum={this.getUserSum(user?.user)}
								disabled={this.props.loading}
								removeFormUser={this.props.removeFormUser}
							/>
						))}
				</tbody>
			</StyledTable>
			// </Page>
		);
	}
}
export default withTranslation(["goals", "common"], { withRef: true })(GoalsUserIndex);

const StyledTable = styled.table`
	position: relative;

	th {
		position: sticky;
		top: 55px;
		background: var(--main3);
		z-index: 99;
		padding-block: 0.625rem;

		&:first-of-type {
			text-align: start;
		}
	}
`;
