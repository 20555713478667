import React, { Component } from "react";

import { Banner, FormLayout, TextField } from "@shopify/polaris";
import { withTranslation } from "react-i18next";
import API from "../API";
import { store } from "../store";
import { toastr } from "./toastr.js";
import RemoveModal from "./remove_modal.js";
import Modal from "src/js/components/modal";

class WebIntegrationModal extends Component {
	constructor(props) {
		super(props);
		this.state = { open: props.open, form: props.integration, saving: false };
	}

	UNSAFE_componentWillReceiveProps(props) {
		if (props.open != this.state.open) {
			this.setState({ open: props.open, form: props.integration });
		}
	}

	updateForm(field, value) {
		const formState = this.state.form;
		formState[field] = value;

		this.setState({ form: formState });
	}

	saveForm() {
		this.setState({ saving: true });
		if (this.state.form.id) {
			API.put("/api/integrations/" + this.state.form.id + ".json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("integration.responses.updated", "Uppdaterade integration"));
					this.setState({ saving: false });
					store.dispatch({ type: "UPDATE_INTEGRATION", integration: result.data.integration });
					this.props.onCreate(result.data.integration);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		} else {
			API.post("/api/integrations.json", this.state.form)
				.then((result) => {
					if (result.data.error) {
						toastr.error(result.data.error);
						return;
					}
					toastr.success(this.props.t("integration.responses.created", "Skapade integration"));
					this.setState({ saving: false });
					store.dispatch({ type: "ADD_INTEGRATION", integration: result.data.integration });
					store.dispatch({ type: "ADD_USER_ROLE", role: "ROLE_INTEGRATION_" + result.data.integration.id });
					this.props.onCreate(result.data.integration);
				})
				.catch((error) => {
					this.setState({ saving: false });
					toastr.error(error);
				});
		}
	}

	doRemove() {
		this.setState({ removing: true });
		API.delete("/api/integrations/" + this.state.form.id + ".json")
			.then((result) => {
				if (result.data.error) {
					toastr.error(result.data.error);
					return;
				}
				this.setState({ removing: false, showRemoveModal: false });
				toastr.success(this.props.t("integration.responses.removed", "Integration borttagen"));
				store.dispatch({ type: "REMOVE_INTEGRATION", id: this.state.form.id });
				if (this.props.onClose) {
					this.props.onClose();
				}
				if (this.props.onRemove) {
					this.props.onRemove();
				}
			})
			.catch((error) => {
				this.setState({ removing: false });
				toastr.error(error);
			});
	}

	render() {
		const type = this.props.integration && this.props.integration.type;
		const secondaryActions = [];

		secondaryActions.push({
			content: this.props.t("common.actions.close", "Stäng"),
			onAction: this.props.onClose,
		});

		if (this.state.form.id && store.getState().user.roles.indexOf("ROLE_ADMIN")) {
			secondaryActions.push({
				content: this.props.t("common.actions.remove", "Ta bort"),
				destructive: true,
				onAction: this.props.onClose,
				onClick: () => {
					this.setState({ showRemoveModal: true });
				},
			});
		}

		return (
			<div>
				<Modal
					open={this.state.open}
					onClose={this.props.onClose}
					title={
						this.state.form.id
							? this.props.t("integration.titles.change", "Ändra integration")
							: this.props.t("integraion.titles.new", "Skapa integration")
					}
					secondaryActions={secondaryActions}
					primaryAction={
						type === "web" && {
							content: this.state.form.id ? this.props.t("common.actions.update", "Uppdatera") : this.props.t("common.actions.create", "Skapa"),
							loading: this.state.saving,
							disabled: !this.state.form.title,
							onAction: this.saveForm.bind(this),
						}
					}
				>
					<Modal.Section>
						<FormLayout>
							<TextField
								label={this.props.t("integration.fields.title.label", "Namn")}
								disabled={type !== "web"}
								value={this.state.form.title}
								onChange={this.updateForm.bind(this, "title")}
							/>
							{type === "web" && (
								<Banner status="warning">
									<p>{this.props.t("integration.fields.text.reminder_ssl", "Tänk på att adresserna måste ha stöd för SSL (https://)")}</p>
								</Banner>
							)}
							{type === "web" && (
								<TextField
									label={this.props.t("integration.fields.dashboard_url.label", "Webbadress till dashboard")}
									placeholder="https://dindoman.se/dashboard.php"
									value={this.state.form.dashboard_url}
									helpText={this.props.t("integration.fields.dashboard_url.helptext", "Syns inne på dashboard")}
									onChange={(value) => {
										this.state.form.dashboard_url = value.trim();
										this.setState({ form: this.state.form });
									}}
								/>
							)}
							{type === "web" && (
								<TextField
									label={this.props.t("integration.fields.iframe_url.label", "Webbadress till kontakt iframe")}
									placeholder="https://dindoman.se/iframe.php"
									value={this.state.form.iframe_url}
									helpText={this.props.t("integration.fields.iframe_url.helptext", "Denna syns inne på kontakten")}
									onChange={(value) => {
										this.state.form.iframe_url = value.trim();
										this.setState({ form: this.state.form });
									}}
								/>
							)}
							<TextField
								label={this.props.t("integration.fields.show_if.label", "Visa om")}
								value={this.state.form.show_if}
								onChange={this.updateForm.bind(this, "show_if")}
							/>
							{type === "web" && (
								<TextField
									label={this.props.t("integration.fields.iframe_url.label", "Webbadress till menyn")}
									placeholder="https://dindoman.se/fullscreen.php"
									value={this.state.form.fullscreen_url}
									helpText={this.props.t("integration.fields.iframe_url.helptext", "Syns i vänstermenyn")}
									onChange={(value) => {
										this.state.form.fullscreen_url = value.trim();
										this.setState({ form: this.state.form });
									}}
								/>
							)}
						</FormLayout>
					</Modal.Section>
				</Modal>
				<RemoveModal
					open={this.state.showRemoveModal}
					onClose={() => {
						this.setState({ showRemoveModal: false });
					}}
					loading={this.state.removing}
					onConfirm={this.doRemove.bind(this)}
					numItems={1}
					objectName={this.state.form.title}
					resourceName={{
						singular: this.props.t("integration.singular", "integration"),
						plural: this.props.t("integration.plural", "integrationer"),
					}}
				/>
			</div>
		);
	}
}
export default withTranslation(["integration", "common"], { withRef: true })(WebIntegrationModal);
